import { generateUUID, HandlerUrlParams } from '@/utis/common';
import store from "@/store";
import { getConfig } from "@/api/api";
export var HandlerPremiseTask = function () {
    var platform = HandlerUrlParams('platform');
    var token = HandlerUrlParams('token');
    var utmSource = HandlerUrlParams('utm_source');
    var pid = HandlerUrlParams('pid');
    var kwai_click_id = HandlerUrlParams('click_id');
    var tt_click_id = HandlerUrlParams('ttclid');
    var fb_click_id = HandlerUrlParams('fbclid');
    var publish_channel = HandlerUrlParams('publish_channel');
    var sub_channel = HandlerUrlParams('sub_channel');
    var reward = HandlerUrlParams('reward');
    var recharge_from = HandlerUrlParams('recharge_from');
    var invited_type = HandlerUrlParams('invited_type');
    var group_no = HandlerUrlParams('group_no');
    // console.log(token,utmSource,pid,kwai_click_id,tt_click_id,fb_click_id)
    if (token)
        store.commit('setToken', token);
    if (utmSource && pid)
        localStorage.setItem('BuryingParams', publish_channel + '___' + pid);
    if (kwai_click_id)
        localStorage.setItem('click_id', kwai_click_id);
    if (tt_click_id)
        localStorage.setItem('click_id', tt_click_id);
    if (fb_click_id)
        localStorage.setItem('click_id', fb_click_id);
    if (platform)
        localStorage.setItem('platform', platform);
    if (sub_channel)
        localStorage.setItem('sub_channel', sub_channel);
    if (reward) {
        localStorage.setItem('reward', reward);
    }
    else {
        localStorage.setItem('reward', '');
    }
    if (recharge_from) {
        localStorage.setItem('recharge_from', recharge_from);
    }
    else {
        localStorage.setItem('recharge_from', '');
    }
    if (invited_type) {
        localStorage.setItem('invited_type', invited_type);
    }
    else {
        localStorage.setItem('invited_type', '');
    }
    if (group_no) {
        localStorage.setItem('group_no', group_no);
    }
    else {
        localStorage.setItem('group_no', '');
    }
    if (window.location.href.indexOf('?') > -1) {
        localStorage.setItem('source', window.location.href);
    }
    handlerUUID();
    store.commit('setClient', judgeClient());
    // 异步任务
    GetConfig();
};
var GetConfig = function () {
    getConfig({
        invite_activity: HandlerUrlParams('invite_activity') ? HandlerUrlParams('invite_activity') : '',
        invite_code: HandlerUrlParams('code') ? HandlerUrlParams('code') : ''
    }).then(function (res) {
        store.commit('setConfig', res);
    });
};
var judgeClient = function () {
    var client = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) && navigator.vendor.indexOf('Apple') !== -1) {
            client = 'ios__safari';
        }
        else
            client = 'ios';
    }
    else if (/(Android)/i.test(navigator.userAgent)) {
        client = 'Android';
    }
    else {
        client = 'PC';
    }
    return client;
    //安卓手机浏览器 Android
    //安卓webview  Android
    //苹果手机浏览器 ios__safari
    //苹果webview ios
};
var handlerUUID = function () {
    var only_uuid = localStorage.getItem('only_uuid');
    if (!only_uuid) {
        var new_uuid = generateUUID();
        localStorage.setItem('only_uuid', new_uuid);
    }
};
// const getCurrentChannel = () => {
//     // 请求渠道
//     getChannel().then(res => {
//         if (res.data) store.commit('setChannel', res.data);
//         store.dispatch('onLoading', false);
//         document.getElementById('init-loading').style.display = 'none';
//         document.getElementById('app').style.display = 'block';
//     })
// }
