<script>
import {defineComponent} from 'vue'
import {getRechargeTaskReward, rechargeTaskConfigs} from "@/api/api";
import {AmountFixed} from "@/utis/common";
import {mapState} from "vuex";

export default defineComponent({
  name: "TaskPopUp",
  data() {
    return {
      show: false,
      confirm: false,
      tasks: []
    }
  },
  computed: {
    ...mapState(['token', 'openCodingTask', 'homePopOrder'])
  },
  watch: {
    openCodingTask(val) {
      if (val && this.token) {
        let recordTime = parseInt(localStorage.getItem('active_pop_show'));
        let currentTime = new Date().getTime();
        if (recordTime && currentTime - recordTime < 24 * 60 * 60 * 1000 && this.homePopOrder === 4) {
          this.$store.commit('setOpenCodingTask', false);
          return;
        }
        this.show = true;
        rechargeTaskConfigs().then(res => {
          this.tasks = res;
        }).catch(() => {})
      } else this.$store.commit('setOpenCodingTask', false);
    },
    show(val) {
      if (!val) this.$store.commit('setOpenCodingTask', false);
    }
  },
  created() {
    if (!this.token) {
      this.show = false;
      return;
    }

    rechargeTaskConfigs().then(res => {
      this.tasks = res;
    }).catch(() => {})
  },
  mounted() {
  },
  methods: {
    AmountFixed,
    notShowing() {
      if (this.confirm) {
        let currentTime = new Date().getTime();
        localStorage.setItem('active_pop_show', currentTime.toString())
      }
      this.show = false;
    },
    reward(taskId) {
      getRechargeTaskReward({
        task_id: taskId
      }).then(res => {
        rechargeTaskConfigs().then(res => {
          this.tasks = res;
          this.$store.dispatch('UserInfo');
        }).catch(() => {})
      }).catch(() => {})
    }
  },
})
</script>

<template>
  <div class="task-context">
    <van-popup v-model="show" class="task-block">
      <div class="task-prompt">
        <div class="task-prompt-title">
          Bônus de Depósito Acumulado
        </div>
        <van-icon class="task-prompt-close" name="cross" size="26" @click="show = false" />

        <div class="task-list-block">
          <div class="task-item" v-for="item in tasks">
            <div class="task-item-row">
              <div class="task-item-dec">{{item.task_desc}}</div>
              <div class="task-item-do" :class="item.status === 2 ? '' : 'task-disable'" @click="reward(item.id)">Prosseguir</div>
            </div>
            <div class="task-item-row">
              <div class="progress-bar">
                <div class="schedule" :style="'width:' + parseFloat(item.total_recharge) / parseFloat(item.need_recharge)"></div>
                <div class="progress-bar-text">{{item.total_recharge + '/' + item.need_recharge}}</div>
              </div>
              <div class="task-reward">
                <img src="../../public/image/common/R$.png" alt="">
                <span>{{AmountFixed(item.reward)}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="task-select-all">
          <van-checkbox v-model="confirm" checked-color="#22A714" class="task-check">Não mostrar novamente<br> nesta sessão</van-checkbox>
          <div class="task-confirm" @click="notShowing">Fechar</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
.task-block {
  width: 80%;
  height: 1000px;
  background-color: #202430;
  border-radius: 20px;
  color: #FFFFFF;
  padding: 20px;
  overflow-y: hidden;
}
.task-list-block {
  height: 760px;
  overflow-y: scroll;
  font-size: 28px;
}
.task-prompt-title {
  font-size: 30px;
  text-align: center;
  margin: 30px 0;
}
.task-prompt-close {
  position: absolute;
  right: 30px;
  top: 46px;
}
.task-check {
  font-size: 28px;
}
.task-select-all {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.task-confirm {
  width: 160px;
  height: 60px;
  font-size: 30px;
  background-color: #22A714;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.task-item-do {
  width: 140px;
  background-color: #22A714;
  padding: 12px 10px;
  color: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.task-disable {
  background-color: #707070;
}
.progress-bar {
  position: relative;
  width: calc(100% - 200px);
  height: 30px;
  background-color: black;
  border-radius: 16px;
  .schedule {
    background-color: #22A714;
    border-radius: 16px;
    height: 100%;
  }
}
.task-reward {
  width: 130px;
  color: #FFD200;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    margin-right: 10px;
  }
}
.task-item {
  background-color: #2E3444;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;

  .task-item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .task-item-row:first-child {
    margin-bottom: 30px;
  }
}
.progress-bar-text {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}

::v-deep .van-checkbox__label {
  color: #8B91A3;
}
</style>