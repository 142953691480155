import {
    post
} from './WebSocketServer'

// 获取游戏前置配置
export const getConfig = p => post('AllConfigs', p)
// 渠道配置
export const getChannel = p => post('DomainChannel', p)
// 下注任务配置（免费币）
export const betsTaskFreeConfig = p => post('BetsTaskFreeConfig', p)


/**
 *
 * 账户操作相关类
 *
 **/

// 登录
export const login = p => post('Login', p)
// 注册
export const register = p => post('RegisterEmail', p)
// 获取手机验证码
export const getCode = p => post('account/get_code', p)
// 绑定pix
export const bindPix = p => post('BindPix', p)
// 获取pixinfor
export const getPix = p => post('GetPixInfo', p)
// 账号绑定google
export const bindGoogle = p => post('BindGoogle', p)
// Google登录
export const authGoogle = p => post('AuthGoogle', p)
// 修改密码
export const modifyPwd = p => post('ModifyPwd', p)
// 充值
export const recharge = p => post('RechargeReq', p)
// 提现
export const withdraw = p => post('WithdrawReq', p)
// 切换当前货币
export const changeBetType = p => post('ChangeBetType', p)
// 免费币兑换
export const exchangeFreeCoin = p => post('ExchangeFreeCoin', p)
// 用户换头像
export const modifyAvatar = p => post('ModifyAvatar', p)
// 用户修改昵称
export const modifyNickName = p => post('ModifyNickName', p)
// 用户修改pix
export const modifyPixAccount = p => post('ModifyPixAccount', p)
// 充值任务配置
export const rechargeTaskConfigs = p => post('RechargeTaskConfigs', p)
// 根据suid获取token
export const getTokenBySuid = p => post('GetTokenBySuid', p)
// 领取Tg 每日奖励
export const claimTgRewards = p => post('ClaimTgRewards', p)
// 获取tg社群红包链接
export const getTgRedPackLink = p => post('GetTgRedPackLink', p)
// Tg今日奖励详情
export const tgTodayReward = p => post('TgTodayReward', p)

/**
 *
 * 个人数据相关
 *
 **/

// 玩家信息
export const getUserInfo = p => post('UserInfo', p)
// 交易记录
export const getTradeRecord = p => post('GetTradeRecord', p)
// 游戏记录
export const getPlayRecord = p => post('GetGameRecord', p)
// 删除邮件
export const delMail = p => post('DelEmail', p)
// 邮件列表
export const getMailList = p => post('GetEmailList', p)
// 获取推广消息
export const getPromoteMsg = p => post('GetPromoteMsg', p)
// 单条推广消息
export const getPromoteDetail = p => post('GetPromoteDetail', p)
// 接收服务端邮件消息
export const receiveMailNotify = p => post('EmailNotify', p)
// 阅读邮件
export const reqReadMail = p => post('ReqReadMail', p)
// 佣金记录
export const commissionRecord = p => post('GetCommissionRecord', p)
// 获取未完成订单
export const getUnFinishedOrders = p => post('GetUnfinishedOrders', p)
// 签到配置
export const getSignConfig = p => post('SignInActInfo', p)
// 每日签到
export const doSignIn = p => post('DoSignIn', p)
// vip签到奖励
export const vipSign = p => post('VipSign', p)
// 显示签到会获得的奖励
export const getVipSignInfo = p => post('GetVipSignInfo', p)
// 浏览埋点上报
export const channelVisit = p => post('ChannelVisit', p)
// 获取提现进度
export const getWithdrawProgress = p => post('GetWithdrawProgress', p)
// 玩家领取VIP升级奖励
export const reqVipLvUpReward = p => post('ReqVipLvUpReward', p)
// 领取下载app奖励
export const getAppLoginBonus = p => post('GetAppLoginBonus', p)
// vip升级权益
export const vipUpRights = p => post('VipUpRights', p)



/**
 *
 * 活动相关
 *
 **/

// 获取玩家能参与的活动
export const getUserActivities = p => post('GetUserActivities', p)
// 获取任务奖励
export const getTaskReward = p => post('GetTaskReward', p)
// 玩家任务列表
export const getTaskList = p => post('GetTasks', p)
// 打码成就任务列表
export const getBetTaskConfig = p => post('BetsTaskConfigs', p)
// 更新下注的方式
export const updateBetSwitch = p => post('UpdateBetSwitch', p)
// 切换正在进行中的任务
export const switchTaskPending = p => post('SwitchTaskPending', p)
// 获取下注的方式
export const getBetSwitch = p => post('GetBetSwitch', p)
//锦标赛配置
export const jackpotConfig = p => post('JackpotConfigs', p)
//锦标赛排行榜
export const jackpotRanks = p => post('JackpotRanks', p)
//锦标赛游戏
export const jackpotGames = p => post('JackpotGames', p)
//锦标赛首页榜单展示
export const jackpotRankBrief = p => post('JackpotShow', p)

//玩家转盘信息
export const getUserWheelInfo = p => post('RouletteInfo', p)
//转盘排行榜
export const getWheelRank = p => post('RouletteRank', p)
//转转盘
export const startWheelReward = p => post('DoRoulette', p)

//月卡活动配置
export const getMonthCardConf = p => post('GetMonthCardConf', p)
//玩家月卡数据
export const userMonthCardInfo = p => post('UserMonthCardInfo', p)
//购买月卡
export const buyMonthCard = p => post('BuyMonthCard', p)
//获取月卡奖励
export const getCardRewards = p => post('GetCardRewards', p)
//兑换码兑换
export const exchangeRedeemCode = p => post('ExchangeRedeemCode', p)
//获取玩家红包配置
export const getUserRedBagInfo = p => post('GetUserRedBagInfo', p)
//获取玩家老虎机配置
export const getUserMarketRoulette = p => post('GetUserMarketRoulette', p)
//充值红包成功激活推送
export const exchangeRechargeRedBag = p => post('ExchangeRechargeRedBag', p)
//开始老虎机
export const doMarketRoulette = p => post('DoMarketRoulette', p)
//开红包
export const openRedBag = p => post('OpenRedBag', p)
//老虎机-跑马灯
export const reqMarketMarquee = p => post('ReqMarketMarquee', p)
//充值拿红包
export const rechargeRedBag = p => post('RechargeRedBag', p)

//获取玩家存钱罐
export const reqUserPot = p => post('ReqUserPot', p)
//兑换存钱罐里的钱
export const exchangeUserPot = p => post('ExchangeUserPot', p)
//获取打码挑战任务奖励(奖励免费币)
export const getChallengeFreeTaskReward = p => post('GetChallengeFreeTaskReward', p)

//获取玩家转盘数据
export const reqUserPddInfo = p => post('ReqUserPddInfo', p)
//玩家转pdd转盘
export const reqUserPddSpin = p => post('ReqUserPddSpin', p)
//玩家领奖
export const reqUserPddReward = p => post('ReqUserPddReward', p)
//玩家放弃pdd活动
export const reqUserPddCancel = p => post('ReqUserPddCancel', p)
//获取累充任务奖励
export const getRechargeTaskReward = p => post('GetRechargeTaskReward', p)
//获取加油/加速礼包信息
export const getUserPack = p => post('GetUserPack', p)
//玩家购买加油/加速礼包
export const userBuyPack = p => post('UserBuyPack', p)
// 获取破产礼包配置
export const getUserGreatePack = p => post('GetUserGreatePack', p)
// 购买破产礼包
export const userBuyGreatePack = p => post('UserBuyGreatePack', p)
// 保险箱列表
export const getBxPack = p => post('GetBxPack', p)
// 领取保险箱奖励
export const claimBxBonus = p => post('ClaimBxBonus', p)
// newcashback vip详情
export const getVipDetail = p => post('GetVipDetail', p)
// pdd大转盘
export const pddTurntable = p => post('PddTurntable', p)
// pdd  大转盘 spin
export const pddSpinTurntable = p => post('PddSpinTurntable', p)
// pdd  领取大转盘奖励
export const pddGetReward = p => post('PddGetReward', p)
// pdd 指定手机号邀请(手机号列表)
export const pddFindInvitePhones = p => post('PddFindInvitePhone', p)
// 获取用户优惠券(充值)
export const getUserCoupons = p => post('GetUserCoupons', p)

/**
 *
 * 代理相关
 *
 **/
// 佣金结算
export const exchangeCommission = p => post('ExchangeCommission', p)
// vip返利兑换
export const getVipBack = p => post('GetCashBack', p)
// 获取用户代理
export const getUserAgent = p => post('UserAgentInfo', p)
// 代理成就任务配置
export const getAgentConfig = p => post('AgentTaskConfigs', p)
// 领取代理奖励
export const claimShareReward = p => post('GetAgentTaskReward', p)
// 领取投注奖励
export const claimBetReward = p => post('GetChallengeTaskReward', p)
// 转换短链接
export const convertShortUrl = p => post('GetUserInviteUrl', p)
// 平台总佣金
export const platformTotalCommission = p => post('PlatformTotalCommission', p)


/**
 *
 * 游戏相关
 *
 **/

// 游戏列表
export const gameList = p => post('GetGameList', p)
// 搜索游戏
export const searchGame = p => post('SearchGame', p)
// 获取游戏地址
export const getGamePlayUrl = p => post('GetGameUrl', p)
// 总排行榜
export const getHallRank = p => post('GetTotalRank', p)

// 领取人头费

export const GetAgentInviteCommission = p => post('GetAgentInviteCommission', p)

// 绑定tg
export const bindTg = p => post('BindTg', p)
// 红点提示
export const getRedDot = p => post('GetRedDot', p)
// vip挑战-参加活动
export const joinVipChallenge = p => post('JoinVipChallenge', p)
// vip挑战-活动详情
export const getVipChallengeDetail = p => post('VipChallenge', p)

//代理活动配置
export const getAgentActivityConfig = p => post('GetAgentActivityConfig', p)
//下级任务列表
export const childAgentTasks = p => post('ChildAgentTasks', p)
//领取奖励
export const getAgentActBonus = p => post('GetAgentActBonus', p)
//被邀请人任务
export const invitedTask = p => post('InvitedTask', p)
//被邀请人领取奖励
export const getInvitedBetBonus = p => post('GetInvitedBetBonus', p)
//提现V2
export const withdrawV2 = p => post('WithdrawV2', p)
//下级任务列表(分页)
export const childTaskPageList = p => post('ChildTaskPageList', p)
//批量领取
export const getAllAgentActBonus = p => post('GetAllAgentActBonus', p)
//领取安装pwa bonus
export const installPwaBonus = p => post('InstallPwaBonus', p)
//爆奖
export const bigWin = p => post('BigWin', p)