<script>
import { defineComponent } from "vue";
import { SplitGamePosterUrl } from "@/utis/common";
import { mapState } from "vuex";
import { getGamePlayUrl } from "@/api/api";
import { dotGTag } from '@/native/native';

export default defineComponent({
    name: "GameCard",
    props: ["isHot", "json", 'isYC'],
    data() {
        return {
            isReadyPlay: false,
            isReadyPlay: false,
            requestGamePending: false,
            gameInfo: {},
        };
    },
    computed: {
        ...mapState(["playingGameName", "paySuccess"]),
    },
    watch: {
        playingGameName(val) {
            this.isReadyPlay =
                val ===
                this.json.name + "_" + this.json.game_id + "_" + this.json.channel;
        },
        paySuccess() {
            if (this.isReadyPlay) {
                console.log("刷新Url", this.isReadyPlay)
                this.gameInfo.valid = false;
                this.isReadyPlay = false;
            }
        }
    },
    created() {
        // console.log("GameCard created", this.json)
    },
    methods: {
        SplitGamePosterUrl,
        readyPlay() {
            if (!localStorage.getItem("token")) {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
                return;
            }
            if (this.json.active_status === 1) return;
            if (this.json.game_typ === 14 || this.json.game_typ === 15) {
                this.$store.dispatch("toast", {
                    show: true,
                    text: "ocê precisa alcançar o VIP8 para poder entrar no jogo.",
                    type: "failed",
                });
                return;
            }

            this.$store.commit("setPlayingGameName", "");
            this.requestGamePending = true;
            getGamePlayUrl({
                gameId: this.json.game_id,
                isTail: false,
            })
                .then((res) => {
                    dotGTag('join_group', { 'group_id': this.json.game_id })
                    if (res) {
                        this.$store.commit(
                            "setPlayingGameName",
                            this.json.name + "_" + this.json.game_id + "_" + this.json.channel
                        );

                        this.requestGamePending = false;
                        this.gameInfo = res;
                        this.gameInfo.game_id = this.json.game_id;
                        if (res.pack) {
                            this.$store.commit("setGameRechargePopup", this.json.game_id);
                        }
                        if (res.valid) {
                            this.$store.commit("setNotAllowGame", true);
                            return;
                        }
                        if (res.cash > 0) {
                            this.$store.commit(
                                "setPartMoneyGame",
                                this.json.game_id + "__" + res.cash + "__" + Math.random()
                            );
                        }
                    }
                })
                .catch(() => {
                    this.requestGamePending = false;
                });
        },
        doPlay() {

            if (Object.keys(this.gameInfo).length === 0) {
                this.isReadyPlay = false;
                return;
            }
            if (this.gameInfo.valid) {
                this.$store.commit("setNotAllowGame", true);
                return;
            }
            if (this.gameInfo.blank) {
                this.$router.push({
                    name: "play",
                    params: { url: "pgGameId__" + this.gameInfo.game_id },
                });
            } else {
                if (this.gameInfo.cash > 0) {
                    this.$store.commit(
                        "setPartMoneyGame",
                        this.gameInfo.game_id +
                        "__" +
                        this.gameInfo.cash +
                        "__" +
                        Math.random()
                    );
                } else {
                    this.$router.push({
                        name: "play",
                        params: { url: this.gameInfo.url },
                    });
                }
            }
        },
    }
});
</script>

<template>
    <div class="game-card">
        <div v-if="json.id">
            <img v-if="isHot" class="game-hot-tag" src="../../public/image/common/hot-tag.png" alt />
            <div @click="readyPlay">
                <img
                    :class="isReadyPlay || requestGamePending ? 'image-filter' : ''"
                    :src="SplitGamePosterUrl(json)"
                    alt
                />
                <div class="zhezhao" v-if="isYC">
                    <div class="zz-b">
                        <span class="zz-zhanghao">{{ json['nickname'] }}</span>
                        <span class="zz-line"></span>
                        <div class="zz-3b">
                            <span>R$ {{ json['bet'] }}</span>
                            <img src="../../public/image/home/lvsejiantou.webp" />
                            <span>R$ {{ json['win_amount'] }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="game-maintenance" v-show="json.active_status === 1">
                <img src="../../public/image/common/game-maintenance.png" alt />
                <span>Em manutenção</span>
            </div>

            <div class="game-play-loading" v-show="requestGamePending">
                <van-loading />
            </div>

            <img
                v-show="isReadyPlay"
                @click="doPlay"
                class="play-btn"
                src="../../public/image/common/playBtn.png"
                alt
            />

            <span v-show="isReadyPlay" class="top_name">{{ json.name }}</span>
            <span v-show="isReadyPlay" class="bottom_types">
                {{
                json.channel === 1
                ? "PragmaticPlay"
                : json.channel === 2
                ? "Pocket Games"
                : ""
                }}
            </span>
        </div>
        <img
            v-if="!json.id"
            @click="$router.push('/gameShop/' + json.channel)"
            src="../../public/image/home/viewAllGame.png"
            alt
        />
    </div>
</template>

<style scoped lang="less">
.zhezhao {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #3333330a, #000000da);

    position: absolute;
    top: 0;
    color: #fff;

    .zz-b {
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        font-size: 24px;
        display: flex;
        flex-direction: column;

        .zz-zhanghao {
            width: 100%;
            text-align: center;
        }

        .zz-line {
            width: 206px;
            margin: 10px auto;
            background: #ffffff8a;
            height: 2px;
        }

        .zz-3b {
            width: calc(100% - 20px);
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            font-size: 20px;
            color: #27ea47;
            font-weight: bold;

            :nth-child(2) {
                width: 18px;
                height: 22px;
            }

            :nth-child(3) {
                width: 120px;
                height: 26px;
                background: #14362c;
                border-radius: 5px;
                text-align: center;
                line-height: 26px;
                white-space: nowrap;
            }
        }
    }
}
.game-card {
    > div {
        border-radius: 20px;
        position: relative;
        min-height: 260px;
        background-color: #213547;
        overflow: hidden;
    }
    @keyframes rolled {
        0% {
            left: -150px;
        }

        100% {
            left: 920px;
        }
    }
    .game-maintenance {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000bf;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0;
        img {
            width: 60px;
            margin-bottom: 20px;
        }
        span {
            font-size: 24px;
            color: #94bde3;
            font-weight: 700;
        }
    }
    img {
        width: 100%;
        border-radius: 20px;
        vertical-align: middle;
    }
}
.game-play-loading {
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.game-hot-tag {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 80px !important;
    height: auto !important;
}
.play-btn {
    width: 100px !important;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    animation: play-scale 200ms ease-in;
}
.top_name {
    display: block;
    width: 110px !important;
    position: absolute;
    left: calc(50% - 55px);
    top: 10px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #94bde3;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.bottom_types {
    display: block;
    width: 100% !important;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #94bde3;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}
.image-filter {
    filter: brightness(0.2);
}
</style>