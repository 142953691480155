import { render, staticRenderFns } from "./NetWork.vue?vue&type=template&id=678ff323&scoped=true"
import script from "./NetWork.vue?vue&type=script&setup=true&lang=ts"
export * from "./NetWork.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NetWork.vue?vue&type=style&index=0&id=678ff323&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678ff323",
  null
  
)

export default component.exports