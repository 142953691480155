<template>
    <div class="myDiv" v-if="isShow">
        <div class="outbox">
            <div class="ob-qis1">
                <img src="../../../public/image/dazhuanpan/qian1.webp" alt />
                <span>Receba R$ 100 de Graça</span>
            </div>
            <div class="ob-qis2">
                <img src="../../../public/image/wallet/paymentType.png" alt />
                <span>SAQUE RÁPIDO</span>
            </div>
            <p class="ob-qis3-p">Parabéns pela sua boa sorte, você ganhou</p>
            <div class="ob-qis3">
                <img src="../../../public/image/dazhuanpan/qian2.webp" alt />
                <span>R$ {{ progress_bonus }}</span>
            </div>
            <van-progress class="ob-qis4" :percentage="jindu+''" />
            <div class="ob-qis5">
                Ainda e necessário
                <span>{{ lastR }}</span> para sacar para o PIX
            </div>
            <div class="ob-qis6" @click="jumpToIndex">Reivindique mais para sacar</div>
            <div class="ob-qis7">
                <div class="ob-qis7-content" :class="animate?'content-margin20':'content-margin0'">
                    <div class="ob-qis7-item" v-for="(item,index) in computedWRData" :key="index">
                        <span>{{ item.phone }}</span>
                        <span>{{ item.name }}</span>
                        <span>+R$ {{ item.withdraw_bonus }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { pddTurntable } from '@/api/api';

export default {
    name: '',
    components: {},
    props: ['message'],
    data() {
        return {
            isShow: false,
            animate: false,
            items: [
                //消息列表对应的数组
                { name: "1" },
                { name: "2" },
                { name: "3" },
                { name: "4" },
                { name: "5" },
                { name: "6" },
                { name: "7" }
            ],
            timer1: null,
            progress_bonus: 0,
            jindu: 0,
            lastR: 0,
            computedWRData: [],
            timer: null,
        }
    },
    watch: {
        showDaZhuanPanResult: {
            handler(val) {
                if (val) {
                    this.isShow = val;
                    this.getTurnCfg();
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(["showDaZhuanPanResult"]),
    },
    methods: {
        getTurnCfg() {
            pddTurntable()
                .then(res => {
                    console.log("转盘配置=====>", res)
                    this.target_bonus = parseFloat(res.target_bonus);
                    this.progress_bonus = parseFloat(res.progress_bonus);
                    if (this.target_bonus != 0) {
                        this.targetValue = this.progress_bonus / this.target_bonus * 100;
                        this.updateProgress();
                        console.error(11111)
                    }
                    this.lastR = (this.target_bonus - this.progress_bonus).toFixed(2);
                    this.computedWRData = res.withdraw_record;
                })
                .catch(err => {
                    console.error("转盘配置错误=====>", err)
                })
        },
        updateProgress() {
            this.timer = setInterval(() => {
                // 每次更新进度
                this.jindu += 1;
                // 达到目标值后停止更新
                if (this.jindu >= this.targetValue) {
                    clearInterval(this.timer);
                    this.jindu = this.targetValue; // 确保最终值为目标值
                }
            }, 10);
        },

        jumpToIndex() {
            this.$store.commit("setShowDaZhuanPanResult", false);
            this.$store.state.isemit = false;
            this.$store.commit("setShowDaZhuanPanIndex", true);
            this.$emit('closed');
        },
        scroll1() {
            this.animate = true;
            setTimeout(() => {
                this.computedWRData.push(this.computedWRData[0]);
                this.computedWRData.shift();
                // this.animate = false;// 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
                this.animate = false;
            }, 500);
        },
    },
    created() {

    },
    mounted() {
        this.timer1 = setInterval(this.scroll1, 2000);

    },
    updated() {

    },
    beforeDestroy() {
        clearInterval(this.timer1);
    }
}
</script>
<style lang="less" scoped>
.myDiv {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat-VF;

    .outbox {
        width: 650px;
        border-radius: 10px;
        background: linear-gradient(#0f1623, #094456);
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: eject-pop 0.3s ease-in-out;
        padding-bottom: 30px;
        .ob-qis1 {
            display: flex;
            align-items: center;
            width: calc(100% - 40px);
            color: #f9c918;
            font-size: 30px;
            padding: 20px;
            font-weight: 600;
            > img {
                width: 24px;
                height: 29px;
                margin-right: 15px;
            }
        }
        .ob-qis2 {
            display: flex;
            align-items: center;
            color: white;
            font-size: 40px;
            padding: 0 20px;
            font-weight: 600;
            > img {
                padding-right: 10px;
            }
        }
        .ob-qis3-p {
            padding: 15px 0 25px 0;
            color: #8192a4;
            font-size: 26px;
        }
        .ob-qis3 {
            display: flex;
            align-items: center;
            font-size: 40px;
            padding: 0 20px;
            font-weight: 600;
            width: 70%;
            height: 100px;
            background: #06090f;
            border-radius: 15px;
            > img {
                padding: 0 40px;
                width: 39px;
                height: 48px;
            }
            > span {
                font-size: 60px;
                font-weight: 700;
                color: #ff3230;
            }
        }
        .ob-qis4 {
            width: 580px;
            height: 14px;
            margin-top: 80px;
            ::v-deep .van-progress__pivot {
                position: absolute;
                top: -10px;
                background: #0d2a39;
            }
        }
        .ob-qis5 {
            font-size: 25px;
            color: #f9c918;
            padding: 25px;
            font-weight: 600;
            > span {
                color: #ff3230;
            }
        }
        .ob-qis6 {
            width: 580px;
            height: 90px;
            background: #ffc500;
            border-radius: 13px;
            color: #2e0000;
            font-size: 35px;
            text-align: center;
            line-height: 90px;
            font-weight: 600;
        }
        .ob-qis7 {
            margin-top: 30px;
            width: 100%;
            height: 160px;
            overflow: hidden;
            // transition: all 0.5s;
            display: flex;
            flex-direction: column;
            align-items: center;

            .ob-qis7-content {
                width: calc(100% - 40px);
            }

            .ob-qis7-item {
                width: 100%;
                height: 30px;
                font-size: 25px;
                color: white;
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                > span:nth-child(3) {
                    color: #ff3230;
                    font-weight: 600;
                }
            }
        }

        .close-panel {
            position: absolute;
            bottom: -100px;
        }
    }
}

.content-margin20 {
    margin-top: -40px;
    transition: all 0.5s;
}
.content-margin0 {
    margin-top: 0px;
}

@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>