<template>
    <div class="formArr">
        <div style="margin-bottom: 19px;" v-if="inorup === 'up'">
            <img style="width: 100%;" src="../../public/image/login/register-banner.png" alt />
        </div>
        <div class="login-tabs">
            <span
                :class="activeIndex === index ? 'activeClass' : 'defaultClass'"
                v-for="(item, index) in loginChannel"
                :key="index"
                @click="change_tabs(item, index)"
            >{{ item.name }}</span>
        </div>

        <div class="input-title" v-if="activeIndex">Email</div>
        <div class="input-title" v-else>Phone</div>

        <div
            class="email_box"
            :class="errorList.includes('email') ? 'errorStyle' : ''"
            v-if="activeIndex"
        >
            <van-icon size="20px" name="contact" class="pre_pics" />
            <input
                type="email"
                placeholder="Por favor, insira o seu e-mail"
                v-model="email"
                @focus="myFocus()"
            />
        </div>

        <div
            class="email_box"
            :class="errorList.includes('phone') ? 'errorStyle' : ''"
            v-if="!activeIndex"
        >
            <van-icon size="20px" name="contact" class="pre_pics" />
            <div class="preNumber">+55</div>
            <input
                type="number"
                onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                placeholder="Digite o número de celular"
                oninput="if (value.length > 11) value = value.slice(0,11)"
                v-model="phone"
                @focus="myFocus()"
            />
        </div>

        <div class="input-title">Password</div>
        <div class="pwd_box" :class="errorList.includes('psw') ? 'errorStyle' : ''">
            <van-icon size="20px" name="lock" class="pre_pics" />
            <input
                type="password"
                placeholder="Introduza a sua palavra-passe"
                v-show="!isshowPwd"
                v-model="password"
                @focus="myFocus()"
            />
            <input
                type="text"
                placeholder="Introduza a sua palavra-passe"
                v-show="isshowPwd"
                v-model="password"
                @focus="myFocus()"
            />
            <img
                src="../../public/image/login/hidden.png"
                alt
                class="after_pics"
                v-show="!isshowPwd"
                @click="showPwd(false)"
            />
            <img
                src="../../public/image/login/show.png"
                alt
                class="after_pics"
                v-show="isshowPwd"
                @click="showPwd(true)"
            />
        </div>

        <!-- <div class="tag_box" v-if="inorup==='up'">
      <van-icon size="20px" name="bookmark" class="pre_pics" />
      <input type="text" placeholder="Código promocional (opcional)" v-model="inviteCode" @focus="myFocus()"
        :readonly="isReadonly" />
        </div>-->
        <button class="submit" @click="clickSubmit(inorup)" v-if="inorup === 'in'">Autenticar-se</button>
        <button class="submit" @click="clickSubmit(inorup)" v-if="inorup === 'up'">Criar conta</button>

        <div class="directly-login">
            <hr />
            <div class="directly-login-text">Or log in directly with</div>
        </div>

        <div class="pic_box" id="google_login_block" v-if="client !== 'android_app'">
            <img @click="jumpGoogle" src="../../public/image/login/pic1.png" alt class="pic1" />
        </div>
        <div class="agree-text">
            <img src="../../public/image/login/agreeLogin.png" alt />
            <div>
                To visit this website,please ensure that you have reached the age of 18 and agree
                <span
                    style="color: #3B4AD6"
                >Terms of Service</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { authGoogle, getCode, login, register, claimTgRewards } from "@/api/api";
import { buryingLog } from '@/api/PromotionInterFace';
import { dotGTag, dotKwai } from '@/native/native';

export default {
    data() {
        return {
            password: "",
            email: "",
            inviteCode: "",
            isError: false,
            isEmailError: false,
            isshowPwd: false,
            isReadonly: false,
            errorList: [],
            code: '',
            optLoading: false,
            time: 2 * 60 * 1000,
            phone: '',
            optState: 'init',
            activeIndex: 0,
            loginChannel: [
                {
                    name: "Celular",
                    type: "selected"
                },
                {
                    name: "Email",
                    type: "Unselected"
                }
            ],
            changeTab: false,
        }
    },
    computed: {
        ...mapState(['inorup', 'client', 'afid', 'userData'])
    },
    created() {
        let inviteCode = localStorage.getItem('inviteCode')
        if (inviteCode && inviteCode !== 'undefined') {
            this.inviteCode = inviteCode
            this.isReadonly = true
        } else {
            this.isReadonly = false
        }
    },
    methods: {
        onKeyDown(event) {
            if (event.key === 'Enter') {
                this.clickSubmit(this.inorup);
                console.log('Enter 键被按下');
            }
        },
        change_tabs(item, index) {
            this.activeIndex = index;
            this.errorList = [];
        },
        doLogin() {
            this.errorList = [];
            const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            const phoneRegex = /^\d{11}$/;
            let Field = '';
            if (this.activeIndex === 0) {
                if (!this.phone || !phoneRegex.test(this.phone)) {
                    this.errorList.push('phone');
                }
                Field = 'phone';
            }
            if (this.activeIndex === 1) {
                if (!this.email || !reg.test(this.email)) {
                    this.errorList.push('email');
                }
                Field = 'email';
            }

            if (!this.password) this.errorList.push('psw');
            if (this.errorList.length > 0) return;

            let p = {}
            p[Field] = this.activeIndex === 0 ? this.phone : this.email;
            p.password = this.password;
            p.channel = localStorage.getItem('channel') || '';
            // if (this.client === 'android_app' || this.client === 'ios') {
            //     p.platform = 'app';
            // }
            let pm = localStorage.getItem('platform');
            if (pm) {
                p.platform = pm;
            } else {
                // p.platform = this.client;
            }
            console.log("登录参数====>", JSON.stringify(p))
            login(p).then(res => {
                console.log("登录成功====>", res)
                this.$store.commit('MASKCONTENT', '')
                this.$store.commit('MASK', false)
                this.$store.commit('setToken', res.Authorization)
                localStorage.setItem('token', res.Authorization)
                localStorage.setItem('active_pop_show', '')
                this.$router.push('/')
                this.$store.commit('setHomePopOrder', 1);

                this.$store.dispatch('toast', 'Login bem sucedido')
                this.$store.commit('setPurseShow', true)

                // let reward = localStorage.getItem('reward');
                // if (reward && reward != '') {
                //     // 如果没有领取过奖励，则跳转到领取页面
                //     if (!this.userData.tg_today_is_reward) {
                //         this.$store.commit("setShowTgReward", true);
                //     }
                // }

                setTimeout(() => {
                    dotGTag('login', { 'method': Field })
                }, 1000);
                const _pwaInstalled = localStorage.getItem('pwaInstalled');
                if (this.deferredPrompt && !_pwaInstalled) {
                    this.$store.commit("setIsShowPWATips", true);
                }
            }).catch(err => { })
        },
        doRegister() {
            this.errorList = [];
            const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            const phoneRegex = /^\d{11}$/;
            let Field = '';
            if (this.activeIndex === 0) {
                if (!this.phone || !phoneRegex.test(this.phone)) {
                    this.errorList.push('phone');
                }
                Field = 'phone';
            }
            if (this.activeIndex === 1) {
                if (!this.email || !reg.test(this.email)) {
                    this.errorList.push('email');
                }
                Field = 'email';
            }

            if (!this.password) this.errorList.push('psw');
            if (this.errorList.length > 0) return;

            let p = {}
            p[Field] = this.activeIndex === 0 ? this.phone : this.email;
            p.password = this.password;

            let buryingParams = localStorage.getItem('BuryingParams');

            if (buryingParams && buryingParams !== 'null') {
                p.publish_channel = buryingParams.split('___')[0];
                p.pid = buryingParams.split('___')[1]
            }
            if (p.publish_channel) {
                p.click_id = localStorage.getItem('click_id')
            }
            if (this.inviteCode) {
                p.code = this.inviteCode
            }
            p.channel = localStorage.getItem('channel') || '';
            p.only_uuid = localStorage.getItem('only_uuid') || '';
            p.sub_channel = localStorage.getItem('sub_channel') || '';
            p.fbp = localStorage.getItem('_fbp') || '';
            p.invited_type = localStorage.getItem('invited_type') || '';

            p.af_id = this.afid;
            // if (this.client === 'android_app' || this.client === 'ios') {
            //     p.platform = 'app';
            // }
            let pm = localStorage.getItem('platform');
            if (pm) {
                p.platform = pm;
            } else {
                // p.platform = this.client;
            }

            console.log("注册参数====>", JSON.stringify(p))
            register(p).then(res => {
                console.log("注册成功====>", res)
                this.$store.commit('MASKCONTENT', '')
                this.$store.commit('MASK', false)
                this.$store.commit('AUTHORIZATION', res.Authorization)
                this.$store.commit('setToken', res.Authorization)
                localStorage.setItem('token', res.Authorization)
                localStorage.removeItem('inviteCode')
                // localStorage.removeItem('channel')
                buryingLog({
                    event_name: 'EVENT_COMPLETE_REGISTRATION',
                    fb_event_name: 'CompleteRegistration'
                })
                this.$router.push('/')
                this.$store.dispatch('toast', 'Login bem sucedido')
                this.$store.commit('setPurseShow', true)
                this.$store.commit('setHomePopOrder', 1);
                this.$store.commit('setLimitRechangeShow', { show: true, userClick: false });//弹出限时充值
                this.$store.dispatch('UserInfo');

                // let reward = localStorage.getItem('reward');
                // if (reward && reward != '') {
                //     // 如果没有领取过奖励，则跳转到领取页面
                //     if (!this.userData.tg_today_is_reward) {
                //         this.$store.commit("setShowTgReward", true);
                //     }
                // }
                this.$store.dispatch('refreshInviteRedDot');
                dotGTag('sign_up', { 'method': Field })
                dotKwai('completeRegistration',{})

                const _pwaInstalled = localStorage.getItem('pwaInstalled');
                if (this.deferredPrompt && !_pwaInstalled) {
                    this.$store.commit("setIsShowPWATips", true);
                }
            }).catch(() => {

            })
        },
        clickSubmit(typ) {
            if (typ === "up") {
                this.doRegister()
            } else {
                this.doLogin()
            }
        },
        jumpGoogle() {
            let param = {};
            let buryingParams = localStorage.getItem('BuryingParams');
            let channel = localStorage.getItem('channel');
            if (this.code !== "") param.invite = this.inviteCode;
            if (channel && channel !== 'undefined') param.channel = channel;

            if (buryingParams && buryingParams !== 'null') {
                param.publish_channel = buryingParams.split('___')[0];
                param.pid = buryingParams.split('___')[1]
            }
            if (param.publish_channel) {
                param.click_id = localStorage.getItem('click_id')
            }
            if (this.client === 'android_app' || this.client === 'ios') {
                param.platform = 'app';
            }

            authGoogle(param).then((res) => {
                window.location.replace(res.url)
            }).catch(() => {

            })
        },
        confirm() {
            this.showDialog = false
        },
        myFocus() {
            this.isError = false;
            this.isEmailError = false
        },
        showPwd(status) {
            this.isshowPwd = !this.isshowPwd
        },
        tosignUp() {
            this.$store.commit('setMenuBlockShow', false);
            this.$store.commit('IN_OR_UP', 'up')
            this.$store.commit('MASKCONTENT', 'login')
            this.$store.commit('MASK', true)
            this.$emit('headerTop')
        },
        tosignIn() {
            this.$store.commit('setMenuBlockShow', false);
            this.$store.commit('IN_OR_UP', 'in')
            this.$store.commit('MASKCONTENT', 'login')
            this.$store.commit('MASK', true)
            this.$emit('headerTop')
        },
        getOTP() {
            if (!this.phone) {
                this.errorList.push('phone')
                return
            }
            if (this.optState !== 'init') return;
            this.optState = 'loading';
            this.time = 2 * 60 * 1000;
            let phone = '55' + this.phone.replaceAll(' ', '')
            getCode({
                phone: phone
            }).then(resp => {
                if (resp.code === 200) {
                    this.optState = 'timer';
                    this.code = resp.data;
                    this.$refs.countDown.reset();
                } else {
                    this.optState = 'init';
                }
            })
        },
        handlerPhone(event) {
            this.phone = event.target.value
        },
        timerFinish() {
            this.optState = 'init';
        }
    },
    watch: {

    },
    mounted() {
        document.addEventListener('keydown', this.onKeyDown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeyDown);
    }
}
</script>
<style lang="less" scoped>
input {
    height: 73px;
    background: #273645;
    font-size: 30px;
    border: none;
    outline: none;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #272727 inset !important;
    -webkit-text-fill-color: #fff !important;
    -webkit-background-clip: text;
}

.formArr {
    width: 85vw;
    margin: 60px auto;

    .email_box,
    .tag_box,
    .pwd_box {
        display: flex;
        align-items: center;
        padding: 6px 15px;
        box-sizing: border-box;
    }

    input {
        width: 80%;
        font-size: 28px;
        font-weight: normal;
        color: #fff;
        line-height: 24px;
        background: #272727;
        padding-left: 20px;
    }

    .email_box {
        background: #272727;
        border-radius: 15px;
        opacity: 1;
        border: 2px solid #353637;

        .pre_pics {
            margin: 0 20px;
        }
    }

    .pwd_box {
        background: #272727;
        border-radius: 15px;
        opacity: 1;
        border: 2px solid #353637;

        .pre_pics {
            margin: 0 20px;
        }

        .after_pics {
            width: 52px;
            height: 52px;
            background: #353637;
            border-radius: 10px 10px 10px 10px;
            margin-left: 10px;
        }
    }

    .tag_box {
        background: #272727;
        border-radius: 15px;
        opacity: 1;
        border: 2px solid #353637;
        margin-top: 40px;

        .pre_pics {
            margin: 0 20px;
        }
    }

    .directly-login {
        margin-top: 60px;
        margin-bottom: 150px;

        hr {
            color: #909093;
        }

        .directly-login-text {
            width: 460px;
            text-align: center;
            font-size: 36px;
            color: #909093;
            margin: -50px auto;
            background: #0e0e0e;
        }
    }

    .tips {
        font-size: 25px;
        color: #a7c9f7;
        margin-top: 40px;
        text-decoration: underline;
    }

    .submit {
        width: 100%;
        background-color: #f82e48;
        background-size: contain;
        padding: 26px 0;
        border: 0;
        border-radius: 20px;
        margin-top: 60px;
        font-size: 35px;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .agree-text {
        width: calc(100% - 100px);
        font-size: 24px;
        color: #959595;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 40px;

        img {
            width: 50px;
            margin-right: 20px;
        }
    }

    .pic_box {
        width: 40%;
        margin: 60px auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        display: none;

        .pic1 {
            width: 61px;
        }

        .pic2 {
            width: 64px;
            height: 64px;
        }
    }

    .login_txt1,
    .login_txt2,
    .register_txt2 {
        font-size: 20px;
        color: #a7c9f7;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    .green_txt {
        color: #53b949;
    }

    .error {
        font-size: 20px;
        color: red;
        margin-top: 15px;
    }

    .errorStyle {
        border: 1px solid red !important;
    }

    .fousStyle {
        border: 2px solid #29a4ff;
    }
}

.login-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.preNumber {
    font-size: 30px;
}

.activeClass {
    font-size: 36px;
    font-weight: 400;
    border-radius: 50px;
    background-color: rgb(39, 39, 39);
    padding: 20px 30px 20px 30px;
}

.defaultClass {
    font-size: 36px;
    font-weight: 400;
    border-radius: 50px;
    background-color: #0e0e0e;
    padding: 20px 30px 20px 30px;
}

.defaultClass span {
    font-size: 36px;
    font-weight: 400;
    border-radius: 50px;
    padding: 20px 30px 20px 30px;
}

.opt {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;

    .input_opt {
        width: 300px;
        height: 90px;
        background: #131f2d;
        border: 2px solid #99bae4;
        border-radius: 15px;
        color: #ffffff;
        padding-left: 100px;
        font-size: 30px;
    }

    img {
        height: 50px;
        position: absolute;
        left: 36px;
        top: 26px;
    }

    .btn_code {
        width: 184px;
        height: 90px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../public/image/wallet/rect_green.png") center
            no-repeat;
        background-size: cover;
        margin-left: 20px;
        border-radius: 20px;

        p {
            height: 18px;
            font-size: 25px;
            text-align: center;
            font-weight: 900;
            color: #ffffff;
            line-height: 25px;
        }
    }
}

.bind-phone-block {
    display: flex;
    position: relative;

    span {
        color: #95bde3;
        border-radius: 20px;
        margin-top: 54px;
        font-size: 34px;
        padding: 28px;
        margin-right: 20px;
        position: absolute;
        left: 80px;
    }

    input {
        margin-top: 54px;
        width: 100%;
        height: 90px;
        background: #131f2d;
        border: 2px solid #99bae4;
        border-radius: 15px;
        color: #ffffff;
        padding-left: 200px;
        font-size: 30px;
    }

    img {
        height: 50px;
        position: absolute;
        left: 36px;
        top: 80px;
    }
}

.opt-loading,
.opt-loading:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.opt-loading {
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.4em solid rgba(255, 255, 255, 0.2);
    border-right: 1.4em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.4em solid rgba(255, 255, 255, 0.2);
    border-left: 1.4em solid #ffffff;
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.input-title {
    margin: 50px 0 20px 0;
    font-size: 30px;
}

@-webkit-keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>