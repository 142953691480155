import { render, staticRenderFns } from "./WidthDrwaQuota.vue?vue&type=template&id=2426fc70&scoped=true"
import script from "./WidthDrwaQuota.vue?vue&type=script&lang=js"
export * from "./WidthDrwaQuota.vue?vue&type=script&lang=js"
import style0 from "./WidthDrwaQuota.vue?vue&type=style&index=0&id=2426fc70&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2426fc70",
  null
  
)

export default component.exports