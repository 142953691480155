import store from "@/store/index";
import {
    getUserInfo,
    getRedDot,
    invitedTask
} from "@/api/api";
import {
    dotEventByChannel
} from "@/native/native";

const actions = {
    UserInfo() {
        const token = localStorage.getItem('token');
        if (token) {
            store.commit('setToken', token);
            getUserInfo({
                token: token,
                client: store.state.client
            }).then(res => {
                localStorage.setItem('userData', res ? JSON.stringify(res) : '')
                store.commit('USERDATA', res);
                //af打点
                let eventData = JSON.stringify({
                    user_id_short: res.uid
                });
                dotEventByChannel('user_info', eventData);
            })
        }
    },
    onLoading(context, isLoading) {
        context.commit('setLoading', isLoading);
    },
    toast(context, text) {
        let toastObj = {
            show: true,
            text: text
        };
        if (typeof text === 'string') {
            toastObj.type = 'success';
        } else {
            toastObj.show = text.show;
            toastObj.type = text.type;
            toastObj.text = text.text;
        }
        context.commit('setToast', toastObj);
    },
    showNetWaiting(context, isShow) {
        context.commit('setShowNetWaiting', isShow);
    },
    refreshRedDot() {
        const token = localStorage.getItem('token');
        if (token) {
            getRedDot().then(res => {
                console.log('红点信息====>', res)
                store.commit('setVipRedDotData', res);
            })
        }
    },
    refreshInviteRedDot() {
        const token = localStorage.getItem('token');
        if (token) {
            invitedTask().then(res => {
                console.log('邀请红点信息====>', res)
                store.commit('setInviteDotData', res);
            })
        }
    },
}
export default actions;