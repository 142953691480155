const getters = {
    getToken(state) {
        if (!state.Authorization) {
            state.Authorization = localStorage.getItem('token')
        }
        return state.Authorization
    },
    getUserData(state) {
        if (state.Authorization && !state.userData) {
            const userDataJson = localStorage.getItem('userData');
            if (userDataJson) {
                try {
                    state.userData = JSON.parse(userDataJson);
                } catch (error) {
                    console.error('Failed to parse userData JSON:', error);
                }
            }
        }
        return state.userData
    },
    getMessageNumber(state) {
        return state.messageNumber
    },
}
export default getters
