<template>
  <div class="container-ads">
    <div class="txt1">Junte-se ao jogo reis , Para obter</div>
    <div class="txt2">
      <div>R$388.888</div>
      <div>grátis</div>
    </div>
    <div class="txt3">
      Ao convidar um usuário válido, você pode ganhar pelo menos <span style="color: #FAD343">R$12</span><br>
      CashBack de até <span style="color: gold">25%</span>
    </div>
    <div class="btnBox">
      <button @click="sign_up">Criar conta</button>
      <div>Receba um bônus de depósito de até <span>200%</span></div>
      <img class="gifts" src="../../public/image/home/titile_gifts_pic.png" alt="">
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      sign_up() {
        this.$store.commit('IN_OR_UP', 'up')
        this.$store.commit('MASKCONTENT', 'login')
        this.$store.commit('MASK', true)
        this.$emit('headerTop')
      }
    },
  }
</script>
<style lang="less" scoped>
  .container-ads {
    height: 470px;
    background: url('../../public/image/home/coins_bg.png');
    background-size: contain;
    position: relative;
    background-color: #000;

    .txt1 {
      color: #FFFFFF;
      font-size: 28px;
      position: absolute;
      top: 49px;
      left: 56px;
      font-weight: bold;
    }

    .txt2 {
      position: absolute;
      top: 99px;
      left: 63px;
      display: flex;
    }

    .txt2 div:first-child {
      font-size: 58px;
      color: #FAD343;
      font-weight: bold;
    }

    .txt2 div:last-child {
      font-size: 58px;
      color: #FFFFFF;
      margin-left: 10px;
      font-weight: bold;
    }

    .txt3 {
      width: 438px;
      height: 54px;
      font-size: 25px;
      font-weight: bold;
      color: #9EB8DD;
      position: absolute;
      top: 173px;
      left: 55px;

      span {
        font-size: 25px;
        /* font-family: Charter-Bold, Charter; */
        font-weight: bold;
        color: #FAD343;
      }
    }

    .btnBox {
      width: 670px;
      height: 114px;
      position: relative;
      top: 310px;
      left: 35px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 57px 57px 57px 57px;
      opacity: 1;

      button {
        width: 230px;
        height: 114px;
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        background: url('../../public/image/home/green_bg.png') no-repeat;
        background-size: contain;
        position: absolute;
        left: -6px;
        border: none;
      }

      div {
        font-size: 28px;
        color: #000;
        /* font-family: 'Arial'; */
        width: 300px;
        position: relative;
        left: 252px;
        font-weight: bold;

        span {
          font-size: 38px;
          /* font-family: 'Arial'; */
          font-weight: bold;
          color: goldenrod;
        }

        span:last-child {
          font-weight: bold;
        }

        span.fw {
          color: #000;
        }
      }

      img {
        display: inline-block;
        width: 77px;
        height: 85px;
        position: absolute;
        top: 10px;
        right: 40px;
      }
    }
  }
</style>