var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent } from "vue";
import { mapState } from "vuex";
import ActivePopUp from "@/views/recharge/activePopUp.vue";
import { ReceiveMessage } from "@/api/WebSocketServer";
export default defineComponent({
    name: "HomePagePopUpLogic",
    data: function () {
        return {};
    },
    components: { ActivePopUp: ActivePopUp },
    computed: __assign({}, mapState([
        "readyPopUp",
        "homePopOrder",
        "signInByDay",
        "slotsTigerShow",
        "openCodingTask",
        "rechargeActiveShow",
        "userData",
    ])),
    created: function () {
        var _this = this;
        ReceiveMessage("RechargeSuc", function (res) {
            console.log("推送消息：RechargeSuc", res);
            return;
            if (res && res.length > 0) {
                res.map(function (item) {
                    if (item.pack_type === 1) {
                        _this.cheerJson = item;
                        if (_this.$route.path === "/" ||
                            _this.$route.name === "index") {
                            _this.cheerIconShow = true;
                        }
                        _this.$store.commit("setCheerPopShow", true);
                    }
                    if (item.pack_type === 2) {
                        _this.speedJson = item;
                        if (_this.$route.path === "/" ||
                            _this.$route.name === "index") {
                            _this.speedIconShow = true;
                        }
                        _this.$store.commit("setSpeedUpPopShow", true);
                    }
                });
            }
            else {
                _this.cheerIconShow = false;
                _this.speedIconShow = false;
                _this.$store.commit("setCheerPopShow", false);
                _this.$store.commit("setSpeedUpPopShow", false);
            }
        });
    },
    watch: {
        homePopOrder: function (val) {
            return;
            console.log("homePopOrder: ", val, this.isNeedRecharge(val));
            this.$store.commit("setReadyPopUp", true);
            if (this.isNeedRecharge(val))
                return;
            if (val === 1) {
                this.$store.commit("setSlotsTigerShow", true);
            }
            else if (val === 2) {
                this.$store.commit("setRechargeActiveShow", true);
            }
            else if (val === 3) {
                this.$store.commit("setSignInByDay", true);
            }
            else if (val === 4) {
                this.$store.commit("setOpenCodingTask", true);
            }
        },
        slotsTigerShow: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        signInByDay: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        openCodingTask: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        rechargeActiveShow: function (val) {
            if (!val && this.readyPopUp)
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
        },
        $route: function (to) {
            if (to.name === "index") {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
            }
        },
        userData: function (val) {
            if (this.homePopOrder === 0)
                this.$store.commit("setHomePopOrder", 1);
        },
    },
    methods: {
        isNeedRecharge: function (val) {
            if ((val === 2 || val === 4) && !this.userData.has_recharge) {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
                return true;
            }
            else if (val === 1 && this.userData.has_recharge) {
                this.$store.commit("setHomePopOrder", this.homePopOrder + 1);
                return true;
            }
            else {
                return false;
            }
        },
    },
    mounted: function () { },
});
