<template>
  <div class="jopos">
    <div class="menuBox">
      <van-tabs v-model="tabActive" swipe-threshold="1">
        <van-tab v-for="(item,keys) in menuList" :key="keys">
          <template #title>
            <img :src="tabActive === keys ? item.imgActive : item.img" :class="'img'+keys" alt="">
            <div class="itemName">{{item.name}}</div>
          </template>
          <div v-if="tabActive === 0">

            <div class="contenArry" v-if="activity === 0" v-for="item in menuList">
              <div class="contenArry_top" :id="item.domName">
                <div class="content_left">
                  <img class="hotImg" :src="item.img" alt="">
                  <span>{{item.name}}</span>
                </div>
                <div class="content-nav-block" @click="clickAll(2)">
                  Ver mais&nbsp; &gt;
                </div>
              </div>
              <div class="game-grid-3-block">
                <div class="" v-for="(json, index) in item.gameList" :key="index">
                  <GameCard :json="json" :isHot="index <= 6"/>
                </div>
              </div>
            </div>
          </div>

          <!-- PG games游戏 -->
          <div class="contenArry" v-if="tabActive===1">
            <div class="contenArry_top" id="pgGame">
              <div class="content_left">
                <img class="pgImg" src="../../public/image/game/pg.png" alt="">
                <span>PG</span>
              </div>
              <div class="content-nav-block" @click="clickAll(6)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="pgGameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in pgGameArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more" :class="playIndex === item.game_id+'pgGame' ? 'imageFilter' : ''"
                        :src="SplitGamePosterUrl(item)" @click="readyPlay(item, 'pgGame')" alt="">

                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'pgGame'">
                        <van-loading />
                      </div>

                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'pgGame'" @click="doPlay('pgGame')"
                        class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(6)" src="../../public/image/home/viewAllGame.png">

                      <span v-show="playIndex === item.game_id + 'pgGame'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'pgGame'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <!-- jili定制 -->
          <div class="contenArry" style="min-height: auto" v-if="tabActive===2">
            <div class="contenArry_top" id="jiLiDesignGame">
              <div class="content_left">
                <img class="designImg" src="../../public/image/game/designJili.png" alt="">
                <span>Design JiLi</span>
              </div>
              <div class="content-nav-block" @click="clickAll(9)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="jiliDesignSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in jiLiDesignArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more" :src="SplitGamePosterUrl(item)"
                        :class="playIndex === item.game_id + 'internal' || item.active_status === 1 ? 'imageFilter' : ''"
                        @click="readyPlay(item, 'internal')" alt="">

                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>

                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'internal'">
                        <van-loading />
                      </div>

                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'internal'"
                        @click="doPlay('internal')" class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(9)" alt="" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'internal'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'internal'" class="bottom_types">
                        {{ item.channel === 1 ? 'Pragmatic Play' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <!-- PP游戏 -->
          <div class="contenArry" v-if="tabActive===3">
            <div class="contenArry_top" id="ppGame">
              <div class="content_left">
                <img class="ppImg" src="../../public/image/game/pp.png" alt="">
                <span>Pragmatic Play</span>
              </div>
              <div class="content-nav-block" @click="clickAll(3)">
                Ver mais&nbsp; &gt;
              </div>
            </div>

            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="ppGameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in ppGameArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more" :src="SplitGamePosterUrl(item)"
                        :class="playIndex === item.game_id+'ppGame' || item.active_status === 1 ? 'imageFilter' : ''"
                        @click="readyPlay(item, 'ppGame')" alt="">
                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>

                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'ppGame'">
                        <van-loading />
                      </div>

                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'ppGame'" @click="doPlay('ppGame')"
                        class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(3)" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'ppGame'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'ppGame'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <!-- PPL游戏 -->
          <div class="contenArry" v-if="tabActive===4">
            <div class="contenArry_top" id="pplGame">
              <div class="content_left">
                <img class="liveImg" src="../../public/image/game/live.png" alt="">
                <span>LIVE</span>
              </div>
              <div class="content-nav-block" @click="clickAll(4)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="pplGameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in pplGameArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more"
                        :class="playIndex === item.game_id+'pplGame' || item.active_status === 1 ? 'imageFilter' : ''"
                        :src="SplitGamePosterUrl(item)" @click="readyPlay(item, 'pplGame')" alt="">
                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>

                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id+ 'pplGame'">
                        <van-loading />
                      </div>

                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'pplGame'" @click="doPlay('pplGame')"
                        class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(4)" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'pplGame'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'pplGame'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <!-- card游戏 -->
          <div class="contenArry" v-if="tabActive===5">
            <div class="contenArry_top" id="card">
              <div class="content_left">
                <img class="cardImg" src="../../public/image/game/table.png" alt="">
                <span>TABLE</span>
              </div>
              <div class="content-nav-block" @click="clickAll(5)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="cardGameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in cardGameArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more"
                        :class="playIndex === item.game_id+'cardGame' || item.active_status === 1 ? 'imageFilter' : ''"
                        :src="SplitGamePosterUrl(item)" @click="readyPlay(item, 'cardGame')" alt="">
                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>
                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'cardGame'">
                        <van-loading />
                      </div>
                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'cardGame'"
                        @click="doPlay('cardGame')" class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(5)" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'cardGame'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'cardGame'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <!-- 自营游戏 -->
          <div class="contenArry" style="min-height: auto" v-if="tabActive===6">
            <div class="contenArry_top" id="internalGame">
              <div class="content_left">
                <img class="internoImg" src="../../public/image/game/Internos.png" alt="">
                <span>House</span>
              </div>
              <div class="content-nav-block" @click="clickAll(1)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="internoSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in internalGameList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more" :src="SplitGamePosterUrl(item)" :class="playIndex === item.game_id + 'internal' || item.active_status === 1 ? 'imageFilter' :
                        ''" @click="readyPlay(item, 'internal')" alt="">

                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>

                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'internal'">
                        <van-loading />
                      </div>

                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'internal'"
                        @click="doPlay('internal')" class="play-btn" src="../../public/image/common/playBtn.png" alt="">

                      <img v-if="item.more" @click="clickAll(1)" alt="" src="../../public/image/home/viewAllGame.png">

                      <span v-show="playIndex === item.game_id + 'internal'" class="top_name">{{ item.name
                        }}</span>
                      <span v-show="playIndex === item.game_id + 'internal'" class="bottom_types">
                        {{ item.channel === 1 ? 'Pragmatic Play' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <!-- JILI游戏 -->
          <div class="contenArry" v-if="tabActive===7">
            <div class="contenArry_top" id="jiLiGame">
              <div class="content_left">
                <img class="jiliImg" src="../../public/image/game/jili.png" alt="">
                <span>JILI</span>
              </div>
              <div class="content-nav-block" @click="clickAll(7)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="jiLiGameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in jiLiArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more"
                        :class="playIndex === item.game_id+'jiLiGame' || item.active_status === 1 ? 'imageFilter' : ''"
                        :src="SplitGamePosterUrl(item)" @click="readyPlay(item, 'jiLiGame')" alt="">
                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>
                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'jiLiGame'">
                        <van-loading />
                      </div>
                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'jiLiGame'"
                        @click="doPlay('jiLiGame')" class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(7)" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'jiLiGame'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'jiLiGame'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>

          <!-- CQ9游戏 -->
          <div class="contenArry" v-if="tabActive===8">
            <div class="contenArry_top" id="cq9Game">
              <div class="content_left">
                <img class="cq9Img" src="../../public/image/game/cq9.png" alt="">
                <span>CQ9</span>
              </div>
              <div class="content-nav-block" @click="clickAll(8)">
                Ver mais&nbsp; &gt;
              </div>
            </div>
            <div class="game-list-block">
              <van-swipe class="my-swipe" ref="cq9GameSwipe" :loop="false" :show-indicators="false">
                <van-swipe-item v-for="(array, index) in cq9ArrayList" :key="index">
                  <div class="game-list-page-block">
                    <div v-for="item in array">
                      <img v-if="!item.more"
                        :class="playIndex === item.game_id+'cq9Game' || item.active_status === 1 ? 'imageFilter' : ''"
                        :src="SplitGamePosterUrl(item)" @click="readyPlay(item, 'cq9Game')" alt="">
                      <div class="game-maintenance" v-show="item.active_status === 1">
                        <img src="../../public/image/common/game-maintenance.png" alt="">
                        <span>Em manutenção</span>
                      </div>
                      <div class="game-play-loading" v-show="playLoading && ensureGameId === item.game_id + 'cq9Game'">
                        <van-loading />
                      </div>
                      <img v-if="!item.more" v-show="playIndex === item.game_id + 'cq9Game'" @click="doPlay('cq9Game')"
                        class="play-btn" src="../../public/image/common/playBtn.png" alt="">
                      <img v-if="item.more" @click="clickAll(8)" src="../../public/image/home/viewAllGame.png">
                      <span v-show="playIndex === item.game_id + 'cq9Game'" class="top_name">{{ item.name }}</span>
                      <span v-show="playIndex === item.game_id + 'cq9Game'" class="bottom_types">
                        {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
                      </span>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="search">
        <img src="../../public/image/game/search.png" alt="" @click="searchShow=true">
      </div>
    </div>
    <div class="btnsArr">

    </div>
    <van-popup v-model="searchShow" position="top" :style="{ height: '20%'}">
      <div class="game-search">
        <input v-model="searchText" class="search" />
        <div class="game-search-icon"><van-icon name="search" size="20" color="#737373" /></div>
      </div>
      <div class="endBtn">
        <button class="toClose" @click="searchShow=false">Voltar</button>
        <button class="toSearch" @click="searchGameHandler" :class="searchText?'':'disabledClass'"
          :disabled="!searchText">Buscar</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { gameList, getGamePlayUrl, searchGame } from "@/api/api";
  import { SplitGamePosterUrl } from "@/utis/common";
  import { mapState } from "vuex";
  import GameRecords from "@/views/record/gameRecords.vue";
  import GameSelector from "@/components/GameSelector.vue";
  import GameCard from "@/components/GameCard.vue";

  export default {
    components: {
      GameCard,
      GameSelector, GameRecords,
    },
    data() {
      return {
        activity: 0,
        swipeWith: 0,
        playLoading: false,
        hotGameList: [],
        ppGameArrayList: [],
        pgGameArrayList: [],
        pplGameArrayList: [],
        cardGameArrayList: [],
        internalGameList: [],
        jiLiArrayList: [],
        jiLiDesignArrayList: [],
        cq9ArrayList: [],

        hotTotal: 0,
        ppTotal: 0,
        pgTotal: 0,
        pplTotal: 0,
        cardTotal: 0,
        internoTotal: 0,
        jiliTotal: 0,
        jiliDesignTotal: 0,
        CQ9Total: 0,

        showPgGameList: [],
        showJogosGameList: [],
        showHotGameList: [],
        showPgGameArrayList: [],
        showCardGameList: [],
        showInternalGameList: [],

        playIndex: -1,
        ensureGameId: '',
        lockList: [],
        haveLoadPage: false,
        isLoadingMore: false,
        totalPageObject: { hot: -1, pp: -1, live: -1, card: -1, pg: -1 },
        appearDomIdList: ['jiLiDesignGame', 'internalGame', 'hotGame', 'ppGame', 'pplGame', 'card', 'pgGame', 'jiLiGame', 'cq9Game'],
        isBlank: false,
        pgContext: '',
        menuList: [
          {
            id: '0',
            img: require('../../public/image/game/hot.png'),
            imgActive: require('../../public/image/game/hot_active.png'),
            name: 'Top',
            gameList: [],
            domName: 'hotGame'
          },
          {
            id: '1',
            img: require('../../public/image/game/pg.png'),
            imgActive: require('../../public/image/game/pg_active.png'),
            name: 'PG',
            gameList: [],
            domName: 'pgGame'
          },
          {
            id: '2',
            img: require('../../public/image/game/jili.png'),
            imgActive: require('../../public/image/game/jili_active.png'),
            name: 'Design JiLi',
            gameList: [],
            domName: 'jiLiDesignGame'
          },
          {
            id: '3',
            img: require('../../public/image/game/pp.png'),
            imgActive: require('../../public/image/game/pp_active.png'),
            name: 'Pragmatic Play',
            gameList: [],
            domName: 'ppGame'
          },
          {
            id: '4',
            img: require('../../public/image/game/live.png'),
            imgActive: require('../../public/image/game/live_active.png'),
            name: 'LIVE',
            gameList: [],
            domName: 'pplGame'
          },
          {
            id: '5',
            img: require('../../public/image/game/table.png'),
            imgActive: require('../../public/image/game/table_active.png'),
            name: 'TABLE',
            gameList: [],
            domName: 'card'
          },
          {
            id: '6',
            img: require('../../public/image/game/Internos.png'),
            imgActive: require('../../public/image/game/Internos_active.png'),
            name: 'House',
            gameList: [],
            domName: 'internalGame'
          },
          {
            id: '7',
            img: require('../../public/image/game/jili.png'),
            imgActive: require('../../public/image/game/jili_active.png'),
            name: 'JILI',
            gameList: [],
            domName: 'jiLiGame'
          },
          {
            id: '8',
            img: require('../../public/image/game/cq9.png'),
            imgActive: require('../../public/image/game/cq9_active.png'),
            name: 'CQ9',
            gameList: [],
            domName: 'cq9Game'
          }
        ],
        tabActive: 0,
        searchShow: false,
        searchText: '',
        isCash: -1,
        gameValid: false,
      }
    },
    created() {
      document.onscroll = () => this.isVisibleArea();
      window.addEventListener('resize', () => {
        this.swipeWith = document.body.clientWidth - 60;
      })
      this.swipeWith = document.body.clientWidth - 60;
    },
    computed: {
      ...mapState(['token'])
    },
    mounted() {
      this.loadGames(999, 'hotGameList')
      setTimeout(() => {
        this.loadGames(4, 'pgGameArrayList')
      }, 3000)
    },
    watch: {
      tabActive(a, b) {
        switch (a) {
          case 1:
            this.loadGames(4, 'pgGameArrayList')
            break;
          case 2:
            this.loadGames(77, 'jiLiDesignArrayList')
            break;
          case 3:
            this.loadGames(1, 'ppGameArrayList')
            break;
          case 4:
            this.loadGames(2, 'pplGameArrayList')
            break;
          case 5:
            this.loadGames(3, 'cardGameArrayList')
            break;
          case 6:
            this.loadGames(5, 'internalGameList')
            break;
          case 7:
            this.loadGames(14, 'jiLiArrayList')
            break;
          case 8:
            this.loadGames(15, 'cq9ArrayList');
            break;
          default:
            break;
        }
      }
    },
    methods: {
      to_bonus() {
        this.$router.push('/bonus')
      },
      to_share() {
        this.$router.push('/share')
      },
      SplitGamePosterUrl,
      isVisibleArea() {
        const viewHeight = window.innerHeight;
        for (let i = 0; i < this.appearDomIdList.length; i++) {
          if (!document.getElementById(this.appearDomIdList[i])) break
          const clientRect = document.getElementById(this.appearDomIdList[i]).getBoundingClientRect()
          if (this.lockList.includes(this.appearDomIdList[i])) continue;

          if (clientRect.bottom >= 0 && clientRect.bottom < viewHeight) {
            this.lockList.push(this.appearDomIdList[i]);
            if (this.appearDomIdList[i] === 'hotGame') this.loadGames(999, 'hotGameList')
            if (this.appearDomIdList[i] === 'ppGame') this.loadGames(1, 'ppGameArrayList')
            if (this.appearDomIdList[i] === 'pplGame') this.loadGames(2, 'pplGameArrayList')
            if (this.appearDomIdList[i] === 'card') this.loadGames(3, 'cardGameArrayList')
            if (this.appearDomIdList[i] === 'pgGame') this.loadGames(4, 'pgGameArrayList')
            if (this.appearDomIdList[i] === 'internalGame') this.loadGames(5, 'internalGameList')
            if (this.appearDomIdList[i] === 'jiLiGame') this.loadGames(14, 'jiLiArrayList')
            if (this.appearDomIdList[i] === 'cq9Game') this.loadGames(15, 'cq9ArrayList');
            if (this.appearDomIdList[i] === 'jiLiDesignGame') this.loadGames(77, 'jiLiDesignArrayList')
          }
        }
      },
      loadGames(channel, gameListName) {
        // channel： 999 hotGame, 1 ppGame, 2 pplGame, 3 card, 4 pgGame
        gameList({
          pageNo: 1,
          pageSize: 11,
          channel: channel
        }).then(res => {
          switch (channel) {
            case 999:
              this.hotTotal = res.total
              this.menuList[0].gameList = res.list
              break;
            case 1:
              this.ppTotal = res.total
              this.menuList[3].gameList = res.list
              break;
            case 2:
              this.pplTotal = res.total
              this.menuList[4].gameList = res.list
              break;
            case 3:
              this.cardTotal = res.total
              this.menuList[5].gameList = res.list
              break;
            case 4:
              this.pgTotal = res.total
              this.menuList[1].gameList = res.list
              break;
            case 5:
              this.internoTotal = res.total
              this.menuList[6].gameList = res.list
              break;
            case 14:
              this.jiliTotal = res.total
              this.menuList[7].gameList = res.list
              break;
            case 15:
              this.CQ9Total = res.total
              this.menuList[8].gameList = res.list
              break;
            case 77:
              this.jiliDesignTotal = res.total
              this.menuList[2].gameList = res.list
              break;
            default:
              break;
          }

          this.arrangeArrayList(res.list, gameListName);
        })
      },
      readyPlay(item, type) {
        if (!localStorage.getItem('token')) {
          this.$store.commit('IN_OR_UP', 'in')
          this.$store.commit('MASKCONTENT', 'login')
          this.$store.commit('MASK', true)
          return
        }
        if (item.active_status === 1) return;
        if (type === 'jiLiGame' || type === 'cq9Game') {
          this.$store.dispatch('toast', {
            show: true,
            text: 'ocê precisa alcançar o VIP8 para poder entrar no jogo.',
            type: 'failed'
          })
          return
        }

        this.playLoading = true;
        this.ensureGameId = item.game_id + type;
        this.playIndex = -1;
        getGamePlayUrl({
          gameId: item.game_id,
          isTail: false
        }).then((res) => {
          this.playLoading = false;
          this.playIndex = item.game_id + type;
          this.gameUrl = res.url;
          this.isBlank = res.blank;
          this.isCash = res.cash;
          this.gameId = item.game_id;
          this.gameValid = res.valid;
          if (res.pack) {
            this.$store.commit('setGameRechargePopup', this.gameId)
          }
          if (this.gameValid) {
            this.$store.commit('setNotAllowGame', true);
            return;
          }
          if (res.cash > 0) {
            this.$store.commit('setPartMoneyGame', item.game_id + '__' + res.cash + '__' + Math.random())
          }
        }).catch(() => {
          this.playIndex = -1;
          this.playLoading = false;
        })
      },
      doPlay() {
        if (this.ensureGameId === this.playIndex) {
          if (this.gameValid) {
            this.$store.commit('setNotAllowGame', true);
            return;
          }
          if (this.isBlank) {
            this.$router.push({ name: 'play', params: { url: 'pgGameId__' + this.gameId } })
          } else {
            if (this.isCash > 0) {
              this.$store.commit('setPartMoneyGame', this.gameId + '__' + this.isCash + '__' + Math.random())
            } else {
              this.$router.push({ name: 'play', params: { url: this.gameUrl } })
            }
          }
        }
      },
      clickAll(type) {
        switch (type) {
          case 1:
            this.$router.push({ name: "internal" })
            break;
          case 2:
            this.$router.push({ name: "populares" })
            break;
          case 3:
            this.$router.push({ name: "slots" })
            break;
          case 4:
            this.$router.push({ name: "ppl" })
            break;
          case 5:
            this.$router.push({ name: "card" })
            break;
          case 6:
            this.$router.push({ name: "pgGame" })
            break;
          case 7:
            this.$router.push({ name: "JiLi" })
            break;
          case 8:
            this.$router.push({ name: "Cq9" })
            break;
          case 9:
            this.$router.push({ name: "JiLiDesign" })
            break;
          default:
            break;
        }
      },
      prev(refName) {
        this.$refs[refName].prev();
      },
      next(refName) {
        this.$refs[refName].next();
      },
      arrangeArrayList(list, targetName) {
        let currentPage = 0, itemArray = [];
        list.push({ more: true })
        list.map((item, index) => {
          if (!this[targetName][currentPage]) this[targetName][currentPage] = []
          if (currentPage === parseInt(index / 6)) {
            itemArray.push(item)
          } else {
            this.$set(this[targetName], currentPage, itemArray);
            currentPage = parseInt(index / 6);
            itemArray = []
            itemArray.push(item)
          }
          if (index === list.length - 1) {
            this.$set(this[targetName], currentPage, itemArray);
          }
        })
      },
      searchGameHandler() {
        if (!this.searchText) {
          return
        }
        this.searchShow = false
        searchGame({
          name: this.searchText
        }).then((res) => {
          this.$store.commit('SetSearchList', res)
          this.$router.push({ name: "searchResultPage", params: { searchName: this.searchText } })
          this.searchText = ''

        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .jopos {
    color: #fff;
    border-radius: 40px;
    padding: 5px 30px 0 30px;

    h3 {
      font-size: 65px;
      color: #fff;
      text-align: center;
      margin-top: 40px;
    }
  }

  .load-image-back {
    background-color: black;
    width: 324px !important;
    height: 234px;
    margin: 0 !important;
  }

  .imageFilter {
    filter: brightness(0.2);
  }

  .imageFilter::after {
    content: "♥";
  }

  .content-nav-block {
    display: flex;
    font-size: 25px;
    align-items: center;
  }

  .play-btn {
    width: 100px !important;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    animation: play-scale 200ms ease-in;
  }

  .top_name {
    display: block;
    width: 110px !important;
    position: absolute;
    left: calc(50% - 55px);
    top: 10px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #94BDE3;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .game-hot-tag {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 80px !important;
  }

  .bottom_types {
    display: block;
    width: 100% !important;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #94BDE3;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }

  @keyframes play-scale {
    from {
      transform: scale(1.5)
    }

    to {
      transform: scale(1)
    }
  }

  .contenArry_top {
    display: flex;
    justify-content: space-between;
    margin: 32px 4px 24px;
  }

  .content_rigth {
    width: 57px;
    height: 56px;
    background: url('../../public/image/home/arrow_bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 14px;
    opacity: 1;
    font-size: 25px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;

    img {
      width: 12px;
    }
  }

  .content_left {
    border-radius: 17px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 0 12px;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    font-weight: bold;

    img {
      width: 29px;
      margin-right: 15px;
      vertical-align: text-top;
    }

    .designImg {
      width: 29px;
      height: 29px;
    }

    .hotImg {
      width: 40px;
    }

    .pgImg {
      width: 51px;
      height: 23px;
    }

    .ppImg {
      width: 30px;
      height: 39px;
    }

    .liveImg {
      width: 31px;
      height: 24px;
    }

    .cardImg {
      width: 36px;
      height: 29px;
    }

    .internoImg {
      width: 29px;
      height: 28px;
    }

    .jiliImg {
      width: 30px;
      height: 30px;
    }

    .cq9Img {
      width: 44px;
      height: 24px;
    }

    span {
      font-size: 25px;
      color: #fff;
      line-height: 40px;
      margin-right: 12px;
    }
  }

  .game-list-page {
    width: max-content;
    height: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
  }

  .game-list-page-block {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    grid-gap: 20px;
    flex-direction: row;
    margin: 0 10px;

    >div {
      border-radius: 20px;
      position: relative;
      min-height: 220px;
      background-color: #213547;
      overflow: hidden;
    }

    @keyframes rolled {
      0% {
        left: -150px;
      }

      100% {
        left: 920px;
      }
    }

    .game-maintenance {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin: 0;

      img {
        width: 60px;
        margin-bottom: 20px;
      }

      span {
        font-size: 24px;
        color: #94BDE3;
        font-weight: 700;
      }
    }

    img {
      width: 100%;
      border-radius: 20px;
      vertical-align: middle;
    }
  }

  .game-grid-3-block {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    grid-gap: 20px;
    flex-direction: row;
    min-height: 600px;
  }

  .game-list-block {
    margin-top: 40px;
    min-height: 300px;
  }

  .game-play-loading {
    position: absolute !important;
    background-color: #00000090 !important;
    top: 0;
    width: 100%;
    height: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .content_rigth_txt {
    min-width: 142px;
    height: 56px;
    background: url('../../public/image/home/viewall_bg.png') no-repeat;
    background-size: 100% 100%;
    font-size: 25px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    box-sizing: border-box;

    span {
      color: #47C1E0;
      margin-left: 10px;
    }
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 900;

    .bonus {
      width: 330px;
      height: 110px;
      background: url('../../public/image/home/bonusBg.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 16px;
      position: relative;

      p {
        width: 165px;
        text-align: center;
        position: relative;
        top: 14px;
        left: 0px;
      }

      .menuCoins1 {
        width: 35px;
        height: 32px;
        position: absolute;
        top: 50px;
        right: 142px;
        animation: 0.7s myBreath linear infinite
      }

      .menuCoins2 {
        width: 30px;
        height: 21px;
        position: absolute;
        top: 13px;
        right: 111px;
        animation: 0.9s myBreath linear infinite
      }

      .menuCoins3 {
        width: 26px;
        height: 25px;
        position: absolute;
        bottom: 24px;
        right: 53px;
        animation: 1s myBreath linear infinite
      }

      .menuCoins4 {
        width: 20px;
        height: 29px;
        position: absolute;
        bottom: 39px;
        right: 20px;
        animation: 1.2s myBreath linear infinite
      }
    }

    .convite {
      width: 330px;
      height: 110px;
      background: url('../../public/image/home/conviteBg.webp') no-repeat;
      background-size: 100% 100%;
      margin: auto 11px;
      position: relative;

      p {
        width: 139px;
        text-align: center;
        position: relative;
        top: 12px;
        left: 16px;
      }

      .gifts {
        width: 50px;
        height: 52px;
        position: absolute;
        bottom: 4px;
        left: 25px;
        animation: rock 1.5s ease-in-out infinite;
      }
    }
  }

  .menuBox {
    margin: 43px auto;
    position: relative;

    ::v-deep .van-tabs__nav {
      background: transparent;
      border-radius: 25px;
      margin: 0 auto;
      height: 30px;
    }

    ::v-deep .van-tabs__nav--line {}

    ::v-deep .van-tab {
      padding: 0 10px !important;
      box-sizing: border-box;
      background: #272727;
      border-radius: 20px;
      color: #D9D9D9;
      margin: 0 7px;
    }

    ::v-deep .van-tab__pane {}

    ::v-deep .van-tab--active {
      color: #fff;
      border: none !important;
      height: 30px;
      background: #2FA1FF;
      border-radius: 20px;
    }

    ::v-deep .van-tabs__line {
      background-color: transparent !important;
    }

    ::v-deep .van-tabs__nav--complete {
      padding: 0 !important;
      box-sizing: border-box;
    }

    ::v-deep .van-tab__text {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    ::v-deep .van-tabs__nav--complete {
      padding-right: 30px !important;
      box-sizing: border-box !important;
    }

    .img0 {
      width: 26px;
      height: 34px;
    }

    .img1 {
      width: 51px;
      height: 23px;
    }

    .img2 {
      width: 29px;
      height: 29px;
    }

    .img3 {
      width: 30px;
      height: 39px;
    }

    .img4 {
      width: 31px;
    }

    .img5 {
      width: 36px;
    }

    .img6 {
      width: 29px;
    }

    .img7 {
      width: 30px;
    }

    .img8 {
      width: 44px;
      height: 24px;
    }

    .itemName {
      font-size: 21px;
    }

    .activeClass {
      .text {
        color: #fff !important;
      }
    }

    .game-list-page-block {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      flex-wrap: wrap;
      grid-gap: 20px;
      flex-direction: row;
      margin: 20px 10px;

      .imgBox {
        border-radius: 20px;
        position: relative;
        min-height: 240px;
        background-color: #1B2231;
      }

      @keyframes rolled {
        0% {
          left: -150px;
        }

        100% {
          left: 920px;
        }
      }

      .game-maintenance {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0;

        img {
          width: 60px;
          margin-bottom: 20px;
        }

        span {
          font-size: 24px;
          color: #94BDE3;
          font-weight: 700;
        }
      }

      img {
        width: 100%;
        border-radius: 20px;
        vertical-align: middle;
      }
    }

    .game-play-loading {
      position: absolute !important;
      background-color: #00000090 !important;
      top: 0;
      width: 100%;
      height: 100%;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    .play-btn {
      width: 100px !important;
      position: absolute;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      animation: play-scale 200ms ease-in;
    }

    .top_name {
      display: block;
      width: 110px !important;
      position: absolute;
      left: calc(50% - 55px);
      top: 10px;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #94BDE3;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .bottom_types {
      display: block;
      width: 100% !important;
      position: absolute;
      left: 0;
      bottom: 10px;
      color: #94BDE3;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
    }

    @keyframes play-scale {
      from {
        transform: scale(1.5)
      }

      to {
        transform: scale(1)
      }
    }

    .search {
      width: 70px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0B111F;
      position: absolute;
      top: 0;
      right: 0;
      box-shadow: -30px 0 50px #000;

      img {
        width: 34px;
        height: 34px;
      }
    }
  }

  ::v-deep .van-popup {
    background: #1D1D1D !important;
  }

  .endBtn {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    height: 83px;
  }

  .toClose {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B6B6B6;
    color: #000;
    font-size: 24px;
    border: none;
  }

  .toSearch {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DA314C;
    color: #fff;
    font-size: 24px;
    border: none;
  }

  .disabledClass {
    background: #410F17;
    color: #4C4C4C;
  }

  .game-search {
    position: relative;
    width: 698px;
    margin: 100px auto;
    font-size: 26px;

    .game-search-icon {
      position: absolute;
      top: 13px;
      right: 32px;
      color: #55657e;
    }

    input {
      width: 100%;
      height: 60px;
      border-radius: 10px;
      border: none;
      background-color: #282828;
      padding: 10px 88px 10px 30px;
      box-sizing: border-box;
      color: #FFFFFF;
    }
  }

  @keyframes rock {
    0% {
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg)
    }

    10% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg)
    }

    20% {
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg)
    }

    30% {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg)
    }

    35% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg)
    }

    40% {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg)
    }

    45% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg)
    }

    50% {
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg)
    }

    100% {
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg)
    }
  }

  @keyframes myBreath {
    0% {
      transform: scale(0.88);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.88);
    }
  }
</style>