import store from "@/store";
import state from "@/store/state";

function getChannel() {
    let channel = localStorage.getItem('channel');
    return channel;
}

function getPlatform() {
    let platform = localStorage.getItem('platform');
    return platform;
}

function getAfid_Ios() {
    switch (getChannel()) {
        case "ios_3025":
            if (window["webkit"] && window["webkit"].messageHandlers) {
                window['remainCall'] = (str) => {
                    let msg = JSON.parse(JSON.stringify(str));
                    store.commit('setAfId', msg.afid);
                }
                window["webkit"].messageHandlers.reminDesc.postMessage(JSON.stringify({
                    name: "startInfo",
                    message: ""
                }));
            } else {
                console.log("ios接口未注册！");
            }
            break;
        default:
    }
}

function getAfid_And() {
    switch (getChannel()) {
        case "and_1042":
            if (window["QRHD"] && window["QRHD"].QRHDJSBridge) {
                let a = window["QRHD"].QRHDJSBridge("getQRHDGaid", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_QRHDJSBridge接口未注册！");
            }
            break;
        case "and_1007":
            if (window["android"] && window["android"].JSRequestCasw) {
                let a = window["android"].JSRequestCasw("AFID", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
        case "and_1024":
            if (window["android"] && window["android"].JSRequestTxo) {
                let a = window["android"].JSRequestTxo("AFID", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_JSRequestTxo接口未注册！");
            }
            break;
        case "and_1009":
            if (window["GoJumpWin"] && window["GoJumpWin"].Jni_JS_Jave) {
                let a = window["GoJumpWin"].Jni_JS_Jave("AFID", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
        case "and_1017":
            if (window["android"] && window["android"].onReasonCall) {
                let a = window["android"].onReasonCall("getADetail", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
        case "and_1049":
            if (window["android"] && window["android"].onReasonCall) {
                let a = window["android"].onReasonCall("AFID", "");
                store.commit('setAfId', a);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
    }
}

function setKeepScreenOn_Ios() {
    switch (getChannel()) {
        case "ios_3025":
            if (window["webkit"] && window["webkit"].messageHandlers) {
                window["webkit"].messageHandlers.reminDesc.postMessage(JSON.stringify({
                    name: "keepLight",
                    message: "ON"
                }));
            } else {
                console.log("ios接口未注册！");
            }
            break;
        default:
    }
}

function setKeepScreenOn_And() {
    switch (getChannel()) {
        case "and_1042":
            if (window["QRHD"] && window["QRHD"].QRHDJSBridge) {
                window["QRHD"].QRHDJSBridge("screenKeepLight", "ON");
            } else {
                console.log("Android_QRHDJSBridge接口未注册！");
            }
            break;
        case "and_1007":
            if (window["android"] && window["android"].JSRequestCasw) {
                window["android"].JSRequestCasw("KEEP_SCREEN_OPEN", "ON");
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
        case "and_1024":
            if (window["android"] && window["android"].JSRequestTxo) {
                window["android"].JSRequestTxo("KEEP_SCREEN_OPEN", "ON");
            } else {
                console.log("Android_JSRequestTxo接口未注册！");
            }
            break;
        case "and_1009":
            if (window["GoJumpWin"] && window["GoJumpWin"].Jni_JS_Jave) {
                window["GoJumpWin"].JSRequestTxo("KEEP_SCREEN_OPEN", "ON");
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
    }

}
//横屏：LANDSCAPE  竖屏：PORTRAIT
function setOrientation_Ios(Orientation) {
    switch (getChannel()) {
        case "ios_3025":
            if (window["webkit"] && window["webkit"].messageHandlers) {
                if (Orientation == 'PORTRAIT') {
                    window["webkit"].messageHandlers.reminDesc.postMessage(JSON.stringify({
                        name: "setRotate",
                        message: "PORTRAIT"
                    }));
                }
                if (Orientation == 'LANDSCAPE') {
                    window["webkit"].messageHandlers.reminDesc.postMessage(JSON.stringify({
                        name: "setRotate",
                        message: "LANDSCAPE"
                    }));
                }
            } else {
                console.log("ios接口未注册！");
            }
            break;
        default:
    }

}

function setOrientation_And(Orientation) {
    switch (getChannel()) {
        case "and_1042":
            if (window["QRHD"] && window["QRHD"].QRHDJSBridge) {
                window["QRHD"].QRHDJSBridge("screenChange", Orientation);
            } else {
                console.log("Android_QRHDJSBridge接口未注册！");
            }
            break;
        case "and_1007":
            if (window["android"] && window["android"].JSRequestCasw) {
                window["android"].JSRequestCasw("CHANGE_ORITATION", Orientation);
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
        case "and_1024":
            if (window["android"] && window["android"].JSRequestTxo) {
                window["android"].JSRequestTxo("CHANGE_ORITATION", Orientation);
            } else {
                console.log("Android_JSRequestTxo接口未注册！");
            }
            break;
        case "and_1009":
            if (window["GoJumpWin"] && window["GoJumpWin"].Jni_JS_Jave) {
                window["GoJumpWin"].Jni_JS_Jave("CHANGE_ORITATION", Orientation);
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
        case "and_1017":
            if (window["android"] && window["android"].onReasonCall) {
                window["android"].onReasonCall("setRequestedOrientation", Orientation);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
        case "and_1049":
            if (window["android"] && window["android"].onReasonCall) {
                window["android"].onReasonCall("CHANGE_ORITATION", Orientation);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
    }
}

function dotEvent_Ios(key, data) {
    switch (getChannel()) {
        case 'ios_3025':
            if (window["webkit"] && window["webkit"].messageHandlers) {
                let message = {
                    key: key,
                    value: data
                }
                console.log("ios打点===>", JSON.stringify(message))
                let obj = {
                    name: "reportEvt",
                    message: JSON.stringify(message)
                };
                window["webkit"].messageHandlers.reminDesc.postMessage(JSON.stringify(obj));
            } else {
                console.log("ios接口未注册！");
            }
            break;
    }
}

function dotEvent_And(key, data) {
    switch (getChannel()) {
        case 'and_1042':
            if (window["QRHD"] && window["QRHD"].QRHDJSBridge) {
                let message = {
                    key: key,
                    value: data
                }
                window["QRHD"].QRHDJSBridge("logQRHDEvent", JSON.stringify(message));
            } else {
                console.log("Android_QRHDJSBridge接口未注册！");
            }
            break;
        case 'and_1007':
            if (window["android"] && window["android"].JSRequestCasw) {
                let message = {
                    key: key,
                    value: data
                }
                window["android"].JSRequestCasw("DOT_EVENT", JSON.stringify(message));
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
        case 'and_1024':
            if (window["android"] && window["android"].JSRequestTxo) {
                let message = {
                    key: key,
                    value: data
                }
                window["android"].JSRequestTxo("DOT_EVENT", JSON.stringify(message));
            } else {
                console.log("Android_JSRequestTxo接口未注册！");
            }
            break;
        case 'and_1009':
            if (window["GoJumpWin"] && window["GoJumpWin"].Jni_JS_Jave) {
                let message = {
                    key: key,
                    value: data
                }
                window["GoJumpWin"].Jni_JS_Jave("DOT_EVENT", JSON.stringify(message));
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
        case 'and_1017':
            if (window["android"] && window["android"].onReasonCall) {
                let message = {
                    key: key,
                    value: data
                }
                window["android"].onReasonCall("sendToA", JSON.stringify(message));
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
        case 'and_1049':
            if (window["android"] && window["android"].onReasonCall) {
                let message = {
                    key: key,
                    value: data
                }
                window["android"].onReasonCall("DOT_EVENT", JSON.stringify(message));
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
    }
}

function shareText_And(text) {
    switch (getChannel()) {
        case 'and_1007':
            if (window["android"] && window["android"].JSRequestCasw) {
                //TODO
                window["android"].JSRequestCasw("SHARE_GAME", text);
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
    }
}

function setClipBoardStr_And(text) {
    switch (getChannel()) {
        case 'and_1007':
            if (window["android"] && window["android"].JSRequestCasw) {
                //TODO
                window["android"].JSRequestCasw("SET_CLIPBOARD", text);
            } else {
                console.log("Android_JSRequestCasw接口未注册！");
            }
            break;
    }
}

function openWv_And(url) {
    switch (getChannel()) {
        case "and_1009":
            if (window["GoJumpWin"] && window["GoJumpWin"].Jni_JS_Jave) {
                window["GoJumpWin"].Jni_JS_Jave("OPEN_URL", url);
            } else {
                console.log("Android_Jni_JS_Jave接口未注册！");
            }
            break;
        case "and_1024":
            if (window["android"] && window["android"].JSRequestTxo) {
                window["android"].JSRequestTxo("OPEN_WEB", url);
            } else {
                console.log("Android_JSRequestTxo接口未注册！");
            }
            break;
        case "and_1017":
            if (window["android"] && window["android"].onReasonCall) {
                window["android"].onReasonCall("openBrowser", url);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
        case "and_1049":
            if (window["android"] && window["android"].onReasonCall) {
                window["android"].onReasonCall("OPEN_WEB", url);
            } else {
                console.log("Android_onReasonCall接口未注册！");
            }
            break;
    }
}
































//获取afid
export function getAfIdByChannel() {
    if (getChannel().indexOf('ios') != -1) {
        getAfid_Ios();
    } else if (getChannel().indexOf('and') != -1) {
        getAfid_And();
    }
}
//保持屏幕长亮
export function setKeepScreenOnByChannel() {
    if (getChannel().indexOf('ios') != -1) {
        setKeepScreenOn_Ios();
    } else if (getChannel().indexOf('and') != -1) {
        setKeepScreenOn_And();
    }
}
//设置屏幕方向
export function setOrientationByChannel(Orientation) {
    if (getChannel().indexOf('ios') != -1) {
        setOrientation_Ios(Orientation);
    } else if (getChannel().indexOf('and') != -1) {
        setOrientation_And(Orientation);
    }
}
//打点
export function dotEventByChannel(key, eventData) {
    if (getChannel().indexOf('ios') !== -1) {
        dotEvent_Ios(key, eventData);
    } else if ((getChannel().indexOf('and') !== -1)) {
        dotEvent_And(key, eventData);
    }
}
// 打开tg
export function openWvByChannel(url) {
    if (url == 'https://t.me/rico13777') {
        setTimeout(() => {
            store.commit('setShowPopupPanel', {
                show: true,
                componentName: 'Tgcb',
                option: {}
            });
        }, 3000);
    }
    if (getChannel().indexOf('ios') !== -1) {

    } else if ((getChannel().indexOf('and') !== -1)) {
        openWv_And(url);
    } else if ((getChannel().indexOf('w2a') !== -1 || getChannel().indexOf('pwa') !== -1) && getPlatform() == 'APK') {
        if (window.NativeInterface) {
            window.NativeInterface.JStoRico("OPEN_WEB", url);
        }
    } else {
        window.open(url, '_blank');
    }

}
//分享文字
export function shareTextByChannel(text) {
    if (getChannel().indexOf('ios') !== -1) {

    } else if ((getChannel().indexOf('and') !== -1)) {
        shareText_And(text);
    }
}
//复制制定字符串到剪贴板
export function setClipBoardStrByChannel(text) {
    if (getChannel().indexOf('ios') !== -1) {

    } else if ((getChannel().indexOf('and') !== -1)) {
        setClipBoardStr_And(text);
    }
}

export function dotGTag(eventName, eventData) {
    let common_pars = {
        'user_id_short': state.userData.uid,
        'utm_source': localStorage.getItem('channel'),
        'user_name': state.userData.name,
        'af_id': state.af_id
    }
    Object.assign(common_pars, eventData);
    console.log("gtag====>", eventName, common_pars);
    gtag('event', eventName, common_pars);
}

export function dotKwai(eventName, eventData) {
    let kwai_id = "252465731211617"
    kwaiq.instance(kwai_id).track(eventName, eventData);
}