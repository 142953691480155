import { render, staticRenderFns } from "./FreePlay.vue?vue&type=template&id=03db7411&scoped=true"
import script from "./FreePlay.vue?vue&type=script&setup=true&lang=js"
export * from "./FreePlay.vue?vue&type=script&setup=true&lang=js"
import style0 from "./FreePlay.vue?vue&type=style&index=0&id=03db7411&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03db7411",
  null
  
)

export default component.exports