import { post } from "@/api/http";
import { channelVisit } from "@/api/api";

export const buryingLog = p => {
    const key = localStorage.getItem('BuryingParams');
    if (!key) return;
    const params = key.split('___');
    const id = params[1];

    if (!id || !params[0]) return;
    privateBuryingLog(params[0], p);
    // if (params[0].toLowerCase() === 'kwai') {
    //     return post('https://www.adsnebula.com/log/common/api', {
    //         clickid: localStorage.getItem('click_id'),
    //         pixelId: id,
    //         access_token: 'FnsuMOvQMfRdxplc1gIt3vLcolpoeRF8wA9+PHAX43I=',
    //         testFlag: false,
    //         trackFlag: false,
    //         is_attributed: 1,
    //         mmpcode: 'PL',
    //         pixelSdkVersion: '9.9.9',
    //         ...p
    //     })
    // } else if (params[0].toLowerCase() === 'fb') {
    //     let param = {
    //         event_id: localStorage.getItem('only_uuid'),
    //         event_name: p.fb_event_name ? p.fb_event_name : 'ViewContent',
    //         event_time: parseInt(new Date().getTime() / 1000),
    //         action_source: 'website',
    //         event_source_url: window.location.href,
    //         user_data: {
    //             client_user_agent: window.navigator.userAgent,
    //             client_ip_address: '0.0.0.0',
    //         },
    //     }
    //     if (p.fb_custom_data) {
    //         param.custom_data = p.fb_custom_data
    //     }
    //
    //     let access_token = 'EAADkMeNJA0QBO9IMVwwHg1YgIWaGjQTeN6hclm9ZCvhWmw1lZAGuMomCBdcmn1tE6mBzCoWZBXp5jLdZAMKUuhl9zZAsZBcEMamZArSwCOZAe22ZC0Pndf26erUH1HJp81THrVaGs7RL0IqqzWf67rPhQMrKWcrdVypiiFhRDcwQTSC6U14PIkQwtosoplQI4UM6LhwZDZD';
    //     if (id === '1744567846014052') {
    //         access_token = 'EAAOC8M6J7o8BOyyLZAyFlSIA7l8ZCHULifMEL84HrOPP6lDhXKjLzZBrKHIJv9lOOhxGfXavGWznoKBvip1kWAJCPicS8xt8RrfiPtdxZBpKZAmPOKDzU1yElgZCYqcdtSfqpqM8muxLjnjGLIQsO9TLWxEzVhzlcGEBnZCgGP2n9zoZCx4NaiR15cmJx4ZB5tnEnZBwZDZD'
    //     }
    //
    //     return post('https://graph.facebook.com/v17.0/' + id + '/events', {
    //         data: [param],
    //         access_token: access_token,
    //         // test_event_code: 'TEST19990',
    //     })
    // }
}

const privateBuryingLog = (channelName, params) => {
    window.boat = new Date().getTime();
    channelVisit({
        event_name: params.event_name,
        source: window.location.href,
        channel: channelName,
        only_uuid: localStorage.getItem('only_uuid')
    }).then((res) => {

    })
}