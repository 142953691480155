<template>
    <div v-if="client.indexOf('ios') === -1 && client.indexOf('android_app') === -1">
        <div class="domain-box" v-if="false">
            <hr class="line1" />
            <h4 class="title">OR LOGIN DIRECTLY WITH</h4>
            <hr class="line2" />
        </div>
        <div class="otherApp">
            <img src="../../public/image/home/fb.png" alt class="appIcon" @click="jumpFb" />
            <img
                src="../../public/image/home/whatsApp.png"
                alt
                style="display: none;"
                @click="jumpWhatsApp"
            />
            <img src="../../public/image/home/tg.png" alt class="appIcon" @click="jumpTg" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { openWvByChannel } from "@/native/native"
export default {
    name: '',
    components: {},
    props: {},
    data() {
        return {
        }
    },
    watch: {},
    computed: {
        ...mapState(['client'])
    },
    methods: {
        jumpFb() {
            window.open("https://www.facebook.com/rico13vip");
        },
        jumpTg() {
            // window.open("https://t.me/rico13777");
            openWvByChannel('https://t.me/rico13777');
        },
        jumpWhatsApp() {

        }
    },
    created() {

    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.domain-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 0px 0;
    .line1 {
        width: 100px;
        border: none;
        height: 1px;
        background-color: #14202e;
    }
    .line2 {
        width: 100px;
        border: none;
        height: 1px;
        background-color: #14202e;
    }

    h4 {
        font-size: 30px;
        color: white;
    }
}

.otherApp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;
    img.appIcon {
        width: 68px;
        height: 67px;
    }
}
</style>