<template>
    <van-popup class="van-pop-custom" v-model="show" closeable>
        <div class="popup-panel">
            <component v-if="show" :message="option" :is="currentComponent" />
        </div>
    </van-popup>
</template>

<script>
import { mapState } from 'vuex';

import Confirm from '@/views/popup/Confirm.vue';
import BindTg from '@/views/popup/BindTg.vue';
import WidthDrawCount from '@/views/popup/WidthDrawCount.vue';
import VipShow from '@/views/popup/VipShow.vue';
import WidthDrawSpeed from '@/views/popup/WidthDrawSpeed.vue';
import WidthDrawRate from '@/views/popup/WidthDrawRate.vue';
import UpgradeRewards from '@/views/popup/UpgradeRewards.vue';
import Tgcb from '@/views/popup/Tgcb.vue';
import WidthDrwaQuota from '@/views/popup/WidthDrwaQuota.vue';
import InviteIntro from '@/views/popup/InviteIntro.vue';
import InviteIntroed from '@/views/popup/InviteIntroed.vue';
import PixConfirm from '@/views/popup/PixConfirm.vue';

export default {
    name: 'PopupLogic',
    components: {
        Confirm,
        BindTg,
        WidthDrawCount,
        VipShow,
        WidthDrawSpeed,
        WidthDrawRate,
        UpgradeRewards,
        Tgcb,
        WidthDrwaQuota,
        InviteIntro,
        InviteIntroed,
        PixConfirm,
    },
    props: {},
    data() {
        return {
            show: false,
            currentComponent: '',
            option: {}
        }
    },
    watch: {
        showPopupPanel(obj) {
            console.log(obj);
            this.show = obj.show;
            if (obj.show) {
                this.currentComponent = obj.componentName;
                this.option = obj.option;
            }
        }
    },
    computed: {
        ...mapState(['showPopupPanel'])
    },
    methods: {},
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.popup-panel {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
.van-pop-custom {
    background: rgba(0, 0, 0, 0);
}
</style>