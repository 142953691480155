import { decryptFromBase64, encryptToBase64 } from '@/utis/xxtea';
import store from "@/store";
var ConnectionState;
(function (ConnectionState) {
    ConnectionState[ConnectionState["CONNECTING"] = 0] = "CONNECTING";
    ConnectionState[ConnectionState["CONNECTED"] = 1] = "CONNECTED";
    ConnectionState[ConnectionState["DISCONNECTED"] = 2] = "DISCONNECTED";
    ConnectionState[ConnectionState["RECONNECTING"] = 3] = "RECONNECTING";
    ConnectionState[ConnectionState["RECOVERY"] = 4] = "RECOVERY";
})(ConnectionState || (ConnectionState = {}));
var WS = /** @class */ (function () {
    function WS(options) {
        this.wsUrl = options.wsUrl;
        this.options = options;
        this.wsSocket = null;
        this.state = ConnectionState.DISCONNECTED;
        this.prevState = ConnectionState.DISCONNECTED;
        this.times = 60 * 1000;
        this.timer = 0;
        this.autoReconnected = true;
        this.lockReconnect = false;
        this.retryTimerId = null;
        this.retryCount = 0;
        this.maxRetryCount = 30;
        this.pendingRequests = new Array();
        this.successCallbacks = new Map();
        this.errorCallbacks = new Map();
        this.heartCheck = this.initHeartCheck();
        this.requestArrayList = new Array();
    }
    // 建立websocket连接
    WS.prototype.connect = function () {
        if (!this.wsUrl && !this.wsUrl.length) {
            console.error('Websocket url is empty!');
            return false;
        }
        this.retryTimerId && clearTimeout(this.retryTimerId);
        if (!this.wsSocket) {
            this.updateWsStateChange(ConnectionState.CONNECTING);
            this.wsSocket = new WebSocket(this.wsUrl);
            if (this.wsUrl) {
                this.wsSocket.onopen = this.onConnect.bind(this);
                this.wsSocket.onmessage = this.onWsMessage.bind(this);
                this.wsSocket.onclose = this.onWsClose.bind(this);
                this.wsSocket.onerror = this.onWsError.bind(this);
            }
        }
        return !!this.wsSocket;
    };
    // 发送消息
    WS.prototype.sendMessage = function (request, successCb, errorCb) {
        if (!request) {
            return false;
        }
        var requestId = request.Name;
        var seqId = '_' + request.Seq;
        if (this.wsSocket) {
            console.debug('发送消息==>' +
                'message: ' + JSON.stringify(request));
            // 对GetGameList处理
            if (requestId === 'GetGameList')
                requestId += request.Msg.channel;
            this.requestArrayList.push({
                name: requestId,
                timerStamp: new Date().getTime(),
                requestBody: JSON.stringify(request)
            });
            // console.warn("发送消息===>", JSON.stringify(requestId + seqId))
            request = encryptToBase64(JSON.stringify(request), 'lk13*%s2293k$kb!');
            if (this.wsSocket.readyState < 1 || this.wsSocket.readyState === 3) {
                this.pendingRequests.push(request);
            }
            else {
                this.send(request);
            }
            if (successCb && typeof successCb === 'function') {
                this.successCallbacks.set(requestId + seqId, successCb);
            }
            if (errorCb && typeof errorCb === 'function') {
                this.errorCallbacks.set(requestId + seqId, errorCb);
            }
            return true;
        }
    };
    /**
     * 发送消息 + 超时机制
     */
    WS.prototype.send = function (msg) {
        var _this = this;
        if (this.wsSocket)
            this.wsSocket.send(msg);
        if (!this.timer) {
            this.timer = window.setTimeout(function () {
                console.error('server timeout');
                if (typeof _this.options.onError === 'function') {
                    _this.options.onError(new Error('server timeout'));
                }
            }, this.times);
        }
    };
    // 关闭websocket连接
    WS.prototype.close = function () {
        if (this.wsSocket) {
            this.autoReconnected = false;
            this.pendingRequests = [];
            this.wsSocket.close();
            this.resetWs();
            this.timer && clearTimeout(this.timer);
            this.retryTimerId && clearTimeout(this.retryTimerId);
            // @ts-ignore
            this.heartCheck.reset();
            // this.heartCheck = null;
            console.log('close websocket');
        }
    };
    // 清空websocket的绑定事件
    WS.prototype.resetWs = function () {
        if (this.wsSocket) {
            this.wsSocket.onmessage = null;
            this.wsSocket.onclose = null;
            this.wsSocket.onerror = null;
            this.wsSocket.onopen = null;
            this.wsSocket = null;
        }
    };
    /**
     * 连接建立成功时触发
     * @private
     */
    WS.prototype.onConnect = function () {
        this.heartCheck.start();
        this.prevState = this.state;
        store.dispatch('UserInfo');
        if (this.retryTimerId) {
            clearTimeout(this.retryTimerId);
            this.retryTimerId = null;
            this.updateWsStateChange(ConnectionState.RECOVERY);
        }
        else {
            this.updateWsStateChange(ConnectionState.CONNECTED);
        }
        if (this.pendingRequests.length) {
            var req = void 0;
            while ((req = this.pendingRequests.shift())) {
                this.send(req);
            }
        }
    };
    /**
     * 收到服务端消息时触发
     */
    WS.prototype.onWsMessage = function (event) {
        var _this = this;
        this.timer && clearTimeout(this.timer);
        this.heartCheck && this.heartCheck.start();
        var decryptData = JSON.parse(decryptFromBase64(event.data, 'lk13*%s2293k$kb!'));
        if (decryptData.name !== 'Heartbeat') {
            var requestList = this.requestArrayList.filter(function (item, index) {
                if (decryptData.name.indexOf('GetGameList') !== -1 &&
                    item.name === decryptData.name + (decryptData.name === 'GetGameList' ? decryptData.data.channel : '')) {
                    _this.requestArrayList.splice(index, 1);
                    return item;
                }
                if (item.name === decryptData.name) {
                    _this.requestArrayList.splice(index, 1);
                    return item;
                }
            });
            if (requestList.length > 0) {
                var request = requestList[0];
                var now = new Date().getTime();
                console.debug('%c ' + (decryptData.code === 200 ? '🌎 ' : '🚨 ') +
                    'message: ' + decryptData.name + ' Time: ' + (now - request.timerStamp) + ' ms \n' +
                    '%c request body: ' + request.requestBody + '\n %o', 'background: #001938;padding: 5px 8px 5px 2px;border-radius: 5px;color: #fff', 'background: green;padding: 2px;border-radius: 5px;color: #fff;margin: 5px 0px 5px -10px', decryptData);
            }
            else {
                console.debug('%c ' + (decryptData.code === 200 ? '📌 ' : '🚨 ') +
                    'message: ' + decryptData.name + ' \n', 'background: #001938;padding: 5px 8px 5px 2px;border-radius: 5px;color: #fff', decryptData);
            }
        }
        if (typeof this.options.onMessage === 'function' && decryptData.name !== 'Heartbeat') {
            this.options.onMessage(decryptData);
        }
        // 根据消息id判断是否要执行请求回调
        var id = decryptData.name + '_' + decryptData.seq;
        if (decryptData.name === 'GetGameList') {
            id = decryptData.name + decryptData.data.channel + '_' + decryptData.seq;
            ;
        }
        // console.error("接收消息===>", decryptData)
        if (id && decryptData.seq != -1) {
            var successCb = this.successCallbacks.get(id);
            var errorCb = this.errorCallbacks.get(id);
            // 成功
            if (successCb) {
                successCb(decryptData);
            }
            // 失败
            if (errorCb) {
                errorCb(decryptData);
            }
            this.successCallbacks.delete(id);
            this.errorCallbacks.delete(id);
        }
        // console.log(this.successCallbacks)
    };
    /**
     * 连接关闭时触发
     */
    WS.prototype.onWsClose = function (event) {
        store.commit('setWsConnect', false);
        console.debug('%c 🚫 message: websocket close ' + '%o', 'background: #001938;padding: 5px 8px 5px 2px;border-radius: 10px;color: #fff', event);
        this.heartCheck && this.heartCheck.reset();
        this.pendingRequests = [];
        this.options.onClose && this.options.onClose(event);
        this.updateWsStateChange(ConnectionState.DISCONNECTED);
    };
    /**
     * 连接报错时触发
     */
    WS.prototype.onWsError = function (event) {
        console.debug('%c ❌ message: websocket error ' + '%o', 'background: #001938;padding: 5px 8px 5px 2px;border-radius: 10px;color: #fff', event);
        this.heartCheck && this.heartCheck.reset();
        this.pendingRequests = [];
        this.options.onError && this.options.onError(event);
        this.updateWsStateChange(ConnectionState.DISCONNECTED);
    };
    /**
     * 更新websocket连接状态
     */
    WS.prototype.updateWsStateChange = function (state) {
        this.prevState = this.state;
        this.state = state;
        if (this.options.onWsStateChange && typeof this.options.onWsStateChange === 'function') {
            this.prevState !== this.state && this.options.onWsStateChange(this.prevState, this.state, this.retryCount);
        }
    };
    /**
     * websocket 重连
     */
    WS.prototype.reconnect = function () {
        var _this = this;
        if (!this.autoReconnected && this.lockReconnect)
            return;
        this.lockReconnect = true;
        this.retryTimerId && clearTimeout(this.retryTimerId);
        if (this.retryCount < this.maxRetryCount) {
            this.retryTimerId = window.setTimeout(function () {
                _this.retryCount++;
                console.log("".concat(new Date().toLocaleString(), " Try to reconnect, count: ").concat(_this.retryCount));
                _this.updateWsStateChange(ConnectionState.RECONNECTING);
                _this.resetWs();
                _this.connect();
                _this.lockReconnect = false;
            }, this.getReconnectDelay(this.retryCount));
        }
        else {
            console.warn("SDK has tried reconnect signal channel for ".concat(this.maxRetryCount, " times, but all failed. please check your network"));
            if (this.options.onWsReconnectFailed) {
                this.options.onWsReconnectFailed();
            }
        }
    };
    /**
     * 心跳机制
     */
    WS.prototype.initHeartCheck = function () {
        var that = this;
        return {
            timeout: 10 * 1000,
            serverTimeout: 20 * 1000,
            timer: 0,
            serverTimer: 0,
            reset: function () {
                clearTimeout(this.timer);
                clearTimeout(this.serverTimer);
                this.timer = 0;
                this.serverTimer = 0;
                return this;
            },
            start: function () {
                var _this = this;
                this.reset();
                this.timer = setTimeout(function () {
                    var HeartbeatMsg = { Name: 'Heartbeat', Msg: { CTime: new Date().getTime() } };
                    var HeartbeatEnCode = encryptToBase64(JSON.stringify(HeartbeatMsg), 'lk13*%s2293k$kb!');
                    that.wsSocket && that.wsSocket.send(HeartbeatEnCode);
                    if (!_this.serverTimer) {
                        _this.serverTimer = setTimeout(function () {
                            console.log(new Date().toLocaleString(), 'not received pong, close the websocket');
                            that.reconnect();
                        }, _this.serverTimeout);
                    }
                }, this.timeout);
            }
        };
    };
    /**
     * 获取重连的定时时间
     * @param count 重连次数
     */
    WS.prototype.getReconnectDelay = function (count) {
        var t = Math.round(count / 2) + 1;
        return t > 6 ? 13 * 1000 : 3 * 1000;
    };
    return WS;
}());
export { WS };
