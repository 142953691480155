import axios from "axios";
import {
    Toast
} from 'vant';
import json from '../ErrorCodeMap/Portugal.json'
import store from "../store/index";

axios.defaults.baseURL = process.env.VUE_APP_BASEURL

axios.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem('token')
        return config
    },
    error => {
        return Promise.error(error)
    })

axios.interceptors.response.use(
    response => {
        if (response.data.Authorization) {
            localStorage.setItem('token', response.data.Authorization)
            store.commit('setToken', response.data.Authorization)
        }
        if (response.data.code === 1013 || response.data.code === 1018) {
            localStorage.setItem('token', '');
            localStorage.setItem('userData', '');
            store.commit('USERDATA', '');
            store.commit('setToken', '')
            return Promise.resolve(response)
        }
        if (response.data.code && response.data.code !== 200) {
            Toast({
                duration: 3000,
                message: json[response.data.code],
                position: 'top',
            });
        }
        return Promise.resolve(response)
    }
)

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}