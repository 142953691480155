var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { installPwaBonus } from "@/api/api";
export default defineComponent({
    name: "downLoadAppTips",
    data: function () {
        return {
            downLoadTips: true,
            isShowDownLoadTips: false,
            closeIosTipsBlock: false,
            showPWATips: false,
        };
    },
    watch: {
        isShowPWATips: {
            handler: function (val) {
                this.showPWATips = val;
            },
            immediate: true,
        },
    },
    computed: __assign({}, mapState(["client", "token", "isShowPWATips"])),
    created: function () {
        this.downLoadTips =
            localStorage.getItem("download_app_tips") === "true";
    },
    methods: {
        changeDownLoadTips: function (isShow) {
            localStorage.setItem("download_app_tips", isShow ? "true" : "false");
        },
        closeDownAppTips: function () {
            if (this.client === "android_app") {
                this.downLoadTips = true;
                localStorage.setItem("download_app_tips", "true");
            }
            else if (this.client.indexOf("ios") !== -1) {
                this.closeIosTipsBlock = true;
            }
            else {
                this.downLoadTips = true;
            }
        },
        closeDownAppIos: function () {
            this.closeIosTipsBlock = false;
            this.downLoadTips = true;
        },
        iosTips: function () {
            this.closeIosTipsBlock = false;
            this.isShowDownLoadTips = true;
            this.downLoadTips = true;
        },
        downLoadAPP: function () {
            if (this.client === "Android") {
                this.downLoadApk();
            }
            else if (this.client.indexOf("ios") !== -1) {
                this.isShowDownLoadTips = true;
            }
        },
        downLoadApk: function () {
            if (this.token) {
                window.open(this.APK_URL);
                this.downLoadTips = true;
                localStorage.setItem("download_app_tips", "true");
            }
            else {
                this.$store.commit("IN_OR_UP", "up");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        closePWATip: function () {
            this.$store.commit("setIsShowPWATips", false);
        },
        installPWA: function () {
            var _this = this;
            if (!this.token) {
                this.$store.commit("setIsShowPWATips", false);
                this.$store.commit("setMenuBlockShow", false);
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
                return;
            }
            // 触发安装提示
            console.log(this.deferredPrompt);
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice.then(function (choiceResult) {
                    if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                        installPwaBonus()
                            .then(function (res) {
                            _this.$store.commit("setIsShowPWATips", false);
                            _this.deferredPrompt = null;
                        })
                            .catch(function (err) { });
                    }
                    else {
                        console.log("User dismissed the install prompt");
                    }
                });
            }
        },
    },
    mounted: function () {
        var _this = this;
        setTimeout(function () {
            var cl = localStorage.getItem("channel");
            if (cl == "pwa" || cl == "and_1024") {
                _this.downLoadTips = true;
            }
        }, 500);
    },
});
