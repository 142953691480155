<template>
    <div class="myDiv" v-if="show">
        <div class="outbox">
            <div @click="seguroBtn" class="seguro">Conferir</div>
            <van-icon @click="incicioBtn" class="close-panel" name="close" size="30" color="#fff" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { dotGTag } from '@/native/native';

export default {
    name: 'VipChllengePop',
    components: {},
    props: {},
    data() {
        return {
            show: false,
        }
    },
    watch: {
        vipChallengePop(val) {
            this.show = val;
        }
    },
    computed: {
        ...mapState(["vipChallengePop"]),
    },
    methods: {
        incicioBtn() {

            console.warn("是否EMIT", this.$store.state.isemit)
            this.$store.state.isemit ? this.$emit('incicioBtn') : '';
            this.$store.commit("setVipChallengePop", false);
        },
        seguroBtn() {
            this.incicioBtn();
            dotGTag('vip_up_join_vip', {})
            this.$store.commit("setVipChallenge", true);
        },
        open() {
            const currentDate = new Date().toLocaleDateString();
            localStorage.setItem('isPopVip', currentDate);
            this.$store.commit("setVipChallengePop", true);
        }
    },
    created() { },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
.myDiv {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    .outbox {
        width: 645px;
        min-height: 730px;
        background: url("../../../public/image/newvip/vipcgpop.webp") no-repeat;
        background-size: 100% 100%;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: eject-pop 0.3s ease-in-out;

        .close-panel {
            position: absolute;
            bottom: -80px;
        }

        .seguro {
            width: 527px;
            height: 80px;
            background: #ffc500;
            border-radius: 10px;
            text-align: center;
            line-height: 80px;
            margin-top: 600px;
            color: #2e0000;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}
@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>