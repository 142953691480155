<template>
    <div class="menu-container">
        <div class="menu-context">
            <div class="mc-topbar">
                <img src="../../public/image/home/logo.png" alt />
            </div>
            <div style="height: 61px;" v-if="!token"></div>
            <div class="mc-user" v-if="token">
                <div class="mscu-avartar" @click="chooseAvatars = !chooseAvatars">
                    <img :src="IMAGE_URL + 'header/' + (userData.avatar || 0) + '.jpg'" alt />
                    <div class="change-avatar" v-if="avatarLoading">
                        <van-loading />
                    </div>
                </div>
                <div class="avatar-list" v-if="chooseAvatars">
                    <img
                        v-for="item in 10"
                        @click="changeAvatar(item - 1)"
                        :src="IMAGE_URL + 'header/' + (item - 1) + '.jpg'"
                        alt
                    />
                </div>
                <div class="mscu-vip-box">
                    <img :src="require(`../../public/image/newvip/xz${vip_lv}.webp`)" alt />
                    <div class="mscu-vip">VIP {{ vip_lv }}</div>
                </div>
                <div class="mscu-u">
                    <span>User ID:</span>
                    <input type="text" :value="getUserData?getUserData.uid:''" ref="uid" readonly />
                    <img src="../../public/image/menu/copyid.webp" alt @click="copyUid" />
                </div>
                <div class="mscu-name">
                    <input type="text" @blur="onBlur" v-model="userName" />
                    <img src="../../public/image/menu/editusername.webp" alt />
                </div>
            </div>
            <div class="adsArray">
                <div class="ads1" @click="openTg">
                    <span>
                        Entre canal de
                        telegram para
                        obter R$ 100
                    </span>
                </div>
                <div class="ads2" @click="openWheel">
                    <span>RICO13 WHEEL</span>
                    <img src="../../public/image/menu/ads2_wheel.png" alt class="ads2_wheel" />
                </div>
                <div class="ads3" @click="openVip('/newvip')">
                    <span>CASH BACK</span>
                </div>
                <div class="ads4" @click="$router.push('/share')">
                    <span>CONVITE</span>
                </div>
                <div class="ads5" @click="$router.push('/tournament')">
                    <span>TORNEIO</span>
                </div>
            </div>
            <div class="deliver"></div>
            <div class="otherGame">
                <div v-for="(val, k) in topTitle">
                    <div class="lc-box" v-if="!val.hasChildren">
                        <img src="../../public/image/menu/yonghuhaoyou.webp" alt />
                        <div class="topItems" @click="clickItem(0)">{{ val.name }}</div>
                    </div>
                    <div v-else class="listContent">
                        <div class="lc-box">
                            <img src="../../public/image/menu/wangyou.webp" alt />
                            <div class="topItems" @click="clickItem(1)">{{ val.name }}</div>
                        </div>
                        <van-collapse v-model="activeNames">
                            <van-collapse-item
                                v-for="(item, index) in dataList"
                                :key="index"
                                :name="index"
                            >
                                <template #title>
                                    <div class="items">
                                        <img :src="item.titleImg" alt class="titleImg" />
                                        <span>{{ item.title }}</span>
                                    </div>
                                </template>
                                <ul class="menuListUl">
                                    <li v-for="(c_item, c_index) in item.subList" :key="c_index">
                                        <div
                                            class="items"
                                            @click="toPage(c_item.name)"
                                            style="margin-top: 10px;"
                                        >
                                            <img :src="c_item.icon" alt class="titleIcon" />
                                            <span>{{ c_item.name }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
            </div>
            <div class="menu-item-box">
                <div class="menu-item" v-for="item in menuList" @click="jumpUrl(item)">
                    <!-- <img :src="require('../../public/image/menupop/' + item.path)" alt /> -->
                    <span>{{ item.name }}</span>
                    <span v-show="item.name == 'Notificações'" class="nums">{{messageNumber}}</span>
                </div>
            </div>
            <div class="down-icon" @click="downLoadApk">
                <img src="../../public/image/downapp/down-app-back.png" />
            </div>
            <div class="login-out" @click="log_out">
                <img src="../../public/image/menu/tuichu.webp" alt />
                <span>Sair</span>
            </div>
            <div style="height: 53px;"></div>

            <!-- <div
                class="download"
                @click="downLoadApk"
                v-if="client.indexOf('ios') === -1 && client.indexOf('android_app') === -1 && downBtn"
            >
                <img src="../../public/image/menu/downImg.png" alt />
                <span>Baixar o APP</span>
            </div>
            <div
                style="display: flex;justify-content: center;align-items: center;"
                v-if="client.indexOf('ios') === -1 && client.indexOf('android_app') === -1 && downBtn"
            >
                <p style="color: white; margin-top: 10px;font-size: 12px;">Baixe e receba R$5</p>
            </div>-->
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { modifyNickName, modifyAvatar } from "@/api/api";
import downLoadAppTips from "@/components/downLoadAppTips.vue";
import { post, get } from "@/api/http";
import { openWvByChannel, dotGTag } from "@/native/native"

export default {
    components: {},
    data() {
        return {
            menuList: [
                {
                    name: 'Centro de bonus',
                    path: 'bonus.png',
                    url: '/bonus'
                },
                {
                    name: 'Passe especial',
                    path: 'SpecialPass.png',
                    url: '/seasonPass'
                },
                // {
                //     name: 'Indique e Ganhe',
                //     path: 'enter&win.png',
                //     url: '/share'
                // },
                // {
                //     name: 'Clube VIP',
                //     path: 'vip.png',
                //     url: '/vip'
                // }
                {
                    name: 'Detalhes',
                    path: 'enter&win.png',
                    url: '/wallet'
                },
                {
                    name: 'Notificações',
                    path: 'vip.png',
                    url: 'Notificações'
                },
                {
                    name: 'Telegram',
                    path: 'enter&win.png',
                    url: 'Telegram'
                },
                {
                    name: 'Meu nível vip',
                    path: 'vip.png',
                    url: '/newvip'
                }
            ],
            activeNames: ['1'],
            topTitle: [
                {
                    name: 'Jogar BIGWIN',
                    hasChildren: true
                },
                {
                    name: 'Outra',
                    path: '/bonus',
                    hasChildren: false
                }
            ],
            dataList: [
                {
                    title: 'SLOTS',
                    titleImg: require('../../public/image/menu/menuList/slotsTitle.png'),
                    subList: [{
                        name: 'PG',
                        icon: require('../../public/image/menu/menuList/pg.png')
                    },
                    {
                        name: 'PP',
                        icon: require('../../public/image/menu/menuList/pp.png')
                    },
                    {
                        name: 'JILI',
                        icon: require('../../public/image/menu/menuList/jili.png')
                    },
                    {
                        name: 'CQ9',
                        icon: require('../../public/image/menu/menuList/cq9.png')
                    }
                    ]
                },
                {
                    title: 'IN-HOUSE',
                    titleImg: require('../../public/image/menu/menuList/houseTitle.png'),
                    subList: [{
                        name: 'Design JiLi',
                        icon: require('../../public/image/menu/menuList/designJili.png')
                    },
                    {
                        name: 'House',
                        icon: require('../../public/image/menu/menuList/house.png')
                    },
                    {
                        name: 'LIVE',
                        icon: require('../../public/image/menu/menuList/liveTitle.png')
                    },
                    {
                        name: 'TABLE',
                        icon: require('../../public/image/menu/menuList/tableTitle.png')
                    },
                    ]
                },
            ],
            downBtn: true,
            userName: '',
            chooseAvatars: false,
            avatarLoading: false,
            vip_lv: 0,
        };
    },
    watch: {
        userData: {
            handler(val) {
                console.warn(val)
                this.userName = val.name;
                this.vip_lv = val.vip_lv || 0;
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(['taskNumber', "token", 'userData', 'client', 'messageNumber']),
        ...mapGetters(['getUserData'])
    },
    created() {
    },
    mounted() {

    },
    methods: {
        scrollTop() {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        onBlur() {
            console.log('blur', this.userName)
            if (!this.userName || this.userName.length > 32) {
                this.$store.dispatch('toast', {
                    text: 'Entrada incorrecta',
                    type: 'failed'
                })
                return;
            }

            modifyNickName({
                new_name: this.userName
            }).then(() => {
                this.$store.dispatch('UserInfo');
                this.$store.dispatch('toast', 'Modificado com sucesso');
            }).catch(() => {

            })
        },
        changeAvatar(name) {
            this.avatarLoading = true;
            modifyAvatar({
                num: name
            }).then(res => {
                this.userData.avatar = name;
                this.chooseAvatars = false;
                this.avatarLoading = false;
            }).catch(() => {
                this.avatarLoading = false;
            })
        },
        log_out() {
            let only_uuid = localStorage.getItem('only_uuid');
            let BuryingParams = localStorage.getItem('BuryingParams');
            let click_id = localStorage.getItem('click_id');
            // localStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("active_pop_show");
            localStorage.removeItem("inviteCode");
            localStorage.removeItem("lastPopupDate");
            localStorage.removeItem("isPopVip");
            localStorage.removeItem("sub_channel");


            localStorage.setItem('only_uuid', only_uuid);
            localStorage.setItem('BuryingParams', BuryingParams);
            localStorage.setItem('BuryingParams', click_id);

            this.$router.push('/')
            history.go(0)
        },
        openWheel() {
            this.$store.commit('setMenuBlockShow', false);

            if (!this.token) {
                this.$store.commit('IN_OR_UP', 'in');
                this.$store.commit('MASKCONTENT', 'login');
                this.$store.commit('MASK', true);
                return;
            }

            if (this.$store.state.has_spin_count > 0) {
                this.$store.commit("setShowDaZhuanPanIndex", true);
            } else {
                this.$store.commit("setShowDaZhuanPanPop", true);
            }
        },
        callRegister() {
            this.$store.commit('setMenuBlockShow', false);
            this.$store.commit('IN_OR_UP', 'up');
            this.$store.commit('MASKCONTENT', 'login');
            this.$store.commit('MASK', true)
        },
        callLogin() {
            this.$store.commit('setMenuBlockShow', false);
            this.$store.commit('IN_OR_UP', 'in');
            this.$store.commit('MASKCONTENT', 'login');
            this.$store.commit('MASK', true)
        },
        jumpUrl(item) {
            console.log(item.url)
            this.$store.commit('setMenuBlockShow', false);

            if (!this.token) {
                this.$store.commit('IN_OR_UP', 'in');
                this.$store.commit('MASKCONTENT', 'login');
                this.$store.commit('MASK', true);
                return;
            }

            if (item.url) {
                if (item.url == 'Telegram') {
                    dotGTag('click_tg_bind', { 'method': 'user_info' })
                    this.$store.commit('setShowPopupPanel',
                        {
                            show: true,
                            componentName: 'BindTg',
                            option: {
                                content: 'Se você fizer um saque quando tiver um saldo de bônus ativo, seu saldo de bônus será perdido.',
                                confirmMsg: 'Continuar',
                                cancelMsg: 'fecho',
                                confirmCb: () => {

                                },
                            }
                        });
                    return;
                }


                if (item.url == 'Notificações') {
                    this.$store.commit('setMessageShow', true);
                    return;
                }

                this.$router.push(item.url);
            }
        },
        depositMenu() {
            this.$store.commit('setMenuBlockShow', false);
            this.$router.push('/recharge');
        },
        downLoadApk() {
            // window.open(this.APK_URL);
            // return;
            let sourceUrl = localStorage.getItem('source') + `&uid=${this.userData.uid}` + `&token=${this.token}`
            //startW2A
            let params = {
                "channel": localStorage.getItem('channel'),   // 渠道
                "source": sourceUrl, // 来源，访问原路由
                "event_name": "EVENT_CONTENT_VIEW", // 固定值
                "only_uuid": localStorage.getItem('only_uuid') // uuid 
            }
            console.log("H5---W2A=====>", params);
            post('https://reisjogo.com/hall/account/channel_visit', params)
                .then(res => {
                    console.log("H5---W2A=====>res", res);
                    if (res.code == 200) {
                        get("https://h5.gexinfa.xyz/api/info/apk/walle/h5?clickId=" + localStorage.getItem('only_uuid') + "&packageName=com.rico13.ricoapp")
                            .then(p => {
                                console.log("H5---W2A=====>p", p);
                                if (p.code == 0) {
                                    this.downBtn = false;
                                    this.downloadFile(p.data.url)
                                }
                            })
                    }

                })
                .catch(err => {
                    console.log(err)
                })
        },
        downloadFile(url) {
            var link = document.createElement('a');
            link.href = url;
            link.download = 'filename'; // 可以指定文件名，如果不指定则使用服务器响应的文件名
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        toPage(item) {
            switch (item) {
                case 'PG':
                    this.$router.push('/gameList/pgGame')
                    break;
                case 'PP':
                    this.$router.push('/gameList/slots')
                    break;

                case 'JILI':
                    this.$router.push('/gameList/JiLi')
                    break;

                case 'CQ9':
                    this.$router.push('/gameList/Cq9')
                    break;

                case 'Design JiLi':
                    this.$router.push('/gameList/JiLiDesign')
                    break;

                case 'House':
                    this.$router.push('/gameList/internal')
                    break;

                case 'LIVE':
                    this.$router.push('/gameList/ppl')
                    break;
                case 'TABLE':
                    this.$router.push('/gameList/card')
                    break;
            }
        },
        clickItem(val, path) {
            if (val == 0 && path) {
                return
            } else {
                this.toggleShow = !this.toggleShow
            }
        },
        copyUid() {
            this.$refs.uid.select()
            let result = document.execCommand('copy')
            if (result) {
                this.$store.dispatch('toast', 'Copia de sucesso')
            }
        },
        openTg() {
            // window.open("https://t.me/rico13777");
            // GoJumpWin.Jni_JS_Jave("OPEN_URL", "https://t.me/rico13777");
            dotGTag('click_tg_join', { 'method': 'user_info' })
            openWvByChannel('https://t.me/rico13777');
        },
        openVip(path) {
            this.$store.commit('setMenuBlockShow', false);

            if (!this.token) {
                this.$store.commit('IN_OR_UP', 'in');
                this.$store.commit('MASKCONTENT', 'login');
                this.$store.commit('MASK', true);
                return;
            }
            this.$router.push(path);
        }
    },
};
</script>
<style lang="less" scoped>
@import "@/assets/style.less";

.menu-container {
    overflow: hidden;
    border: 0;
    width: 100%;
    height: 100%;
    background: #0b131d;
}

.menu-logo {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
        width: 260px;
    }
}

.menu-header {
    width: calc(100% + 2px);
    height: 220px;
    background-color: rgba(27, 28, 28);
}

.menu-context {
    // height: calc(100vh - 106px);
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    // padding-top: 60px;
    // padding-bottom: 120px;
    box-sizing: border-box;

    .mc-topbar {
        width: calc(100% - 86px);
        padding: 0 43px;
        height: 122px;
        background: linear-gradient(to top, #0b131d, #00326c);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 1;

        > img {
            width: 236px;
            height: 41px;
        }
    }
    .mc-user {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 122px;

        .mscu-avartar {
            width: 145px;
            height: 145px;
            border-radius: 50%;
            overflow: hidden;
            margin-top: 28px;
            position: relative;

            > img {
                width: 145px;
                height: 145px;
            }

            .change-avatar {
                width: 145px;
                height: 145px;
                position: absolute;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: black;
                opacity: 0.6;
            }
        }
        .avatar-list {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-around;
            align-items: center;
            margin: 30px auto;
            animation: avatar-list-show 500ms ease-in;

            img {
                width: 125px;
                height: 125px;
                border-radius: 50%;
                margin: 10px 8px;
            }
        }
        @keyframes avatar-list-show {
            from {
                opacity: 0;
                // transform: translateY(-100px);
            }

            to {
                opacity: 1;
            }
        }
        .mscu-vip-box {
            display: flex;
            height: 61px;
            align-items: center;
            margin-top: 20px;

            img {
                width: 48px;
                height: 61px;
            }
            .mscu-vip {
                width: 111px;
                height: 31px;
                background: #ed1d49;
                border-radius: 15px;
                text-align: center;
                line-height: 31px;
                color: #ffffff;
                font-size: 21px;
                margin-left: 13px;
            }
        }

        .mscu-u {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            color: #a7c2ff;
            margin-top: 30px;

            :nth-child(2) {
                width: 150px;
                background: none;
                text-align: center;
            }
            :nth-child(3) {
                width: 23px;
            }
        }
        .mscu-name {
            width: 633px;
            height: 76px;
            border-radius: 38px;
            background: #161f2c;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 24px 0;
            z-index: 0;

            :nth-child(1) {
                width: 500px;
                background: none;
                color: white;
                text-align: center;
                font-size: 32px;
            }
            :nth-child(2) {
                width: 25px;
                height: 25px;
                position: absolute;
                right: 25px;
            }
        }
    }
}

.down-icon {
    width: 100%;
    height: 106px;
    position: absolute;
    bottom: 0px;
    background: red;

    > img {
        width: 100%;
        height: 106px;
        display: block;
    }
}

.menu-item-box {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 70px);
    padding: 0 35px;
}

.menu-item {
    width: 331px;
    height: 76px;
    background: #161f2c;
    font-size: 28px;
    color: #768fb6;
    text-align: center;
    line-height: 76px;
    border-radius: 15px;
    margin: 6px auto;
    font-weight: bold;
    position: relative;

    .nums {
        width: 30px;
        height: 30px;
        background: #d72349;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        top: 23px;
    }
}

.menu-header-operate {
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
        width: 250px;
        height: 60px;
        border-radius: 10px;
        font-size: 35px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    div:first-child {
        background-color: #d1d1d9;
        color: #525858;
        margin-right: 16px;
    }

    div:last-child {
        background-color: #f82e48;
        color: #ffffff;
    }
}

.adsArray {
    width: 520px;
    margin: 60px auto;
    color: #ffffff;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-size: 24px;
    scale: 1.27;

    .ads1 {
        width: 257px;
        height: 140px;
        background: url("@{IMAGE_CDN}menu/ads1.png") no-repeat;
        background-size: 100% 100%;
        display: flex;

        span {
            width: 220px;
            display: inline-block;
            margin-top: 5px;
            margin-left: 10px;
            text-shadow: 0 6px 10px #000000;
            text-transform: uppercase;
            line-height: 35px;
        }
    }

    .ads2 {
        width: 257px;
        height: 140px;
        background: url("@{IMAGE_CDN}menu/ads2.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        position: relative;

        .ads2_wheel {
            width: 132px;
            position: absolute;
            bottom: -13px;
            right: 3px;
            animation: rotation 1.2s linear infinite;
        }

        span {
            width: 162px;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
            text-shadow: 0 6px 10px #000000;
        }
    }

    .ads3 {
        width: 169px;
        height: 136px;
        background: url("@{IMAGE_CDN}menu/ads3.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 13px;

        span {
            width: 100px;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
            text-shadow: 0 6px 10px #000000;
        }
    }

    .ads4 {
        width: 169px;
        height: 136px;
        background: url("@{IMAGE_CDN}menu/ads4.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 13px;

        span {
            width: 144px;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
            text-shadow: 0px 6px 10px #000000;
        }
    }

    .ads5 {
        width: 169px;
        height: 136px;
        background: url("@{IMAGE_CDN}menu/ads5.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 13px;

        span {
            width: 145px;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
            text-shadow: 0 6px 10px #000000;
        }
    }
}

.otherGame {
    width: calc(100% - 86px);
    margin: 0 auto;
    margin-top: 20px;

    .items {
        display: flex;
        align-items: center;
        margin: 10px 0;
        font-weight: bold;
    }
}

.van-collapse {
}

.login-out {
    width: calc(100% - 86px);
    padding: 38px 43px;
    display: flex;
    justify-content: end;
    align-items: center;
    > img {
        width: 38px;
        height: 39px;
    }
    > span {
        color: #55657e;
        font-size: 28px;
        margin-left: 5px;
    }
}

::v-deep .van-cell {
    background: transparent !important;
    color: #fff !important;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.titleImg {
    width: 45px;
    margin-right: 39px;
}

.titleIcon {
    width: 45px;
    margin-right: 41px;
}

::v-deep .van-cell__title {
    div {
        display: flex !important;
        align-items: center !important;
    }
}

::v-deep .van-collapse-item__content {
    padding: 6px 10px;
    background: transparent;
    color: #d1d1d9;
}

::v-deep .van-collapse-item__title--expanded::after {
    display: none !important;
}

::v-deep [class*="van-hairline"]::after {
    border: none !important;
}

::v-deep .van-cell::after {
    border: none !important;
}

::v-deep .van-collapse-item--border::after {
    border: none !important;
}

::v-deep .van-collapse-item {
    background: #161f2c;
    margin-top: 5px;
    border-radius: 5px;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.lc-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;

    > img {
        width: 33px;
        margin-right: 20px;
    }
}

.topItems {
    color: #f4f5f9;
    font-size: 30px;
    font-weight: bold;
}

.menuListUl {
}

.deliver {
    width: 676px;
    height: 2px;
    background: #0b131d;
    margin: 21px auto 26px;
}

.download {
    width: 520px;
    height: 86px;
    margin: 0 auto;

    background: url("../../public/image/menu/downBg.png") no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;

    img {
        width: 70px;
        margin-right: 18px;
    }
}
</style>