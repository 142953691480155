<script>
import {defineComponent} from 'vue'
import {searchGame} from "@/api/api";

export default defineComponent({
  name: "gameSearch",
  props: ['show', 'close'],
  data() {
    return {
      isShow: false,
      text: '',
    }
  },
  watch: {
    show(val) {
      this.isShow = val;
    },
    isShow(val) {
      if (!val) this.$emit('close')
    }
  },
  methods: {
    searchGameHandler() {
      if (!this.text) {
        return
      }
      this.isShow = false
      searchGame({
        name: this.text
      }).then((res) => {
        this.$store.commit('SetSearchList', res)
        this.$router.push({ name: "searchResultPage", params: { searchName: this.text } })
        this.text = ''
      })
    }
  }
})
</script>

<template>
  <div>
    <van-popup v-model="isShow" position="top" :style="{ height: '20%', background: '#1d1d1d' }">
      <div class="game-search">
        <input v-model="text" class="search-input"/>
        <div class="game-search-icon">
          <van-icon name="search" size="20" color="#737373"/>
        </div>
      </div>
      <div class="search-btn-block">
        <button class="close-btn" @click="isShow = false">Voltar</button>
        <button class="search-btn" @click="searchGameHandler" :class="text ? '' : 'disabled'"
                :disabled="!text">Buscar
        </button>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
.disabled {
  background: #410F17;
  color: #4C4C4C;
}
.game-search {
  position: relative;
  width: 698px;
  margin: 100px auto;
  font-size: 26px;

  .game-search-icon {
    position: absolute;
    top: 13px;
    right: 32px;
    color: #55657e;
  }

  input {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: none;
    background-color: #282828;
    padding: 10px 88px 10px 30px;
    box-sizing: border-box;
    color: #FFFFFF;
  }
}
.search-input {
  width: 70px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0B111F;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -30px 0 50px #000;
}
.search-btn-block {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: 83px;
}
.close-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #B6B6B6;
  color: #000;
  font-size: 24px;
  border: none;
}
.search-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DA314C;
  color: #fff;
  font-size: 24px;
  border: none;
}
</style>