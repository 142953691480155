<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import {getGamePlayUrl} from "@/api/api";

export default defineComponent({
  name: "GeneralPromptTem1",
  data() {
    return {
      gameId: '',
      cash: '',
      show: false,
    }
  },
  computed: {
    ...mapState(['partMoneyGame'])
  },
  watch: {
    partMoneyGame(val) {
      this.gameId = val.split('__')[0];
      this.cash = val.split('__')[1];
      this.queryGameUrl();
      this.show = true;
    }
  },
  methods: {
    queryGameUrl() {
      getGamePlayUrl({
        gameId: this.gameId,
        isTail: false
      }).then(res => {
        this.gameUrl = res.url;
      }).catch(() => {
      })
    },
    doPlay() {
      console.log(this.gameUrl)
      if (!this.gameUrl) {
        this.show = false;
        return;
      }
      this.$router.push({ name: 'play', params: { url: this.gameUrl }});
      this.show = false;
    },
    close() {
      this.show = false;
    }
  }
})
</script>

<template>
  <div>
    <van-popup v-model="show" closeable class="confirm-block">
      <div class="game-prompt">
        <div class="game-prompt-title"></div>
        <p> Devido às restrições do fabricante, você só pode levar </p>
        <p class="game-prompt-cash">R$ {{cash}} </p>
        <p> para o jogo </p>
        <div class="game-prompt-ok" @click="doPlay">ok</div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="less">
.confirm-block {
  width: 80%;
  height: 800px;
  background-color: #FFFFFF;
  border-radius: 20px;
}
.game-prompt {
  width: 100%;

  p {
    font-size: 34px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin: 40px 0;
  }
}
.game-prompt-title {
  height: 90px;
  background-color: #F82E48;
  margin-bottom: 120px;
}
.game-prompt-cash {
  font-size: 60px !important;
  color: #008600;
  margin: 20px 0;
}
.game-prompt-ok {
  width: 500px;
  background-color: #F82E48;
  color: #FFFFFF;
  border-radius: 14px;
  padding: 16px;
  text-align: center;
  margin: 100px auto;
}
</style>