<template>
    <div class="confirm">
        <div class="confirm-title">{{ message.content }}</div>
        <div class="confirm-btn" @click="confirmClick">{{ message.confirmMsg }}</div>
        <span class="confirm-ca" @click="cancelarClick">{{ message.cancelMsg }}</span>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    components: {},
    props: {
        message: {
            type: Object,
        }
    },
    data() {
        return {

        }
    },
    watch: {},
    computed: {
    },
    methods: {
        confirmClick() {
            this.cancelarClick();
            this.message.confirmCb();
        },
        cancelarClick() {
            this.$store.commit('setShowPopupPanel',
                {
                    show: false,
                    componentName: '',
                    option: {}
                });
        }
    },
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.confirm {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 80px 30px 40px 30px;

    .confirm-title {
        width: 100%;
        min-height: 300px;
        font-size: 26px;
        text-align: center;
        line-height: 40px;
        padding: 30px 0;
        color: #fff;
    }
    .confirm-btn {
        width: 462px;
        height: 100px;
        background: #3cb408;
        border-radius: 15px;
        text-align: center;
        line-height: 100px;
        font-size: 30px;
        text-transform: uppercase;
        color: #fefefe;
        margin-bottom: 30px;
    }
    .confirm-ca {
        color: #666666;
        font-size: 30px;
    }
}
</style>