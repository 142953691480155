<script setup>
import { ref, watchEffect } from "vue";
import store from "@/store";

const show = ref(false);
const text = ref('');
const type = ref('');
let timer = null;

const toastToHide = () => {
    store.dispatch('toast', {
        show: false,
        text: '',
        type: 'success'
    })
}

watchEffect(() => {
    if (store.state.toast && (store.state.toast.show ||
        (store.state.toast.show === undefined && store.state.toast.text))) {
        clearTimeout(timer);
        const toast = store.state.toast;
        show.value = true;
        text.value = toast.text;
        type.value = toast.type;
        timer = setTimeout(toastToHide, 3000)
    } else {
        show.value = false;
    }
})

</script>

<template>
    <div class="toast" v-show="show">
        <div
            class="toast-block"
            :style="{backgroundColor: type === 'failed' ? '#B54223' : '#47B053'}"
        >{{text}}</div>
    </div>
</template>

<style scoped lang="less">
.toast {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    font-size: 40px;
    z-index: 9999;
    color: #ffffff;
    animation: toastShow 200ms ease-in;

    .toast-block {
        background-color: #47b053;
        padding: 24px 20px;
        margin: 20px 40px;
        text-align: center;
        border-radius: 20px;
    }
}

@keyframes toastShow {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}
@keyframes toastHide {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}
</style>