<template>
    <div :class="isShowTop?'limited-mask-block':'limited-mask-block-1' " v-if="isShow">
        <div class="limited-box-block" v-if="isShowTop">
            <div class="parabns">PARABÉNS</div>
            <div class="limited-title-des-block">Sua conta foi criada com sucesso.</div>

            <div class="limited-bg-block">
                <div
                    class="limited-des"
                >Deposite qualquer quantia dentro de 2 horas e você receberá R$100 bônus em dinheiro.</div>

                <div :class="isShowTop?'limited-time-block':'limited-time-block-duan'">
                    <div class="hour">{{ hour }}</div>
                    <div>:</div>
                    <div class="minute">{{ minute }}</div>
                    <div>:</div>
                    <div class="second">{{ secoud }}</div>
                </div>
                <span @click="depositoBtn" class="deposito">Depósito</span>
            </div>
            <div class="limited-btn-block">
                <div @click="incicioBtn">< INCICIO</div>
                <div @click="depositoBtn">DEPÓSITO ></div>
            </div>
        </div>

        <div class="limited-box-block-1" v-show="isShowBot">
            <div class="limited-bot-bg">
                <div class="limited-item-box">
                    <div
                        :class="curtIndex == index ?'limited-item-select':'limited-item'"
                        v-for="(item,index) in itemConfig "
                        @click="itemClick(item,index)"
                    >
                        <img
                            v-if="index==1"
                            class="hotIcon"
                            src="../../public/image/recharge/hotIcon.png"
                            alt
                        />
                        <span class="limited-item-money">{{ item }}</span>
                        <img
                            class="limited-item-img"
                            src="../../public/image/limitRechange/limited-rechange3.webp"
                            alt
                        />
                    </div>
                </div>

                <div :class="isShowBot?'limited-time-block-duan':'limited-time-block'">
                    <div class="hour">{{ hour }}</div>
                    <div>:</div>
                    <div class="minute">{{ minute }}</div>
                    <div>:</div>
                    <div class="second">{{ secoud }}</div>
                </div>

                <div
                    class="limited-des"
                >Deposite qualquer quantia dentro de 2 horas e você receberá R$100 bônus em dinheiro.</div>

                <span @click="pullupOrder" class="deposito">Depósito</span>
                <van-icon
                    @click="incicioBtn"
                    class="close-panel"
                    name="close"
                    size="30"
                    color="#fff"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getUserPack, userBuyPack } from "@/api/api";
import { mapState } from "vuex";
import { Toast } from 'vant';
export default {
    name: 'LimitedRechange',
    components: {},
    props: ["tagIndex"],
    data() {
        return {
            isShow: false,
            isShowTop: true,
            isShowBot: false,
            timer: null,
            hour: "0",
            minute: "0",
            secoud: "00",
            itemConfig: [],
            curtIndex: 1,
            pullupOrderBtnStates: false,
            endTime: 0,
            rechargeAmount: 0,
            id: 0,
        }
    },
    watch: {
        paySuccessType(val) {
            if (val == 4) {
                console.error("限时充值成功！", val);
                this.$store.commit('setLimitRechangeShow', { show: false, userClick: true });
            }
        },
        limitRechangeSource: {
            handler(val) {
                this.isShowTop = !val;
                this.isShowBot = val;
            },
            immediate: true
        },
        limitRechangeShow: {
            handler(val) {
                this.isShow = val;
                if (val) {
                    getUserPack()
                        .then((res) => {
                            if (res) {
                                res.map((item) => {
                                    if (item.pack_type === 4) {

                                        clearInterval(this.timer);
                                        this.endTime = item.expired_at;
                                        this.itemConfig = item.need_recharge.split(",");
                                        // console.log(this.itemConfig)
                                        this.rechargeAmount = this.itemConfig[this.curtIndex];
                                        this.id = item.id;
                                        this.startTimer();
                                    }
                                });
                            }
                        })
                        .catch(error => {

                        })
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState([
            "token", "paySuccessType", "limitRechangeShow", "limitRechangeSource"
        ]),
    },
    methods: {
        pullupOrder() {
            if (this.pullupOrderBtnStates) return;
            this.pullupOrderBtnStates = true;
            console.log("拉起订单")
            this.$store.dispatch('showNetWaiting', true);
            let params = {
                id: this.id,
                channel: "cwt",
                money: this.rechargeAmount.toString(),
                pix: "master"
            };
            console.log(params)

            userBuyPack(params).then(res => {
                this.$store.dispatch('showNetWaiting', false);
                this.pullupOrderBtnStates = false;
                this.$store.commit('setPayforUrl', res)
            }).catch((err) => {
                Toast.fail('fail (' + err.code + ')');
                this.pullupOrderBtnStates = false;
                this.$store.dispatch('showNetWaiting', false);
            })
        },
        incicioBtn() {
            !this.limitRechangeSource ? this.$emit('incicioBtn') : '';
            this.$store.commit('setLimitRechangeShow', { show: false, userClick: true });
        },
        depositoBtn() {
            this.isShowTop = false;
            this.isShowBot = true;
        },
        itemClick(item, index) {
            this.curtIndex = index;
            this.rechargeAmount = item;
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.updateTime();
            }, 10);
        },
        updateTime() {
            const currentTime = new Date().getTime();
            const endTime = new Date(this.endTime).getTime(); // 设置倒计时结束时间

            let remainingTime = endTime - currentTime;

            if (remainingTime <= 0) {
                clearInterval(this.timer);
                remainingTime = 0;
            }

            const hours = Math.floor(remainingTime / (1000 * 60 * 60));
            const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
            const milliseconds = Math.floor((remainingTime % 1000) / 10);

            // 格式化时间，保留秒到毫秒的两位数
            this.hour = `${this.formatTimeElement(hours)}`;
            this.minute = `${this.formatTimeElement(minutes)}`;
            this.secoud = `${this.formatTimeElement(seconds)}.${this.formatTimeElement(milliseconds)}`;
        },
        formatTimeElement(time) {
            return time.toString().padStart(2, '0');
        },
    },
    created() {
        // console.log("created", this.$store.state.limitRechangeSource)


    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
.limited-mask-block {
    width: 100%;
    height: 100vh;
    background: #000d11;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
        text-align: center;
    }
}
.limited-mask-block-1 {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
        text-align: center;
    }
}

.parabns {
    font-family: Montserrat-VF;
    font-size: 60px;
    font-weight: bold;
    font-style: italic;
    color: #ffc500;
    margin-top: 87px;
}
.limited-title-des-block {
    font-size: 29px;
    margin: 25px 0 29px 0;
    font-family: Montserrat-VF;
}
.limited-des {
    font-size: 22px;
    font-weight: 500;
    color: #ffc500;
    margin: 39px 0 21px 0;
    font-family: Montserrat-VF;
    padding: 0 20px;
}

.limited-box-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: eject-pop 0.3s ease-in-out;
    background: url("../../public/image/limitRechange/limited-rechange1.webp")
        no-repeat;
    background-size: 100%;
}
.limited-box-block-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: eject-pop 0.3s ease-in-out;
}
.limited-bg-block {
    position: relative;
    width: calc(100% - 166px);
    min-height: 890px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.limited-btn-block {
    width: calc(100% - 166px);
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    font-size: 23.5px;
    font-weight: 500;
}
.limited-time-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .hour {
        width: 147px;
        height: 195px;
        background: rgba(0, 0, 0, 0.801);
    }
    .minute {
        width: 147px;
        height: 195px;
        background: rgba(0, 0, 0, 0.801);
    }
    .second {
        width: 202px;
        height: 195px;
        background: rgba(0, 0, 0, 0.801);
    }
    > div {
        border-radius: 15px;
        text-align: center;
        line-height: 195px;
        color: #ffde00;
        font-style: italic;
        font-weight: 600;
        font-size: 68px;
    }
}
.limited-time-block-duan {
    &:extend(.limited-time-block all);
    .minute,
    .second,
    .hour {
        height: 134px;
    }
    > div {
        line-height: 134px;
    }
}
.deposito {
    position: absolute;
    width: 526px;
    height: 80px;
    background-color: #ffc500;
    border-radius: 10px;
    bottom: 38px;
    text-align: center;
    font-size: 30.5px;
    font-weight: bold;
    color: #2e0000;
    font-family: Montserrat-VF;
    line-height: 80px;
}

.limited-bot-bg {
    position: relative;
    top: 199px;
    width: calc(100% - 135px);
    min-height: 767px;
    background: linear-gradient(to bottom, #0f1623, #094557);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.limited-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.close-panel {
    position: absolute;
    bottom: -113px;
}
.limited-item {
    width: 170px;
    height: 292px;
    border-radius: 20px;
    border: solid 2px white;
    background-color: #0b111d;
    margin: 55px 0;
    color: #ffffff;
    font-size: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .limited-item-money::before {
        content: "R$ ";
        color: #ffc500;
        display: inline-block;
        margin-top: 49px;
    }

    .limited-item-img {
        width: 118px;
        height: 108px;
        margin-top: 44px;
    }
}

.limited-item-select {
    &:extend(.limited-item all);
    border: solid 2px #fed102;
    background-color: #fed102;
    color: #0f1623;
    font-weight: 600;

    .limited-item-money::before {
        content: "R$ ";
        color: #0f1623;
    }
}

.hotIcon {
    width: 80px;
    position: absolute;
    top: -6px;
    right: 0px;
}

@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>
