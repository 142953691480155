<template>
  <div class="ranking  pdt70">
    <div class="ranking_box">
      <h3>RANKING</h3>
      <div class="ranking-btn">
        <div v-for="(item, index) in typeList" :class="currentType === index ? 'active' : ''" @click="selectType(index)">
          {{ item }}
        </div>
      </div>
      <div class="list">
        <div class="list-title">
          <div class="rank">Classificação</div>
          <div class="name">Jogadores</div>
          <div v-show="currentType == 0" class="score">Multiplicador</div>
          <div v-show="currentType == 1" class="score">Ganhos totais</div>
          <div v-show="currentType == 2" class="score">Multiplicador</div>
        </div>
        <ul class="list-ul" v-show="currentType == 0">
          <li v-for="(item, index) in cost_rankList" :key="index">
            <div class="rank">
              <div v-if="index == 0"><img src="../../public/image/home/rank_1.png" alt=""></div>
              <div v-else-if="index == 1"><img src="../../public/image/home/rank_2.png" alt=""></div>
              <div v-else-if="index == 2"><img src="../../public/image/home/rank_3.png" alt=""></div>
              <div v-if="index > 2">{{ index + 1 }}</div>
            </div>
            <div class="name">
              <div class="avatar">
                <img :src="IMAGE_URL + 'header/' + item.avatar + '.jpg'" alt="">
              </div>
              <div class="rank-name-text">{{ item.uid }}</div>
            </div>
            <div class="score">
              <div class="money"><span>R$</span>{{ AmountFixed(parseInt(item.score)) }}</div>
            </div>
          </li>
          <div class="progress_bar_block">
            <div id="progress_bar">
              <div id="progress_back"
                :style="{ width: cost_rankList ? (calcPercent(cost_rankList.length, cost_total) * 100 + '%') : 0 }">
              </div>
            </div>
          </div>
        </ul>
        <ul class="list-ul" v-show="currentType == 1">
          <li v-for="(item, index) in back_rankList" :key="index">
            <div class="rank">
              <div v-if="index == 0"><img src="../../public/image/home/rank_1.png" alt=""></div>
              <div v-else-if="index == 1"><img src="../../public/image/home/rank_2.png" alt=""></div>
              <div v-else-if="index == 2"><img src="../../public/image/home/rank_3.png" alt=""></div>
              <div v-if="index > 2">{{ index + 1 }}</div>
            </div>
            <div class="name">
              <div class="avatar">
                <img :src="IMAGE_URL + 'header/' + item.avatar + '.jpg'" alt="">
              </div>
              <div class="rank-name-text">{{ item.uid }}</div>
            </div>
            <div class="score">
              <div class="money"><span>R$</span>{{ AmountFixed(parseInt(item.score)) }}</div>
            </div>
          </li>
          <div class="progress_bar_block">
            <div id="progress_bar">
              <div id="progress_back"
                :style="{ width: back_rankList ? (calcPercent(back_rankList.length, back_total) * 100 + '%') : 0 }">
              </div>
            </div>
          </div>
        </ul>
        <ul class="list-ul" v-show="currentType == 2">
          <li v-for="(item, index) in rate_rankList" :key="index">
            <div class="rank">
              <div v-if="index == 0"><img src="../../public/image/home/rank_1.png" alt=""></div>
              <div v-else-if="index == 1"><img src="../../public/image/home/rank_2.png" alt=""></div>
              <div v-else-if="index == 2"><img src="../../public/image/home/rank_3.png" alt=""></div>
              <div v-if="index > 2">{{ index + 1 }}</div>
            </div>
            <div class="name">
              <div class="avatar">
                <img :src="IMAGE_URL + 'header/' + item.avatar + '.jpg'" alt="">
              </div>
              <div class="rank-name-text">{{ item.uid }}</div>
            </div>
            <div class="score">
              <div class="money money_y">x{{ parseInt(item.score) }}</div>
            </div>
          </li>
          <div class="progress_bar_block">
            <div id="progress_bar">
              <div id="progress_back"
                :style="{ width: rate_rankList ? (calcPercent(rate_rankList.length, rate_total) * 100 + '%') : 0 }">
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div v-if="currentType === 0" class="views-btn" v-show="cost_rankList.length < 50">
        <button class="viewAll" @click="cost_nextPage">Ver tudo</button>
      </div>
      <div v-if="currentType === 1" class="views-btn" v-show="back_rankList.length < 50">
        <button class="viewAll" @click="back_nextPage">Ver tudo</button>
      </div>
      <div v-if="currentType === 2" class="views-btn" v-show="rate_rankList.length < 50">
        <button class="viewAll" @click="rate_nextPage">Ver tudo</button>
      </div>
    </div>
  </div>
</template>
<script>
import { getHallRank } from "@/api/api";
import { AmountFixed } from "@/utis/common";
//  cost, back, rate
export default {
  data() {
    return {
      typeList: [
        'vitórias', 'hoje ', 'Lucky'
      ],
      currentType: 0,
      cost_totalList: [],
      back_totalList: [],
      rate_totalList: [],
      cost_rankList: [],
      back_rankList: [],
      rate_rankList: [],
      pageNo: 2,
      cost_total: 0,
      back_total: 0,
      rate_total: 0,
    }
  },
  methods: {
    AmountFixed,
    getRank(typ) {
      this.cost_total = 0;
      this.back_total = 0;
      this.rate_total = 0;
      getHallRank({
        typ: typ
      }).then(res => {
        switch (typ) {
          case 'cost':
            this.cost_totalList = res;
            this.cost_total = res.length;
            if (this.cost_totalList) this.cost_rankList = this.cost_totalList.slice(0, 10);
            break;
          case 'back':
            this.back_totalList = res;
            this.back_total = res.length;
            if (this.back_totalList) this.back_rankList = this.back_totalList.slice(0, 10);
            break;
          case 'rate':
            this.rate_totalList = res;
            this.rate_total = res.length;
            if (this.rate_totalList) this.rate_rankList = this.rate_totalList.slice(0, 10);
            break;
          default:
            break;
        }
      }).catch(() => { })
    },
    selectType(index) {
      this.currentType = index;
      let type;
      if (index === 0) type = 'cost'
      if (index === 1) type = 'back'
      if (index === 2) type = 'rate'
      this.pageNo = 2;
      this.getRank(type)
    },
    cost_nextPage() {
      if (this.pageNo * 5 >= 50) return
      let newList = this.cost_totalList.slice(this.pageNo * 5, this.pageNo * 5 + 5);
      this.cost_rankList = this.cost_rankList.concat(newList);
      this.pageNo++;
    },
    back_nextPage() {
      if (this.pageNo * 5 >= 50) return
      let newList = this.back_totalList.slice(this.pageNo * 5, this.pageNo * 5 + 5);
      this.back_rankList = this.back_rankList.concat(newList);
      this.pageNo++;
    },
    rate_nextPage() {
      if (this.pageNo * 5 >= 50) return
      let newList = this.rate_totalList.slice(this.pageNo * 5, this.pageNo * 5 + 5);
      this.rate_rankList = this.rate_rankList.concat(newList);
      this.pageNo++;
    },
    calcPercent(val, max) {
      if ((val / max) > 1) {
        return 1
      } else return val / max;
    }
  },
  created() {
    this.getRank("cost");
  }
}
</script>
<style lang="less" scoped>
.ranking {
  background-color: #12212E;
  border-radius: 35px;
  padding: 60px 0;
  margin-top: 60px;
}

.domain{
  display: flex;
  flex-direction: row;
  // .line1 {
  //   border: none;
  //   height: 1px;
  //   background-color: #15DA00;
  // }
  // .line2 {
  //   border: none;
  //   height: 1px;
  //   background-color: #15DA00;
  // }
}

.ranking_box {
  width: 96%;
  margin: 0 auto;
  color: #fff;

  h3 {
    font-size: 60px;
    color: #fff;
    text-align: center;
  }

  .ranking-btn {
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;

    div {
      width: 218px;
      height: 76px;
      background: #192C3D;
      color: #7B9AA3;
      border-radius: 38px;
      opacity: 1;
      border: none;
      font-size: 25px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .active {
      background: url('../../public/image/home/rank_btns.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
    }
  }

  .list {
    width: 94%;
    margin: 55px auto;
    text-align: center;

    .list-title {
      display: flex;
      font-size: 24px;
      font-weight: bold;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;

      .rank,
      .name,
      .score {
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        text-align: center;
      }

      .rank {
        width: 15%;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .name {
        width: 40%;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .score {
        width: 18%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .money {
      font-size: 30px !important;
      font-weight: bold;

      span {
        color: #15DA00;
        margin-right: 5px;
      }
    }

    .money_y {
      color: #EF9B03;
    }

    .list-ul {
      width: 100%;

      li {
        font-size: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        height: 120px;
        border-bottom: 2px solid #95BEE2;
      }

      li:nth-last-child(2) {
        border: none;
      }

      .rank {
        width: 15%;
        font-size: 30px;
        font-weight: bold;

        img {
          width: 86px;
        }
      }

      .name {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 25px;
        font-weight: bold;
      }

      .score {
        width: 18%;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .views-btn {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .viewAll {
      padding: 20px 60px;
      background-size: 100% 100%;
      border-radius: 40px;
      border: none;
      font-size: 30px;
      font-weight: bold;
      background-color: #3B4AD6;
    }
  }
}

.avatar {
  width: 70px;
  height: 70px;
  background-size: contain;
  float: left;

  img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    object-fit: contain;
    padding: 4px 3px 6px 3px;
  }
}

.rank-name-text {
  float: left;
  height: 80px;
  line-height: 80px;
  margin-left: 20px;
}

.progress_bar_block {
  width: 310px;
  height: 7px;
  margin: 0 auto;
}

#progress_bar {
  width: 100%;
  height: 7px;
  background: linear-gradient(0deg, #DFE9F3 0%, #FFFFFF 100%);
  border-radius: 2px;
  margin-top: 80px;

  div {
    width: 350px;
    background: linear-gradient(to right, rgb(26, 89, 126), rgb(87, 187, 204));
    height: 7px;
    text-align: center;
    border-radius: 2px;
  }
}</style>
