<script>
import {defineComponent} from 'vue'
import GameCard from "@/components/GameCard.vue";
import {gameList} from "@/api/api";
import GameSearch from "@/views/game/gameSearch.vue";

export default defineComponent({
  name: "gameTabs",
  components: {GameSearch, GameCard},
  props: ['menuList', 'changeTab'],
  data() {
    return {
      tabActive: 0,
      menuArray: [],
      selectGameList: [],
      showSearch: false,
    }
  },
  created() {
    this.menuArray = this.menuList;
  },
  watch: {
    tabActive(val) {
      this.$emit('changeTab', val);
      this.selectGameList = [];
      if (this.menuArray[val].gameList.length > 0) {
        this.arrangeArrayList(this.menuArray[val].gameList);
      } else {
        this.loadGames(this.menuList[val].channel)
      }
    },
    menuList(val) {
      // this.menuArray = val;
    }
  },
  methods: {
    arrangeArrayList(list) {
      let currentPage = 0, itemArray = [];
      setTimeout(() => {
        list.map((item, index) => {
          if (!this.selectGameList[currentPage]) this.selectGameList[currentPage] = []
          if (currentPage === parseInt(index / 6)) {
            itemArray.push(item)
          } else {
            this.$set(this.selectGameList, currentPage, itemArray);
            currentPage = parseInt(index / 6);
            itemArray = []
            itemArray.push(item)
          }
          if (index === list.length - 1) {
            this.$set(this.selectGameList, currentPage, itemArray);
          }
        })
      }, 300)
    },
    loadGames(channel) {
      this.menuList.map((item, index) => {
        if (item.channel === channel && this.menuArray[index].gameList.length === 0) {
          // channel： 999 hotGame, 1 ppGame, 2 pplGame, 3 card, 4 pgGame, 77 jiliDesign
          gameList({
            pageNo: 1,
            pageSize: 11,
            channel: channel
          }).then(res => {
            let list = res.list;
            list.push({ more: true })
            this.menuArray[this.tabActive].gameList = list;
            this.arrangeArrayList(list);
          })
          return false;
        }
      })
    },
  }
})
</script>

<template>
<div>
  <van-tabs class="game-tabs" v-model="tabActive" swipe-threshold="1">
    <van-tab v-for="(item, keys) in menuList" :key="keys">
      <template #title>
        <img :src="item.img" :class="'img' + keys" alt="">
        <div class="itemName">{{item.name}}</div>
      </template>
    </van-tab>
    <van-icon class="game-tabs-search" @click="showSearch = true" name="search" color="#fff" size="26" />
  </van-tabs>

  <div class="game-tabs-content" v-if="tabActive !== 0">
    <div class="content_top">
      <div class="content_left">
        <img :src="menuList[tabActive].img" alt="">
        <span>{{menuList[tabActive].name}}</span>
      </div>
      <div class="content-nav-block" @click="">
        Ver mais&nbsp; &gt;
      </div>
    </div>
    <div class="game-tabs-swipe-block">
      <van-loading class="game-tabs-loading" v-show="selectGameList.length === 0" type="spinner" />
      <van-swipe v-if="selectGameList.length > 0" ref="gameTableSwipe" :loop="false" :show-indicators="false">
        <van-swipe-item v-for="(array, index) in selectGameList" :key="index">
          <div class="game-grid-3-block">
            <div v-for="item in array">
              <GameCard :json="item" :isHot="index <= 6"/>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>

  <gameSearch :show="showSearch" @close="showSearch = false"/>
</div>
</template>

<style scoped lang="less">
.game-grid-3-block {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  grid-gap: 20px;
  flex-direction: row;
  margin: 20px 10px;
}
.content_top {
  display: flex;
  justify-content: space-between;
  margin: 32px 4px 24px;
}
.game-tabs-swipe-block {
  height: 800px;
}
.game-tabs-loading {
  width: 100%;
  text-align: center;
  margin-top: 120px;
}
.content_left {
  border-radius: 17px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 0 12px;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: bold;

  img {
    width: 40px;
    margin-right: 15px;
    vertical-align: text-top;
  }

  span {
    font-size: 25px;
    color: #fff;
    line-height: 40px;
    margin-right: 12px;
  }
}
.content-nav-block {
  display: flex;
  font-size: 25px;
  align-items: center;
  color: #FFFFFF;
}
.game-list-block {
  margin-top: 40px;
  min-height: 300px;
}
.itemName {
  font-size: 21px;
}

.game-list-page-block {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  grid-gap: 20px;
  flex-direction: row;
  margin: 20px 10px;

  .imgBox {
    border-radius: 20px;
    position: relative;
    min-height: 240px;
    background-color: #1B2231;
  }

  @keyframes rolled {
    0% {
      left: -150px;
    }

    100% {
      left: 920px;
    }
  }

  .game-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0;

    img {
      width: 60px;
      margin-bottom: 20px;
    }

    span {
      font-size: 24px;
      color: #94BDE3;
      font-weight: 700;
    }
  }

  img {
    width: 100%;
    border-radius: 20px;
    vertical-align: middle;
  }
}

.game-tabs {
  margin: 43px auto;
  position: relative;
  .game-tabs-search {
    position: absolute;
    right: 0;
    top: 0;
    background: #0b111f;
    box-shadow: -0.4rem 0 0.66667rem #000;
    padding: 10px 10px 0 10px;
  }

  ::v-deep .van-tabs__nav {
    background: transparent;
    border-radius: 25px;
    margin: 0 auto;
    height: 30px;
  }

  ::v-deep .van-tabs__nav--line {}

  ::v-deep .van-tab {
    padding: 0 10px !important;
    box-sizing: border-box;
    background: #272727;
    border-radius: 20px;
    color: #D9D9D9;
    margin-right: 10px;
  }

  ::v-deep .van-tab__pane {}

  ::v-deep .van-tab--active {
    color: #fff;
    border: none !important;
    height: 30px;
    background: #2FA1FF;
    border-radius: 20px;
  }

  ::v-deep .van-tabs__line {
    background-color: transparent !important;
  }

  ::v-deep .van-tabs__nav--complete {
    padding: 0 !important;
    box-sizing: border-box;
  }

  ::v-deep .van-tab__text {
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }

  ::v-deep .van-tabs__nav--complete {
    padding-right: 30px !important;
    box-sizing: border-box !important;
  }

  .img0 {
    width: 26px;
    height: 34px;
  }

  .img1 {
    width: 51px;
    height: 23px;
  }

  .img2 {
    width: 29px;
    height: 29px;
  }

  .img3 {
    width: 30px;
    height: 39px;
  }

  .img4 {
    width: 31px;
  }

  .img5 {
    width: 36px;
  }

  .img6 {
    width: 29px;
  }

  .img7 {
    width: 30px;
  }

  .img8 {
    width: 44px;
    height: 24px;
  }

  .itemName {
    font-size: 21px;
  }

  .activeClass {
    .text {
      color: #fff !important;
    }
  }



  .top_name {
    display: block;
    width: 110px !important;
    position: absolute;
    left: calc(50% - 55px);
    top: 10px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #94BDE3;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .bottom_types {
    display: block;
    width: 100% !important;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #94BDE3;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }

  @keyframes play-scale {
    from {
      transform: scale(1.5)
    }

    to {
      transform: scale(1)
    }
  }

  .search {
    width: 70px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0B111F;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -30px 0 50px #000;

    img {
      width: 34px;
      height: 34px;
    }
  }
}
</style>