const mutations = {
    IN_OR_UP(state, v) {
        state.inorup = v;
    },
    MASK(state, v) {
        state.mask = v;
    },
    USERDATA(state, v) {
        state.userData = v
    },
    ACCOUNT_OPERATIONS(state, v) {
        state.Account_Operations = v
    },
    AUTHORIZATION(state, v) {
        state.Authorization = v
    },
    USERDATABIND(state, v) {
        state.userData.bind_pix = v
    },
    INNERMASK(state, v) {
        state.innerMask = v;
    },
    INNERCONTENT(state, v) {
        state.innerContent = v
    },
    MASKCONTENT(state, v) {
        state.maskContent = v
    },
    CHOOSE_MONEY(state, v) {
        state.choose_money = v
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    setMenuBlockShow(state, isShow) {
        state.menuBlockShow = isShow;
    },
    setOpenRecharge(state, recharge) {
        if (state.channel.open_recharge) state.openRecharge = recharge;
    },
    setMessageNumber(state, number) {
        state.messageNumber = number;
    },
    setMessageShow(state, v) {
        state.isMessageShow = v;
    },
    setWinMoney(state, number) {
        state.winMoney = number;
    },
    setAmountMoney(state, number) {
        state.amountMoney = number;
    },
    setTaskNumber(state, number) {
        state.taskNumber = number;
    },
    setChannel(state, channel) {
        state.channel = channel;
    },
    setToken(state, number) {
        localStorage.setItem('token', number);
        state.token = number;
    },
    setEventBuryingClickId(state, id) {
        state.eventBuryingClickId = id;
    },
    setConfig(state, config) {
        state.config = config;
    },
    setClient(state, client) {
        state.client = client;
    },
    setWheelShow(state, isShow) {
        state.wheelShow = isShow;
    },
    setToast(state, toast) {
        state.toast = toast;
    },
    setRedBagId(state, v) {
        state.redBagId = v;
    },
    setSlotsTigerShow(state, v) {
        state.slotsTigerShow = v;
    },
    setWsConnect(state, v) {
        state.WsConnect = v;
    },
    setPurseShow(state, v) {
        state.purseShow = v;
    },
    setPursePopupShow(state, v) {
        state.PursePopupShow = v;
    },
    SetSearchList(state, v) {
        state.searchList = v;
    },
    setPartMoneyGame(state, v) {
        state.partMoneyGame = v;
    },
    setOpenCodingTask(state, v) {
        state.openCodingTask = v;
    },
    setOpenBetTaskList(state, v) {
        state.openBetTaskList = v;
    },
    setNotAllowGame(state, v) {
        state.notAllowGame = v;
    },
    setSignInByDay(state, v) {
        state.signInByDay = v;
    },
    setHomePopOrder(state, v) {
        state.homePopOrder = v;
    },
    setRechargeActiveShow(state, v) {
        state.rechargeActiveShow = v;
    },
    setReadyPopUp(state, v) {
        state.readyPopUp = v;
    },
    setCheerPopShow(state, v) {
        state.cheerPopShow = v;
    },
    setSpeedUpPopShow(state, v) {
        state.speedUpPopShow = v;
    },
    setGameRechargePopup(state, v) {
        state.gameRechargePopup = v;
    },
    setPayforUrl(state, v) {
        state.payforUrl = v;
    },
    setPlayingGameName(state, v) {
        state.playingGameName = v;
    },
    setPaySuccess(state, v) {
        state.paySuccess = v;
    },
    setPaySuccessType(state, v) {
        state.paySuccessType = v;
    },
    setAfId(state, v) {
        state.afid = v;
    },
    setLimitRechangeShow(state, obj) {
        state.limitRechangeShow = obj.show;
        state.limitRechangeSource = obj.userClick;
    },
    setBankruptcyShow(state, v) {
        state.bankruptcyShow = v;
    },
    setBankruptcyBtnStatus(state, v) {
        state.bankruptcyBtnStatus = v;
    },
    setSafeBoxShow(state, v) {
        state.safeBoxShow = v;
    },
    setShowCashbackIndex(state, v) {
        state.showCashbackIndex = v;
    },
    setShowNetWaiting(state, v) {
        state.showNetWaiting = v;
    },
    setShowDaZhuanPanPop(state, v) {
        state.showDaZhuanPanPop = v;
    },
    setShowDaZhuanPanResult(state, v) {
        state.showDaZhuanPanResult = v;
    },
    setShowDaZhuanPanIndex(state, v) {
        state.showDaZhuanPanIndex = v;
    },
    setShowExtraCashback(state, v) {
        state.showExtraCashback = v;
    },
    setShowPopupPanel(state, value) { //显隐弹窗   
        state.showPopupPanel = value;
    },
    setShowTgReward(state, value) {
        state.showTgReward = value;
    },
    setVipData(state, value) {
        state.vipData = value;
    },
    setVipRedDotData(state, value) {
        state.vipRedDotData = value;
    },
    setVipChallenge(state, value) {
        state.vipChallenge = value;
    },
    setVipChallengePop(state, value) {
        state.vipChallengePop = value;
    },

    setIsZhiXingTiXian(state, value) {
        state.isZhiXingTiXian = value;
    },
    setInviteDotData(state, value) {
        state.inviteDotData = value;
    },
    setIsShowPWATips(state, value) {
        state.isShowPWATips = value;
    }
}
export default mutations