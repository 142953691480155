var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent } from "vue";
import { mapState } from "vuex";
export default defineComponent({
    name: "activePopUp",
    data: function () {
        return {
            show: false,
        };
    },
    computed: __assign({}, mapState(["token", "userData", "rechargeActiveShow"])),
    watch: {
        rechargeActiveShow: function (val) {
            if (this.userData.has_today_recharge && val) {
                this.$store.commit("setRechargeActiveShow", false);
                return;
            }
            this.show = val;
        },
        show: function (val) {
            this.$store.commit("setRechargeActiveShow", val);
        },
    },
    methods: {
        toLogin: function () {
            this.show = false;
            this.$store.commit("IN_OR_UP", "in");
            this.$store.commit("MASKCONTENT", "login");
            this.$store.commit("MASK", true);
            this.$store.commit("setReadyPopUp", false);
        },
        close: function () {
            this.show = false;
        },
        toRecharge: function () {
            this.$store.commit("setReadyPopUp", false);
            this.$router.push("/recharge");
            this.$store.commit("setReadyPopUp", false);
            this.show = false;
        },
    },
});
