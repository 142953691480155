<script>
import {defineComponent} from 'vue'
import GameCard from "@/components/GameCard.vue";
import GameTabs from "@/views/home/gameTabs.vue";
import {gameList} from "@/api/api";

export default defineComponent({
  name: "gameModule",
  components: {GameTabs, GameCard},
  data() {
    return {
      tabActive: 0,
      selectGameList: [],
      lockList: [],
      menuList: [
        {
          id: '0',
          img: this.IMAGE_URL + 'game/hot.png',
          imgActive: require('../../../public/image/game/hot_active.png'),
          name: 'Top',
          gameList: [],
          channel: 999,
        },
        {
          id: '1',
          img: require('../../../public/image/game/pg.png'),
          imgActive: require('../../../public/image/game/pg_active.png'),
          name: 'PG',
          gameList: [],
          channel: 4,
        },
        {
          id: '2',
          img: require('../../../public/image/game/jili.png'),
          imgActive: require('../../../public/image/game/jili_active.png'),
          name: 'Design JiLi',
          gameList: [],
          channel: 77,
        },
        {
          id: '3',
          img: require('../../../public/image/game/pp.png'),
          imgActive: require('../../../public/image/game/pp_active.png'),
          name: 'Pragmatic Play',
          gameList: [],
          channel: 1,
        },
        {
          id: '4',
          img: require('../../../public/image/game/live.png'),
          imgActive: require('../../../public/image/game/live_active.png'),
          name: 'LIVE',
          gameList: [],
          channel: 2,
        },
        {
          id: '5',
          img: require('../../../public/image/game/table.png'),
          imgActive: require('../../../public/image/game/table_active.png'),
          name: 'TABLE',
          gameList: [],
          channel: 3,
        },
        {
          id: '6',
          img: require('../../../public/image/game/Internos.png'),
          imgActive: require('../../../public/image/game/Internos_active.png'),
          name: 'House',
          gameList: [],
          channel: 5,
        },
        {
          id: '7',
          img: require('../../../public/image/game/jili.png'),
          imgActive: require('../../../public/image/game/jili_active.png'),
          name: 'JILI',
          gameList: [],
          channel: 14,
        },
        {
          id: '8',
          img: require('../../../public/image/game/cq9.png'),
          imgActive: require('../../../public/image/game/cq9_active.png'),
          name: 'CQ9',
          gameList: [],
          channel: 15,
        }
      ],
    }
  },
  created() {
    document.onscroll = () => this.isVisibleArea();
  },
  mounted() {
    if (this.menuList[0].gameList.length === 0) {
      this.loadGames(999)
      setTimeout(() => {
        this.loadGames(4)
      }, 3000)
    }
  },
  methods: {
    loadGames(channel) {
      this.menuList.map((item, index) => {
        if (item.channel === channel && this.menuList[index].gameList.length === 0) {

          // channel： 999 hotGame, 1 ppGame, 2 pplGame, 3 card, 4 pgGame, 77 jiliDesign
          gameList({
            pageNo: 1,
            pageSize: 11,
            channel: channel
          }).then(res => {
            this.menuList[index].gameList = res.list;
            this.menuList[index].gameList.push({ more: true, channel:  channel})
          })
          return false;
        }
      })
    },
    isVisibleArea() {
      const viewHeight = window.innerHeight;
      for (let i = 0; i < this.menuList.length; i++) {
        let dom = 'demonstrate-' + this.menuList[i].channel;
        if (!document.getElementById(dom)) break
        const clientRect = document.getElementById(dom).getBoundingClientRect()
        if (this.lockList.includes(dom)) continue;

        if (clientRect.bottom >= 0 && clientRect.bottom < viewHeight) {
          this.lockList.push(dom);
          this.loadGames(this.menuList[i].channel)
        }
      }
    },
    changeTab(index) {
      this.tabActive = index
    }
  }
})
</script>

<template>
<div class="game-module">
  <gameTabs :menuList="menuList" @changeTab="changeTab"/>

  <div v-if="tabActive === 0">
    <div class="" v-for="item in menuList">
      <div class="content_top" :id="'demonstrate-' + item.channel">
        <div class="content_left">
          <img class="hotImg" :src="item.img" alt="">
          <span>{{item.name}}</span>
        </div>
        <div class="content-nav-block" @click="$router.push('/gameShop/' + item.channel)">
          Ver mais&nbsp; &gt;
        </div>
      </div>
      <div class="game-grid-3-block">
        <div class="" v-for="(json, index) in item.gameList" :key="index">
          <GameCard :json="json" :isHot="index <= 5"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="less">
.game-module {
  padding: 20px;
}
.game-grid-3-block {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  grid-gap: 20px;
  flex-direction: row;
  min-height: 600px;
}
.game-list-block {
  margin-top: 40px;
  min-height: 300px;
}
.itemName {
  font-size: 21px;
}
.content_top {
  display: flex;
  justify-content: space-between;
  margin: 32px 4px 24px;
}
.content_left {
  border-radius: 17px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: bold;

  img {
    width: 29px;
    margin-right: 15px;
    vertical-align: text-top;
  }

  .designImg {
    width: 29px;
    height: 29px;
  }

  .hotImg {
    width: 40px;
  }

  .pgImg {
    width: 51px;
    height: 23px;
  }

  .ppImg {
    width: 30px;
    height: 39px;
  }

  .liveImg {
    width: 31px;
    height: 24px;
  }

  .cardImg {
    width: 36px;
    height: 29px;
  }

  .internoImg {
    width: 29px;
    height: 28px;
  }

  .jiliImg {
    width: 30px;
    height: 30px;
  }

  .cq9Img {
    width: 44px;
    height: 24px;
  }

  span {
    font-size: 25px;
    color: #fff;
    line-height: 40px;
    margin-right: 12px;
  }
}
.content-nav-block {
  display: flex;
  font-size: 25px;
  align-items: center;
  color: #FFFFFF;
}
</style>