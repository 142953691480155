<template>
    <div class="reis pdt70">
        <div class="reis_box">
            <h3 class="mg-auto">Por que escolher o <span style="color:#53B949 ;">jogo Reis</span></h3>
            <div class="reis_content">
                <h1>Garantia de jogo justo</h1>
                <p>
                    Nossos jogos são absolutamente justos, sem interferência nos resultados dos sorteios. A plataforma é
                    supervisionada e regulamentada por alianças internacionais para garantir sua tranquilidade e justiça
                    nos
                    jogos.
                </p>
                <img :src="IMAGE_URL + 'home/balance_pic.png'" alt="">
            </div>


            <div class="reis-content-r">
                <h1> Pagamentos automáticos</h1>
                <p>
                    Colaboramos com bancos brasileiros para
                    garantir 100% de segurança dos fundos dos
                    usuários. Oferecemos saques rápidos 24/7,
                    sem revisão manual, desde que você atenda
                    aos requisitosbásicos de saque.
                </p>
                <img :src="IMAGE_URL + 'home/money_pic.png'" alt="">

            </div>

            <div class="reis_content reis_content2">
                <h1>Alto valor de cashback</h1>
                <p>
                    Ao participar das apostas, você acumula
                    progresso no VIP. O status VIP é válido
                    permanentemente e cada nível VIP possui
                    uma taxa de cashback diferente, podendo
                    chegar a até 5%.
                    O seu cashback será automaticamente
                    creditado em sua conta no dia seguinte,
                    e você pode retirá-lo diretamente ou
                    continuar jogando.
                </p>
                <img :src="IMAGE_URL + 'home/vip_pic.png'" alt="">
            </div>

            <div class="reis-content-r">
                <h1>Retorno generoso
                    para agentes</h1>
                <p>
                    Por cada usuário válido que você convidar,
                    você receberá uma comissão de R$12.
                    Quanto mais convidar, maior será a recompensa.
                    Além disso, você poderá obter até 1% de
                    reembolso das apostas de cada usuário convidado.
                </p>
                <img class="agen" :src="IMAGE_URL + 'home/dice_pic.png'" alt="">
            </div>

        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="less" scoped>
    .reis {
        padding-top: 70px;
    }

    .reis_box {
        background-color: #12212E;
        min-height: 900px;
        border-radius: 35px;
        color: #fff;
        padding: 60px 0;

        h3 {
            width: 80%;
            font-size: 60px;
            text-align: center;
        }
    }

    .reis_content {
        width: 694px;
        min-height: 420px;
        background: #213648;
        box-shadow: 0px 14px 60px 14px #000000;
        border-radius: 40px 40px 40px 40px;
        margin: 80px auto;
        position: relative;
        padding: 15px;
        box-sizing: border-box;

        h1 {
            width: 100%;
            text-align: center;
            font-size: 40px;
            padding: 30px 30px 0 0;
            /* position: relative; */
            /* margin-left: 150px; */
        }

        p {
            font-size: 28px;
            text-align: center;
            /* margin-top: 10px; */
            padding: 20px 30px 0 0;
            width: 68%;
            position: absolute;
            right: 0;
            line-height: 40px;
        }

        img {
            width: 276px;
            height: 305px;
            position: absolute;
            left: -30px;
            bottom: -15px;
        }

    }

    .reis_content2 {
        min-height: 480px;
        padding: 15px;
        box-sizing: border-box;

        p {
            font-size: 28px;
            text-align: center !important;
            width: 68% !important;
            line-height: 34px !important;
            margin-top: 0px;
        }
    }


    .reis-content-r {
        width: 694px;
        min-height: 380px;
        background: #213648;
        box-shadow: 0px 14px 60px 14px #000000;
        border-radius: 40px 40px 40px 40px;
        margin: 80px auto;
        position: relative;
        padding: 15px 0px;

        h1 {
            /* min-width: 320px;
            max-width: 480px; */
            text-align: left;
            font-size: 40px;
            padding: 30px 0 0 30px;
            margin-left: 15px;
        }

        p {
            font-size: 28px;
            text-align: left;
            padding: 20px 0 0 30px;
            width: 70%;
            position: absolute;
            left: 0;
            margin-top: 20px;
            margin-left: 15px;
        }

        img {
            width: 276px;
            height: 305px;
            position: absolute;
            right: -30px;
            bottom: -15px;
        }
    }

    img.agen {
        width: 525px;
        height: 306px;
    }
</style>