<template>
    <div class="confirm">
        <div class="c-title">Primeiro nome do Telegram</div>
        <input class="c-input" :class="{'c-input-red':isErro1}" v-model="value1" type="text" />
        <div class="c-title">Sobrenome do Telegram</div>
        <input class="c-input" :class="{'c-input-red':isErro2}" v-model="value2" type="text" />
        <div class="c-title">Nome de usuário do Telegram</div>
        <input class="c-input" :class="{'c-input-red':isErro2}" v-model="value3" type="text" />
        <p class="c-p">
            Para garantir que as recompensas possam ser
            distribuídas com precisão, confirme se as
            informações do Telegram estão corretas.
        </p>
        <div class="c-btn-box">
            <span @click="cancelarClick">Cancelar</span>
            <span @click="vincularClick">Vincular</span>
        </div>
    </div>
</template>

<script>
import { bindTg } from '@/api/api';
import { mapState } from 'vuex';
import { dotGTag } from '@/native/native';

export default {
    name: 'BindTg',
    components: {},
    props: {

    },
    data() {
        return {
            value1: '',
            value2: '',
            value3: '',
            isErro1: false,
            isErro2: false,
        }
    },
    watch: {},
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        vincularClick() {
            console.log(this.value1, this.value2, this.value3);
            if (this.value1 || this.value2 || this.value3) {
                dotGTag('click_tg_bind_submit', { 'name1': this.value1, 'name2': this.value2, 'name3': this.value3 })

                bindTg({
                    nickname: this.value1,
                    first_name: this.value2,
                    last_name: this.value3,
                }).then(res => {
                    this.cancelarClick();
                    this.$store.dispatch('toast', 'Vinculação bem-sucedida')
                    this.$store.dispatch('refreshRedDot');


                }).catch(err => { })

            } else {

                this.$store.dispatch("toast", {
                    show: true,
                    text: "Por favor preencha as informações",
                    type: "failed",
                });
            }
        },
        cancelarClick() {
            this.$store.commit('setShowPopupPanel',
                {
                    show: false,
                    componentName: '',
                    option: {}
                });
        }
    },
    created() {
        let tg_nickname = this.userData.tg_nickname;
        let tg_first_name = this.userData.tg_first_name;
        let tg_last_name = this.userData.tg_last_name;

        this.value1 = tg_nickname;
        this.value2 = tg_first_name;
        this.value3 = tg_last_name;
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.confirm {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 120px 30px 40px 30px;

    .c-title {
        width: 100%;
        font-size: 26px;
        font-weight: bold;
        color: #ffffff;
    }
    .c-input {
        width: calc(100% - 60px);
        height: 78px;
        background: #151d29;
        border-radius: 20px;
        color: #758eb5;
        font-size: 26px;
        padding: 0 30px;
        margin: 17px 0 50px 0;
    }
    .c-input-red {
        border: 1px solid #ff0000;
    }
    .c-p {
        font-size: 24px;
        color: #748395;
        text-align: center;
    }
    .c-btn-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        text-transform: uppercase;

        :nth-child(1) {
            width: 231px;
            height: 85px;
            background: #273343;
            border-radius: 15px;
            text-align: center;
            line-height: 85px;
            font-size: 31px;
            color: #5a6c82;
        }
        :nth-child(2) {
            width: 328px;
            height: 85px;
            background: #3cb408;
            border-radius: 15px;
            text-align: center;
            line-height: 85px;
            font-size: 31px;
            color: #fff;
        }
    }
}
</style>