<template>
    <div class="confirm">
        <div
            class="confirm-title"
        >- Você já entrou no canal rico13.com? Após ingressar, vincule seu número de telefone do Telegram ou nome de usuário para garantir que possamos distribuir a recompensa de R$ 5 em sua conta o mais rápido possível.</div>
        <div class="confirm-btn" @click="confirmClick">vincular Telegram</div>
        <span class="confirm-ca" @click="cancelarClick">Entrar no canal</span>
    </div>
</template>

<script>
import { openWvByChannel } from "@/native/native"
export default {
    name: 'Tgcb',
    components: {},
    props: {
        message: {
            type: Object,
        }
    },
    data() {
        return {

        }
    },
    watch: {},
    computed: {
    },
    methods: {
        confirmClick() {
            this.$store.commit('setShowPopupPanel',
                {
                    show: true,
                    componentName: 'BindTg',
                    option: {
                        content: 'Se você fizer um saque quando tiver um saldo de bônus ativo, seu saldo de bônus será perdido.',
                        confirmMsg: 'Continuar',
                        cancelMsg: 'fecho',
                        confirmCb: () => {

                        },
                    }
                });
        },
        cancelarClick() {
            openWvByChannel('https://t.me/rico13777')
        }
    },
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.confirm {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 80px 30px 40px 30px;

    .confirm-title {
        width: 100%;
        min-height: 300px;
        font-size: 26px;
        text-align: center;
        line-height: 40px;
        padding: 30px 0;
        color: #fff;
    }
    .confirm-btn {
        width: 462px;
        height: 100px;
        background: #3cb408;
        border-radius: 15px;
        text-align: center;
        line-height: 100px;
        font-size: 30px;
        text-transform: uppercase;
        color: #fefefe;
        margin-bottom: 30px;
    }
    .confirm-ca {
        color: #666666;
        font-size: 30px;
    }
}
</style>