<template>
    <div class="cashback">
        <div class="cashback-bar" @click="closed">
            <van-icon class="jiantou" name="arrow-left" size="20px" />
            <span>Receba R$ 100 de Graça</span>
            <van-icon class="que" name="question-o" @click.stop="openIntro" />
        </div>
        <div style="height: 40px;"></div>
        <div class="top-box">
            <div class="top-box-1">
                <div class="top-box-2">
                    <img src="../../../public/image/dazhuanpan/qian2.webp" alt />
                    <div>R$ {{ progress_bonus }}</div>
                </div>
                <div @click="sacarBtn" class="top-box-3">SACAR</div>
            </div>
            <van-progress class="ob-qis4" :percentage="jindu+''" />
            <p class="top-box-4">
                Ainda e necessário
                <span>{{ lastR }}</span> para sacar para o PIX
            </p>
        </div>
        <div class="outbox-bg">
            <div class="bg"></div>
            <div class="outbox">
                <img
                    class="pandi"
                    :style="{transform:rotate_deg,transition:rotate_transition}"
                    src="../../../public/image/dazhuanpan/dipan.webp"
                    alt
                />
                <img class="kuang" src="../../../public/image/dazhuanpan/kuang.webp" alt />
                <img
                    v-if="isAllowClick"
                    class="shanshuo"
                    src="../../../public/image/dazhuanpan/shanshuo.gif"
                    alt
                />
                <img
                    v-else
                    class="zhuanquan"
                    src="../../../public/image/dazhuanpan/zhuanquan.gif"
                    alt
                />
                <img class="zhongjian" src="../../../public/image/dazhuanpan/zhongjian.webp" alt />
                <div @click="rotating" class="cishu">{{ spin_count }}</div>
            </div>
            <img class="jinbi" src="../../../public/image/dazhuanpan/jinbi.webp" alt />
            <div class="btn-box">
                <div class="btn-box-djs">
                    <span>Expira em :</span>
                    <span>
                        <van-count-down class="time-cl" :time="time" format="DD d HH:mm:ss" />
                    </span>
                </div>
                <div @click="popBottom" class="btn-box-con">
                    <span>CONVIDE AMIGOS PARA AJUDAR COM SAQUES</span>
                    <van-icon name="share" size="20px" style="margin-left: 10px;" />
                </div>
            </div>
        </div>

        <div class="btm">
            <div class="btm-bar">
                <span @click="reBtn" :class="isSelect?'btm-btn':'btm-btn-default'">RELATÓRIO</span>
                <span @click="miBtn" :class="!isSelect?'btm-btn':'btm-btn-default'">MINHA REFERÊNCIA</span>
            </div>
            <div class="btm-content1" v-if="isSelect">
                <div class="ob-qis7-content" :class="animate?'content-margin20':'content-margin0'">
                    <div class="ob-qis7-item" v-for="(item,index) in withdraw_record" :key="index">
                        <span>{{ item.phone }}</span>
                        <span>{{ item.name }}</span>
                        <span>+R$ {{ item.withdraw_bonus }}</span>
                    </div>
                </div>
            </div>
            <div class="btm-content1" v-else>
                <div class="ob-qis7-content" :class="animate?'content-margin20':'content-margin0'">
                    <div class="ob-qis7-item" v-for="(item,index) in invite_record" :key="index">
                        <span>{{ item.account }}</span>
                        <span>{{ item.invite_time }}</span>
                        <span>+R$ {{ item.add_bonus }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-retain" v-if="showRetain">
            <div class="content-box">
                <div class="ob-qis1">
                    <span>Em breve poderá sacar</span>
                </div>
                <p class="ob-qis3-p">Total de dinheiro a ser pago em breve</p>
                <div class="ob-qis3">
                    <img src="../../../public/image/dazhuanpan/qian2.webp" alt />
                    <span>R$ {{ progress_bonus }}</span>
                </div>
                <div class="msg-1">
                    <span>Conta de saque</span>
                    <span>{{ userData.phone !== '' ? userData.phone : userData.email}}</span>
                </div>
                <div class="msg-1">
                    <span>Formas de Pagamentos</span>
                    <span>PIX</span>
                </div>
                <div class="what-app">
                    <div class="what-app-and">
                        <img src="../../../public/image/dazhuanpan/gaoliang.webp" alt />
                        <span>O pedido de pagamento foi submetido</span>
                    </div>
                    <div class="what-app-and">
                        <img src="../../../public/image/dazhuanpan/gaoliang.webp" alt />
                        <span>Ainda é necess¡rio 3,6 para realizar do saque</span>
                    </div>
                    <div class="what-app-and">
                        <img
                            v-if="progress_bonus >= target_bonus"
                            src="../../../public/image/dazhuanpan/gaoliang.webp"
                            alt
                        />
                        <img v-else src="../../../public/image/dazhuanpan/huise.webp" alt />
                        <span>100 R$ serāo pagos na sua conta PlX</span>
                    </div>
                </div>
                <div
                    v-if="progress_bonus < target_bonus"
                    @click="popBottom"
                    class="ob-qis6"
                >Convide amigos para ajudar com</div>
                <div v-else @click="jumpBonus" class="ob-qis6">Sacar</div>
                <van-icon
                    @click="closeRetain"
                    class="close-panel"
                    name="close"
                    size="30"
                    color="#fff"
                />
            </div>
        </div>

        <van-popup class="showIntro" v-model="showIntro">
            <van-icon
                @click="closeIntro"
                name="close"
                color="white"
                style="right: 10px;position: absolute;top: 10px;"
            />
            <h4>Regras</h4>
            <p>1. Você pode iniciar um saque quando o valor acumulado atingir 100 BRL.</p>
            <p>2. Convidar amigos para se registrarem no rico13.com irá ajudá- lo a sacar dinheiro rapidamente.</p>
            <p>3. Cada usuário receberá uma única rodada grátis por dia.</p>
            <p>4. Cada usuário tem um período de validade de 3 dias para cada atividade e pode participar da atividade várias vezes.</p>
            <p>5. Se você receber um bônus de dinheiro extra, pode jogar jogos de graça e ganhar ainda mais dinheiro real.</p>
            <p>6. Se você não retirar o dinheiro dentro de 3 dias,será perdido.</p>
        </van-popup>

        <van-popup
            class="showBottm"
            v-model="showBottom"
            position="bottom"
            @open="openEvent"
            :overlay="true"
        >
            <div class="share-content">
                <p>1. Convide amigos para ajudar com saques</p>
                <div class="chan-box">
                    <div
                        class="chan"
                        v-for="(item,index) in platform"
                        @click="platformBtn(item.key)"
                    >
                        <div class="chan-touxiang">
                            <img :src="item.imgurl" alt />
                        </div>
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="link">
                    <span ref="textToCopy">{{ inviteUrl + "_P1" }}</span>
                    <span @click="toCopy">Cópia</span>
                </div>
                <p>2.Enviar convite para jogador aleatório ajudar</p>
                <div class="yaoqing-box">
                    <div class="yaoqing-box-in">
                        <template v-for="(item,index) in phoneArr">
                            <div :class="phoneIndex >= index?'huise':''">{{ item }}</div>
                        </template>
                    </div>
                    <div class="yaoqing-btn-box">
                        <div class="yaoqing-btn-box-s" @click="whatsAppBtn">
                            <div class="yaoqing-btn-box-msg">
                                <span>Enviar Mensagem</span>
                                <span>no WhatsAPP</span>
                            </div>
                            <img src="../../../public/image/dazhuanpan/dianhua.webp" alt />
                        </div>
                        <div class="yaoqing-btn-box-s" @click="SMSBtn">
                            <div class="yaoqing-btn-box-msg">
                                <span>Enviar Mensagem</span>
                                <span>SMS</span>
                            </div>
                            <img src="../../../public/image/dazhuanpan/sms.webp" alt />
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { pddTurntable, pddSpinTurntable, pddGetReward, pddFindInvitePhones, convertShortUrl } from '@/api/api';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { openWvByChannel } from "@/native/native";

export default {
    name: '',
    components: {
    },
    props: {},
    data() {
        return {
            showIntro: false,
            showBottom: false,
            showRetain: false,//挽留
            angle: 45,
            isAllowClick: true,
            rotate_deg: 0,
            rotate_transition: "transform 3s ease-in-out",
            isSelect: true,
            progress_bonus: 0,
            target_bonus: 0,
            spin_count: 0,
            time: 0,
            lastR: 0,
            invite_record: [],
            withdraw_record: [],
            timer1: null,
            animate: false,
            isRetain: true,//是否挽留
            platform: [
                { name: 'Mais', key: 'P1', imgurl: require("../../../public/image/dazhuanpan/1.png") },
                { name: 'Facebook', key: 'P2', imgurl: require("../../../public/image/dazhuanpan/2.png") },
                { name: 'Whatsapp', key: 'P3', imgurl: require("../../../public/image/dazhuanpan/3.png") },
                { name: 'Telegram', key: 'P4', imgurl: require("../../../public/image/dazhuanpan/4.png") },
                { name: 'Twitter', key: 'P5', imgurl: require("../../../public/image/dazhuanpan/5.png") },
                { name: 'Email', key: 'P6', imgurl: require("../../../public/image/dazhuanpan/6.png") },
            ],
            inviteUrl: '',
            phoneIndex: -1,
            phoneArr: [],
            inviteWriting: '',
            jindu: 0,
            targetValue: 0, // 目标进度值
            interval: 10, // 更新间隔（毫秒）
            timer: null,
        }
    },
    watch: {
        showDaZhuanPanIndex: {
            handler(val) {
                if (val) {
                    this.getPddTurntable();
                } else {
                    clearInterval(this.timer1);
                    this.jindu = 0;
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(["showDaZhuanPanIndex", "userData", "config"]),
    },
    methods: {
        updateProgress() {
            this.timer = setInterval(() => {
                // 每次更新进度
                this.jindu += 1;
                // 达到目标值后停止更新
                if (this.jindu >= this.targetValue) {
                    clearInterval(this.timer);
                    this.jindu = this.targetValue; // 确保最终值为目标值
                }
            }, this.interval);
        },
        closed() {
            if (this.isRetain) {
                console.log(this.progress_bonus >= this.target_bonus)
                this.showRetain = true;
                return;
            }
            this.$store.commit('setShowDaZhuanPanIndex', false);
        },
        openIntro() {
            this.showIntro = true;
        },
        closeIntro() {
            this.showIntro = false;
        },
        popBottom() {
            this.showBottom = true;
        },
        closeRetain() {
            this.showRetain = false;
            this.isRetain = false;
        },
        rotating() {
            if (this.progress_bonus >= this.target_bonus) {
                Toast('Por favor, retire o dinheiro primeiro');
                return;
            }
            if (!this.isAllowClick) return;
            if (this.spin_count == 0) {
                this.popBottom();
                return;
            };
            this.isAllowClick = false;
            pddSpinTurntable()
                .then(res => {
                    console.log(res.info)
                    this.spin_count = res.info.spin_count;
                    this.target_bonus = parseFloat(res.info.target_bonus);
                    this.progress_bonus = parseFloat(res.info.progress_bonus);

                    const values = [0, 4];
                    const randomIndex = Math.floor(Math.random() * values.length);
                    const randomValue = values[randomIndex];

                    var winningIndex = 0;
                    if (res.info.index == 5 || res.info.index == 6) {
                        winningIndex = randomValue;
                    }
                    if (res.info.index == 0) {
                        winningIndex = 3;
                    }

                    this.rotate_transition = "transform 3s ease-in-out";
                    let rand_circle = 5; //默认多旋转5圈
                    let deg = rand_circle * 360 + winningIndex * this.angle; //将要旋转的度数
                    this.rotate_deg = "rotate(" + deg + "deg)";
                    console.log("winningIndex===>", winningIndex)
                    setTimeout(() => {
                        this.isAllowClick = true;
                        this.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
                        this.rotate_transition = "";
                        this.getPddTurntable();
                    }, 5000)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        reBtn() {
            this.isSelect = true;
        },
        miBtn() {
            this.isSelect = false;
        },
        scroll1() {
            this.animate = true;
            setTimeout(() => {
                this.withdraw_record.push(this.withdraw_record[0]);
                this.withdraw_record.shift();
                // this.animate = false;// 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
                this.animate = false;
            }, 500);
        },
        sacarBtn() {
            if (this.progress_bonus >= this.target_bonus) {
                pddGetReward()
                    .then(res => {
                        Toast('sucesso');
                        this.$store.dispatch('UserInfo');
                        // this.getPddTurntable();
                        this.$store.commit('setShowDaZhuanPanIndex', false);
                    })
            } else {
                // Toast('Condição não atendida');
                this.showRetain = true;
            }
        },
        getPddTurntable() {
            pddTurntable()
                .then(res => {
                    console.log(res)
                    this.$store.state.has_spin_count = res.has_spin_count;

                    this.progress_bonus = parseFloat(res.progress_bonus);
                    this.target_bonus = parseFloat(res.target_bonus);
                    this.spin_count = res.spin_count;
                    this.time = res.countdown * 1000;

                    if (this.target_bonus != 0) {
                        this.targetValue = this.progress_bonus / this.target_bonus * 100;
                        this.updateProgress();
                    }
                    this.lastR = (this.target_bonus - this.progress_bonus).toFixed(2);
                    this.invite_record = res.invite_record;
                    this.withdraw_record = res.withdraw_record;
                    if (this.invite_record.length != 0) {
                        this.isSelect = false;
                    }
                })
                .catch(err => {

                })
        },
        openEvent() {
            pddFindInvitePhones()
                .then(res => {
                    console.warn(res)
                    this.phoneArr = res.list;
                })
                .catch(err => {

                })

            convertShortUrl({
                sub_channel: ''
            }).then(res => {
                console.log(res)
                this.inviteUrl = res.Url;
            })
        },
        platformBtn(tag) {
            let url = this.inviteUrl + `_${tag}`;
            let openUrl = ''
            switch (tag) {
                case 'P1':
                    openUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${this.inviteWriting}`
                    break;
                case 'P2':
                    openUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${this.inviteWriting}`
                    break;
                case 'P3':
                    openUrl = `whatsapp://send?text=${this.inviteWriting}${'\n'}${url}`
                    break;
                case 'P4':
                    openUrl = `https://t.me/share/url?url=${url}&text=${this.inviteWriting}`
                    break;
                case 'P5':
                    openUrl = `https://twitter.com/intent/tweet?text=${this.inviteWriting}&url=${url}`
                    break;
                case 'P6':
                    openUrl = `mailto:?body=${this.inviteWriting}${'\n'}${url}`
                    break;
            }
            console.log(openUrl)
            // window.open(openUrl, '_blank');
            openWvByChannel(openUrl);
        },
        toCopy() {
            const textToCopy = this.$refs.textToCopy.innerText;
            const textArea = document.createElement('textarea');

            // 设置文本区域的属性，使其不可见
            textArea.value = textToCopy;
            textArea.style.position = 'fixed';
            textArea.style.opacity = '0';
            textArea.style.pointerEvents = 'none';

            document.body.appendChild(textArea);
            textArea.select(); // 选中文本

            try {
                const successful = document.execCommand('copy');
                console.log(successful ? 'Copy successful' : 'Copy failed');
                if (successful) {
                    this.$store.dispatch('toast', 'Copia de sucesso')
                }
            } catch (err) {
                console.error('Oops, unable to copy', err);
            }

            document.body.removeChild(textArea); // 移除文本区域
        },
        whatsAppBtn() {
            this.phoneIndex += 1;
            console.log(this.phoneArr[this.phoneIndex])
            let openUrl = `whatsapp://send?phone=${this.phoneArr[this.phoneIndex]}&text=${this.inviteWriting}${'\n'}${this.inviteUrl}`;
            // window.open(openUrl, '_blank');
            console.log(openUrl)
            openWvByChannel(openUrl);
        },
        SMSBtn() {
            var str = '';
            this.phoneArr.map(e => {
                str += e + ';';
            })
            console.log(str)
            // window.open(`sms:${str}?body=${this.inviteWriting}${'  '}${this.inviteUrl}`, '_blank');
            openWvByChannel(`sms:${str}?body=${this.inviteWriting}${'  '}${this.inviteUrl}`);
            console.log(`sms:${str}?body=${this.inviteWriting}${'  '}${this.inviteUrl}`)
        },
        jumpBonus() {
            this.showRetain = false;
            this.$store.commit('setShowDaZhuanPanIndex', false);
            this.$router.push({ name: 'recharge', params: { tabIndex: 2 } });
        }
    },
    created() {
        this.inviteWriting = `Oferta por tempo limitado,  ${this.config.pdd_turntable.extra_cashback_rate}% extra cashback já disponível. Receba 100 BRL de graça,Pix SAQUE RÁPIDO.`;
        console.log(this.inviteWriting)
    },
    mounted() {
        this.timer1 = setInterval(this.scroll1, 2000);
    }
}
</script>
<style lang="less" scoped>
.cashback {
    width: 100%;
    height: 100%;
    font-family: Montserrat-VF;
    background: #0b131d;
    overflow-x: hidden;
}
.cashback-bar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    color: white;
    position: fixed;
    top: 0;
    background: #14202d;
    z-index: 18;
    .jiantou {
        margin-left: 30px;
    }
    > span {
        font-size: 30px;
    }
    .que {
        margin-left: 240px;
        font-size: 40px;
    }
}
.top-box {
    width: calc(100% - 50px);
    padding: 25px 25px;
    background: linear-gradient(to bottom, #0a1524, #1a1330);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    .top-box-1 {
        width: 100%;
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .top-box-2 {
        width: 469px;
        height: 86px;
        background: #050512;
        border-radius: 42px;
        display: flex;
        align-items: center;
        > img {
            width: 39px;
            height: 48px;
            margin-left: 40px;
        }
        > div {
            color: #ff3230;
            font-size: 60px;
            font-weight: 600;
            margin-left: 22px;
        }
    }
    .top-box-3 {
        width: 118px;
        height: 56px;
        color: white;
        background: #d22929;
        font-size: 28px;
        text-align: center;
        line-height: 56px;
        border-radius: 10px;
    }
    .ob-qis4 {
        width: 690px;
        height: 14px;
        margin: 10px 0 20px 0;
        ::v-deep .van-progress__portion {
            background: #1bdf46;
        }
        ::v-deep .van-progress__pivot {
            // display: none;
            top: -10px;
            left: 100px;
            background: none;
        }
    }
    .top-box-4 {
        font-size: 24px;
        color: #ffc500;
        > span {
            color: #ff3230;
        }
    }
}

.outbox-bg {
    width: 100%;
    height: 844px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .bg {
        width: 816px;
        height: 875px;
        background: url("../../../public/image/dazhuanpan/guang.webp") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-46%, -65%);
    }
    .outbox {
        width: 514px;
        height: 514px;
        position: relative;
        animation: eject-pop 0.3s ease-in-out;
        margin-top: 0px;

        .pandi {
            width: 524px;
            height: 524px;
        }
        .kuang {
            position: absolute;
            width: 547px;
            height: 577px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -52%);
        }
        .shanshuo {
            position: absolute;
            width: 584px;
            height: 584px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -50%);
        }
        .zhuanquan {
            position: absolute;
            width: 584px;
            height: 584px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -50%);
        }
        .zhongjian {
            position: absolute;
            width: 138px;
            height: 143px;
            top: 50%;
            left: 50%;
            transform: translate(-46%, -54%);
        }
        .cishu {
            position: absolute;
            width: 120px;
            height: 120px;
            text-align: center;
            // background: #1f6400;
            color: white;
            text-shadow: -1px -1px 0 #1f6400, 1px -1px 0 #1f6400,
                -1px 1px 0 #1f6400, 1px 1px 0 #1f6400; /* 描边效果，可以根据需要调整偏移量和颜色 */
            line-height: 120px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-48%, -49%);
            font-size: 40px;
        }
    }
}
.jinbi {
    width: 779px;
    height: 389px;
    position: absolute;
    top: 58%;
}
.btn-box {
    position: absolute;
    top: 85%;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btn-box-djs {
        width: 408px;
        height: 53px;
        border-radius: 13px;
        background: rgb(0, 0, 0, 0.76);
        font-size: 26px;
        color: #a2b5cb;
        text-align: center;
        line-height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        .time-cl {
            color: #ffd200;
            padding-left: 10px;
        }
    }
    .btn-box-con {
        width: 694px;
        height: 78px;
        background: #3cb408;
        border-radius: 15px;
        font-size: 24px;
        color: #ffffff;
        text-align: center;
        line-height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.btm {
    width: calc(100% - 72px);
    padding: 50px 36px;
    .btm-bar {
        width: 100%;
        font-size: 26px;
        display: flex;
        text-align: center;
        line-height: 84px;
        font-weight: 600;
        > span:nth-child(1) {
            border-radius: 0 0 0 15px;
        }
        > span:nth-child(2) {
            border-radius: 0 0 15px 0;
        }
        .btm-btn {
            width: 340px;
            height: 84px;
            background: #ffd200;
            color: #040a23;
        }
        .btm-btn-default {
            width: 340px;
            height: 84px;
            color: #ffd200;
            background: #212b38;
        }
    }
    .btm-content1 {
        margin-top: 30px;
        width: 100%;
        height: 160px;
        overflow: hidden;
        transition: all 0.5s;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ob-qis7-content {
            width: calc(100% - 40px);
        }

        .ob-qis7-item {
            width: 100%;
            height: 30px;
            font-size: 25px;
            color: white;
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            > span:nth-child(3) {
                color: #ff3230;
                font-weight: 600;
            }
        }
    }
}

.showBottm {
    width: 100%;
    height: 830px;
    background: linear-gradient(to bottom, #060e1e, #04293a);
}
.share-content {
    width: calc(100% -60px);
    padding: 30px;
    > p {
        font-size: 24px;
        color: #a2c6f4;
    }
    .chan-box {
        width: 100%;
        display: flex;
        .chan {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 35px 7px;
            .chan-touxiang {
                width: 93px;
                height: 93px;
                background: #094557;
                border-radius: 50%;
                > img {
                    width: 93px;
                    height: 93px;
                }
            }
            > span {
                font-size: 22px;
                color: white;
                margin-top: 15px;
            }
        }
    }
    .link {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 62px);
        height: 79px;
        padding: 0 31px;
        background: #1e2b3a;
        border-radius: 10px;
        line-height: 79px;
        font-weight: 600;
        margin-bottom: 28px;
        > span:nth-child(1) {
            font-size: 24px;
            color: white;
        }
        > span:nth-child(2) {
            font-size: 24px;
            color: #ffc500;
        }
    }
    .yaoqing-box {
        width: calc(100% - 56px);
        background: linear-gradient(to bottom, #202734, #1c3d4b);
        margin-top: 32px;
        border-radius: 30px;
        padding: 18px 28px;

        .yaoqing-box-in {
            font-size: 20px;
            color: #8c9eb1;
            width: 100%;
            text-align: center;
            margin-bottom: -5px;
            display: flex;
            flex-wrap: wrap;
            > div {
                width: 25%;
                padding: 12px 0;
            }
        }
        .huise {
            color: #4e555e;
        }
        .yaoqing-btn-box {
            width: 100%;
            height: 79px;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .yaoqing-btn-box-s {
                width: 315px;
                height: 100%;
                border-radius: 10px;
                background: #ffc500;
                display: flex;
                align-items: center;
                justify-content: space-around;
                > img {
                    width: 55px;
                    height: 55px;
                }
                .yaoqing-btn-box-msg {
                    font-size: 24px;
                    color: #2e0000;
                    display: flex;
                    flex-direction: column;
                    > span:nth-child(2) {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.showIntro {
    width: 80%;
    height: 80%;
    background: #1c2532;
    padding: 0 30px 30px 30px;
    border-radius: 10px;
    > h4 {
        text-align: center;
        font-size: 29px;
        color: white;
        padding-top: 20px;
    }
    > p {
        font-size: 22px;
        color: #8ba3c8;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

::-webkit-scrollbar {
    display: none;
}

.my-retain {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 18;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat-VF;

    .content-box {
        width: 650px;
        min-height: 698px;
        border-radius: 10px;
        background: linear-gradient(#0f1623, #094456);
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: eject-pop 0.3s ease-in-out;
        padding-bottom: 10px;
        .ob-qis1 {
            display: flex;
            align-items: center;
            width: calc(100% - 40px);
            color: #f9c918;
            font-size: 24px;
            padding: 20px;
            font-weight: 600;
            > img {
                width: 24px;
                height: 29px;
                margin-right: 15px;
            }
        }
        .ob-qis3-p {
            padding: 15px 0 25px 0;
            color: #8192a4;
            font-size: 26px;
        }
        .ob-qis3 {
            display: flex;
            align-items: center;
            font-size: 40px;
            padding: 0 20px;
            font-weight: 600;
            width: 70%;
            height: 100px;
            background: #06090f;
            border-radius: 15px;
            > img {
                padding: 0 40px;
                width: 39px;
                height: 48px;
            }
            > span {
                font-size: 60px;
                font-weight: 700;
                color: #ff3230;
            }
        }

        .ob-qis6 {
            width: 580px;
            height: 80px;
            background: #ffc500;
            border-radius: 13px;
            color: #2e0000;
            font-size: 24px;
            text-align: center;
            line-height: 80px;
            font-weight: 700;
            margin-top: 20px;
        }
        .close-panel {
            position: absolute;
            bottom: -100px;
        }
        .msg-1 {
            width: calc(100% - 100px);
            display: flex;
            justify-content: space-between;
            font-size: 24px;
            color: #8c9eb1;
            padding: 20px 50px 0px 50px;
            > span:nth-child(2) {
                color: white;
            }
        }
        .what-app {
            width: 572px;
            border-radius: 30px;
            background: #1e3340;
            margin-top: 30px;
            padding: 20px 0;
            .what-app-and {
                padding: 10px 30px;
                display: flex;
                font-size: 20px;
                color: #8c9eb1;
                > img {
                    width: 28px;
                    height: 28px;
                    margin-right: 20px;
                }
            }
        }
    }
}

.content-margin20 {
    margin-top: -40px;
    transition: all 0.5s;
}
.content-margin0 {
    margin-top: 0px;
}

@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>