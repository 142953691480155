<template>
    <div v-if="showSelf" class="dialog" :style="{'z-index': zIndex}">
        <div class="dialog-mark" @click.self="closeMyself" :style="{'z-index': zIndex + 1}"></div>
        <transition name="dialog">
            <div class="dialog-sprite" :style="{'z-index': zIndex + 2}">
                <section v-if="title" class="header" :style="{'z-index': zIndex +3}">{{ title }}</section>
                <section class="dialog-body" :style="{'z-index': zIndex + 3}">
                    <slot></slot>
                </section>
                <section class="dialog-footer" :style="{'z-index': zIndex + 3}">
                    <div v-if="showCancel" class="btn btn-refuse" @click="cancel">{{cancelText}}</div>
                    <div class="btn btn-confirm" @click="confirm">{{confirmText}}</div>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
                required: true,
            },
            title: {
                type: String,
                required: false,
            },
            showCancel: {
                typs: Boolean,
                default: false,
                required: false,
            },
            cancelText: {
                type: String,
                default: 'cancel',
                required: false,
            },
            confirmText: {
                type: String,
                default: 'confirm',
                required: false,
            },
        },
        data() {
            return {
                name: 'dialog',
                showSelf: false,
                zIndex: this.getZIndex(),
                bodyOverflow: ''
            }
        },
        watch: {
            show(val) {
                if (!val) {
                    this.closeMyself()
                } else {
                    this.showSelf = val
                }
            }
        },
        created() {
            this.showSelf = this.show;
        },
        mounted() {
            this.forbidScroll()
        },
        methods: {
            forbidScroll() {
                this.bodyOverflow = document.body.style.overflow
                document.body.style.overflow = 'hidden'
            },

            getZIndex() {
                let zIndexInit = 20190315;
                return zIndexInit++
            },

            cancel() {
                this.$emit('cancel', false);
            },

            confirm() {
                this.$emit('confirm', false)
            },

            closeMyself(event) {
                this.showSelf = false;
                this.sloveBodyOverflow()
            },

            sloveBodyOverflow() {
                document.body.style.overflow = this.bodyOverflow;
            },
        }
    }
</script>

<style lang="less" scoped>
    .dialog-enter-active,
    .dialog-leave-active {
        transition: opacity .5s;
    }

    .dialog-enter,
    .dialog-leave-to {
        opacity: 0;
    }

    .dialog {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .dialog-mark {
            position: absolute;
            /* top: 0;
            height: 0; */
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .6);

        }
    }

    .dialog-sprite {
        position: absolute;
        top: 15%;
        left: 15%;
        right: 15%;
        /* bottom: 25%; */
        display: flex;
        flex-direction: column;
        max-height: 75%;
        min-height: 180px;
        overflow: hidden;
        z-index: 23456765435;
        background: #11212D;
        padding: 30px;
        box-sizing: border-box;
        border-radius: 20px;

        .header {
            padding: 15px;
            box-sizing: border-box;
            text-align: center;
            font-size: 35px;
            /* font-family: 'ArialBold'; */
            font-weight: bold;
            color: #FFFFFF;
            margin-bottom: 30px;
        }

        .dialog-body {
            flex: 1;
            overflow-x: hidden;
            overflow-y: scroll;
            padding: 0 15px 20px 15px;
            font-size: 25px;
            color: #94BDE3;
            text-align: center;
        }

        .dialog-footer {
            /* position: relative; */
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 53px;
            /* &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: #ddd;
                transform: scaleY(.5);
            } */

            .btn {
                /* flex: 1; */
                text-align: center;
                /* padding: 15px; */
                font-size: 17px;
                color: #fff;
                padding: 20px 30px;
                box-sizing: border-box;
                margin: 0px 10px;

                &:nth-child(2) {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 1px;
                        height: 100%;
                        background: #ddd;
                        transform: scaleX(.5);
                    }
                }
            }

            .btn-confirm {
                background: url('../../public/image/common/green_bg.png') no-repeat;
                background-size: 100% 100%;
            }

            .btn-refuse {
                background: url('../../public/image/common/red_bg.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
</style>