<template>
  <div v-if="showState" class="faq">
    <img @click="closeFAQ" class="faq-close" src="../../public/image/message/close.png" alt="">
    <!--    <div class="faq-bar">-->
    <!--      <div :class="currentTab === 0 ? 'selectedBar' : ''" @click="currentTab = 0">Básica</div>-->
    <!--      <div :class="currentTab === 1 ? 'selectedBar' : ''" @click="currentTab = 1">Presente</div>-->
    <!--    </div>-->
    <div class="faq-context">
      <div class="faq-title">FAQ</div>
      <div class="faq-text" v-show="currentTab === 0">
        <p>
          Cada depósito requer uma aposta de 1x.
        </p>
        <p>
          e se você participar de uma promoção de depósito, será necessário cumprir requisitos adicionais de aposta para
          o bônus concedido.
        </p>
        <p>
          Se você tiver várias tarefas de depósito, todas elas serão combinadas, e você deve completar as apostas
          exigidas antes de poder sacar.
        </p>
        <p>
          Se seu saldo não for suficiente para continuar jogando, suas tarefas de aposta serão automaticamente
          canceladas.
        </p>
      </div>
      <div class="faq-text" v-show="currentTab === 1">
        <p>
          Aqui estão incluídos os requisitos básicos de
          apostas de depósito e as tarefas de apostas
          de bônus em andamento (incluindo as concluídas).
        </p>
        <p>
          As tarefas básicas de apostas de depósito só
          podem ser realizadas simultaneamente com a
          primeira tarefa de bônus acionada, não sendo
          possível realizar várias tarefas ao mesmo tempo.
        </p>
        <p>
          Se a tarefa de apostas de bônus for concluída,
          a próxima tarefa será iniciada automaticamente.
        </p>
      </div>
      <div class="faq-title">O Método de Cálculo</div>
      <div class="faq-text" v-show="currentTab === 0">
        <div class="faq-table-head">
          <div>Apostas</div>
          <div>Ganhou</div>
          <div>Aposta</div>
          <div>Descrição</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>40.00</div>
          <div>100.00</div>
          <div>Derrotada</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>80.00</div>
          <div>100.00</div>
          <div>Derrotada</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>100.00</div>
          <div>100.00</div>
          <div>Empatadas</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>120.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>160.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>200.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>80000.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
      </div>
      <div class="faq-text" v-show="currentTab === 1">
        <div class="faq-table-head">
          <div>Apostas</div>
          <div>Ganhou</div>
          <div>Aposta válido</div>
          <div>Descrição</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>40.00</div>
          <div>100.00</div>
          <div>Derrotada</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>80.00</div>
          <div>100.00</div>
          <div>Derrotada</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>100.00</div>
          <div>100.00</div>
          <div>Empatadas</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>120.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>160.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>200.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
        <div class="faq-table-tr">
          <div>100.00</div>
          <div>80000.00</div>
          <div>100.00</div>
          <div>Vitórias</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['showState'],
    data() {
      return {
        currentTab: 0
      }
    },
    methods: {
      loadMessage() {

      },
      closeMessage(id) {
        this.spliceIndex = id;
        setTimeout(() => {
          this.list.map((item, index) => {
            if (item.id === id) {
              this.list.splice(index, 1);
              this.spliceIndex = -1;
            }
          })
        }, 250)
        console.log(this.list)
      },
      closeFAQ() {
        this.$emit('closeFAQ');
      }
    }
  }
</script>

<style scoped lang="less">
  .faq {
    background: #131F2D;
    width: 100vw;
    height: 82vh;
    position: fixed;
    top: 10vh;
    left: 0;
    border-radius: 60px;
    animation: eject-pop 0.3s ease-in-out;
  }

  @keyframes eject-pop {
    from {
      transform: scale(0.6)
    }

    to {
      transform: scale(1)
    }
  }

  .faq-bar {
    margin: 40px 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    font-size: 36px;
  }

  .faq-bar div {
    background-color: #213648;
    border-radius: 20px;
    padding: 20px 60px;
    color: #93BDE1;
    font-weight: bold;
  }

  .faq-title {
    font-size: 36px;
    text-align: center;
    margin: 10px 0 24px 0;
    font-weight: bold;
  }

  .faq-text {
    margin: 40px;
    padding: 20px 40px 40px 40px;
    background: #213648;
    border-radius: 20px;
    color: #93BDE1;
    line-height: 40px;
  }

  .faq-text p {
    padding-top: 40px;
  }

  .faq-close {
    float: right;
    width: 30px;
    margin: 40px;
  }

  .selectedBar {
    background-color: #53B949 !important;
    color: #FFFFFF !important;
  }

  .message-block {
    height: 200px;
    background-color: #12212E;
    margin: 30px;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }

  .faq-context {
    font-size: 28px;
    overflow: scroll;
    height: 64vh;
    margin-top: 130px;
  }

  .faq-table-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    background-color: #53B949;
    padding: 12px 20px;
    border-radius: 20px;
    margin: 20px 0 40px 0;
    font-size: 22px;
    font-weight: bold;
  }

  .faq-table-tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    border-bottom: 1px solid;
    padding: 8px 0;

    div {
      width: 25%;
      text-align: center;
    }
  }
</style>