<script setup>
import {getPpGame, getTrailGame} from "@/api/api";
import {reactive, getCurrentInstance, ref} from "vue";

const props = defineProps(['show']);
const emit = defineEmits(['close'])
const {proxy} = getCurrentInstance();

let games = reactive({array: []});
let playIndex = ref(-1), ensureGameId = '', gameUrl = '';
const getGameList = () => {
  getTrailGame().then((res) => {
    games.array = res.data;
  })
}
const readyPlay = (gameId) => {
  playIndex.value = gameId
  getPpGame({
    gameId: gameId,
    isTail: true
  }).then((res) => {
    gameUrl = res.data.gameList;
    // doPlay()
  })
}
const doPlay = () => {
  proxy.$root.$router.push({name: 'play', params: {url: gameUrl}})
}
const closeMask = () => {
  emit('close')
}
getGameList();
</script>

<template>
  <div class="gameMask" v-show="props.show">
    <div class="game-mask-block">
      <div class="game-mask-close">
        <img class="close_pics" src="../../public/image/login/close.png" alt="" @click="closeMask">
      </div>

      <div class="bonus-context">
        <div class="bonus-context-title">Escolha um jogo</div>
        <div class="bonus-context-info">Você pode escolher um jogo das opções abaixo para usar sua aposta grátis. Uma vez que você selecione um jogo, não será possível trocar.</div>
      </div>
      <div class="bonus-game-list">
        <div class="bonus-game-block" v-for="item in games.array">
          <img :class="playIndex === item.game_id ? 'imageFilter' : ''" :src="item.icon"
               @click="readyPlay(item.game_id)" alt="">
          <img v-show="playIndex === item.game_id" class="play-btn"
               src="../../public/image/common/playBtn.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.game-mask-block {
  padding: 40px;
  background-color: #12212E;
  border-radius: 40px;
  margin-top: 60px;
  animation: eject-pop 0.3s ease-in;

  .game-mask-close {
    text-align: right;
    float: right;
    img {
      width: 40px;
    }
  }
}
.gameMask {
  width: 100%;
  height: calc(100vh - 240px);
  background: rgba(2, 2, 2, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999 !important;
  color: #fff;
  margin-top: 16%;
  .bonus-context {
    font-size: 28px;
    text-align: center;
  }
  .bonus-context-title {
    margin: 20px 0;
    font-size: 40px;
  }
  .bonus-context-info {
    line-height: 40px;
  }
  .bonus-game-list {
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    align-items: center;
    min-height: 50vh;
    max-height: 50vh;
    overflow: scroll;
  }
  .bonus-game-block {
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 0;
    img {
      width: 100%;
    }
  }
}
.imageFilter {
  filter: brightness(0.3);
}

.play-btn {
  width: 100px !important;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  animation: play-scale 200ms ease-in;
}

@keyframes play-scale {
  from {
    transform: scale(1.5)
  }
  to {
    transform: scale(1)
  }
}
.activity-block {
  min-height: 200px;
}
@keyframes eject-pop {
  from { transform: scale(0.8) }
  to { transform: scale(1) }
}
</style>