import Vue from 'vue'
import App from './App.vue'
import "../public/css/reset.css"
import Vant, {
    Swipe,
    SwipeItem,
    DropdownMenu,
    DropdownItem,
    Divider
} from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible'
import "@/assets/font/text.css";
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import {
    Locale
} from 'vant';
import enUS from 'vant/es/locale/lang/en-US';

import {
    HandlerPremiseTask
} from '@/PremiseTask';

import './registerServiceWorker'
Locale.use('en-US', enUS);
Vue.config.productionTip = false;
Vue.prototype.IMAGE_URL = 'https://d25pkupzrfbq8i.cloudfront.net/AssetImage/';
Vue.prototype.APK_URL = 'https://d25pkupzrfbq8i.cloudfront.net/apk/Rico13.apk';
Vue.prototype.$eventBus = new Vue();
Vue.prototype.deferredPrompt = null;


Vue.prototype.stopScroll = function () {
    var mo = function (e) {
        e.preventDefault()
    }
    document.body.style.overflow = 'hidden'
    document.addEventListener('touchmove', mo, false)
}

Vue.prototype.canScroll = function () {
    var mo = function (e) {
        e.preventDefault()
    }
    document.body.style.overflow = ''
    document.removeEventListener('touchmove', mo, false)
}
HandlerPremiseTask()
Vue.use(Vant);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Toasted)
Vue.use(Divider)
// new Vue({
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')

function generateUID() {
    // 生成一个随机的字符串作为 uid
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uid = '';
    for (let i = 0; i < 12; i++) {
        uid += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return uid;
}

async function checkServerStatus() {
    try {
        const response = await fetch('https://www.rico13.com/hall/auth/get_server_state');
        const data = await response.json();
        if (!data.invalid) {
            new Vue({
                router,
                store,
                render: h => h(App)
            }).$mount('#app')
        } else {
            const randomUID = generateUID();
            window.location.href = 'https://www.rico13.com/a_stairs/?uid=' + randomUID;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

checkServerStatus();

//pwa 引导
window.addEventListener('beforeinstallprompt', (event) => {
    const _pwaInstalled = localStorage.getItem('pwaInstalled');
    console.log('_pwaInstalled===>', _pwaInstalled);

    if (!_pwaInstalled && localStorage.getItem('platform') == 'h5') {
        // 阻止浏览器默认的安装提示
        event.preventDefault();
        // 保存事件对象，以便稍后触发
        Vue.prototype.deferredPrompt = event;
        // 用户没有安装过pwa
        store.commit('setIsShowPWATips', true);
    }
});