<template>
    <div class="invite-intro">
        <div>Regras</div>
        <p>
            1. Se o amigo que você convidar recarregar
            <span>R$ {{ message.child_target_recharge }}</span>, você receberá imediatamente um bônus de
            <span>R$ {{ message.parent_bonus_1 }}</span>.
        </p>
        <p>
            2. Se o valor da aposta do amigo que você convidar chegar a
            <span>R$ {{ message.child_target_bet }}</span>, você também poderá receber mais
            <span>R$ {{ message.parent_bonus_2 }}</span> de bônus.
        </p>
        <p>3. Os bônus podem ser sacados diretamente através do PIX ou transferidos para sua conta de jogo.</p>
        <p>4. Não há limite máximo para o número de amigos que você pode convidar e não há limite máximo para o bônus.</p>
        <p>5. Por favor, não use outros métodos ilegais, a plataforma tem o direito de cancelar sua qualificação de bônus.</p>
    </div>
</template>

<script>


export default {
    name: 'InviteIntro',
    components: {},
    props: {
        message: {
            type: Object,
        }
    },
    data() {
        return {

        }
    },
    watch: {},
    computed: {},
    methods: {},
    created() {

    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.invite-intro {
    width: 600px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 80px 40px 140px 40px;

    > div {
        width: 100%;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
    }

    > p {
        font-size: 27px;
        color: #758eb5;
        margin-top: 20px;

        > span {
            color: #ffd200;
        }
    }
}
</style>