<template>
    <div class="messages loadIn" v-if="isShow">
        <div class="out_block">
            <div class="titlebox">
                <img src="../../../public/image/message/close.png" alt @click.self="toCloseAll" />
            </div>
            <div class="desc">
                Abra o app com sua chave PIX cadastrada, escolha Pagar com Pix e escaneie o QR Codeou copie e cole o
                código.
            </div>

            <div class="coins">
                <div v-show="coinType === 1">
                    <img src="../../../public/image/recharge/paymentType.png" alt class="preimg" />
                    <span>pix</span>
                </div>
                <img class="coin-type-icon" src="../../../public/image/recharge/arrow.png" alt />
            </div>
            <div class="qrcode">
                <vueQr :text="newArr.qrcode"></vueQr>
            </div>
            <div class="amount">Valor {{newArr.amount}}</div>
            <!-- <input class="userIdText" type="hidden" :value="newArr.qrcode" ref="myqrcode" /> -->
            <!-- <input ref="refLink" :value="newArr.qrcode" type="hidden" readonly /> -->
            <div class="copyBtn" @click="toCopy">
                <img src="../../../public/image/recharge/copyIcon.png" alt />
                <span>Copiar</span>
            </div>
        </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr'
import { mapState } from 'vuex';
export default {
    // props: ['message'],
    components: { vueQr },
    data() {
        return {
            isShow: false,
            newArr: {},
            coinType: 1,
        }
    },
    computed: {
        ...mapState(['payforUrl', 'paySuccess']),
    },
    created() {
    },
    methods: {
        toCloseAll() {
            this.isShow = false
            this.$store.commit('setPayforUrl', '')
        },
        toCopy() {
            let text = this.newArr.qrcode;
            let inputElement = document.createElement('input')
            inputElement.value = text;
            document.body.appendChild(inputElement);
            inputElement.select();
            document.execCommand("copy");
            inputElement.remove();
            this.$store.dispatch('toast', 'Copia de sucesso')
        }
    },
    watch: {
        payforUrl(valObj) {
            if (!this.payforUrl) {
                return
            }
            //   let urlStr = new URL(this.payforUrl)
            //   const urlSearchParams = new URLSearchParams(urlStr.search);
            //   this.newArr = Object.fromEntries(urlSearchParams.entries())


            // var url2 = url.substring((url.lastIndexOf('?')) + 1)
            // let paramsArr = url2.split('&')
            // let amount = paramsArr[2].substring((paramsArr[2].indexOf('=')) + 1)
            // let qrcode = paramsArr[3].substring((paramsArr[3].indexOf('=')) + 1)
            // this.newArr = {
            //     amount, qrcode
            // }
            this.newArr = {
                amount: valObj.amount,
                qrcode: valObj.url
            }
            this.isShow = valObj.url;
        },
        paySuccess: {
            handler(val) {
                console.log("支付成功====>payforPage ", val);
                this.toCloseAll();
            }
        }
    },
}
</script>

<style scoped lang="less">
.messages {
    width: 100vw;
    height: calc(100vh - 100px);
    position: fixed;
    top: 100px;
    left: 0;
    overflow: scroll;
    background: #08111c;
    z-index: 3000;
    padding-top: 44px;
    box-sizing: border-box;
}

.out_block {
    width: 681px;
    background: url("../../../public/image/recharge/payBg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    /* z-index: 1000 !important; */
}

.titlebox {
    width: 100%;
    /* height: 81px; */
    /* display: flex;
        justify-content: space-between;
        align-items: center; */
    /* padding: 0px 36px; */
    padding-left: 36px;
    box-sizing: border-box;
    text-align: right;

    .title {
        font-size: 30px;
        color: #ffffff;
    }

    img {
        width: 32px;
        /* height: 33px; */
    }
}

.desc {
    width: 582px;
    font-size: 24px;
    font-weight: 500;
    color: #bebebe;
    margin: 0 auto;
    text-align: center;
    margin-top: 24px;
}

.loadIn {
    animation: messageLoadIn 0.3s ease-in-out;
}

.loadOut {
    animation: messageLoadOut 0.3s ease-in-out;
}

@keyframes messageLoadIn {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(0vw);
    }
}

@keyframes messageLoadOut {
    from {
        transform: translateX(0vw);
    }

    to {
        transform: translateX(-100vw);
    }
}

.amount {
    font-size: 40px;
    font-weight: bold;
    color: #f7ee06;
    text-align: center;
    margin-top: 41px;
}

.copyBtn {
    width: 534px;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../../public/image/recharge/copyRedBg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 30px;
    font-weight: 900;
    color: #ffffff;
    margin: 88px auto;

    img {
        width: 49px;
        height: 52px;
        margin-right: 23px;
    }
}

.qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 38px auto 41px;

    img {
        width: 356px;
        height: 356px;
        background: #bebebe;
        padding: 6px;
        box-sizing: border-box;
    }
}

.coins {
    width: 166px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin: 73px auto 0px;

    > div {
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;

        img {
            width: 27px;
            height: 27px;
            margin-right: 10px;
        }

        span {
            font-size: 24px;
            font-weight: 400;
            color: #a0a0a0;
            line-height: 49px;
        }
    }
}

.coin-type-icon {
    width: 30px;
    height: 18px;
    /* position: absolute;
        right: 10px;
        top: 15px; */
    color: #fff;
    /* font-weight: bold; */
}
</style>