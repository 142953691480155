<template>
    <div class="widthdraw-count">
        <div class="wc-title">
            <span>VIP</span>
            <span>Limite de retirada</span>
        </div>
        <div class="wc-title yanse" v-for="(item,index) in vt_data" :class="index%2==0?'huise':''">
            <span>{{ item.lv }}</span>
            <span>R$ {{ item.withdraw_total }}</span>
        </div>
        <div class="qua">Quanto maior o nível VIP, maior será o valor do saque.</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'WidthDrwaQuota',
    components: {},
    props: {},
    data() {
        return {
            vt_data: []
        }
    },
    watch: {},
    computed: {
        ...mapState(['vipData']),
    },
    methods: {
    },
    created() {
        this.vt_data = this.vipData.list;
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.widthdraw-count {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 100px 30px 40px 30px;

    .wc-title {
        width: 100%;
        height: 70px;
        border-radius: 10px;
        background-color: #0b1724;
        display: flex;
        align-items: center;
        color: #a7c2ff;
        font-size: 24px;

        :nth-child(1) {
            width: 40%;
            text-align: center;
        }
        :nth-child(2) {
            width: 60%;
            text-align: center;
        }
    }
    .yanse {
        color: #0fc683;
    }
    .huise {
        background: #1c2532;
    }
    .qua {
        color: #748395;
        font-size: 24px;
        text-align: center;
        line-height: 31px;
        margin-top: 30px;
    }
}
</style>