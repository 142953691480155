<template>
    <div class="myDiv" @click="rotating" v-if="isShow">
        <div class="bg" v-if="!showDaZhuanPanResult"></div>
        <div class="outbox" v-if="!showDaZhuanPanResult">
            <img
                class="pandi"
                :style="{transform:rotate_deg,transition:rotate_transition}"
                src="../../../public/image/dazhuanpan/dipan.webp"
                alt
            />
            <img class="kuang" src="../../../public/image/dazhuanpan/kuang.webp" alt />
            <img
                v-if="isAllowClick"
                class="shanshuo"
                src="../../../public/image/dazhuanpan/shanshuo.gif"
                alt
            />
            <img v-else class="zhuanquan" src="../../../public/image/dazhuanpan/zhuanquan.gif" alt />
            <img class="zhongjian" src="../../../public/image/dazhuanpan/zhongjian.webp" alt />
            <div class="cishu">{{ LuckyClick }}</div>

            <div class="receba-btn" @click.stop="jumpToResult">
                Receba R$
                <span>{{target_bonus==0?'???':target_bonus }}</span> de graca
            </div>
        </div>
        <DaZhuanPanPopResult @closed="closed" />
        <van-icon @click="closed" class="close-panel" name="close" size="30" color="#fff" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DaZhuanPanPopResult from '@/views/dazhuanpan/dazhuanpanPopResult.vue';
import { pddTurntable, pddSpinTurntable } from '@/api/api';
export default {
    name: '',
    components: {
        DaZhuanPanPopResult
    },
    props: {},
    data() {
        return {
            isShow: false,
            LuckyClick: 0,
            angle: 45,
            isAllowClick: true,
            rotate_deg: 0,
            rotate_transition: "transform 3s ease-in-out",
            target_bonus: 0,
        }
    },
    watch: {
        showDaZhuanPanPop(val) {
            this.isShow = val;
            if (val) {
                this.getTurnCfg();
            }
        }
    },
    computed: {
        ...mapState(["showDaZhuanPanPop", "showDaZhuanPanResult"]),
    },
    methods: {
        getTurnCfg() {
            pddTurntable() // 大转盘配置
                .then(res => {
                    console.log("转盘配置=====>", res)
                    this.LuckyClick = res.spin_count;
                    // this.target_bonus = parseFloat(res.progress_bonus);
                    this.$store.state.has_spin_count = res.has_spin_count;
                })
                .catch(err => {
                    console.error("转盘配置错误=====>", err)
                })
        },
        closed() {
            console.warn("是否EMIT", this.$store.state.isemit)
            this.$store.state.isemit ? this.$emit('closed') : '';
            this.$store.commit("setShowDaZhuanPanResult", false);
            this.$store.commit("setShowDaZhuanPanPop", false);
        },
        open() {
            const currentDate = new Date().toLocaleDateString();
            localStorage.setItem('lastPopupDate', currentDate);
            this.$store.state.isemit = true;
            this.$store.commit("setShowDaZhuanPanPop", true);
            console.warn("打开了转盘弹窗", this.$store.state.isemit)
        },
        jumpToResult() {
            if (this.LuckyClick == 0 && this.isAllowClick) {
                // this.$store.state.isemit = true;
                console.warn("是否EMIT", this.$store.state.isemit)
                this.$store.commit("setShowDaZhuanPanResult", true);
            } else {
                this.rotating();
            }
        },
        rotating() {
            if (!this.isAllowClick) return;
            if (this.LuckyClick == 0) return;
            this.isAllowClick = false;

            pddSpinTurntable() // 大转盘抽奖
                .then(res => {
                    this.LuckyClick--;
                    this.target_bonus = parseFloat(res.info.progress_bonus);

                    const values = [0, 4];
                    const randomIndex = Math.floor(Math.random() * values.length);
                    const randomValue = values[randomIndex];

                    this.rotate_transition = "transform 3s ease-in-out";
                    let rand_circle = 5; //默认多旋转5圈
                    let deg = rand_circle * 360 + randomValue * this.angle; //将要旋转的度数
                    this.rotate_deg = "rotate(" + deg + "deg)";

                    setTimeout(() => {
                        this.isAllowClick = true;
                        this.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
                        this.rotate_transition = "";

                        if (this.LuckyClick == 0) {
                            this.jumpToResult();
                        }
                    }, 4000)
                    this.LableEffect({ time: 1000, initNum: 0, num: this.target_bonus, regulator: 10 });

                    pddTurntable() // 大转盘配置
                        .then(res => {
                            this.$store.state.has_spin_count = res.has_spin_count;
                        })
                        .catch(err => {
                        })
                })
        },
        LableEffect(options) {
            options = options || {};
            if (options.initNum == options.num) return;
            var time = options.time,//总时间--毫秒为单位
                finalNum = options.num, //要显示的真实数值
                regulator = options.regulator || 100, //调速器，改变regulator的数值可以调节数字改变的速度         
                step = (finalNum - options.initNum) / (time / regulator),/*每30ms增加的数值--*/
                count = options.initNum, //计数器      
                initial = options.initNum;

            var timer = setInterval(() => {
                count = count + step;
                if (count >= finalNum && options.initNum < finalNum) {
                    clearInterval(timer);
                    count = finalNum;
                }

                if (count <= finalNum && options.initNum > finalNum) {
                    clearInterval(timer);
                    count = finalNum;
                }
                this.target_bonus = count.toFixed(1);
            }, 30);
        }
    },
    created() {
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.myDiv {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-panel {
        position: absolute;
        bottom: 150px;
        z-index: 18;
    }
    .bg {
        width: 816px;
        height: 875px;
        background: url("../../../public/image/dazhuanpan/guang.webp") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-46%, -65%);
    }

    .outbox {
        width: 514px;
        height: 514px;
        position: relative;
        animation: eject-pop 0.3s ease-in-out;
        margin-top: -200px;

        .pandi {
            width: 524px;
            height: 524px;
        }
        .kuang {
            position: absolute;
            width: 547px;
            height: 577px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -52%);
        }
        .shanshuo {
            position: absolute;
            width: 584px;
            height: 584px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -50%);
        }
        .zhuanquan {
            position: absolute;
            width: 584px;
            height: 584px;
            top: 50%;
            left: 50%;
            transform: translate(-49%, -50%);
        }
        .zhongjian {
            position: absolute;
            width: 138px;
            height: 143px;
            top: 50%;
            left: 50%;
            transform: translate(-46%, -54%);
        }
        .cishu {
            position: absolute;
            width: 120px;
            height: 120px;
            text-align: center;
            // background: #1f6400;
            color: white;
            text-shadow: -1px -1px 0 #1f6400, 1px -1px 0 #1f6400,
                -1px 1px 0 #1f6400, 1px 1px 0 #1f6400; /* 描边效果，可以根据需要调整偏移量和颜色 */
            line-height: 120px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-48%, -49%);
            font-size: 40px;
        }
        .receba-btn {
            position: absolute;
            width: 538px;
            height: 90px;
            background: #f3c300;
            border-radius: 10px;
            line-height: 90px;
            font-size: 35px;
            text-align: center;
            font-weight: 600;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 440%);
            color: #2e0500;
            > span {
                color: #ff3230;
            }
        }
    }
}
@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>