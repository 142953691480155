<template>
    <div class="upgrade-rewards">
        <div class="ur-icon">
            <div class="uri-box">
                <div
                    class="uri-con"
                    :style="{ transform: `translateX(${top_moveX}px)`,
                    transition: 'all 300ms ease 0s' }"
                >
                    <div class="uric-box" v-for="(i,index) in vip_list" @click="iClick(index)">
                        <div
                            class="uricb-c"
                            :class="activeIndex === index?'uricb-c-active':'uricb-c'"
                        >
                            <div class="uri-i-1">
                                <img src="../../../public/image/newvip/qy0.webp" alt />
                            </div>
                            <span>BRL {{ i.upgrade_bonus }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="icc-vip">
            <div class="iccv-box">
                <div class="it-1" :class="dynamicClasss">
                    <div class="it-3" v-show="pg1>0">
                        <van-icon color="#fff" size="20px" name="success" />
                    </div>
                </div>
                <van-progress class="it-2" :percentage="pg1" :class="dynamicClasss" />
                <div class="it-1">
                    <div class="it-3" v-show="pg1==100">
                        <van-icon color="#fff" size="20px" name="success" />
                    </div>
                </div>
                <van-progress class="it-2" :percentage="pg2" :class="dynamicClasse" />
                <div class="it-1" :class="dynamicClasse">
                    <div class="it-3" v-show="pg2==100">
                        <van-icon color="#fff" size="20px" name="success" />
                    </div>
                </div>
            </div>
            <div class="iccv-lv">
                <span :class="dynamicClasss">VIP {{ v_str_l }}</span>
                <span>VIP {{ v_str_c }}</span>
                <span :class="dynamicClasse">VIP {{ v_str_r }}</span>
            </div>
        </div>

        <div v-if="btn_st==0" class="ur-btn">Coletar R$ {{ c_bouns }}</div>
        <div v-if="btn_st==1" class="ur-btn">Condição não atendida</div>
        <div v-if="btn_st==2" @click="reqVipReward" class="ur-btn-s">Coletar R$ {{ c_bouns }}</div>
        <p class="ur-p">
            {{ j_m }} apostas para VIP {{ j_v }} e ganhe
            <span>R${{ j_b }}</span> de bônus
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { reqVipLvUpReward, vipUpRights } from '@/api/api';

export default {
    name: 'UpgradeRewards',
    components: {},
    props: {},
    data() {
        return {
            top_moveX: 0,
            bot_moveX: 0,
            activeIndex: 1,
            vip_user: {},
            vip_list: [],
            vip_other: {},
            v_str_l: '',
            v_str_c: '',
            v_str_r: '',
            pg1: 0,
            pg2: 0,
            c_bouns: 0,
            j_m: 0,
            j_v: 0,
            j_b: 0,
            vip_rights: [],
            btn_st: 0
        }
    },
    watch: {},
    computed: {
        ...mapState(['vipData']),
        dynamicClasss() {
            return [
                this.activeIndex == 0 ? 'it-1-opacity' : '',
            ];
        },
        dynamicClasse() {
            return [
                this.activeIndex == this.vip_list.length - 1 ? 'it-1-opacity' : '',
            ];
        }
    },
    methods: {
        reqVipReward() {
            reqVipLvUpReward({
                lv: this.vip_rights[this.activeIndex].lv,
            }).then(res => {
                vipUpRights().then(res => {
                    this.vip_rights = res.list;
                })
                this.$store.dispatch('UserInfo');
                this.$store.dispatch('refreshRedDot');
                // Toast('Reivindicado com sucesso, verifique a página de bônus. Continue apostando e resgate seu bônus.');
                this.$store.commit('setShowPopupPanel',
                    {
                        show: true,
                        componentName: 'Confirm',
                        option: {
                            content: 'Reivindicado com sucesso, verifique a página de bônus. Continue apostando e resgate seu bônus.',
                            confirmMsg: 'Continuar',
                            cancelMsg: 'fecho',
                            confirmCb: () => {
                                setTimeout(() => {
                                    this.$store.commit('setShowPopupPanel',
                                        {
                                            show: true,
                                            componentName: 'UpgradeRewards',
                                            option: {}
                                        });
                                }, 100);
                            },
                        }
                    });
            })
        },
        iClick(index) {
            this.top_moveX = 100 - 100 * index;
            this.activeIndex = index;

            let base_lv = parseInt(this.vip_rights[index].lv);

            this.v_str_l = base_lv - 1;
            this.v_str_c = base_lv;
            this.v_str_r = base_lv + 1;

            this.c_bouns = this.vip_list[index].upgrade_bonus;

            // 0.未达到领取条件，1、已领取，2.未领取
            this.btn_st = this.vip_rights[this.activeIndex].status;

            if (this.vip_user.vip_lv < 2) {
                this.pg1 = 0;
                this.pg2 = 0;
                return;
            }
            console.warn("点击的index===> ", this.activeIndex)
            console.warn("用户等级===>", this.vip_user.vip_lv)

            let total = parseInt(this.vip_user.total_recharge);

            let pre_need = 0;
            let next_need = 0;

            if (this.activeIndex > 0) {
                pre_need = parseInt(this.vip_list[this.activeIndex - 1].need_bets);
            }

            let currt_need = parseInt(this.vip_list[this.activeIndex].need_bets);

            if (this.activeIndex + 1 > this.vip_list.length - 1) {
                next_need = 0;
            } else {
                next_need = parseInt(this.vip_list[this.activeIndex + 1].need_bets);
            }

            console.log(this.vip_list)
            console.log('total===>', total)
            console.log('pre_need===>', pre_need)
            console.log('currt_need===>', currt_need)
            console.log('next_need===>', next_need)


            this.pg1 = (total / currt_need) * 100 > 100 ? 100 : (total / currt_need) * 100;
            this.pg2 = (total / next_need) * 100 > 100 ? 100 : (total / next_need) * 100;

            console.log(this.pg1, this.pg2)
            if ((this.activeIndex + 1) > this.vip_user.vip_lv) {
                console.log('大于等于')
                this.pg1 = 0;
                this.pg2 = 0;
            }
            if ((this.activeIndex + 1) == this.vip_user.vip_lv) {
                console.log('等于')
                this.pg2 = 0;
            }
        }
    },
    created() {
        this.vip_list = this.vipData.list.slice(2);
        console.log(this.vip_list, 'vip_list')
        this.vip_user = this.vipData.user;
        this.vip_other = this.vipData.other;

        this.j_m = this.vip_user.upgrade_money;
        this.j_v = this.vip_user.next_vip_lv;
        this.j_b = this.vipData.list[this.vip_user.next_vip_lv].upgrade_bonus;

        vipUpRights().then(res => {
            console.log(res.list, '权益列表')
            this.vip_rights = res.list;
            if (this.vip_user.vip_lv < 2) {
                this.iClick(1);
            } else {
                this.iClick(this.vip_user.vip_lv - 2);
            }
        })
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.it-1-opacity {
    opacity: 0;
}
.upgrade-rewards {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 100px 30px 40px 30px;

    .ur-p {
        font-size: 24px;
        color: #748395;

        span {
            color: #ffd200;
        }
        margin-bottom: 60px;
    }

    .ur-btn {
        width: 570px;
        height: 84px;
        background: #47576b;
        border-radius: 15px;
        margin: 100px 0 66px 0;
        text-align: center;
        line-height: 84px;
        font-size: 31px;
        color: #fff;
        text-transform: uppercase;
    }
    .ur-btn-s {
        width: 570px;
        height: 84px;
        background: #3cb408;
        border-radius: 15px;
        margin: 100px 0 66px 0;
        text-align: center;
        line-height: 84px;
        font-size: 31px;
        color: #fff;
        text-transform: uppercase;
    }

    .icc-vip {
        width: 600px;
        height: 137px;
        padding: 0 20px;
        background: #151d29;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .iccv-lv {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            span {
                font-size: 26px;
                color: #a7c2ff;
            }
        }

        .iccv-box {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .it-1 {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                background: #24303e;
            }
            .it-2 {
                width: 204px !important;
                background: #24303e;

                /deep/.van-progress__pivot {
                    display: none;
                }
                /deep/.van-progress__portion {
                    background: #3cb408;
                }
            }
            .it-3 {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                background: #3cb408;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .iccvbi-b {
                font-size: 26px;
                color: #a7c2ff;
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }

    .ur-icon {
        width: 100%;
        display: flex;
        justify-content: center;

        .uri-box {
            width: 600px;
            overflow: hidden;

            .uri-con {
                display: flex;
                white-space: nowrap;

                .uric-box {
                    width: 200px;
                    height: 300px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: end;
                    position: relative;

                    .uricb-c-active {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        transform: scale(1.5) !important;
                        transition: all 300ms ease 0s;
                        position: absolute;
                        top: -28px !important;
                    }

                    .uricb-c {
                        width: 100%;
                        height: 100%;
                        // background: blue;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        transition: all 300ms ease 0s;
                        position: absolute;
                        top: 0px;

                        .uri-i-1 {
                            width: 110px;
                            height: 110px;
                            background: #293340;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 67px;
                                height: 69px;
                            }
                        }

                        span {
                            color: #3cb408;
                            font-size: 22px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        //jiexian

        .uri-i-2 {
            width: 182px;
            height: 182px;
            background: #293340;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 113px;
                height: 113px;
            }
        }
        .uri-i-3 {
            width: 110px;
            height: 110px;
            background: #293340;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 67px;
                height: 69px;
            }
        }
    }
}
</style>