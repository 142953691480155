<template>
    <div class="cashback">
        <div class="cashback-bar" @click="backBarClick">
            <van-icon name="arrow-left" size="20px" />
            <span>CASHBACK 30%</span>
        </div>
        <div class="cb-banner">
            <div class="cb-banner-top">
                <div class="cb-banner-top-bg">
                    <span class="cb-banner-top-14">{{ gameRebate*100 }}%</span>
                    <div class="cb-banner-top-max">
                        <span>Cashback</span>
                        <span>Max {{ maxGameRebate *100 }}%</span>
                    </div>
                </div>
                <div class="cb-banner-top-vip">VIP {{ vipLevel }}</div>
            </div>
            <van-progress :percentage="progress" />
            <div class="cb-next-level">{{ upgradeMoney }} Apostas para VIP{{ nextVipLevel }}</div>
        </div>
        <div class="cb-space">Seu Cashback de Semanal</div>
        <div class="cb-seu">
            <div class="cb-seu-vo" v-if="otherData.cashback==='???'">
                Você ganhará
                <span>{{ otherData.cashback }}</span> BRL em cashback jogando esta
                semana.
            </div>
            <div class="cb-seu-vo" v-else>
                Um total de
                <span>{{ otherData.cashback }}</span> BRL foi devolvido na semana passada.
            </div>
            <div class="cb-seu-pe">Periodo {{ otherData.compute_start }}-{{ otherData.compute_end }}</div>
            <div class="cb-seu-re" @click="doAgoraClick">REIVINDICAR AGORA</div>
            <div class="cb-seu-te">Tempo de reivindicação:</div>
            <span
                :class="showHighlight?'cb-seu-teb-s':'cb-seu-teb'"
            >{{ otherData.claim_start }}-{{ otherData.claim_end }}</span>
        </div>
        <div class="cb-space">Status VIP Cashback</div>
        <div class="cb-vip">
            <div class="cb-vip-title">
                <span>VIP</span>
                <span>APOSTA</span>
                <span>CASHBACK</span>
            </div>
            <div
                :class="index%2==0?'cb-vip-title-b':'cb-vip-title-s'"
                v-for="(item,index) in vipListData"
            >
                <span>{{ index }}</span>
                <span>{{ item.need_bets }}+BRL</span>
                <span>{{ (parseFloat(item.self_game_rebate) *100).toFixed(2) }}%</span>
            </div>
        </div>
        <div class="cb-space">TERMOS E REGRAS</div>
        <div class="cb-termos">
            <p>1. O cashback semanal é dado como recompensa todas as semanas.</p>
            <p>2. O período sobre o qual é calculado o cashback semanal vai de segunda-feira às 00:00 a domingo às 23:59.</p>
            <p>3. Horário de solicitação do cashback: De segunda-feira da próxima semana 06:00 a sexta-feira 23:59, expirará se não for resgatado.</p>
            <p>4. O número de Perdas de dinheiro real multiplicado pela % de reembolso é o reembolso/cashback da semana.</p>
            <p>5. Caso você não tenha apostado durante o período em que o cashback estava ativo ou se seus ganhos da última semana ou ganhos totais são maiores que suas perdas, você não receberá cashback.</p>
            <p>6. Limite máximo de recompensa de cashback por pessoa por semana: 10000 BRL</p>
            <p>7. O valor do cashback pode ser sacar diretamente ou utilizado para continuar jogando</p>
        </div>
    </div>
</template>

<script>
import { getVipDetail, getVipBack } from '@/api/api';
import { mapState } from 'vuex';

export default {
    name: '',
    components: {
    },
    props: {},
    data() {
        return {
            showHighlight: false,
            gameRebate: 0,
            maxGameRebate: 0,
            vipListData: [],
            userData: {},
            otherData: {},
            vipLevel: 0,
            upgradeMoney: 0,
            nextVipLevel: 0,
            totalRecharge: 0,
            needBets: 0,
            progress: 0,
            timer: null,
        }
    },
    watch: {
        showCashbackIndex: {
            handler(val) {
                if (val) {
                    this.getVipData();
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(["showCashbackIndex",])
    },
    methods: {
        backBarClick() {
            this.$store.commit('setShowCashbackIndex', false);
        },
        async doAgoraClick() {
            if (this.otherData && this.otherData.cashback == '??') {
                this.showHighlight = true;
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.showHighlight = false;
                }, 2000);
            } else {
                try {
                    await getVipBack();
                    this.$store.dispatch('toast', `Cashback de ${this.otherData.cashback} BRL recebido com sucesso`)
                    this.$store.dispatch('UserInfo');
                    this.$store.dispatch('refreshRedDot');
                    console.log("领取vip返奖====>");
                } catch (err) {
                    console.error("领取vip返奖====>", err);
                }
            }

        },
        async getVipData() {
            try {
                const data = await getVipDetail();
                console.log("返现模块====>", data);
                this.vipListData = data.list;
                this.userData = data.user;
                this.otherData = data.other;

                this.gameRebate = this.getGameRebateByVipLevel(this.userData.vip_lv);
                this.maxGameRebate = parseFloat(this.vipListData[this.vipListData.length - 1].self_game_rebate);
                this.vipLevel = this.userData.vip_lv;
                this.nextVipLevel = this.userData.next_vip_lv;
                this.upgradeMoney = parseFloat(this.userData.upgrade_money);
                // this.totalRecharge = parseFloat(this.userData.total_recharge);
                // this.needBets = this.getNeedBetsByVipLevel(this.userData.vip_lv);

                let totalRecharge = this.userData.total_recharge;
                let needBets = this.userData.next_vip_recharge;

                this.progress = needBets == 0 ? 0 : (totalRecharge / needBets) * 100;
                if (this.progress > 100) {
                    this.progress = 100;
                }

            } catch (err) {
                console.error("返现模块====>", err);
            }
        },
        getGameRebateByVipLevel(level) {
            return parseFloat(this.vipListData[level].self_game_rebate);
        },
        getNeedBetsByVipLevel(level) {
            return parseFloat(this.vipListData[level].need_bets);
        }
    },
    created() {

    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.cashback {
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 15px 0 15px;
    font-family: Montserrat-VF;
    background: #0b131d;
    overflow-x: hidden;
}
.cashback-bar {
    width: 100%;
    height: 80px;
    // padding-left: 40px;
    display: flex;
    align-items: center;
    color: white;
    position: fixed !important;
    top: 0 !important;
    background: #0c1a29;
    z-index: 100;
    > span {
        font-size: 30px;
    }
}
.cb-banner {
    width: 100%;
    height: 202px;
    background: #14202d;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    .cb-banner-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 20px;
        .cb-banner-top-bg {
            width: 409px;
            height: 97px;
            background: #0e1924;
            border-radius: 30px;
            display: flex;
            align-items: center;
            .cb-banner-top-14 {
                font-size: 63px;
                color: #ffd200;
                margin-left: 42px;
            }
            .cb-banner-top-max {
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                > span:nth-child(1) {
                    color: white;
                    font-size: 28px;
                }
                > span:nth-child(2) {
                    color: #748395;
                    font-size: 19px;
                }
            }
        }
        .cb-banner-top-vip {
            width: 168px;
            height: 54px;
            background: #ffd200;
            border-radius: 10px;
            text-align: center;
            line-height: 54px;
            font-size: 29px;
            color: #040a23;
            font-weight: 600;
        }
    }
    ::v-deep .van-progress {
        width: 325px;
        height: 8px;
        margin-top: 10px;
        background: #293340;
    }
    ::v-deep .van-progress__portion {
        background: #ffd200;
    }
    ::v-deep .van-progress__pivot {
        display: none;
    }
    .cb-next-level {
        font-size: 20px;
        color: #748395;
        margin-top: 10px;
        width: calc(100% - 70px);
        text-align: right;
    }
}
.cb-space {
    width: calc(100% - 20px);
    height: 80px;
    color: white;
    line-height: 80px;
    font-size: 30px;
    font-weight: 700;
    padding-left: 20px;
}
.cb-seu {
    width: calc(100% - 60px);
    padding: 30px;
    color: white;
    font-size: 25px;
    background: #14202d;
    border-radius: 10px;
    .cb-seu-vo {
        > span {
            color: #ff0000;
            font-size: 28px;
        }
    }
    .cb-seu-pe {
        margin: 38px 0;
    }
    .cb-seu-re {
        width: calc(100% - 108px);
        margin: 38px 54px;
        height: 80px;
        background: #3cb408;
        border-radius: 10px;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
    }
    .cb-seu-te {
        font-size: 22px;
        color: #748395;
    }
    .cb-seu-teb {
        display: block;
        font-size: 22px;
        color: #748395;
        margin-top: 10px;
    }
    .cb-seu-teb-s {
        display: block;
        font-size: 22px;
        color: #ff0000;
        margin-top: 10px;
    }
}
.cb-vip {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 48px);
    padding: 40px 24px;
    background: #14202d;
    .cb-vip-title {
        background: #0b131d;
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        > span {
            color: #a7c2ff;
            font-size: 24px;
        }
        > span:nth-child(1) {
            width: 50px;
            margin-left: 90px;
        }
        > span:nth-child(2) {
            margin-left: 150px;
        }
        > span:nth-child(3) {
            margin-left: 120px;
        }
    }
    .cb-vip-title-b {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        > span {
            color: #0da46d;
            font-size: 24px;
            width: 200px;
            text-align: center;
        }
    }
    .cb-vip-title-s {
        width: 100%;
        height: 70px;
        line-height: 70px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        background: #0b131d;
        > span {
            color: #0da46d;
            font-size: 24px;
            width: 200px;
            text-align: center;
        }
    }
}
.cb-termos {
    width: calc(100% - 60px);
    padding: 30px;
    font-size: 23px;
    color: #748395;
    background: #14202d;
    border-radius: 10px;
    > p {
        margin-bottom: 10px;
    }
}
</style>