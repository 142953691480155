<template>
    <div class="invite-intro">
        <div>Regras</div>
        <p>
            1. Ao completar o valor da aposta dentro do
            prazo especificado, você receberá um
            bônus de
            <span>R$ {{ message.bouns }}</span>
        </p>
        <p>
            2. O bônus será adicionado automaticamente
            ao saldo da sua conta e poderá ser usado
            para apostar no jogo.
        </p>
        <p>
            3. Por favor, não use outros métodos ilegais
            a plataforma tem o direito de cancelar sua
            qualificação de bônus.
        </p>
    </div>
</template>

<script>
export default {
    name: 'InviteIntroed',
    components: {},
    props: {
        message: {
            type: Object,
        }
    },
    data() {
        return {
        }
    },
    watch: {},
    computed: {},
    methods: {},
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.invite-intro {
    width: 600px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 80px 40px 140px 40px;

    > div {
        width: 100%;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
    }

    > p {
        font-size: 27px;
        color: #758eb5;
        margin-top: 20px;

        > span {
            color: #ffd200;
        }
    }
}
</style>