<template>
    <div class="vip-show">
        <img src="../../../public/image/newvip/newvip (1).webp" alt />
        <div class="vs-uv">
            <span>Usuários VIP</span>
            <span>
                têm
                <i>exibição exclusiva</i>
                ao lado de seu nome de usuário
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VipShow',
    components: {},
    props: {},
    data() {
        return {
        }
    },
    watch: {},
    computed: {},
    methods: {},
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.vip-show {
    // width: 613px;
    // background: #1c2532;
    // border-radius: 54px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    font-family: "Montserrat-VF";
    // padding: 100px 30px 40px 30px;
    position: relative;

    img {
        width: 673px;
        height: 390px;
        margin-bottom: 500px;
    }

    .vs-uv {
        display: flex;
        flex-direction: column;
        color: #fff;
        position: absolute;
        top: 100px;
        left: 23px;

        :nth-child(1) {
            font-size: 45px;
            font-weight: bolder;
            margin-bottom: 20px;
        }
        :nth-child(2) {
            font-size: 25px;
            font-weight: bold;
            width: 400px;
            line-height: 35px;
            i {
                font-size: 25px;
                color: #fff600;
            }
        }
    }
}
</style>