<template>
    <div class="widthdraw-count">
        <div class="wc-title">
            <span>VIP</span>
            <span>Canal de saque exclusivo</span>
        </div>
        <div class="wc-title yanse" v-for="(item,index) in vt_data" :class="index%2==0?'huise':''">
            <span>{{ item.level }}</span>
            <span>{{ item.tdm }}</span>
        </div>
        <div class="qua">
            Se o saque falhar, confirme se as informações do seu CPF
            estão corretas ou altere para outras informações do CPF
        </div>
    </div>
</template>

<script>
export default {
    name: 'WidthDrawSpeed',
    components: {},
    props: {},
    data() {
        return {
            vt_data: [
                {
                    level: 1,
                    tdm: 'Bloqueado'
                }, {
                    level: 2,
                    tdm: 'Bloqueado'
                },
                {
                    level: 3,
                    tdm: 'Bloqueado'
                },
                {
                    level: 4,
                    tdm: '1 canal principal'
                },
                {
                    level: 5,
                    tdm: '1 canal principal, 1 canal de backup'
                },
                {
                    level: 6,
                    tdm: '1 canal principal, 2 canal de backup'
                },
                {
                    level: 7,
                    tdm: '1 canal principal, 4 canal de backup'
                },
                {
                    level: 8,
                    tdm: '2 canal principal, 4 canal de backup'
                },
                {
                    level: 9,
                    tdm: '2 canal principal, 6 canal de backup'
                },
                {
                    level: 10,
                    tdm: '4 canal principal, 10 canal de backup'
                }
            ]
        }
    },
    watch: {},
    computed: {},
    methods: {},
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.widthdraw-count {
    width: 613px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 100px 30px 40px 30px;

    .wc-title {
        width: 100%;
        height: 70px;
        border-radius: 10px;
        background-color: #0b1724;
        display: flex;
        align-items: center;
        color: #a7c2ff;
        font-size: 24px;

        :nth-child(1) {
            width: 20%;
            text-align: center;
        }
        :nth-child(2) {
            width: 80%;
            text-align: center;
        }
    }
    .yanse {
        color: #0fc683;
    }
    .huise {
        background: #1c2532;
    }
    .qua {
        color: #748395;
        font-size: 24px;
        text-align: center;
        line-height: 31px;
        margin-top: 30px;
        span {
            color: #ffd200;
        }
    }
}
</style>