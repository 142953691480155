<template>
  <div class="login">
    <div class="login-title">
      <div :class="activeIndex === index ? 'activeClass' : 'defaultClass'" v-for="(item, index) in btn" :key="index"
        @click="change_status(item, index)">
        {{ item.name }}
      </div>
      <van-icon size="30" name="cross" @click="closeMask" />
    </div>
    <div class="login_box">
      <CommonLogin />
    </div>
  </div>
</template>
<script>
import CommonLogin from '@/components/CommonLogin.vue'
import { mapState } from 'vuex';
import { buryingLog } from "@/api/PromotionInterFace";

export default {
  data() {
    return {
      activeIndex: 0,
      btn: [
        {
          name: 'ENTRAR',
          type: 'in'
        },
        {
          name: 'REGISTRAR',
          type: 'up'
        }
      ]
    }
  },
  components: {
    CommonLogin,
  },
  methods: {
    closeMask() {
      this.$store.commit('MASKCONTENT', '')
      this.$store.commit('MASK', false)
    },
    change_status(status, index) {
      this.activeIndex = index
      this.$store.commit('IN_OR_UP', status.type)
    },
  },
  computed: {
    ...mapState(['inorup'])
  },
  mounted() {
    buryingLog({
      event_name: 'EVENT_CONTENT_VIEW',
      properties: JSON.stringify({ content_name: 'login' })
    })
  },
  created() {
    if (this.inorup == 'in') {
      this.activeIndex = 0
    } else if (this.inorup == 'up') {
      this.activeIndex = 1
    }
  },
  watch: {
    inorup(val1) {
      if (val1 == 'in') {
        this.activeIndex = 0
      } else if (val1 == 'up') {
        this.activeIndex = 1
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  margin: 0 auto;
  border-radius: 40px 40px 40px 40px;
  width: 100%;
  height: calc(100%);
  overflow-y: scroll;
  overflow-x: hidden;

  .close_pics {
    width: 30px;
    position: absolute;
    top: 60px;
    right: 40px;
  }
}

.login-title {
  padding: 40px 40px 20px 80px;
  color: #FFFFFF;
  font-size: 40px;
  border-bottom: 2px solid #3B3B3B;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.login_box {
  position: relative;
  // padding: 80px 0;
  overflow: scroll;

  .btns {
    display: flex;
    margin-left: 4vw;

    div {
      height: 71px;
      font-size: 30px;
      font-weight: bold;
      border-radius: 20px 20px 20px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      padding: 0 20px;
    }
  }


}

.activeClass {
  color: #FFFFFF;
}

.defaultClass {
  color: #9A9A9A;
}
</style>