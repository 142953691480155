<template>
    <div class="vip-challenge">
        <div class="cashback-bar" @click="backBarClick">
            <van-icon name="arrow-left" size="20px" />
            <span>Desafio de atualização rápida VIP</span>
        </div>
        <div class="vc-center">
            <div class="vcc-bg">
                <img class="vcc-img" src="../../../public/image/newvip/evento-bg.webp" alt />
                <div class="vcc-str">
                    <div>Evento por tempo limitado RICO13</div>
                    <span>Desafio de nível VIP</span>
                    <span>Usuários novos e antigos podem participar</span>
                </div>
            </div>
            <div class="vcc-sb">
                <img class="vcc-sbimg" src="../../../public/image/newvip/newvip (10).webp" alt />
                <div class="vcc-ccn">
                    <img :src="require(`../../../public/image/newvip/xz${self_vip}.webp`)" alt />
                    <div class="vcc-vplevel">{{ self_vip }}</div>
                    <div class="vcc-niv">Nivel atual</div>
                    <div class="vcc-vvv">VIP {{ self_vip }}</div>
                </div>
            </div>
            <div class="vcc-zhuzi">
                <img class="vcc-zhuzi-img" src="../../../public/image/newvip/vipzhuzi.webp" alt />
                <div class="vcc-val">
                    <div class="vcc-val-item" v-for="item in vip_lv_list">
                        <span>-{{ item.reduce_rate *100 }}%</span>
                        <span>R$ {{ item.act_recharge_amount }}</span>
                        <span>R$ {{ item.recharge_amount }}</span>
                    </div>
                </div>
                <div class="vcc-zhu-v">
                    <span v-for="item in vip_lv_list">VIP {{ item.lv }}</span>
                </div>
            </div>
            <div class="vc-swipe">
                <van-swipe
                    @change="onChange"
                    :loop="false"
                    class="my-swipe"
                    :height="350"
                    indicator-color="white"
                    :initial-swipe="user_vip >=5 ? 4:currtVipIndex + 1"
                >
                    <van-swipe-item v-for="item in user_vip">
                        <div
                            v-if="self_vip < parseInt(item.lv)"
                            class="ms-desa"
                        >Desafio obtido com sucesso</div>
                        <div v-else class="vcc-tag">Você obteve</div>
                        <div @click="openStVip" class="ms-todos">Todos os direitos e interesses</div>
                        <div class="ms-box">
                            <img
                                :src="require(`../../../public/image/newvip/xz${item.lv}.webp`)"
                                alt
                            />
                            <span>VIP {{ item.lv }}</span>
                        </div>
                        <div class="ms-jindutiao">
                            <van-progress
                                :percentage="((parseFloat(item.act_recharge_amount)-parseFloat(item.need_recharge))/parseFloat(item.act_recharge_amount) * 100).toFixed(2)"
                                stroke-width="4"
                                color="#ed3c51"
                                track-color="#14202d"
                                :show-pivot="true"
                            />
                        </div>
                        <div class="ms-80">{{ item.need_recharge }} Apostas para VIP{{ item.lv }}</div>
                        <div class="ms-3-box">
                            <div class="ms-3-item">
                                <img src="../../../public/image/newvip/qy2.webp" alt />
                                <span>CASHBACK</span>
                                <p>vip{{ item.lv }} aumentou para {{ parseFloat(item.self_game_rebate)*100 }}%</p>
                            </div>
                            <div class="ms-3-item">
                                <img src="../../../public/image/newvip/qy5.webp" alt />
                                <span>Limite de retirada</span>
                                <p>Até R$ {{ item.withdraw_total }}</p>
                            </div>
                            <div class="ms-3-item">
                                <img src="../../../public/image/newvip/qy0.webp" alt />
                                <span>Bônus de atualização</span>
                                <p>Recompensa de R$ {{ item.upgrade_bonus }}</p>
                            </div>
                        </div>
                        <div class="ms-tip">
                            Recarregue um total de
                            <span>R$ {{ item.need_recharge }}</span>&nbsp;
                            <span>R$ {{ item.recharge_amount }}</span>
                            para fazer upgrade
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="vc-te" v-if="userData.challenge_status==1">
                <img class="vct-bg" src="../../../public/image/newvip/vipother.webp" alt />
                <van-count-down class="time" :time="time" format="DD : HH : mm : ss" />
                <div class="time-str">
                    <span>Dias</span>
                    <span>Horas</span>
                    <span>Min</span>
                    <span>Seg</span>
                </div>
                <div class="vct-btn">
                    <div>Ciclo de desafio</div>
                    <div>{{ hour }} horas após o registro</div>
                </div>
            </div>
            <div class="de-title">Descrição do Evento</div>
            <div class="de-content">
                <p>1. Durante o evento, após o valor de recarga cumulativa atingir a meta, você será rapidamente atualizado para o nível VIP correspondente;</p>
                <p>2. Tanto usuários novos quanto antigos podem participar deste evento. Se você já é usuário VIP, após participar do evento, o valor da sua recarga só precisa atingir a diferença entre os dois níveis para fazer o upgrade direto. Por exemplo, você atualmente é VIP2 e precisa recarregar R$ 400 para fazer upgrade para VIP3 sem participar do evento. Após participar do evento, você só precisa recarregar R$ 280 para fazer upgrade.</p>
                <p>3. O evento é aplicável apenas a upgrades rápidos VIP2-5.</p>
                <p>4. Após a atualização, o nível VIP não será rebaixado por nenhum motivo.</p>
                <p>5. O valor da recarga calculado pela atividade só poderá ser contabilizado após você participar da atividade na página da atividade atual.</p>
                <p>6. Cada usuário só poderá participar do evento uma vez. Espero que você aproveite a oportunidade. Prevalecerão os dados do CPF no momento do saque final.</p>
                <p>7. Se você se envolver em qualquer comportamento fraudulento, a plataforma reserva-se o direito de cancelar todo o conteúdo da recompensa.</p>
            </div>
        </div>
        <div @click="seBtn" v-if="userData.challenge_status==0" class="vc-bottom">Inscrever-se</div>
        <div
            class="vc-bottom-noclick"
            v-if="userData.challenge_status==1 || userData.challenge_status==2"
        >Evento em andamento</div>
        <div class="vc-bottom-noclick" v-if="userData.challenge_status==3">O evento acabou</div>
        <van-popup class="pbp" v-model:show="show">
            <div class="pop-box">
                <p>
                    Confirme a sua participação neste evento. O evento terminará
                    <span>{{ hour }}</span> horas após sua confirmação, preste atenção ao horário do evento.
                </p>
                <i>Boa sorte com sua atualização!</i>
                <div class="pb-box">
                    <span @click="canClick">Cancelar</span>
                    <span @click="envClick">Enviar</span>
                </div>
            </div>
        </van-popup>
        <van-popup class="st-vip" v-model:show="showStvip">
            <div class="ni-title">Status VIP</div>
            <div class="ni-bottom">
                <div class="nib-title">
                    <span>VIP</span>
                    <span>APOSTA</span>
                    <span>Requisito de rolagem</span>
                    <span>Número de saques por dia</span>
                    <span>Bônus extra do envelope vermelho do grupo</span>
                    <span>Número de máquinas caça-níqueis</span>
                    <span>Atualizar pacote de presente</span>
                </div>
                <div
                    class="nib-item"
                    v-for="(item,index) in vip_list"
                    :class="itemClass(item,index)"
                >
                    <span>{{ item.lv }}</span>
                    <span>{{ item.need_bets }}+BRL</span>
                    <span>{{ AmountFixed(item.withdraw_tax *100)}}%</span>
                    <span>{{ item.withdraw_count }}</span>
                    <span>{{ AmountFixed(item.tg_everyday_extrarate *100) }}%</span>
                    <span>{{ item.tiger_count }}</span>
                    <span>{{ item.upgrade_bonus }}</span>
                </div>
            </div>
        </van-popup>
        <van-popup class="pbp-wanliu" v-model:show="wanLiuShow">
            <div class="pop-box">
                <p>Participar de eventos por tempo limitado pode atualizar rapidamente seu nível VIP.</p>
                <div class="pb-box">
                    <span @click="reClick">Rejeitar</span>
                    <span @click="juClick">Juntar</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { getVipDetail, joinVipChallenge, getVipChallengeDetail } from '@/api/api';
import { mapState } from 'vuex';
import { AmountFixed } from "@/utis/common";
import { dotGTag } from "@/native/native";

export default {
    name: 'VipChallenge',
    components: {},
    props: {},
    data() {
        return {
            time: 0,
            show: false,
            showStvip: false,
            wanLiuShow: false,
            vip_list: [],

            user_vip: [],
            self_vip: 0,
            vip_lv_list: [],
            hour: 0,
            currtVipIndex: -1,
        }
    },
    watch: {},
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        onChange(index) {
            // console.warn('当前 Swipe 索引：' + index);
        },
        AmountFixed,
        backBarClick() {
            if (this.userData.challenge_status == 0) {
                this.wanLiuShow = true;
            } else {
                this.$store.commit('setVipChallenge', false);
            }
        },
        seBtn() {
            this.show = true;
        },
        openStVip() {
            this.showStvip = true;
        },
        itemClass(item, index) {
            let classes = [];

            // 根据索引的奇偶性添加不同的背景色
            if (index % 2 === 0) {
                classes.push('red-background');
            }

            // 如果 item.lv 等于 self_vip，添加特定的背景色
            if (parseInt(item.lv) === parseInt(this.self_vip)) {
                classes.push('self-background');
            }

            // 返回组合后的类名数组
            return classes.join(' ');
        },
        canClick() {
            this.show = false;
        },
        envClick() {
            dotGTag('vip_up_event', {})
            joinVipChallenge().then(res => {
                this.show = false;
                this.$store.dispatch('UserInfo');
                this.actionStart();
            }).catch(err => { })
        },
        reClick() {
            this.$store.commit('setVipChallenge', false);
        },
        juClick() {
            this.wanLiuShow = false;
            //baoming
            this.envClick();
        },
        async actionStart() {
            const vipData = await getVipChallengeDetail();
            console.error(vipData);
            this.user_vip = vipData.user_vip.slice(0, 4);
            this.time = vipData.countdown * 1000;
            this.vip_lv_list = vipData.list.slice(0, 4);
            this.hour = vipData.hour;

            const data = await getVipDetail();
            console.error("vip详情===>", data);
            this.vip_list = data.list;
            this.self_vip = data.user.vip_lv;

            this.user_vip.some((item, index) => {
                if (parseInt(item.lv) == this.self_vip) {
                    this.currtVipIndex = index;
                    return true;
                }
                return false;
            })
        }
    },
    created() {
        this.actionStart();
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
/deep/ .van-swipe__indicators {
    bottom: 28px;
}
.vip-challenge {
    width: 100%;
    height: 100%;
    font-family: Montserrat-VF;
    background: #0b131d;
    overflow-x: hidden;

    .st-vip {
        width: 100%;
        .ni-title {
            width: 100%;
            height: 90px;
            background: #030b17;
            text-align: center;
            line-height: 90px;
            color: #d605ac;
            font-size: 40px;
            font-weight: bold;
        }

        .ni-bottom {
            width: calc(100% - 50px);
            background: #14202d;
            overflow-x: scroll;
            padding: 40px 25px;

            .nib-title {
                height: 71px;
                display: inline-flex;
                flex-wrap: nowrap;
                background: #0b1724;
                border-radius: 10px;

                > span {
                    width: 300px;
                    font-size: 24px;
                    color: #a7c2ff;
                    text-align: center;
                    line-height: 71px;
                }
            }
            .nib-item {
                height: 71px;
                display: inline-flex;
                flex-wrap: nowrap;
                background: #0b1724;
                border-radius: 10px;

                > span {
                    width: 300px;
                    font-size: 24px;
                    color: #0da46d;
                    text-align: center;
                    line-height: 71px;
                }
            }
            .red-background {
                background-color: #0c1a29;
            }
            .self-background {
                background-color: #6c16c3;
                span {
                    color: #fff;
                }
            }
            .nib-item-s {
                height: 71px;
                display: inline-flex;
                flex-wrap: nowrap;
                background: #6c16c3;
                border-radius: 10px;

                > span {
                    width: 300px;
                    font-size: 24px;
                    color: #fff;
                    text-align: center;
                    line-height: 71px;
                }
            }
        }
    }

    .pbp-wanliu {
        background: #000000;

        .pop-box {
            width: 600px;
            background: #1c2532;
            border-radius: 20px;
            padding: 100px 40px;

            p {
                font-size: 33px;
                color: #fff;
                line-height: 72px;
                text-align: center;
                span {
                    font-size: 36px;
                    color: #f3184a;
                }
            }

            .pb-box {
                width: 100%;
                height: 85px;
                display: flex;
                align-items: center;
                margin-top: 75px;
                text-transform: uppercase;
                font-size: 31px;
                text-align: center;
                line-height: 85px;

                :nth-child(1) {
                    width: 230px;
                    height: 85px;
                    background: #273343;
                    border-radius: 15px;
                    color: #5a6c82;
                }
                :nth-child(2) {
                    width: 328px;
                    height: 85px;
                    background: #3cb408;
                    border-radius: 15px;
                    margin-left: 30px;
                    color: #fff;
                }
            }
        }
    }

    .pbp {
        background: #000000;

        .pop-box {
            width: 600px;
            background: #1c2532;
            border-radius: 20px;
            padding: 100px 40px;

            p {
                font-size: 26px;
                color: #fff;
                line-height: 60px;
                text-align: center;
                span {
                    font-size: 36px;
                    color: #f3184a;
                }
            }
            i {
                font-size: 31px;
                color: #748395;
            }
            .pb-box {
                width: 100%;
                height: 85px;
                display: flex;
                align-items: center;
                margin-top: 75px;
                text-transform: uppercase;
                font-size: 31px;
                text-align: center;
                line-height: 85px;

                :nth-child(1) {
                    width: 230px;
                    height: 85px;
                    background: #273343;
                    border-radius: 15px;
                    color: #5a6c82;
                }
                :nth-child(2) {
                    width: 328px;
                    height: 85px;
                    background: #3cb408;
                    border-radius: 15px;
                    margin-left: 30px;
                    color: #fff;
                }
            }
        }
    }

    .vc-center {
        width: 100%;
        height: calc(100% - 200px);
        overflow-y: scroll;

        .de-content {
            width: calc(711px - 30px);
            margin: 0 auto;
            background: #14202d;
            border-radius: 15px;
            padding: 15px 15px 0 15px;

            p {
                font-size: 22px;
                color: #748395;
                margin-bottom: 15px;
            }
        }

        .de-title {
            font-weight: bold;
            font-size: 30px;
            width: calc(100% - 50px);
            color: #fff;
            padding: 25px;
        }

        .vc-te {
            width: calc(100% - 40px);
            padding: 0 20px;
            position: relative;

            .vct-bg {
                width: 100%;
            }
            .time {
                color: #fff;
                display: inline-block;
                position: absolute;
                top: 120px;
                left: 90px;
                font-size: 37px;
                color: #4dff00;
                font-weight: bold;
            }
            .time-str {
                color: #fff;
                font-size: 19px;
                position: absolute;
                top: 160px;
                left: 85px;
                margin-top: 5px;

                span {
                    margin: 0 13px;
                }
            }
            .vct-btn {
                width: 452px;
                height: 62px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffd200;
                position: absolute;
                top: 220px;
                left: 50px;
                font-weight: bold;

                :nth-child(1) {
                    width: 88px;
                    font-size: 19px;
                    color: #040a23;
                }
                :nth-child(2) {
                    font-size: 26px;
                    color: #040a23;
                    font-weight: bolder;
                }
            }
        }

        .vc-swipe {
            width: 100%;
            margin: 40px 0;

            .my-swipe .van-swipe-item {
                background-image: linear-gradient(to bottom, #00397c, #14202d);
                color: #fff;
                border-radius: 10px;
                scale: 0.95;

                .ms-tip {
                    width: 100%;
                    font-size: 12px;
                    text-align: center;

                    :nth-child(1) {
                        color: #ffd200;
                        font-size: 11px;
                    }
                    :nth-child(2) {
                        color: #49627b;
                        font-size: 11px;
                        text-decoration: line-through;
                    }
                }

                .ms-3-box {
                    width: calc(100% - 20px);
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;

                    .ms-3-item {
                        width: 32%;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        background: linear-gradient(
                            to bottom,
                            #013f83,
                            #111a52
                        );
                        padding: 10px 0;

                        :nth-child(1) {
                            width: 50px;
                        }
                        :nth-child(2) {
                            font-size: 11px;
                            text-transform: uppercase;
                            text-align: center;
                            margin: 10px 0;
                            font-weight: bold;
                        }
                        :nth-child(3) {
                            width: 90%;
                            font-size: 12px;
                            color: #a7c2ff;
                            text-align: center;
                            font-weight: bold;
                        }
                    }
                }

                .ms-80 {
                    width: 300px;
                    text-align: center;
                    font-size: 12px;
                    margin: 0 auto;
                    color: #a7c2ff;
                    font-weight: bold;
                }

                .ms-jindutiao {
                    width: 300px;
                    margin: 10px auto;
                }

                .ms-box {
                    width: 375px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    :nth-child(1) {
                        width: 50px;
                    }
                    :nth-child(2) {
                        font-size: 26px;
                        font-weight: 900;
                        margin-left: 20px;
                    }
                }
                .vcc-tag {
                    width: 180px;
                    height: 18px;
                    background: #3cb408;
                    text-align: center;
                    line-height: 18px;
                    color: #fff;
                    font-size: 10px;
                    border-radius: 10px 10px 10px 0;
                }

                .ms-desa {
                    width: 180px;
                    height: 18px;
                    background: #ed1d49;
                    text-align: center;
                    line-height: 18px;
                    color: #fff;
                    font-size: 10px;
                    border-radius: 10px 10px 10px 0;
                    font-weight: bold;
                }
                .ms-todos {
                    width: calc(100% - 10px);
                    font-size: 10px;
                    color: #a7c2ff;
                    text-decoration: underline;
                    text-align: right;
                }
            }
        }

        .vcc-zhuzi {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .vcc-zhu-v {
                color: #fff;
                width: 100%;
                display: flex;

                span {
                    width: 25%;
                    text-align: center;
                    font-size: 30px;
                }
                :nth-child(1) {
                    position: absolute;
                    top: 140px;
                }
                :nth-child(2) {
                    position: absolute;
                    top: 80px;
                    left: 25%;
                }
                :nth-child(3) {
                    position: absolute;
                    top: 10px;
                    left: 50%;
                }
                :nth-child(4) {
                    position: absolute;
                    top: -50px;
                    left: 75%;
                }
            }

            .vcc-val {
                width: 100%;
                display: flex;
                margin-top: 10px;

                .vcc-val-item {
                    font-size: 28px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 25%;

                    span:nth-child(1) {
                        color: #ed1d49;
                    }
                    span:nth-child(2) {
                        color: #ffd200;
                    }
                    span:nth-child(3) {
                        color: #3b536c;
                        text-decoration: line-through;
                    }
                }
            }

            .vcc-zhuzi-img {
                width: 750px;
            }
        }

        .vcc-sb {
            width: 100%;
            height: 417px;
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 50px;

            .vcc-ccn {
                position: absolute;
                top: -50px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .vcc-vvv {
                    width: 382px;
                    height: 71px;
                    border-radius: 35px;
                    background: #00172f;
                    margin-top: 13px;
                    text-align: center;
                    line-height: 71px;
                    color: #d605ac;
                    font-weight: bolder;
                    font-size: 45px;
                }

                .vcc-niv {
                    font-size: 30px;
                    color: #fff;
                    font-weight: bold;
                    margin-top: 32px;
                }

                .vcc-vplevel {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #00172f;
                    color: #fff;
                    text-align: center;
                    line-height: 40px;
                    font-size: 29px;

                    position: absolute;
                    top: 130px;
                    right: 90px;
                }

                img {
                    width: 145px;
                }
            }

            .vcc-sbimg {
                width: 709px;
            }
        }

        .vcc-bg {
            width: 100%;
            height: 208px;
            position: relative;

            .vcc-str {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: 0;

                :nth-child(1) {
                    width: 100%;
                    height: 100px;
                    text-align: center;
                    font-size: 35px;
                    font-weight: bold;
                    line-height: 100px;
                    color: #fff;
                }

                :nth-child(2) {
                    font-size: 48px;
                    color: #f31e4b;
                    font-weight: bolder;
                }
                :nth-child(3) {
                    font-size: 28px;
                    color: #fff;
                    font-weight: bold;
                }
            }

            .vcc-img {
                width: 100%;
            }
        }
    }

    .vc-bottom {
        width: 100%;
        height: 100px;
        background: #f32251;
        text-align: center;
        line-height: 100px;
        font-weight: bold;
        color: white;
        text-transform: uppercase;
        font-size: 35px;
    }
    .vc-bottom-noclick {
        width: 100%;
        height: 100px;
        background: #273343;
        text-align: center;
        line-height: 100px;
        font-weight: bold;
        color: white;
        text-transform: uppercase;
        font-size: 35px;
    }

    .cashback-bar {
        width: calc(100% - 20px);
        height: 100px;
        display: flex;
        align-items: center;
        color: white;
        background: #0c1a29;
        z-index: 100;
        padding-left: 20px;

        > span {
            font-size: 30px;
            margin-left: 20px;
        }
    }
}
</style>