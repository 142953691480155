<template>
    <van-popup class="personal-context" v-model="personalShow" position="right" z-index="3">
        <div class="personal_box">
            <div class="dataCards">
                <div class="top" :style="{borderBottom: chooseAvatars ?'' : ''}">
                    <div class="avatarBox">
                        <div class="avatar" @click="chooseAvatars = !chooseAvatars">
                            <img :src="IMAGE_URL + 'header/' + userData.avatar + '.jpg'" alt />
                            <div class="avatar-edit-icon">
                                <van-icon name="edit" size="16" />
                            </div>
                            <div class="change-avatar" v-if="avatarLoading">
                                <van-loading />
                            </div>
                        </div>
                    </div>
                    <div class="InfoBox">
                        <div class="name">{{ getUserData ? getUserData.name : '' }}</div>
                        <div class="userId">
                            <span>User ID</span>
                            <div class="rightPart">
                                <input
                                    class="userIdText"
                                    type="text"
                                    :value="getUserData?getUserData.uid:''"
                                    ref="uid"
                                    readonly
                                />
                                <img
                                    class="copy_icon"
                                    src="../../../public/image/personal/file.png"
                                    alt
                                    @click="copyUid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="avatar-list" v-if="chooseAvatars">
                    <img
                        v-for="item in 10"
                        @click="changeAvatar(item - 1)"
                        :src="IMAGE_URL + 'header/' + (item - 1) + '.jpg'"
                        alt
                    />
                </div>
                <div class="moneyOut">
                    <div class="VipCards" @click="toJumpRechange">
                        <div class="top">
                            <div class="right">
                                <div class="retairAble">
                                    <span class="title">Valor sacavel</span>
                                    <span
                                        class="num"
                                        style="color:#54B949 ;"
                                    >R$ {{userData.withdraw}}</span>
                                </div>
                                <div class="vip" v-if="drawProgress">
                                    <div class="txt">
                                        <span class="showGrade">Precisa Aposta</span>
                                        <span class="showGrade">
                                            {{drawProgress.valid_bets}} /
                                            {{drawProgress.need_bets}}
                                        </span>
                                    </div>
                                    <div class="progress_bar_block">
                                        <div class="progress_bar">
                                            <div
                                                class="progress_back"
                                                :style="{width: (drawProgress.valid_bets / drawProgress.need_bets) * 100 + '%'}"
                                            >
                                                <p>
                                                    <!-- {{ ((drawProgress.valid_bets / drawProgress.need_bets) * 100).toFixed(2) }}% -->
                                                    R${{drawProgress.valid_bets}} / R${{drawProgress.need_bets}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vip" v-else>
                                    <div class="txt">
                                        <span class="showGrade">Precisa Aposta</span>
                                        <span class="showGrade">0 / 0</span>
                                    </div>
                                    <div class="progress_bar_block">
                                        <div class="progress_bar">
                                            <div class="progress_back" style="width: 100%;">
                                                <p>0 / 0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Zerar">
                        <!-- <div>
              <span>Zerar</span>
              <img src="../../../public/image/personal/question.png" alt @click="toSolve" />
                        </div>-->
                    </div>
                    <div class="solvePop" v-show="showSolvePop">
                        <div class="title">Descrição de Zerar</div>
                        <div class="content">
                            Quando o seu saldo estiver abaixo de R$0.2, a sua barra de apostas ou 'Rollover' será reiniciada.Isso
                            significa
                            que você
                            não precisará fazer apostas em quantias elevadas para efetuar um saque.
                        </div>
                    </div>
                </div>
                <div class="btn" v-if="!channel.banned_user">
                    <button @click="toWallet">
                        <img src="../../../public/image/personal/bag.png" alt />
                        <span>Detalhes</span>
                    </button>
                </div>
            </div>
            <div class="notice" @click="openShowMessage">
                <div>
                    <img src="../../../public/image/personal/icon1.png" alt />
                    <span>Notificações</span>
                </div>
                <span class="nums">{{messageNumber}}</span>
            </div>
            <div class="btnList">
                <div class="oddBtn" @click="toPersonal">
                    <div>
                        <img src="../../../public/image/personal/icon2.png" alt />
                        <span>Centro Pessoal</span>
                    </div>
                    <img src="../../../public/image/personal/listArrow.png" alt class="listArrow" />
                </div>
                <div class="oddBtn" @click="to_vip">
                    <div>
                        <img src="../../../public/image/personal/icon3.png" alt />
                        <span>Meu nível vip</span>
                    </div>
                    <img src="../../../public/image/personal/listArrow.png" alt class="listArrow" />
                </div>
                <div class="oddBtn" @click="to_bonus">
                    <div>
                        <img src="../../../public/image/personal/icon4.png" alt />
                        <span>My Bonus</span>
                    </div>
                    <img src="../../../public/image/personal/listArrow.png" alt class="listArrow" />
                </div>
                <div class="oddBtn" @click="to_share">
                    <div>
                        <img src="../../../public/image/personal/icon5.png" alt />Convite
                    </div>
                    <img src="../../../public/image/personal/listArrow.png" alt class="listArrow" />
                </div>
                <div class="oddBtn">
                    <div @click="openService">
                        <img src="../../../public/image/personal/icon6.png" alt />
                        <span>Contate-nos</span>
                    </div>
                    <img src="../../../public/image/personal/listArrow.png" alt class="listArrow" />
                </div>
            </div>
            <div class="log_out" @click="log_out">
                <div>
                    <img src="../../../public/image/personal/logOut.png" alt />
                    <span>Sair</span>
                </div>
            </div>
            <!-- <div class="close">
        <img @click="closePopUps" src="../../../public/image/personal/close.png" alt="">
            </div>-->

            <!-- <div v-if="isShowMessage">
        <SiteMessage :showState="isShowMessage" :message="messageList" @closeAll="toCloseAll" />
            </div>-->

            <FAQ :showState="isShowFAQ" @closeFAQ="() => isShowFAQ = false" />
            <Dialog
                :show="showCopyDialog"
                :confirmText="`confirm`"
                @confirm="showCopyDialog = false"
            >
                <div>Copiado com sucesso</div>
            </Dialog>
        </div>
    </van-popup>
</template>
<script>
import SiteMessage from "@/components/SiteMessage.vue";
import FAQ from "@/components/FAQ.vue";
import { mapState, mapGetters } from "vuex";
import { getMailList, getTaskList, modifyAvatar, getWithdrawProgress } from "@/api/api";
import Dialog from '@/components/Dialog'
export default {
    components: { FAQ, SiteMessage, Dialog },
    data() {
        return {
            personalShow: false,
            // isShowMessage: false,
            isShowFAQ: false,
            currentLevel: {},
            messageList: [],
            totleBonus: 0,
            showCopyDialog: false,
            chooseAvatars: false,
            avatarLoading: false,
            drawProgress: 0,
            showSolvePop: false
        }
    },
    watch: {
        innerMask(val) {
            if (!val) {
                this.personalShow = false;
            }
            this.personalShow = val;
            if (val) {
                getWithdrawProgress().then((res) => {
                    if (res) {
                        this.drawProgress = res;
                    }
                    else {
                        // this.drawProgress = {
                        //   valid_bets: 0,
                        //   need_bets: 0
                        // }
                    }
                }).catch(() => { })
            }
        },
        personalShow(val) {
            this.$store.commit('INNERMASK', val);
        },
        drawProgress(val) {
            // console.log('cal', val);
        }
    },
    computed: {
        ...mapState(['userData', 'channel', 'messageNumber', 'innerMask']),
        ...mapGetters(['getUserData'])
    },
    created() {

        this.getTaskInfo();

    },
    methods: {
        closePopUps() {
            this.$store.commit('INNERMASK', false)
        },
        toPersonal() {
            this.$store.commit('INNERCONTENT', '')
            this.$store.commit('INNERMASK', false)
            this.$router.push('/personal')
        },
        openShowMessage() {
            this.$store.commit('setMessageShow', true);
            // this.isShowMessage = true;
        },
        to_vip() {
            this.$store.commit('INNERCONTENT', '')
            this.$store.commit('INNERMASK', false)
            this.$router.push('/vip')
        },
        to_share() {
            this.$store.commit('INNERCONTENT', '')
            this.$store.commit('INNERMASK', false)
            this.$router.push('/share')
        },
        to_bonus() {
            this.$store.commit('INNERCONTENT', '')
            this.$store.commit('INNERMASK', false)
            this.$router.push('/bonus')
        },
        log_out() {
            let only_uuid = localStorage.getItem('only_uuid');
            let BuryingParams = localStorage.getItem('BuryingParams');
            let click_id = localStorage.getItem('click_id');
            // localStorage.clear();
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("active_pop_show");
            localStorage.removeItem("inviteCode");
            localStorage.removeItem("lastPopupDate");
            localStorage.removeItem("isPopVip");
            localStorage.removeItem("sub_channel");


            localStorage.setItem('only_uuid', only_uuid);
            localStorage.setItem('BuryingParams', BuryingParams);
            localStorage.setItem('BuryingParams', click_id);

            this.$router.push('/')
            history.go(0)
        },
        toWallet() {
            this.$store.commit('INNERMASK', false)
            this.$router.push('/wallet')
        },
        getTaskInfo() {
            getTaskList().then((res) => {
                if (res.data) {
                    res.data.map(item => {
                        if (item.status !== 2) {
                            this.totleBonus = parseInt(this.totleBonus) + parseInt(item.bonus)
                        }
                    })
                }
            }).catch(() => { })
        },
        copyUid() {
            this.$refs.uid.select()
            let result = document.execCommand('copy')
            if (result) {
                // this.showCopyDialog = true
                this.$store.dispatch('toast', 'Copia de sucesso')
            }
        },
        getMessageList() {
            getMailList({
                pageNo: 1,
                pageSize: 100
            }).then(res => {
                this.$store.commit('setMessageNumber', res.list.length);
                this.messageList = res.list;
            }).catch(() => { })
        },
        openService() {
            window.open('https://chat.ss-chat.com/service/fgtnic')
        },
        calcPercent(val, max) {
            if ((val / max) > 1) {
                return 1
            } else return val / max;
        },
        changeAvatar(name) {
            this.avatarLoading = true;
            modifyAvatar({
                num: name
            }).then(res => {
                this.userData.avatar = name;
                this.chooseAvatars = false;
                this.avatarLoading = false;
            }).catch(() => {
                this.avatarLoading = false;
            })
        },
        // toCloseAll() {
        //   this.isShowMessage = false
        // },
        toSolve() {
            this.showSolvePop = !this.showSolvePop
        },
        toJumpRechange() {
            // if (this.$route.path === "/recharge") return;
            this.closePopUps();
            this.$router.push({ name: 'recharge', params: { tabIndex: 2 } });
        }
    },
}
</script>
<style>
</style>
<style lang="less" scoped>
.personal-context {
    width: 506px;
    padding: 106px 0 100px 0;
    z-index: 4 !important;
}

.personal_box {
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
    background: #1d1d29;
    padding: 80px 30px 200px;
    box-sizing: border-box;
}

.dataCards {
    width: 458px;
    /* min-height: 661px; */
    /* background: url('../../../public/image/personal/bg1.png') no-repeat;
    background-size: 100% 100%; */
    padding-top: 46px;
    padding-bottom: 23px;
    box-sizing: border-box;
    margin: 0 auto;
}

.innercards {
    background: url("../../../public/image/personal/bg_icon.png") no-repeat;
    background-size: 100% 100%;
}

.avatarBox {
    width: 126px;
    height: 126px;
    margin-right: 30px;
    /* background: url('../../../public/image/personal/bg_icon.png') no-repeat;
    background-size: 100% 100%; */
}

.top {
    /* width: 600px; */
    margin: 0 auto;
    display: flex;

    .avatar {
        width: 126px;
        height: 126px;
        /* background: url('../../../public/image/personal/avatar_border.png') no-repeat;
      background-size: 100% 100%; */
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        img {
            width: 126px;
            height: 126px;
            border-radius: 50%;
        }
    }

    .name {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        line-height: 40px;
    }

    .userId {
        font-size: 25px;
        font-weight: bold;
        color: #b3b6c5;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        line-height: 40px;
        margin-top: 15px;

        span {
            /* width: 100px; */
            margin-right: 30px;
        }

        .rightPart {
            position: relative;
            display: flex;
            align-items: center;

            .userIdText {
                width: 180px;
                background: transparent;
                color: #b3b6c5 !important;
            }

            .copy_icon {
                width: 23px;
                vertical-align: middle;
                position: absolute;
                right: 0;
            }
        }
    }
}

.InfoBox {
    /* width: 577px; */
    /* height: 108px; */
    /* padding: 26px 0px 22px;
    box-sizing: border-box; */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.moneyOut {
    padding: 30px 15px;
    box-sizing: border-box;
    background: url("../../../public/image/personal/lineBd.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 36px;
    margin-bottom: 25px;
    position: relative;
}

.vip {
    width: 426px;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .rightBox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;

        .txt {
            display: flex;
            justify-content: flex-end;
        }

        .progress {
            width: 351px;
            height: 26px;
            background: #ffffff;
            border-radius: 5px 5px 5px 5px;
            z-index: 2;
            color: #000;
            display: flex;

            .progress_length {
                width: 187px;
                height: 26px;
            }

            span {
                position: relative;
                left: -15px;
            }
        }
    }
}

/* .Wallet {
    margin: 28px auto 32px;
    padding: 10px 0px;
    box-sizing: border-box;
    width: 577px;
    height: 100px;
    background: #1C2B3F;
    border-radius: 23px;

    .title {
      font-size: 26px;
      color: #FFFFFF;
      line-height: 49px;
      text-align: center;
      font-weight: bold;
    }

    .banlance {

      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 30px;

      .txt1,
      .txt2 {
        span {
          font-size: 20px;
          color: #FAD543;
          margin-left: 9px;
        }
      }

      .txt1 {
        span {
          margin-right: 23px;
        }
      }
    }


  } */

.Zerar {
    width: 94%;
    margin: 22px auto;
    display: flex;
    justify-content: flex-end;

    div {
        width: 200px;
        height: 39px;
        background: url("../../../public/image/personal/ZerarBg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 28px;
        display: flex;
        align-items: center;
        position: relative;

        span {
            flex: 1;
            text-align: center;
            color: #b3b6c5;
            font-weight: bold;
        }

        img {
            width: 26px;
            height: 26px;
            position: absolute;
            right: 9px;
        }
    }
}

.btn {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
    color: #fff;
    /* font-weight: bold; */

    button {
        width: 100%;
        height: 76px;
        border: none;
        background: url("../../../public/image/personal/btnBg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        margin: 0 auto;
        padding: 0px 30px;
        box-sizing: border-box;
    }

    img {
        width: 32px;
        /* height: 39px; */
        margin-right: 30px;
    }
}

.btnList {
    width: 458px;
    /* margin-top: 15px; */
    margin: 0 auto;
    font-size: 24px;
    text-align: center;
    color: #99abd1;

    .oddBtn {
        background: url("../../../public/image/personal/btn_unactive.png")
            no-repeat;
        background-size: 100% 100%;
        font-size: 24px;
        text-align: center;
        position: relative;
        color: #99abd1;
        display: flex;
        align-items: center;

        div {
            width: 454px;
            height: 76px;
            display: flex;
            /* justify-content: center; */
            align-items: center;
            padding: 0px 30px;
            box-sizing: border-box;
            /* font-weight: bold; */
        }

        img {
            width: 34px;
            margin-right: 30px;
        }

        .listArrow {
            width: 12px;
            height: 21px;
        }
    }

    .oddBtn:first-child {
        background: url("../../../public/image/personal/firstBg.png") no-repeat;
        background-size: 100% 100%;

        div {
            display: flex;
            align-items: center;
        }
    }

    .oddBtn:last-child {
        background: url("../../../public/image/personal/lastBg.png") no-repeat;
        background-size: 100% 100%;

        div {
            display: flex;
            align-items: center;
        }
    }
}

.notice {
    width: 458px;
    margin: 0 auto;
    font-size: 24px;
    text-align: center;
    color: #99abd1;
    height: 76px;
    background: url("../../../public/image/personal/noticeBg.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;

    div {
        width: 458px;
        height: 76px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* font-weight: bold; */
    }

    img {
        width: 28px;
        margin-right: 30px;
    }

    .nums {
        min-width: 30px;
        min-height: 30px;
        background: #d72349;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.log_out {
    width: 100%;
    margin: 0 auto;
    color: #99abd1;
    font-size: 25px;
    /* font-weight: bold; */
    margin-top: 29px;

    div {
        display: flex;
        align-items: center;
    }

    img {
        width: 32px;
        margin-right: 24px;
    }
}

.close {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;

    img {
        width: 82px;
        height: 82px;
    }
}

.progress_bar_block {
    width: 100%;
    margin: 0 auto;
    display: flex;
    border-radius: 10px;
}

.grade {
    width: 87px;
    height: 71px;
    background: #1c2b3f;
    border-radius: 23px;
    /* margin-right: 30px; */
    font-size: 25px;
    color: #fad543;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 39px;
        /* height: 29px; */
        margin-bottom: 9px;
    }
}

.progress_bar {
    width: 100%;
    height: 31px;
    background: #ccc;
    border-radius: 20px;

    div {
        width: 100%;
        background: #4280ed;
        height: 31px;
        line-height: 31px;
        text-align: center;
        border-radius: 20px;

        p {
            width: 426px;
            height: 31px;
            font-size: 20px;
            /* font-family: Arial, serif; */
            /* font-weight: 900; */
            font-weight: bold;
            color: #fff;
            border-radius: 20px;
        }
    }
}

.change-avatar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 0.6;
}

.avatar-edit-icon {
    position: absolute;
    bottom: 14px;
    width: 130px;
    background-color: #000;
    text-align: center;
    opacity: 0.4;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 60px 60px;
}

.avatar-list {
    width: 426px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    /* margin: 0 20px; */
    margin: 30px auto;
    /* border-bottom: 2px solid #91ABD5; */
    animation: avatar-list-show 500ms ease-in;

    img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        margin: 10px 8px;
    }
}

@keyframes avatar-list-show {
    from {
        opacity: 0;
        /* //transform: translateY(-200px); */
    }

    to {
        opacity: 1;
        /* //transform: translateY(0); */
    }
}

.VipCards {
    /* background: url('../../../public/image/personal/baseBg.png') no-repeat;
    background-size: 100% 100%; */
    display: flex;
    justify-content: center;
    align-items: center;

    .name {
        width: 180px;
        height: 78px;
        font-size: 32px;
        color: #ffffff;
        height: 80px;
        font-weight: bold;
        background: url("../../../public/image/personal/titleBg.png") no-repeat;
        background-size: 100% 100%;
        padding: 20px 30px;
        box-sizing: border-box;
    }

    .vip {
        font-size: 20px;
        color: #ffffff;
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .txt {
            width: 94%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .showGrade {
                font-size: 22px;
                font-weight: bold;
                color: #b3b6c5;
            }
        }

        .progress {
            width: 351px;
            height: 26px;
            background: #ffffff;
            border-radius: 5px 5px 5px 5px;
            z-index: 2;
            color: #000;
            display: flex;
            margin-top: 10px;

            .progress_length {
                width: 187px;
                height: 26px;
                background: #fbd543;
            }

            span {
                /* position: relative;
          left: -15px; */
            }
        }
    }

    .all_level {
        font-size: 26px;
        font-weight: bold;
        color: #89adca;
        margin-top: 17px;
    }

    .retairAble {
        width: 426px;
        height: 56px;
        font-size: 25px;
        color: #6b84a2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: url("../../../public/image/personal/wallect_gray_bg.png")
            no-repeat;
        background-size: 100% 100%;
        padding: 0px 20px;
        box-sizing: border-box;
        margin: 0 auto;
    }
}

.solvePop {
    width: 276px;
    min-height: 293px;
    background: url("../../../public/image/personal/solvePopBg.png") no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    color: #b3b6c5;
    position: absolute;
    top: 270px;
    right: 30px;
    z-index: 1;
    padding: 30px 15px;
    box-sizing: border-box;

    .title {
        font-size: 24px;
        color: #feaa09;
    }
}
</style>