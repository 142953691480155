<template>
    <div class="cashback">
        <div class="cashback-bar" @click="backBarClick">
            <van-icon name="arrow-left" size="20px" />
            <span>Telegram de recompensa diária</span>
        </div>
        <div style="height: 40px;"></div>
        <div class="box-1" v-if="!isReceive && !not_vip">
            <div class="tg-img">
                <img src="../../../public/image/newvip/tg1.webp" alt />
            </div>
            <div class="tg-center">
                <span class="tgc-r">R$ {{ total_amount }}</span>
                <div class="tgc-c">
                    <div class="tgcc-s">
                        <span>R$ {{ base_amount }}</span>
                        <span>Recompensa Básica</span>
                    </div>
                    <div class="tgcc-s">
                        <span>R$ {{ extra_amount }}</span>
                        <span>Recompensa Extra Vip</span>
                    </div>
                </div>
                <p>
                    O coeficiente adicional do
                    <span>VIP {{ user_vip_level}}</span> é de
                    <span>{{ vip_extra_rate }}%</span>. Quanto
                    maior o nível VIP, maior será o coeficiente
                    de recompensa adicional。
                </p>
            </div>
            <div class="btn" @click="reiviClick">Reivindicar recompensa</div>
            <span class="as">As recompensas podem ser usadas para sacar ou apostar</span>
        </div>
        <div class="box-2" v-if="isReceive && !not_vip">
            <img src="../../../public/image/newvip/tg2.webp" alt />
            <p>
                você já recebeu a recompensa hoje. Você pode
                reivindicar recompensas uma vez por dia.
                Lembre-se de verificar o link do Telegram
                amanhã para reivindicar recompensas.
            </p>
            <div class="btn" @click="backBarClick">retornar</div>
        </div>
        <div class="box-3" v-if="not_vip">
            <div class="tg-img">
                <img src="../../../public/image/newvip/tg1.webp" alt />
            </div>
            <div class="bb-bg">
                Você precisa ser VIP1 ou superior
                para receber recompensas diárias.
            </div>
            <div class="btn" @click="desClick">Depósito</div>
        </div>
    </div>
</template>

<script>
import { claimTgRewards, tgTodayReward } from '@/api/api';
import { mapState } from 'vuex';
export default {
    name: 'TgReward',
    components: {},
    props: {},
    data() {
        return {
            isReceive: false,
            not_vip: false,
            total_amount: "0", // 总奖金
            base_amount: "0", // 基础奖金
            extra_amount: "0", // 额外奖金
            vip_extra_rate: "0", // vip 系数
            user_vip_level: 1, // 用户vip
        }
    },
    watch: {},
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        backBarClick() {
            localStorage.setItem('reward', '');
            this.$store.commit('setShowTgReward', false);
        },
        desClick() {
            this.backBarClick();
            if (this.$route.path === '/recharge') return;
            this.$router.replace('/recharge');
        },
        claimTgRewardFun() {
            claimTgRewards({
                p: localStorage.getItem('reward'),
            }).then(res => {
                console.log(res);
                this.$store.commit('setShowPopupPanel',
                    {
                        show: true,
                        componentName: 'Confirm',
                        option: {
                            content: 'Recebido com sucesso, lembre-se de voltar amanhã',
                            confirmMsg: 'Jogo',
                            cancelMsg: 'fecho',
                            confirmCb: () => {
                                this.backBarClick();
                            },
                        }
                    });
            }).catch(err => {

            })
        },
        tgReward() {
            tgTodayReward().then(res => {
                console.log(res, 111);
                this.isReceive = res.status;

                this.total_amount = res.total_amount;
                this.base_amount = res.base_amount;
                this.extra_amount = res.extra_amount;
                this.vip_extra_rate = res.vip_extra_rate;
                this.user_vip_level = res.user_vip_level;

                this.not_vip = this.user_vip_level == 0;

            }).catch(err => {
                console.error(err);
            })
        },
        reiviClick() {
            this.claimTgRewardFun();
        }
    },
    created() {
        this.tgReward();
    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.cashback {
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 15px 0 15px;
    font-family: Montserrat-VF;
    background: #0b131d;
    overflow-x: hidden;

    .box-3 {
        .btn {
            width: 643px;
            height: 85px;
            background: #3cb408;
            text-align: center;
            line-height: 85px;
            color: #fff;
            font-size: 30px;
            border-radius: 15px;
            text-transform: uppercase;
            margin: 50px auto;
            font-weight: bold;
        }

        .bb-bg {
            width: calc(100% - 100px);
            background: #03101f;
            padding: 80px 50px;
            font-size: 32px;
            color: #3cb408;
        }
        .tg-img {
            width: 100%;
            height: 408px;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 334px;
                height: 408px;
            }
        }
    }

    .box-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 174px;
            margin-top: 129px;
        }

        p {
            width: 90%;
            font-size: 25px;
            color: #fff;
            text-align: center;
            margin-top: 110px;
        }

        .btn {
            width: 643px;
            height: 85px;
            background: #ffd300;
            text-align: center;
            line-height: 85px;
            color: #0b131d;
            font-size: 30px;
            border-radius: 15px;
            text-transform: uppercase;
            margin: 100px auto;
            font-weight: bold;
        }
    }

    .box-1 {
        .as {
            display: block;
            font-size: 23px;
            color: #748395;
            text-align: center;
        }

        .btn {
            width: 643px;
            height: 85px;
            background: #227200;
            text-align: center;
            line-height: 85px;
            color: #fff;
            font-size: 30px;
            border-radius: 15px;
            text-transform: uppercase;
            margin: 20px auto;
        }

        .tg-center {
            width: 100%;
            background: #14202d;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                width: 90%;
                font-size: 25px;
                color: #748395;
                text-align: center;
                margin: 30px auto;

                span {
                    color: #ffd200;
                }
            }

            .tgc-r {
                font-size: 80px;
                color: #ffd200;
                font-weight: bolder;
                margin: 90px 0 60px 0;
            }
            .tgc-c {
                width: 676px;
                height: 145px;
                background: #0b1724;
                border-radius: 10px;
                display: flex;
                align-items: center;

                .tgcc-s {
                    width: calc(50% - 50px);
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 50px;

                    :nth-child(1) {
                        color: #3cb408;
                        font-size: 40px;
                        font-weight: bold;
                    }
                    :nth-child(2) {
                        color: #a7c2ff;
                        font-size: 18px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .tg-img {
            width: 100%;
            height: 408px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 334px;
                height: 408px;
            }
        }
    }
}
.cashback-bar {
    width: 100%;
    height: 80px;
    // padding-left: 40px;
    display: flex;
    align-items: center;
    color: white;
    position: fixed !important;
    top: 0 !important;
    background: #0c1a29;
    z-index: 100;
    > span {
        font-size: 30px;
    }
}
</style>