<script setup>

  import { ref, reactive, watchEffect } from "vue";
  import store from "@/store";
  import { getUserWheelInfo, getWheelRank, startWheelReward } from "@/api/api";
  import { AmountFixed, TimeTurn } from "@/utis/common";
  import router from '../router/index.js';
  const backgroundStart = ref(false);
  const wheelStart = ref(false);
  const blockShow = ref(true);
  let rewardIndex = -1;
  const isShowHistory = ref(false);
  const historyFirstRender = ref(false);
  const wheelLoading = ref(true);
  let imageLoadNumber = 0;
  const userWheelInfo = reactive({ info: {} });
  const wheelRank = reactive({ rank: [] });
  const countDownTimeList = reactive({ list: [] });
  let timer = null;
  const wheelWinReward = ref(false);
  const wheelWinInfo = reactive({ info: {} });
  watchEffect(() => {
    if (store.state.wheelShow) {
      getWheelInfo();
      store.commit('setMenuBlockShow', false);
    }
  })
  const closeSelf = () => {
    store.commit('setWheelShow', false);
    isShowHistory.value = false;
    wheelStart.value = false;
    blockShow.value = true;
    wheelWinReward.value = false;
    historyFirstRender.value = false;
    clearInterval(timer);
  }
  const closeHistory = () => {
    setTimeout(() => historyFirstRender.value = false, 500)
  }

  const getWheelInfo = () => {
    getUserWheelInfo().then(res => {
      userWheelInfo.info = res;

      if (res.next_at && res.count === 0) {
        // timer = setInterval(() => {
        //   countDownTimeList.list = TimeTurn(res.next_at).split(':');
        //
        //   if (countDownTimeList.list[1] === ' 00 ' && countDownTimeList.list[2] === ' 00 ' && countDownTimeList.list[3] === ' 00') {
        //     clearInterval(timer);
        //     getWheelInfo();
        //   }
        // }, 1000)
      }
    }).catch(() => {

    })

    getWheelRank().then(res => {
      wheelRank.rank = res.rank;
    }).catch(() => { })
  }

  const imageLoaded = () => {
    imageLoadNumber++;
    if (imageLoadNumber >= 2) {
      wheelLoading.value = false;

      setTimeout(() => {
        backgroundStart.value = true
      }, 100)
    }
  }
  const startWheel = () => {
    if (!store.state.token) {
      closeSelf();
      store.commit('IN_OR_UP', 'in')
      store.commit('MASKCONTENT', 'login')
      store.commit('MASK', true)
      return;
    }

    wheelStart.value = true;
    setTimeout(() => {
      blockShow.value = false;
    }, 300)

    wheelAnimateQueue()
  }
  const wheelReStart = () => {
    if (userWheelInfo.info.count === 0) return
    wheelWinReward.value = false;
    wheelAnimateQueue();
  }

  const recharge = () => {
    closeSelf();
    // setTimeout(() => {
    // store.commit('setOpenRecharge', true);
    router.push('/')
    // }, 200)
  }

  const wheelAnimateQueue = () => {
    if (!wheelStart.value) return;

    let animationList = document.getElementById('wheel').getAnimations();
    if (animationList.length > 0) {
      animationList.map((item) => {
        item.cancel()
      })
    }

    let random = (Math.random() * (11 - -11 + 1)) - 11;

    startWheelReward().then(res => {
      rewardIndex = res.index;
      userWheelInfo.info.count = res.count;

      setTimeout(() => {
        document.getElementById('wheel').animate([
          { transform: "rotate(0)" },
          { transform: "rotate(" + ((4 * 360) + (rewardIndex * 30) + random) + "deg)" },
        ], {
          duration: 6000,
          iterations: 1,
          fill: 'forwards',
          easing: 'cubic-bezier(.19,-.11,.03,1.04)'
        })

        document.getElementById('wheel').getAnimations()[0].finished.then((animation) => {
          wheelWinInfo.info = res;

          setTimeout(() => {
            store.dispatch('UserInfo');
            wheelWinReward.value = true;
            if (animation) {
              animation.cancel();
            }
          }, 1000)
        });
      }, 500)
    }).catch(() => {
      return false;
    })
  }

</script>

<template>
  <div class="wheel-mask" v-if="store.state.wheelShow">
    <div class="wheel-block">
      <div class="wheel-close">
        <van-icon name="cross" color="#93ACD3" size="24" @click.prevent="closeSelf" />
      </div>

      <div v-show="wheelLoading" class="wheel-load">
        <van-loading />
      </div>

      <div v-show="!wheelLoading">
        <div class="wheel-background" v-if="backgroundStart" :class="wheelStart ? 'start-wheel-back' : ''">
          <img src="../../public/image/active/wheel-decor.webp" alt="background">
        </div>
        <div class="wheel-self" :class="wheelStart ? 'start-wheel' : ''">
          <img id="wheel" src="../../public/image/active/wheel-context.png" alt="background" @load="imageLoaded">
          <img src="../../public/image/active/wheel_1.webp" alt="1">
          <img src="../../public/image/active/wheel_2.webp" alt="2">
          <img src="../../public/image/active/wheel_3.webp" alt="3">
          <img src="../../public/image/active/wheel_4.webp" alt="4">
          <img src="../../public/image/active/wheel_5.webp" alt="5">
          <img src="../../public/image/active/wheel_6.webp" alt="6">
          <img src="../../public/image/active/wheel_7.webp" alt="7">
          <img src="../../public/image/active/wheel_8.webp" alt="8">
          <img class="wheel-arrow" src="../../public/image/active/wheel_arrow.webp" alt="arrow">
          <img class="wheel-start" src="../../public/image/active/wheel-start-btn.png" alt="start">
          <span class="wheel-count">{{userWheelInfo.info.count}}</span>
        </div>

        <div class="wheel-title" v-show="blockShow" :class="wheelStart ? 'disappear-down' : ''">
          <img src="../../public/image/active/wheel-title.png" alt="title" @load="imageLoaded">

          <div v-if="userWheelInfo.info.count > 0 || !store.state.token" class="wheel-btn" @click="startWheel">
            {{store.state.token ? 'Spin and win' : 'Faça login para girar'}}
          </div>

          <div v-if="userWheelInfo.info.count === 0" class="wheel-count-down">
            <div class="wheel-next-text">
              A cada <span style="color: #2BB31B">R$200</span> apostados, você pode girar a roda uma vez.
            </div>
            <div class="recharge-btn" @click="recharge">Aposta</div>
          </div>

          <div class="wheel-history-btn" @click="isShowHistory = true;historyFirstRender = true">
            <span style="margin-right: 10px">Ganhos Recentes</span>
            <van-icon name="arrow" color="#1A71D9" />
          </div>
        </div>
      </div>

      <div class="wheel-history-block" v-if="historyFirstRender"
        :class="isShowHistory ? 'wheel-history-in' : 'wheel-history-out'">
        <div class="wheel-history-title">
          <div class="back-btn" @click="isShowHistory = false;closeHistory()">
            <van-icon name="arrow-left" size="16" color="#93acd3" />
          </div>
          <span>Ganhos Recentes</span>
        </div>

        <div class="wheel-history-table">
          <table>
            <tr>
              <th>Tempo</th>
              <th>User</th>
              <th>Bónus</th>
            </tr>
            <tr v-for="item in wheelRank.rank">
              <td style="color:#7A8FB1;
              font-weight: bold;">{{item.spin_at}}</td>
              <td>
                <div class="table-cell">
                  <img :src="IMAGE_URL + 'header/' + item.avatar + '.jpg'" alt="avatar" />
                  {{item.name}}
                </div>
              </td>
              <td>
                <div class="table-cell">
                  <img src="../../public/image/headerImg/R$_pics.png" alt="blr" />
                  {{AmountFixed(item.score)}}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="wheel-win" v-show="wheelWinReward">
        <img src="../../public/image/active/wheel-win-background.png" alt="win">

        <div class="wheel-win-info">
          <div class="wheel-win-title" v-show="wheelWinInfo.info.brl">
            <img src="../../public/image/headerImg/R$_pics.png" alt="blr" />
            <div>&nbsp;{{wheelWinInfo.info.brl}}&nbsp;</div> BRL
          </div>
          <div class="wheel-win-title" v-show="wheelWinInfo.info.free_coin">
            <img src="../../public/image/headerImg/free-coin.png" alt="free" />
            <div>&nbsp;{{wheelWinInfo.info.free_coin}}&nbsp;</div> ReisCoin
          </div>

          <div style="font-size: 20px;margin-top: 60px">
            Você recebeu
            <span style="color: #FFB636">
              {{wheelWinInfo.info.brl ? 'R$' + wheelWinInfo.info.brl : 'F$' + wheelWinInfo.info.free_coin}}
            </span>
            , <br /><br />aproveite o jogo e boa sorte.
          </div>
        </div>

        <div v-show="wheelWinInfo.info.count !== 0" class="wheel-win-btn" @click="wheelReStart">continuar</div>
        <div v-show="wheelWinInfo.info.count === 0" class="wheel-win-btn" @click="closeSelf">Fechar</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .wheel-mask {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    color: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .wheel-block {
    width: calc(100% - 40px);
    height: 1040px;
    background: linear-gradient(180deg, #180ae8 0%, #010939 40%);
    margin: 0 20px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
  }

  .wheel-close {
    position: absolute;
    right: 0;
    text-align: right;
    padding: 20px 40px;
    z-index: 99999;
  }

  .wheel-background {
    position: absolute;
    width: 1400px;
    animation: wheelBackground 1.2s linear .6s forwards;
    transform: translate(-50%, -70%) scale(0);
    left: 50%;
    top: 50%;

    img {
      width: 100%;
    }
  }

  .wheel-self {
    width: 100%;
    height: 600px;
    position: absolute;
    top: -40px;
    transform: translateY(-40px);
    text-align: center;
    animation: wheelSelf 500ms linear;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img {
      width: 90%;
      position: absolute;
    }

    img:first-child {
      width: 75%;
    }

    img:nth-child(4) {
      animation: rotate 10s linear infinite;
    }

    img:nth-child(5) {
      animation: rotate-reverse 10s linear infinite;
    }

    img:nth-child(6) {
      animation: rotate 10s linear infinite;
    }

    img:nth-child(7) {
      animation: rotate-reverse 10s linear infinite;
    }

    img:nth-child(8) {
      animation: rotate 10s linear infinite;
    }

    img:nth-child(9) {
      animation: rotate-reverse 10s linear infinite;
    }
  }

  .wheel-self:before {
    z-index: 1111;
    background-size: cover;
    height: 505px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%) scale(0);
    width: 538px;
  }

  .wheel-title {
    position: absolute;
    width: 100%;
    top: 300px;
    text-align: center;
    animation: wheelTitle 500ms linear;

    img {
      width: 80%;
    }
  }

  .wheel-btn {
    width: max-content;
    align-items: center;
    background: linear-gradient(180deg, #c0f, #11b0ff);
    border-radius: 28px;
    box-shadow: 0 20px 70px #00a3ff8f;
    display: flex;
    font-size: 36px;
    font-weight: 600;
    justify-content: center;
    line-height: 46px;
    margin: 40px auto;
    overflow: hidden;
    padding: 26px 40px;
    position: relative
  }

  .wheel-btn:before,
  .wheel-btn:after {
    animation: highlight-btn 3s linear infinite;
    background: #FFFFFF;
    content: "";
    filter: blur(5px);
    height: 180px;
    opacity: .4;
    position: absolute;
    top: 50%;
  }

  .wheel-btn:before {
    left: 20px;
    width: 20px
  }

  .wheel-btn:after {
    left: 80px;
    width: 30px
  }

  .wheel-btn:active {
    transform: scale(0.95);
  }

  .wheel-history-btn {
    font-size: 32px;
    color: #2283f6;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .disappear-down {
    animation: disappear-down 300ms ease-out;
    transform: translateY(100%);
  }

  .start-wheel {
    animation: start-wheel 500ms ease-in;
    transform: translateY(200px);
  }

  .start-wheel-back {
    animation: start-wheel-back 500ms ease-in;
    transform: translate(-50%, -53%);
  }

  .wheel-arrow {
    width: 50px !important;
    right: 37px;
  }

  .wheel-start {
    width: 140px !important;
    border: 2px solid #010958;
    border-radius: 50%;
  }

  .wheel-start:active {
    transform: scale(0.95);
  }

  .wheel-count-down {
    height: 160px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    span {
      color: #8f9ef6;
      font-size: 28px;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 8px;
      text-align: center;
    }
  }

  .count-down-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #5e4190;

    >div {
      padding: 5px 15px;
      border-radius: 16px;
      margin: 0 16px;
      background: linear-gradient(180deg, #7c1da9, #5d32ef);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      font-weight: 700;
      letter-spacing: 3px;
      color: #FFFFFF;
    }
  }

  .wheel-history-block {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #031655 0%, #0F1420 20%);

    .wheel-history-title {
      margin-top: 80px;
      padding: 0 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      .back-btn {
        background-color: #2a3546;
        padding: 13px 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
      }

      span {
        font-size: 36px;
        color: #FFFFFF;
        margin-left: 14px;
        font-weight: 600;
      }
    }
  }

  .wheel-history-table {
    padding: 40px;

    table {
      font-size: 26px;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;

      tr {
        border-bottom: 1px solid #1c2532;
        padding: 20px 0;
      }

      tr:last-child {
        border-bottom: 0;
      }

      th {
        color: #55657e;
        text-align: left;
        padding: 20px 0;
      }

      td {
        text-align: left;
        padding: 18px 0;

        .table-cell {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
        }

        img {
          width: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }
      }
    }
  }

  .wheel-history-in {
    animation: history-in 500ms ease-in;
  }

  .wheel-history-out {
    transform: translateX(100%);
    animation: history-out 500ms ease-out;
  }

  .wheel-load {
    margin-top: 400px;
    text-align: center;
  }

  .wheel-count {
    z-index: 10;
    position: absolute;
    color: #371D00;
    font-size: 40px;
  }

  .wheel-win {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation: win-show 300ms ease-in;

    img {
      width: 100%;
    }

    .wheel-win-btn {
      position: absolute;
      bottom: 100px;
      font-size: 44px;
      background-color: #2BB31B;
      border-radius: 30px;
      padding: 20px 40px;
    }

    .wheel-win-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
      }
    }

    .wheel-win-info {
      position: absolute;
      top: 320px;
      font-size: 44px;
      border-radius: 30px;
      padding: 20px 40px;
    }
  }

  .wheel-next-text {
    width: 500px;
    font-size: 28px;
    color: #FFFFFF;
    margin: 20px;
  }

  .recharge-btn {
    background-color: #D7234A;
    color: #FFFFFF;
    padding: 20px 40px;
    border-radius: 20px;
    font-size: 36px;
  }

  @keyframes wheelBackground {
    from {
      transform: translate(-50%, -70%) scale(0);
    }

    to {
      transform: translate(-50%, -70%) scale(1)
    }
  }

  @keyframes wheelSelf {
    from {
      transform: translateY(-600px);
    }

    to {
      transform: translateY(-40px);
    }
  }

  @keyframes wheelTitle {
    from {
      transform: translateY(600px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  @keyframes rotate-reverse {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes highlight-btn {

    0%,
    80% {
      transform: translate(-150px, -50%) rotate(45deg)
    }

    to {
      transform: translate(300px, -50%) rotate(45deg)
    }
  }

  @keyframes disappear-down {
    from {
      transform: translateY(0)
    }

    to {
      transform: translateY(100%)
    }
  }

  @keyframes start-wheel {
    from {
      transform: translateY(-40px)
    }

    to {
      transform: translateY(200px)
    }
  }

  @keyframes start-wheel-back {
    from {
      transform: translate(-50%, -70%)
    }

    to {
      transform: translate(-50%, -53%)
    }
  }

  @keyframes history-in {
    from {
      transform: translateX(100%)
    }

    to {
      transform: translateX(0)
    }
  }

  @keyframes history-out {
    from {
      transform: translateX(0)
    }

    to {
      transform: translateX(100%)
    }
  }

  @keyframes win-show {
    from {
      transform: scale(0.6)
    }

    to {
      transform: scale(1)
    }
  }
</style>