<template>
  <div class="messages loadIn" v-if="isShow">
    <div class="titlebox">
      <div class="title">Notifications</div>
      <img src="../../public/image/message/close.png" alt="" @click.self="toCloseAll">
    </div>
    <div class="tab">
      <div class="options1" :class="activeIndex === index ? 'activeClass' : ''" v-for="(item, index) in btn"
        :key="index" @click="change_status(item, index)">
        <div class="optionItem">{{ item.name }}</div>
        <div class="num">{{ index === 0 ? promoteList.length : messageList.length }}</div>
      </div>
    </div>
    <div v-for="(item) in messageList" class="message-block" :class="spliceIndex === item.id ? 'loadOut' : ''"
      v-show="activeIndex===1" :style="{backgroundColor: item.just ? '#1EB83E20' : '#12212E'}">
      <div v-show="readyDelIndex === item.id" :class="readyDelIndex === item.id ? 'imageFilter' : ''"></div>
      <img class="message-head" :src="item.image" alt="image">
      <div class="message-context">
        <div class="message-context-title">{{ item.title }}</div>
        <div class="message-context-text" v-html="item.content"></div>
        <div class="toMark">
          <div class="text">Lido</div>
          <div class="choose" :class="item.status === 2 ? 'choose_active' : ''" @click="readMessage(item.id)"></div>
        </div>
      </div>
      <van-loading class="splice-loading" color="#1989fa" v-show="readyDelIndex === item.id" />
      <img v-show="readyDelIndex !== item.id" @click="closeMessage(item.id)" class="message-close"
        src="../../public/image/message/close.png" alt="" />
    </div>
    <!--平台消息-->
    <div v-show="activeIndex===0" class="platform_block" v-for="(item, index) in promoteList">
      <div class="top">
        <div class="left">
          <div class="time">{{transformTime(item.create_at)}}</div>
          <div class="title">
            {{item.title}}
          </div>
        </div>
        <div class="tips" v-show="isToday(item.create_at)">NEW</div>
      </div>
      <div class="imgbox">
        <img :src="item.banner" alt="">
      </div>
      <div class="wrap">
        <div class="desc" ref="exp" :class="expandList[index] ? 'showAll' : ''">
          <div class="btn" v-show="!expandList[index]" @click="showDesc(index)">Show all
            <img src="../../public/image/message/down.png" alt="">
          </div>
          <div class="btn" v-show="expandList[index]" @click="hideDesc(index)">Hide
            <img src="../../public/image/message/up.png" alt="">
          </div>
          {{item.description}}
        </div>
      </div>
      <div class="bar">
        <div class="left" @click="linkMore(item.id)">
          <span>Saber mais</span> <img src="../../public/image/message/arrowto.png" alt="">
        </div>
        <!--        <div class="mark">-->
        <!--          <div class="text">Lido</div>-->
        <!--          <div class="choose" :class="isChecked ? 'choose_active' : ''" @click="closeMessage(item.id)"></div>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="defaultImg" v-show="activeIndex === 0 && promoteList.length === 0">
      <img src="../../public/image/message/noContent.png" alt="">
      <p>You don't have any platform notifications</p>
    </div>
    <div class="defaultImg" v-show="activeIndex === 1 && messageList.length === 0">
      <img src="../../public/image/message/noContent.png" alt="">
      <p>You don't have any personal notifications</p>
    </div>
  </div>
</template>

<script>
  import { delMail, getMailList, getPromoteMsg, reqReadMail } from "@/api/api";
  import { mapState } from "vuex";
  import { ReceiveMessage } from "@/api/WebSocketServer";

  export default {
    props: ['message'],
    data() {
      return {
        list: [],
        spliceIndex: -1,
        readyDelIndex: -1,
        activeIndex: 0,
        isChecked: false,
        isShowAll: false,
        btn: [
          {
            name: 'Platform',
          },
          {
            name: 'Personal',
          }
        ],
        promoteList: [],
        messageList: [],
        expandList: [],
        isShow: false
      }
    },
    computed: {
      ...mapState(['messageNumber', 'isMessageShow']),
    },
    watch: {
      messageNumber(val) {
        this.$store.commit('setMessageNumber', val);
      },
      isMessageShow(val) {
        this.isShow = val
        this.$store.commit('INNERMASK', false);
      }
    },
    created() {
      this.loadPromoteMessage();
      this.loadPrivateMessage();
      this.messageListener()
    },
    methods: {
      loadPromoteMessage() {
        getPromoteMsg().then(res => {
          this.promoteList = res.list;
          if (!this.promoteList) this.promoteList = [];

        }).catch(() => { })
      },
      loadPrivateMessage() {
        getMailList({
          pageNo: 1,
          pageSize: 100
        }).then(res => {
          this.$store.commit('setMessageNumber', res.list.length);
          this.messageList = res.list;
        }).catch(() => { })
      },
      closeMessage(id) {
        this.readyDelIndex = id;
        delMail({
          mail_id: id
        }).then(res => {
          this.spliceIndex = id;
          setTimeout(() => {
            this.messageList.map((item, index) => {
              if (item.id === id) {
                this.messageList.splice(index, 1);
                this.$store.commit('setMessageNumber', this.messageList.length);
                this.spliceIndex = -1;
              }
            })
          }, 250)
        }).catch(() => {
          this.readyDelIndex = -1;
        })
      },
      change_status(status, index) {
        this.activeIndex = index
      },
      toCloseAll() {
        // this.$emit('closeAll', false)
        this.$store.commit('setMessageShow', false);
      },
      showDesc(index) {
        this.$set(this.expandList, index, true);
      },
      hideDesc(index) {
        this.$set(this.expandList, index, false);
      },
      linkMore(id) {
        this.$router.push({ name: 'messageChild', params: { id: id } });

        this.$store.commit('INNERMASK', false);
        this.toCloseAll();
      },
      readMessage(id) {
        reqReadMail({
          mail_id: id
        }).then(res => {
          this.loadPrivateMessage();
        }).catch(() => { })
      },
      transformTime(timeStamp) {
        let time = new Date(timeStamp);

        return time.getFullYear() + '/' + (time.getMonth() + 1) + '/' + time.getDate() + '  '
          + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes())
          + '  ' + (time.getHours() <= 12 ? 'Am' : 'PM');
      },
      isToday(timeStamp) {
        let time = new Date(timeStamp);
        let toDay = new Date();

        return time.toDateString() === toDay.toDateString()
      },
      messageListener() {

        ReceiveMessage('EmailNotify', (res) => {
          this.$store.commit('setMessageNumber', this.messageNumber + 1);
          res.msg.just = true;
          this.messageList.unshift(res.msg);
        })
      }
    }
  }
</script>

<style scoped lang="less">
a{
  color: #3B4AD6;
}
  .messages {
    width: 100vw;
    height: calc(100vh - 100px);
    position: fixed;
    top: 100px;
    left: 0;
    overflow: scroll;
    background: #08111C;
    z-index: 10;
  }

  .message-head {
    width: 163px;
    height: 210px;
    margin: 7px 19px 1px 4px;
    color: #FFFFFF;
    font-size: 24px;
  }

  .message-close {
    width: 24px;
    height: 25px;
    position: absolute;
    top: 19px;
    right: 19px;
  }

  .message-block {
    position: relative;
    background-color: #12212E;
    margin: 20px 37px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }

  .message-context {
    font-size: 26px;
    color: #000;
    padding: 38px;
    box-sizing: border-box;
    width: 100%;
  }

  .message-context-title {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }

  .imageFilter {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.6;
  }

  .splice-loading {
    margin: 0 40px;
  }

  .message-context-text {
    color: #85A5CC;
    font-size: 20px;
    overflow: hidden;

  }

  .loadIn {
    animation: messageLoadIn 0.3s ease-in-out;
  }

  .loadOut {
    animation: messageLoadOut 0.3s ease-in-out;
  }

  @keyframes messageLoadIn {
    from {
      transform: translateX(100vw)
    }

    to {
      transform: translateX(0vw)
    }
  }

  @keyframes messageLoadOut {
    from {
      transform: translateX(0vw)
    }

    to {
      transform: translateX(-100vw)
    }
  }

  .titlebox {
    width: 100%;
    height: 81px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 36px;
    box-sizing: border-box;
    background: #162939;

    .title {
      font-size: 30px;
      color: #FFFFFF;
    }


    img {
      width: 30px;
      height: 31px;
    }
  }

  .tab {
    width: 619px;
    display: flex;
    justify-content: center;
    margin: 42px auto 37px;
    background: url('../../public/image/message/gray_bg.png') no-repeat;
    background-size: 100% 100%;

  }

  .options1 {
    width: 50%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8798AE;

    .optionItem {
      font-size: 25px;
      font-weight: bold;

    }

    .num {
      width: 45px;
      height: 45px;
      padding: 5px 10px;
      box-sizing: border-box;
      color: #12212E;
      background: url('../../public/image/message/gray_round.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 14px;
      font-weight: bold;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .activeClass {
    width: 50%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../public/image/message/blue_bg.png') no-repeat;
    background-size: 100% 100%;
    color: #fff;

    .num {
      background: url('../../public/image/message/white_round.png') no-repeat;
      background-size: 100% 100%;
      color: #0888FF;

    }
  }

  .toMark {
    margin-top: 21px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .text {
      font-size: 20px;
      color: #8594A7;
    }

    .choose {
      width: 17px;
      height: 17px;
      background: url('../../public/image/message/unchoosed.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 15px;
    }

    .choose_active {
      width: 17px;
      height: 17px;
      //background: url('../../public/image/message/choosed.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 15px;
      background-color: green;
      border-radius: 50%;
    }
  }

  .defaultImg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 277px;
      height: 277px;
    }

    p {
      font-size: 18px;
      color: #7F8FA4;
      line-height: 24px;
    }
  }

  .platform_block {
    position: relative;
    background-color: #12212E;
    margin: 20px 37px;
    border-radius: 25px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 26px 20px 20px 36px;
      box-sizing: border-box;

      .left {
        .time {
          color: #8594A7;
          font-size: 16px;
        }

        .title {
          font-size: 25px;
          font-weight: bold;
          color: #FFFFFF;
          margin-top: 15px;
        }
      }

      .tips {
        width: 105px;
        height: 53px;
        background: url('../../public/image/message/tips_bg.png') no-repeat;
        background-size: 100% 100%;
        font-size: 24px;
        font-weight: bold;
        padding: 15px 25px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .imgbox {
      text-align: center;

      img {
        width: 618px;
        //height: 194px;
      }
    }

    .wrap {
      display: flex;

      .desc {
        width: 581px;
        margin: 0 auto;
        font-size: 18px;
        color: #85A5CC;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        .btn {
          font-size: 18px;
          color: #FFFFFF;
          float: right;
          clear: both;

          img {
            width: 21px;
            height: 19px;
            margin-left: 5px;
            margin-top: 10px;
          }
        }

        &::before {
          content: '';
          float: right;
          width: 0;
          height: 100%;
          margin-bottom: -30px;
        }
      }
    }

  }

  .showAll {
    -webkit-line-clamp: 999 !important;
  }

  .bar {
    width: 100%;
    height: 65px;
    margin-top: 21px;
    background: #0E1A25;
    border-radius: 20px;
    box-shadow: 0 0 8px 0 #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 24px;
      font-weight: bold;
      color: #1C98FF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 28px;

      img {
        width: 19px;
        height: 21px;
        margin-left: 24px;
      }
    }

    .mark {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 49px;

      .text {
        font-size: 20px;
        color: #8594A7;
      }

      .choose {
        width: 17px;
        height: 17px;
        background: url('../../public/image/message/unchoosed.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 15px;
      }

      .choose_active {
        width: 17px;
        height: 17px;
        //background: url('../../public/image/message/choosed.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 15px;
        background-color: green;
        border-radius: 50%;
      }
    }
  }
</style>