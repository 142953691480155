<template>
  <div class="record-block">
    <!-- <div class="record-title">
      <img src="../../../public/image/personal/game_pages_title.png" alt="">
    </div> -->
    <!-- <Selector id="2" /> -->
    <div class="tablleBox">
      <div class="record-condition">
        <div class="record-date">
          <div @click="pickDate" :style="{width: title2 === 5 ? '108%' : '100%'}">
            {{ timestampToTime(defaultTime) }}
            <img src="../../../public/image/personal/calendar.png" alt="">
          </div>
        </div>
        <div class="select_type">
          <select class="select" v-model="title2" @change="onSelectGame(title2)">
            <option v-for="(option,index) in options2" :key="index" :value="option.id">
              {{ option.title }}
            </option>
          </select>
        </div>
        <div class="select_type" v-show="title2 === 5">
          <select class="select" v-model="internal" @change="queryData">
            <option v-for="(option,index) in internalGame" :key="index" :value="option.game_id">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="record-table">
        <div class="record-table-block">
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>GID</th>
                <th>Game</th>
                <th>Bet</th>
                <th>Pay</th>
                <th>Result</th>
                <th>Balance</th>
              </tr>
            </thead>
            <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
            </van-divider>
            <tr v-for="(item, index) in recordList" :class="index % 2 === 0 ? 'singular' : ''">
              <td>{{ timestampToTime(item.play_at) }}</td>
              <td>{{ item.game_id }}</td>
              <td>{{ item.game_name }}</td>
              <td>{{ item.currency_type === 1 ? 'BRL ' : 'F$ '}}{{ item.amount }}</td>
              <td>{{ item.currency_type === 1 ? 'BRL ' : 'F$ '}}{{ item.back }}</td>
              <td>{{ (parseFloat(item.back) - parseFloat(item.amount)).toFixed(2) }}</td>
              <td>{{ item.currency_type === 1 ? 'BRL ' : 'F$ '}}{{ item.balance }}</td>
            </tr>

          </table>
          <div v-show="show" class="noData"><img src="../../../public/image/personal/noData.png" alt=""></div>
        </div>
        <div class="record-table-page">
          <van-pagination v-model="pageNo" :page-count="pageCount" :items-per-page="perPage" prev-text="pre"
            :force-ellipses="pageCount > 3" @change="changePageNo" />

        </div>
      </div>
    </div>
    <van-calendar title="" color="#1989fa" confirm-text="confirm" v-model:show="calendarShow" :min-date="minDate"
      @confirm="onConfirm" />
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import Copyright from "@/components/Copyright.vue";
  import { gameList, getPlayRecord } from "@/api/api";
  import Selector from '@/views/personal/Selector'

  export default defineComponent({
    name: "gameRecords",
    components: { Copyright, Selector },
    data() {
      return {
        calendarShow: false,
        recordList: [],
        currentPage: 1,
        title: "Registros de jogos",
        options: [
          { id: 1, title: "Centro Pessoal" },
          { id: 2, title: "Transaction" },
          { id: 3, title: "Registros de jogos" }
        ],
        options2: [
          { id: 5, title: "Jogo interno" },
          { id: 1, title: "Crash" },
          { id: 2, title: "Pragmatic Play" },
          { id: 6, title: "Pocket Game" },
          { id: 3, title: "Live Casino" },
          { id: 4, title: "Scratch card" },
        ],
        internalGame: [
          { id: 0, title: "ALL" },
          { id: 1, title: "DICE" },
        ],
        title2: 5,
        defaultTime: new Date().setHours(0, 0, 0, 0),
        date: '',
        pageCount: 0,
        pageNo: 1,
        perPage: 10,
        gameChannel: '5',
        minDate: new Date(2023, 0, 1),
        maxDate: new Date(2023, 12, 31),
        showPage: 1,
        total: 0,
        gameId: '0',
        show: false,
        internal: 0
      }
    },
    created() {
      this.queryData();
      this.queryInternalGame();
    },
    methods: {
      queryData() {
        let p = {}
        p.date = this.date ? this.formatDateToStr(this.date) : this.formatDateToStr(this.defaultTime);
        p.pageSize = this.perPage;
        p.pageNo = this.pageNo;
        p.gameChannel = this.gameChannel;
        if (this.gameChannel === '5') {
          p.gameId = this.internal === 0 ? '' : this.internal
        } else {
          p.gameId = this.gameChannel === '0' ? '10425' : '';
        }
        this.show = false;
        this.recordList = [];

        p.gameChannel = parseInt(p.gameChannel);
        // p.gameId = parseInt(p.gameId);
        getPlayRecord(p).then((res) => {
          if (res.total === 0) {
            this.show = true
          }
          this.recordList = res.list;
          if (res.total < 10) {
            this.pageCount = 1
          } else if ((res.total % this.perPage) !== 0) {
            this.pageCount = Math.floor((res.total / this.perPage)) + 1
          } else {
            this.pageCount = (res.total / this.perPage)
          }
        }).catch(() => { })
      },
      queryInternalGame() {
        gameList({
          pageNo: 1,
          pageSize: 100,
          channel: 5
        }).then(res => {
          this.internalGame = res.list;
          this.internalGame.unshift({
            id: 0,
            game_id: 0,
            name: 'ALL'
          })
        }).catch(() => { })
      },
      pickDate() {
        this.calendarShow = true;
      },
      onConfirm(val) {
        this.calendarShow = false;
        this.show = false;
        this.defaultTime = val
        this.date = new Date(val).getTime();
        this.queryData()
      },
      changePageNo(pageNo) {
        this.queryData(Math.floor(pageNo))
      },
      formatDateToStr(str) {
        if (!str) return str;
        const date = new Date(str);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return year + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day)
      },
      timestampToTime(timestamp) {
        timestamp = timestamp ? timestamp : null;
        let date = new Date(timestamp);
        let Y = date.getFullYear() + '/';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D;
      },
      onSelectGame(evt) {
        switch (evt) {
          case 1:
            this.gameChannel = '0'
            break;
          case 2:
            this.gameChannel = '1'
            break;
          case 3:
            this.gameChannel = '2'
            break;
          case 4:
            this.gameChannel = '3'
            break;
          case 5:
            this.gameChannel = '5'
            break;
          case 6:
            this.gameChannel = '4'
            break;
          default:
            break;
        }
        this.date = this.date ? this.date : this.defaultTime
        this.queryData()
      }
    }
  })
</script>

<style scoped lang="less">
  table {
    color: #fff;
    font-size: 25px;
    width: 180%;
    border-collapse: collapse;


    tr {
      height: 80px;
      padding: 0 20px;
    }

    /* tr:not(:first-child) {
      background: #141F2D;
    } */

    th {
      text-align: left;
      padding-left: 40px;
      font-weight: normal;
    }

    td {
      height: 20px;
      padding-left: 40px;

    }
  }

  .record-table-block {
    width: 100%;
    overflow: scroll;
    min-height: 500px;
  }

  .record-block {
    /* background: url('../../../public/image/personal/gameRecordBg.png') no-repeat;
    background-size: 100% 100%;
    height: 100vh; */
  }

  .record-title {
    width: 100%;
    text-align: center;
    padding-top: 50px;
    margin-bottom: 20px;

    img {
      width: 607px;
      /* height: 67px; */
    }
  }

  .selectedBar {
    background-color: #54B949 !important;
  }

  .record-date {
    /* margin: 20px 0; */
    font-size: 25px;
    background: #fff;
    border-radius: 24px;
    padding: 20px;
    box-sizing: border-box;
    width: 330px;
    font-weight: bold;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      box-sizing: border-box;
    }

    img {
      width: 30px;
    }
  }

  .record-table {
    background: url('../../../public/image/personal/tableBg.png') no-repeat !important;
    background-size: 100% 100% !important;
    padding: 20px 0;
    box-sizing: border-box;
    margin-top: 47px;
  }

  .record-table-head {
    color: #5E7A94;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    padding: 10px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #5E7A94;
    margin-bottom: 20px;
    text-align: center;
  }

  .record-table-tr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    color: #7C99BE;
    font-size: 12px;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .singular {
    background-color: #2D4052;
  }

  .record-table-page {
    padding: 40px 0;
  }

  .selectBox {
    width: calc(100% - 48px);
    background: #2F7FB9;
    border-radius: 15px;
    margin-top: 49px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    padding: 20px 18px 20px 25px;

    img {
      width: 39px;
      height: 22px;
    }
  }

  .record-condition {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  .table_name,
  .table_bet,
  .table_play,
  .table_result,
  .table_balance {
    flex: 2;
    text-align: center;
  }

  .table_time,
  .table_gid {
    flex: 3;
    text-align: center;
  }

  ::v-deep .van-pagination__item {
    width: 10px !important;
    height: 32px !important;
    background: #131F2D;
    color: #fff;
    margin: 10px;
    border-radius: 10px;


  }

  ::v-deep .van-pagination__prev {
    min-width: 40px !important;
  }

  ::v-deep .van-pagination__next,
  .van-pagination__prev {
    min-width: 40px !important;
  }

  ::v-deep .van-pagination__item::after {
    border: 0 !important;
  }

  ::v-deep .van-pagination__item--active {
    color: #fff;
    background-color: #3180BA !important;
  }

  ::v-deep .van-calendar__confirm {
    height: 60px !important;
    line-height: 60px !important;
  }

  .select_type {
    height: 70px;
    background: #fff;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 25px !important;
    margin-left: 10px;
    font-weight: bold;

    img {
      width: 47px;
      height: 41px;
    }

    select {
      height: 70px;
      background: #fff;
      color: #000;
      font-size: 25px !important;
      margin-top: 0;
      font-weight: bold;

      option {
        width: 600px !important;
        background: #fff;
        position: absolute;
        /* left: -10px; */
        font-size: 25px !important;
        font-weight: bold;

      }
    }
  }

  .noData {
    width: 100%;
    height: 100%;
    min-height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 116px;
      height: 119px;
      position: absolute;
    }
  }

  .tablleBox {
    width: 681px;
    /* background: url('../../../public/image/personal/bd.png') no-repeat;
    background-size: 100% 100%; */
    margin: 41px auto;

    thead {
      width: 80%;
      margin: 0 auto;
    }
  }

  ::v-deep .van-divider {
    width: calc(100% - 100px);
    height: 2px;
    border-color: #fff !important;
    background: #fff !important;
    color: #fff !important;
    position: absolute;
    left: 30px;
    margin: 0 auto;
  }
</style>