<template>
    <div class="container">
        <div style="background-color: rgb(11, 19, 29)">
            <div class="banner" v-if="channel.open_recharge">
                <div style="width: 100%">
                    <van-swipe
                        class="my-swipe"
                        :show-indicators="true"
                        :autoplay="3000"
                        indicator-color="#717070"
                    >
                        <van-swipe-item
                            v-for="(item, index) in banners"
                            :key="item.index"
                            @click="clickBanner(item.fun)"
                            :class="gameLoading ? 'imageFilter' : ''"
                        >
                            <img class="swipe-image" :src="item.img" alt />
                        </van-swipe-item>
                    </van-swipe>
                </div>
            </div>
            <BriefMenu />
            <GameModule />
            <!--      <Jopos/>-->
            <Ranking />
            <Domain />
            <HomePagePopUpLogic />
            <!-- <HomePagePopUpLogicNew /> -->
            <DownLoadAppTips />
        </div>
        <div class="hover-icon">
            <div v-show="isShowLimitBtn">
                <img @click="openDeposito" :src="IMAGE_URL + 'common/cheerGift.webp'" alt />
                <img :src="IMAGE_URL + 'common/kkli.png'" alt />
                <van-count-down
                    v-show="false"
                    @finish="finished_4"
                    :time="limitTime_4"
                    format="DD 天 HH 时 mm 分 ss 秒"
                    style="color: white;"
                />
            </div>
            <div v-show="bankruptcyBtnStatus">
                <img @click="openBankruptcy" :src="IMAGE_URL + 'common/cashback.webp'" alt />
                <van-count-down
                    v-show="false"
                    @finish="finished_6"
                    :time="limitTime_6"
                    format="DD 天 HH 时 mm 分 ss 秒"
                    style="color: white;"
                />
            </div>
            <div class="seguro">
                <img @click="openSeguro" :src="IMAGE_URL + 'common/seguro.webp'" alt />
            </div>
            <div class="vipchallenge">
                <img @click="openVipChallenge" :src="IMAGE_URL + 'common/viptiaozhan.webp'" alt />
                <div
                    v-show="userData.challenge_status==0||userData.challenge_status==1"
                    class="vip-reddot"
                ></div>
            </div>
            <!-- <img @click="openSignIn" :src="IMAGE_URL + 'common/hover-recharge.png'" alt />
            <img @click="openTigerSlot" :src="IMAGE_URL + 'common/hover-tiger-icon.png'" alt />-->
        </div>
    </div>
</template>
<script>
import Ads from "../../components/Ads.vue";
import Jopos from "@/components/Jopos.vue";
import ReisFull from "../../components/Reis.vue";
import Ranking from "../../components/Ranking.vue";
import Copyright from "../../components/Copyright.vue";
import TaskPopUp from "@/components/TaskPopUp.vue";
import BriefMenu from "@/views/home/briefMenu.vue";
import Domain from "../../components/Domain.vue";

import { mapState } from "vuex";
import { buryingLog } from "@/api/PromotionInterFace";
import { userMonthCardInfo, getUserPack } from "@/api/api";
import DownLoadAppTips from "@/components/downLoadAppTips.vue";
import HomePagePopUpLogic from "@/components/HomePagePopUpLogic.vue";
// import HomePagePopUpLogicNew from "@/components/HomePagePopUpLogicNew.vue";
import GameModule from "@/views/home/gameModule.vue";
import { openWvByChannel, dotGTag } from "@/native/native"

export default {
    name: "home",
    components: {
        GameModule,
        HomePagePopUpLogic,
        // HomePagePopUpLogicNew,
        DownLoadAppTips,
        TaskPopUp,
        Ads,
        Jopos,
        ReisFull,
        Ranking,
        Copyright,
        BriefMenu,
        Domain,
    },
    computed: {
        ...mapState([
            "userData",
            "channel",
            "isLoading",
            "token",
            "config",
            "innerMask",
            "paySuccess",
            "paySuccessType",
            "bankruptcyBtnStatus"
        ]),
    },
    data() {
        return {
            banners: [],
            isReady: false,
            swipePopTips: 0,
            showSignReward: false,
            gameLoading: false,
            isShowPass: false,
            isCherrShow: false,
            isSpeedShow: false,
            limitTime_4: 0,
            limitTime_6: 0,
            isShowLimitBtn: true,
        };
    },
    watch: {
        paySuccessType(val) {
            if (val == 4) {
                this.isShowLimitBtn = false;
            }
            if (val == 6) {
                this.$store.commit('setBankruptcyBtnStatus', false);
            }
        },
        token(val) {
            if (val) {
                this.getLimitTime();
            }
        },
        // '$route'(to, from) {
        //     // 当前页面发生变化时触发
        //     console.log('当前页面改变了', to, from);
        // }
    },
    created() {
        if (this.token) {
            this.getLimitTime();
        }
    },
    methods: {
        openVipChallenge() {
            if (this.token) {
                dotGTag('vip_up_join_index_fixed', {})
                this.$store.commit('setVipChallenge', true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        openSeguro() {
            if (this.token) {
                this.$router.push('/safe')
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        finished_4() {
            if (this.token) {
                this.isShowLimitBtn = false;
            }
        },
        finished_6() {
            if (this.token) {
                this.$store.commit('setBankruptcyBtnStatus', false);
            }
        },
        openBankruptcy() {
            if (this.token) {
                this.$store.commit('setBankruptcyShow', true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        getLimitTime() {
            console.log("首页获取活动配置")
            getUserPack()
                .then(res => {
                    console.log("首页活动配置===>", res)
                    this.$store.commit('setBankruptcyBtnStatus', false);
                    this.isShowLimitBtn = false;

                    if (res && res.length != 0) {
                        res.map((item) => {
                            if (item.pack_type === 4) {
                                if (!item.has_recharge) {
                                    this.limitTime_4 = (item.expired_at - (new Date().getTime()));
                                    this.isShowLimitBtn = true;
                                }
                            }
                            if (item.pack_type === 6) {
                                if (!item.has_recharge) {
                                    this.limitTime_6 = (item.expired_at - (new Date().getTime()));
                                    this.$store.commit('setBankruptcyBtnStatus', true);
                                }
                            }
                        });
                    }
                })
        },
        clickBanner(funstr) {
            switch (funstr) {
                case '充值':
                    this.token ? this.$router.push("/recharge") : this.showLogin();
                    break;
                case '跳转tg':
                    dotGTag('click_tg_join', { 'method': 'banner' })
                    openWvByChannel('https://t.me/rico13777');
                    break;
                case 'share':
                    this.token ? this.$router.push("/share") : this.showLogin();
                    break;
                case 'vip':
                    this.token ? this.$router.push("/vip") : this.showLogin();
                    break;
                case 'bonus':
                    this.token ? this.$router.push("/bonus") : this.showLogin();
                    break;
                case 'tournament':
                    this.token ? this.$router.push("/tournament") : this.showLogin();
                    break;
                case 'vip挑战':
                    dotGTag('vip_up_join_index', {})
                    this.token ? this.$store.commit('setVipChallenge', true) : this.showLogin();
                    break;
            }
        },
        toRegister() {
            this.$store.commit("setMenuBlockShow", false);
            this.$store.commit("IN_OR_UP", "up");
            this.$store.commit("MASKCONTENT", "login");
            this.$store.commit("MASK", true);
            this.$emit("headerTop");
        },
        loadBanner() {
            this.banners = [
                { img: this.IMAGE_URL + "home/promo7.webp", fun: 'vip挑战' },
                { img: this.IMAGE_URL + "home/promo1.png", fun: '充值' },
                { img: this.IMAGE_URL + "home/promo6.png", fun: '跳转tg' },
                { img: this.IMAGE_URL + "home/promo2.png", fun: 'share' },
                { img: this.IMAGE_URL + "home/promo3.png", fun: 'vip' },
                { img: this.IMAGE_URL + "home/promo4.png", fun: 'bonus' },
                { img: this.IMAGE_URL + "home/promo5.png", fun: 'tournament' },
            ];
        },
        showLogin() {
            this.$store.commit("IN_OR_UP", "in");
            this.$store.commit("MASKCONTENT", "login");
            this.$store.commit("MASK", true);
        },
        openDeposito() {
            if (this.token) {
                this.$store.commit('setLimitRechangeShow', { show: true, userClick: true });
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        openSignIn() {
            if (this.token) {
                this.$store.commit("setSignInByDay", true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        openTigerSlot() {
            if (this.token) {
                this.$store.commit("setSlotsTigerShow", true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        opencheerPop() {
            if (this.token) {
                this.$store.commit("setCheerPopShow", true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
        openspeedUpPop() {
            if (this.token) {
                this.$store.commit("setSpeedUpPopShow", true);
            } else {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
            }
        },
    },
    mounted() {
        buryingLog({
            event_name: "EVENT_CONTENT_VIEW",
            properties: JSON.stringify({
                content_name: "index",
                content_type: "product",
                content_id: "index",
            }),
        });
        setTimeout(() => {
            this.loadBanner();
            // this.$store.commit("setSafeBoxShow", true);
            // this.$store.commit("setBankruptcyShow", true);

        }, 300);
        userMonthCardInfo()
            .then((res) => {
                if (res && res.length > 1) {
                    this.isShowPass = false;
                } else if (res && res.length === 1 && res[0].unlock === true) {
                    this.isShowPass = false;
                } else {
                    this.isShowPass = true;
                }
            })
            .catch(() => { });
    }
};
</script>
<style lang="less" scoped>
.banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-swipe {
    height: 350px;
    background: rgb(11, 19, 29);
    position: relative;
}

.swipe-image {
    width: 100%;
    height: 313px;
}

.left {
    width: 50px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 30px;
    margin-left: -30px;
}

.right {
    width: 50px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 30px;
    margin-right: -30px;
}

::v-deep .van-swipe__indicators {
    bottom: 4px !important;
}

.imageFilter {
    filter: brightness(0.2);
}

.hover-icon {
    position: fixed;
    bottom: 300px;
    right: 50px;
    display: flex;
    flex-direction: column;
    div {
        display: flex;
        flex-direction: column;
    }

    div > img:first-child {
        margin-bottom: -2px;
    }

    img {
        width: 90px;
        margin-bottom: 20px;
    }

    .vipchallenge {
        margin-top: 20px;
        position: relative;
        img {
            width: 152px;
            position: absolute;
            left: -32px;
        }
        div {
            width: 15px;
            height: 15px;
            background: red;
            border-radius: 50%;
            position: absolute;
            right: 0px;
        }
    }
}
</style>