var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { openWvByChannel, dotGTag } from "@/native/native";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { getGamePlayUrl, bigWin } from "@/api/api";
import { SplitGamePosterUrl } from "@/utis/common";
import GameCard from "@/components/GameCard.vue";
export default defineComponent({
    name: "briefMenu",
    components: {
        GameCard: GameCard,
    },
    data: function () {
        return {
            bj_list: [],
            animate: false,
            computedWRData: [],
        };
    },
    methods: {
        SplitGamePosterUrl: SplitGamePosterUrl,
        bounsClick: function () {
            dotGTag("click_tg_join", { method: "small_banner" });
            openWvByChannel("https://t.me/rico13777");
            // window.open("https://t.me/rico13777");
        },
        goRedGroup: function () {
            var _this = this;
            if (!localStorage.getItem("token")) {
                this.$store.commit("IN_OR_UP", "in");
                this.$store.commit("MASKCONTENT", "login");
                this.$store.commit("MASK", true);
                return;
            }
            this.$store.dispatch("showNetWaiting", true);
            dotGTag("join_mine", {});
            getGamePlayUrl({
                gameId: "10548",
                isTail: false,
            })
                .then(function (res) {
                _this.$store.dispatch("showNetWaiting", false);
                if (res.valid) {
                    _this.$store.commit("setNotAllowGame", true);
                }
                else {
                    window.location.href = res.url;
                }
            })
                .catch(function (err) { });
        },
        scroll: function () {
            var _this = this;
            this.animate = true;
            setTimeout(function () {
                _this.computedWRData.push(_this.computedWRData[0]);
                _this.computedWRData.shift();
                _this.animate = false;
            }, 500);
        },
        cardClick: function (c) {
            console.log(c);
        },
    },
    computed: __assign({}, mapState(["token", "json"])),
    created: function () {
        var _this = this;
        bigWin()
            .then(function (res) {
            var sourceArray = res.List;
            var targetArray = [];
            for (var i = 0; i < sourceArray.length; i += 3) {
                var chunk = sourceArray.slice(i, i + 3);
                targetArray.push(chunk);
            }
            _this.computedWRData = targetArray;
            console.error(_this.computedWRData);
        })
            .catch(function (err) { });
    },
    mounted: function () {
        // setInterval(this.scroll, 5000);
    },
});
