<template>
  <div class="jopos">
    <div class="card_top">
      <h3>Pragmatic Play</h3>
      <div style="width: 100%;margin-top: 20px;background-color: #11212D;padding: 20px 10px;border-radius: 10px;">
        <GameSelector id="3" @searchEvent="searchGameHandler" search="true" />
      </div>
    </div>

    <div class="contenArry" style="margin-top: 30px;">
      <div class="content_list">
        <div v-for="(item, index) in gameList" class="listItem">
          <img :class="playIndex === item.game_id ? 'imageFilter' : ''" :src="SplitGamePosterUrl(item)"
            @click="readyPlay(item.game_id)" alt="">

          <div class="game-maintenance" v-show="item.active_status === 1">
            <img src="../../../../public/image/common/game-maintenance.png" alt="">
            <span>Em manutenção</span>
          </div>

          <img v-show="playIndex === item.game_id" @click="doPlay" class="play-btn"
            src="../../../../public/image/common/playBtn.png" alt="">
          <span v-show="playIndex === item.game_id" class="top_name">{{ item.name }}</span>
          <span v-show="playIndex === item.game_id" class="bottom_types">
            {{ item.channel === 1 ? 'PragmaticPlay' : (item.channel === 2 ? 'Pocket Games' : '') }}
          </span>
        </div>
        <div class="load-btn-block" v-show="!isSearch">
          <div class="search_progress">
            <div class="progress_bar_block">
              <div id="progress_bar">
                <div id="progress_back" :style="{width:gameList? (calcPercent(gameList.length,total)* 100 + '%'):0}">
                </div>
              </div>
            </div>
            <div class="words">
              Exposição {{ gameList.length }} /{{ total }} Registro
            </div>
          </div>
          <div class="load-more" @click="loadMorePgGames" :class="isLoadingMore ? 'loadBtnClass' : ''"
            v-show="haveLoadPage">
            <div class="bonus-loading" v-show="isLoadingMore"></div>
            <span v-show="!isLoadingMore">Carregue mais</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { gameList, getGamePlayUrl } from "@/api/api";
  import FreePlay from "@/components/FreePlay.vue";
  import { SplitGamePosterUrl } from "@/utis/common";
  import GameSelector from "@/components/GameSelector.vue";

  export default {
    components: { GameSelector, FreePlay },
    data() {
      return {
        gameList: [],
        loadPage: false,
        gameUrl: '',
        playIndex: -1,
        pageNo: 1,
        total: 0,
        ensureGameId: '',
        isSearch: false,
        isLoadingMore: false,
        haveLoadPage: false,
        gameValid: false
      }
    },
    created() {
      this.getGameList()
    },
    methods: {
      SplitGamePosterUrl,
      getGameList() {
        this.isLoadingMore = true
        gameList({
          pageNo: this.pageNo,
          pageSize: 9,
          channel: 1
        }).then(res => {
          if (res) {
            this.total = res.total;
            this.gameList = this.gameList.concat(res.list)
            this.haveLoadPage = this.gameList.length < res.total;
            this.isLoadingMore = false
          }
        })
      },
      searchGameHandler(list) {
        this.isSearch = true;
        this.gameList = list;
      },
      doPlay() {
        if (this.ensureGameId === this.playIndex) {
          if (this.gameValid) {
            this.$store.commit('setNotAllowGame', true);
            return
          }
          this.$router.push({ name: 'play', params: { url: this.gameUrl } })
        }
      },
      readyPlay(gameId) {
        if (!localStorage.getItem('token')) {
          this.$store.commit('IN_OR_UP', 'in')
          this.$store.commit('MASKCONTENT', 'login')
          this.$store.commit('MASK', true)
          return
        }
        this.playIndex = gameId
        getGamePlayUrl({
          gameId: gameId,
          isTail: false
        }).then((res) => {
          this.gameUrl = res.url;
          this.ensureGameId = gameId;
          this.gameValid = res.valid;
          if (this.gameValid) {
            this.$store.commit('setNotAllowGame', true);
          }
        }).catch(() => {
          this.playIndex = -1
        })
      },
      loadMorePgGames() {
        this.pageNo++;
        this.getGameList();
      },
      calcPercent(val, max) {
        if ((val / max) > 1) {
          return 1
        } else return val / max;
      }
    },
  }
</script>

<style lang="less" scoped>
  .jopos {
    color: #fff;
    /* background: #14202D; */
    border-radius: 40px;

    h3 {
      font-size: 65px;
      color: #fff;
      text-align: center;
    }
  }

  .imageFilter {
    filter: brightness(0.3);
  }

  .contenArry {
    margin-top: 60px;
    min-height: 900px;
  }

  .contenArry_top {
    display: flex;
    justify-content: space-between;
  }

  .play-btn {
    width: 100px !important;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    animation: play-scale 200ms ease-in;
  }

  .top_name {
    display: block;
    width: 110px !important;
    position: absolute;
    left: calc(50% - 55px);
    top: 10px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #94BDE3;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .bottom_types {
    display: block;
    width: 100% !important;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #94BDE3;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }

  @keyframes play-scale {
    from {
      transform: scale(1.5)
    }

    to {
      transform: scale(1)
    }
  }

  .content_left {
    background: #213547;
    border-radius: 17px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;

    img {
      width: 43px;
      height: 42px;
      margin-right: 15px;
    }

    span {
      font-size: 25px;
      color: #94BDE3;
    }
  }

  .content_rigth {
    background: #213547;
    border-radius: 17px;
    opacity: 1;
    font-size: 25px;
    color: #94BDE3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 40px;
  }

  .content_list {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;

    .listItem:not(:last-child) {
      margin: 10px;
      border-radius: 20px;
      position: relative;
      width: 30%;
      min-height: 240px;
      background-color: #213547;
    }

    img {
      width: 100%;
      border-radius: 20px;
      vertical-align: middle;
    }
  }

  .card_top {
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 65px;
      color: #fff;
      text-align: center;
    }

    .select_rect {
      width: 632px;
      height: 82px;
      background: #11212D;
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 3px solid #FFFFFF;
      display: flex;
      align-items: center;
      margin-top: 50px;
      padding-right: 20px;
      padding-left: 10px;

      img {
        width: 47px;
        height: 41px;
        margin-right: 20px;
      }

      select {
        width: 700px;
        height: 82px;
        background: #11212D;
        color: white;
        font-size: 32px !important;
        letter-spacing: 1px;
      }
    }
  }

  .content_search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;

    img {
      width: 60px;
      height: 60px;
      margin-left: 40px
    }

    input {
      width: 82%;
      border-radius: 16px;
      font-size: 32px;
      padding: 20px;
      color: black;
    }
  }

  .load-more {
    background-color: #3771E4;
    border-radius: 20px;
    font-size: 30px;
    font-weight: bold;
    width: 300px;
    height: 80px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .load-btn-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .bonus-loading {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #A1C5F3;
    animation: spin 800ms linear infinite;
    width: 40px;
    height: 40px;
  }

  .words {
    font-size: 25px;
    margin-top: 35px;
    font-weight: bold;
    text-align: center;
  }

  .loadBtnClass {
    filter: brightness(0.7);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .progress_bar_block {
    margin-top: 15px;
  }

  #progress_bar {
    width: 100%;
    height: 4px;
    background: linear-gradient(0deg, #DFE9F3 0%, #FFFFFF 100%);
    border-radius: 2px;
    margin-top: 80px;

    div {
      width: 350px;
      background: #3771E4;
      height: 4px;
      text-align: center;
      border-radius: 2px;

      p {
        width: 350px;
        height: 15px;
        font-size: 20px;
        /* font-family: Arial, serif; */
        font-weight: 900;
        color: #2B2B2B;
      }
    }
  }

  .game-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0;

    img {
      width: 60px;
      margin-bottom: 20px;
    }

    span {
      font-size: 24px;
      color: #94BDE3;
      font-weight: 700;
    }
  }
</style>