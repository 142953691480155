<template>
    <div class="active-mask-block" v-if="showMask">
        <div class="active-block" v-show="show">
            <div class="active-close-block" v-show="closeIcon">
                <img src="../../public/image/purse/close.png" alt="close" @click.stop="closePoster">
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
            <div class="btns">
                <slot name="btns"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['closeIcon'],
        data() {
            return {
                show: true,
                showMask: true,
            }
        },
        methods: {
            closePoster() {
                this.show = false;
                this.showMask = false;
            },
        },
    }
</script>
<style lang="less" scoped>
    .active-mask-block {
        width: 100%;
        height: 100vh;
        background: rgba(2, 2, 2, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
        color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .active-block {
            position: relative;
            width: 632px;
            padding: 0px 50px;
            box-sizing: border-box;
            background: url('../../public/image/purse/popupBg.png') no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            box-sizing: border-box;
            animation: eject-pop 0.3s ease-in-out;
        }




        .active-close-block {
            position: absolute;
            top: 10px;
            right: 39px;

            img {
                width: 30px;
            }
        }

        .content {
            color: #FFFFFF;
            font-weight: 300;
            font-size: 21px;
            margin-top: 100px;
            text-align: center;
        }

        .btns {
            width: 417px;
            height: 67px;
            font-size: 21px;
            color: #FFFFFF;
            font-weight: 600;
            background: url('../../public/image/purse/blueBtn.png') no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 47px auto;
        }

    }


    @keyframes eject-pop {
        from {
            transform: scale(0.6)
        }

        to {
            transform: scale(1)
        }
    }
</style>