<template>
    <div id="app" class="app-box" ref="app">
        <HeadBar />
        <div class="fixed-height">
            <keep-alive :include="['home', 'vip']">
                <router-view v-show="!isLoading" />
            </keep-alive>
            <div v-show="isLoading" class="loading">
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
            <Copyright />
        </div>
        <TabBar />
        <Toast />
        <SlotsTiger />
        <WheelReward />
        <RedBag />
        <!-- <Purse /> -->
        <!-- <NetWork />-->
        <GamePrompt />
        <NotAllowGamingTips />
        <SignReward />
        <PersonalEnter />
        <TaskPopUp />
        <CheerPopUp />

        <BetTaskPopUp />
        <GameRechargeActivity />

        <div class="innerMask login-mask no-padding" @touchmove.prevent v-if="mask">
            <LoginPages v-if="maskContent === 'login'" />
        </div>
        <van-popup
            class="menu-style"
            v-model="menuShow"
            position="left"
            :style="{ width:'100%',height: '100%'}"
            z-index="15"
            closeable
            close-icon="cross"
            @closed="menuClosed"
            @opened="menuOpened"
        >
            <Menu ref="childRef" />
        </van-popup>
        <SiteMessage />
        <payforPage />
        <van-popup
            class="menu-style-cashback"
            v-model="cashbackShow"
            position="right"
            :style="{ width:'100%',height: '100%'}"
            z-index="15"
        >
            <CashbackIndex />
        </van-popup>

        <van-popup
            class="menu-style-cashback"
            v-model="dazhuanpanShow"
            position="right"
            :style="{ width:'100%',height: '100%' }"
            z-index="15"
        >
            <DaZhuanPanIndex />
        </van-popup>

        <van-popup
            class="menu-style-cashback"
            v-model="tgShow"
            position="right"
            :style="{ width:'100%',height: '100%' }"
            z-index="15"
            @closed="tgClosed"
            @open="tgOpen"
        >
            <TgReward v-if="tgRender" />
        </van-popup>

        <van-popup
            class="menu-style-cashback"
            v-model="vipChallengeShow"
            position="right"
            :style="{ width:'100%',height: '100%' }"
            z-index="15"
            @closed="vipChallengeClosed"
            @open="vipChallengeOpen"
        >
            <VipChallenge v-if="vipChallengeRender" />
        </van-popup>

        <NetWaiting />
        <ExtraCashback />
        <BankruptcyRechange />
        <HomePagePopUpLogicNew />
        <PopupLogic />
    </div>
</template>
<script>
import TabBar from "@/components/Tabbar.vue";
import HeadBar from '@/components/Headbar.vue'
import LoginPages from '@/components/LoginPages.vue'
import PersonalEnter from '@/views/personal/PersonalEnter'
import Menu from '@/components/Menu'
import { mapState } from 'vuex';
import Copyright from "@/components/Copyright.vue";
// import Recharge from "@/components/Recharge.vue";
import SignReward from "@/components/SignReward.vue";
import WheelReward from "@/components/WheelReward.vue";
import store from "@/store";
import Toast from "@/components/Toast.vue";
import Slots from "@/views/game/gameTypes/Slots.vue";
import SlotsTiger from "@/components/SlotsTiger.vue";
import RedBag from '@/components/Redbag'
import NetWork from "@/components/NetWork.vue";
import Purse from "@/components/Purse.vue";
import GamePrompt from "@/components/GeneralPromptTem1.vue";
import NotAllowGamingTips from "@/views/game/notAllowGamingTips.vue";
import TaskPopUp from "@/components/TaskPopUp.vue";
import BetTaskPopUp from "@/views/bonus/betTaskPopUp.vue";
import CheerPopUp from '@/views/recharge/CheerPopUp';
import SiteMessage from '@/components/SiteMessage';
import payforPage from '@/views/recharge/payforPage';
import GameRechargeActivity from "@/views/bonus/gameRechargeActivity.vue";
import { setKeepScreenOnByChannel, setOrientationByChannel, getAfIdByChannel } from '@/native/native';
import CashbackIndex from "@/views/cashback/cashbackIndex.vue";
import { getTokenBySuid, claimTgRewards, getTgRedPackLink } from "@/api/api";
import NetWaiting from "@/components/NetWaiting.vue";
import DaZhuanPanIndex from "@/views/dazhuanpan/dazhuanpanIndex.vue";
import ExtraCashback from "@/components/ExtraCashback.vue";
import HomePagePopUpLogicNew from "@/components/HomePagePopUpLogicNew.vue";
import BankruptcyRechange from "@/components/BankruptcyRechange.vue";
import PopupLogic from "@/components/PopupLogic.vue";
import TgReward from "@/views/tg/TgReward.vue";
import VipChallenge from "@/views/newvip/VipChallenge.vue";


export default {
    components: {
        GameRechargeActivity,
        BetTaskPopUp,
        TaskPopUp,
        GamePrompt,
        NetWork,
        Slots,
        Toast,
        SignReward,
        Copyright,
        TabBar,
        HeadBar,
        LoginPages,
        PersonalEnter,
        Menu,
        // Recharge,
        WheelReward,
        RedBag,
        SlotsTiger,
        Purse,
        NotAllowGamingTips,
        CheerPopUp,
        SiteMessage,
        payforPage,
        CashbackIndex,
        NetWaiting,
        DaZhuanPanIndex,
        ExtraCashback,
        HomePagePopUpLogicNew,
        BankruptcyRechange,
        PopupLogic,
        TgReward,
        VipChallenge,
    },
    data() {
        return {
            menuShow: false,
            cashbackShow: false,
            dazhuanpanShow: false,
            tgShow: false,
            tgRender: false,
            vipChallengeShow: false,
            vipChallengeRender: false,
        }
    },
    created() {
        if (window.NativeInterface) {
            store.commit('setClient', window.NativeInterface.getTag());
        }
    },
    computed: {
        ...mapState(['mask', 'maskContent', 'innerMask', 'isLoading',
            'menuBlockShow', 'openRecharge', 'token', 'userData',
            'wheelShow', 'client', 'showCashbackIndex', 'showDaZhuanPanIndex',
            'config', 'showTgReward', 'vipChallenge']),
    },
    watch: {
        config(val) {
            console.warn(val.invite_info)

            if (val.invite_info.cashback_rate != '' && val.invite_info.account != '' && !this.token) {
                this.$store.commit("setShowExtraCashback", {
                    show: true,
                    cashback_rate: val.invite_info.cashback_rate,
                    account: val.invite_info.account
                });
            }
        },
        showDaZhuanPanIndex: {
            handler(val) {
                this.dazhuanpanShow = val;
            },
            immediate: true
        },
        menuBlockShow(val) {
            if (!val) {
                this.menuShow = false
            } else {
                this.$store.commit('INNERMASK', false);
                this.menuShow = val
            }
        },
        showCashbackIndex(val) {
            this.cashbackShow = val
        },
        showTgReward(val) {
            this.tgShow = val;
        },
        token() {
            // this.loadCustomerService();
        },
        innerMask(a, b) {
            if (a) {
                this.stopScroll()
            } else {
                this.canScroll()
            }
        },
        userData(val) {
            console.log('用户信息====>', val);

            let reward = localStorage.getItem('reward');
            console.log(typeof reward);
            if (reward && reward != '') {
                if (!this.token) {
                    this.$store.commit("IN_OR_UP", "in");
                    this.$store.commit("MASKCONTENT", "login");
                    this.$store.commit("MASK", true);
                    return;
                }
                // 如果没有领取过奖励，则跳转到领取页面
                // if (!val.tg_today_is_reward) {
                //     this.$store.commit("setShowTgReward", true);
                // }
                this.$store.commit("setShowTgReward", true);
            }
            // 红包邀请
            let group_no = localStorage.getItem('group_no');
            let invited_type = localStorage.getItem('invited_type');

            if (invited_type == '1' && group_no != '') {
                if (!this.token) {
                    this.$store.commit("IN_OR_UP", "in");
                    this.$store.commit("MASKCONTENT", "login");
                    this.$store.commit("MASK", true);
                    return;
                }

                window.location.href = `http://red.rico13.com/?token=${this.token}&group_no=${group_no}`;
            }
        },
        vipChallenge(val) {
            this.vipChallengeShow = val;
        }
    },
    mounted() {

        // this.$router.push('/safe')
        localStorage.setItem('inviteCode', this.$route.query.code ? this.$route.query.code : '');
        // localStorage.setItem('channel', this.$route.query.utm_source ? this.$route.query.utm_source : '');
        let lc = localStorage.getItem('channel');
        console.log("channel==>", lc);
        console.log("client==>", this.client);
        if (!lc) {
            localStorage.setItem('channel', this.$route.query.utm_source ? this.$route.query.utm_source : '');
        } else {
            if (this.$route.query.utm_source) {
                localStorage.setItem('channel', this.$route.query.utm_source);
            }
        }

        getAfIdByChannel();
        setKeepScreenOnByChannel();
        setOrientationByChannel("PORTRAIT");

        window.saveAppClickId = (id) => {
            if (id) {
                // localStorage.setItem('click_id', id);
                // localStorage.setItem('BuryingParams', 'app___android_pid');

                //uuid换取token
                getTokenBySuid({ s_uid: id })
                    .then(res => {
                        if (res && res.token) {
                            localStorage.setItem('token', res.token);
                            this.$store.dispatch('UserInfo')
                        }
                    })

                localStorage.setItem('only_uuid', id);
            }
        }
    },
    methods: {
        tgOpen() {
            this.tgRender = true;
            console.log('tgOpen');
        },
        tgClosed() {
            this.tgRender = false;
            console.log('tgClosed');
        },
        vipChallengeClosed() {
            this.vipChallengeRender = false;
        },
        vipChallengeOpen() {
            console.log('vipChallengeOpen');
            this.vipChallengeRender = true;
        },
        loadCustomerService() {
            (function (d, s, id, w, n) {
                w.__ssc = w.__ssc || {};
                w.__ssc.license = 'fgtnic';
                if (w.ssq) return false;
                n = w.ssq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
                n.push = n; n.loaded = !0; n.queue = [];
                let js, sjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://assets.salesmartly.com/chat/widget/code/install.js';
                sjs.parentNode.insertBefore(js, sjs);

                setTimeout(() => {
                    let ss = document.getElementById('sspWidget');
                    if (ss) ss.hidden = true;
                }, 500)
            }(document, 'script', 'ss-chat', window))
        },
        toSavePage() {
            this.$router.push('/saveMoney')
        },
        openPddWheel() {
            this.$store.commit('setPurseShow', true)
        },
        menuClosed() {
            this.$store.commit("setMenuBlockShow", false);
        },
        menuOpened() {
            this.$refs.childRef.scrollTop();
        }
    },
};
</script>

<style lang="less">
html,
body {
    width: 100vw;
    overflow-x: hidden;
    background: rgb(11, 19, 29);
    -webkit-text-size-adjust: none;
    font-family: "Montserrat-VF";
}

.app-box {
    display: none;
    background-color: rgb(11, 19, 29);
    touch-action: none;
}

.mask {
    width: 100%;
    height: 100%;
    background: rgba(2, 2, 2, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99 !important;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fixed-height {
    overflow: hidden;
    overflow-y: scroll;
    min-height: calc(100vh - 240px);
}

.no-padding {
    padding-top: 0 !important;
}

.innerMask {
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(2, 2, 2, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    margin-top: 14%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 10;
}

.login-mask {
    background: #0e0e0e !important;
    margin-top: 0;
    height: 100vh;
}

.innerMaskShow {
    animation: userCenterIn 0.3s ease;
}

.innerMaskHide {
    animation: userCenterOut 0.3s ease;
}

.menu-block-show {
    animation: slide-left-enter 0.3s ease;
}

.menu-block-hide {
    animation: slide-left-out 0.3s ease;
}

@keyframes userCenterIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes userCenterOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}

@keyframes slide-left-enter {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-left-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.loading {
    height: calc(100vh - 400px);
    display: flex;
    color: #ffffff;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: center;

    img {
        width: 50vw;
    }
}

.menu-block {
    height: 100%;
    overflow: scroll;
}

.menu-height {
    height: calc(100vh - 180px);
    background: rgba(2, 2, 2, 0.9);
    position: fixed;
    top: 0;
    color: #fff;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 4vh;
    z-index: 3 !important;
}

.content {
    width: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}

.close {
    width: 54px;
}

.menu-style {
    // background-color: #3a3c41 !important;
    // height: calc(100vh - 106px);
    // width: 76%;
    // border: 0;
    // padding-top: 106px;
}
.menu-style-cashback {
    background-color: #0b131d !important;
    overflow-x: hidden !important;
}

.sign-day-icon {
    display: flex;
    position: fixed;
    bottom: 300px;
    right: 46px;
    background-color: #d7234a;
    border-radius: 50%;
    padding: 8px;
    z-index: 10;
}

.sk-chase {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #a1c5f3;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {
    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }

    100%,
    0% {
        transform: scale(1);
    }
}

.saveBox {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 230px;
    right: 50px;
    z-index: 10;

    img {
        width: 100%;
    }
}

.pdd-wheel-icon {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 310px;
    right: 50px;
    z-index: 10;

    img {
        width: 100%;
    }
}
</style>