<template>
    <div class="br-mask" v-if="isShow">
        <div class="br-bg">
            <van-count-down @finish="finish" :time="time" millisecond class="br-time">
                <template v-slot="timeData">
                    <div class="hour">{{formatTime(timeData.hours) }}</div>
                    <div>:</div>
                    <div class="minute">{{formatTime(timeData.minutes) }}</div>
                    <div>:</div>
                    <div
                        class="second"
                    >{{formatTime(timeData.seconds) }}.{{String(timeData.milliseconds).padStart(3, '0').slice(0, 2) }}</div>
                </template>
            </van-count-down>
            <span class="sevoce">
                Se você perder dinheiro real enquanto joga,
                ofereceremos até 60% de Cash Back se você
                comprar este presente.
            </span>
            <div class="br-item-box">
                <div
                    :class="defaultSelect===index ?'br-item-select':'br-item'"
                    v-for="(item,index) in itemData"
                    @click="itemClick(item,index)"
                >
                    <span class="br-des1">Depósito</span>
                    <span class="br-de">{{ item.deposito }}</span>
                    <van-icon name="plus" class="br-plus" />
                    <span class="br-des2">Cashback</span>
                    <span class="br-de">{{ item.cashback }}</span>
                    <span class="br-bot">R$ {{ item.deposito }}</span>
                </div>
            </div>
            <van-icon @click="incicioBtn" class="close-panel" name="close" size="30" color="#fff" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getUserPack, userBuyPack } from "@/api/api";
export default {
    name: 'BankruptcyRechange',
    components: {},
    props: {},
    data() {
        return {
            isShow: false,
            hour: '0',
            minute: '0',
            secoud: '00',
            itemData: [],
            defaultSelect: -1,
            time: 0,
            pullupOrderBtnStates: false,
        }
    },
    watch: {
        paySuccessType(val) {
            if (val == 6) {
                console.log("破产保护支付成功===>", val);
                this.$store.commit('setBankruptcyShow', false);
                this.$store.commit('setBankruptcyBtnStatus', false);
            }
        },
        bankruptcyShow(val) {
            this.isShow = val;
            if (val && this.token) {
                getUserPack()
                    .then((res) => {
                        if (res) {
                            res.map((item) => {
                                if (item.pack_type === 6) {
                                    this.time = item.expired_at - new Date().getTime();

                                    let nr = item.need_recharge.split(",");
                                    let bs = item.bonus.split(",");

                                    this.itemData = nr.map((e, i) => {
                                        return {
                                            deposito: e,
                                            cashback: bs[i],
                                            id: item.id
                                        }
                                    })

                                    console.log(this.itemData)
                                }
                            });
                        }
                    })
                    .catch(error => {

                    })
            }
        }
    },
    computed: {
        ...mapState(["bankruptcyShow", "token", "paySuccessType"]),
        formatTime() {
            return function (time) {
                return String(time).padStart(2, '0');
            };
        }
    },
    methods: {
        finish() {
            if (this.token) {

            }
        },
        itemClick(item, index) {
            if (this.pullupOrderBtnStates) return;
            this.defaultSelect = index;

            this.pullupOrderBtnStates = true;
            console.log("拉起订单")
            this.$store.dispatch('showNetWaiting', true);
            let params = {
                id: item.id,
                channel: "cwt",
                money: item.deposito.toString(),
                pix: "master"
            };
            console.log(params)

            userBuyPack(params).then(res => {
                this.$store.dispatch('showNetWaiting', false);
                this.pullupOrderBtnStates = false;
                this.$store.commit('setPayforUrl', res)
            }).catch(() => {
                this.pullupOrderBtnStates = false;
                this.$store.dispatch('showNetWaiting', false);
            })
        },
        incicioBtn() {
            this.$emit('incicioBtn')
            this.$store.commit('setBankruptcyShow', false);
            this.$store.commit('setBankruptcyBtnStatus', true);
        },
        open() {
            this.$store.commit('setBankruptcyShow', true);
        }
    },
    created() {
    },
    mounted() {
    }
}
</script>
<style lang="less" scoped>
.br-mask {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
}
.br-bg {
    width: calc(100% - 163px);
    min-height: 802px;
    background: url("../../public/image/limitRechange/limited-rechange10.webp")
        no-repeat;
    background-size: 100% 100%;
    position: relative;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: eject-pop 0.3s ease-in-out;

}
.br-time {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 260px;
    .hour,
    .minute {
        width: 147px;
        height: 134px;
        background: rgba(0, 0, 0, 0.8);
    }
    .second {
        width: 202px;
        height: 134px;
        background: rgba(0, 0, 0, 0.8);
        text-align: left;
    }
    > div {
        border-radius: 15px;
        text-align: center;
        line-height: 134px;
        color: #87ff11;
        font-style: italic;
        font-weight: 600;
        font-size: 68px;
    }
}
.sevoce {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    color: #ffc500;
    text-align: center;
    width: 495px;
    height: 76px;
    margin: 24px 0;
    font-family: Montserrat-VF;
}
.br-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.br-item {
    width: 170px;
    height: 242px;
    background: black;
    border-radius: 20px;
    position: relative;
    border: solid 2px white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat-VF;
}
.br-item-select {
    &:extend(.br-item all);
    background: linear-gradient(to bottom, #0b111d, #01b093);
}
.br-bot {
    width: 100%;
    height: 46px;
    background-color: #fed102;
    border-radius: 0 0 20px 20px;
    position: absolute;
    bottom: 0px;
    font-size: 30.5px;
    font-weight: 600;
    text-align: center;
    line-height: 46px;
}
.br-des1 {
    color: white;
    font-size: 19px;
    margin-top: 22px;
}
.br-des2 {
    color: white;
    font-size: 19px;
}
.br-plus {
    color: #4ae431;
    font-size: 32px;
    font-weight: 700;
    margin: 5px 0;
}
.br-de {
    font-size: 35px;
    font-weight: 500;
    color: #ffffff;
}
.br-de::before {
    content: "R$ ";
    font-size: 35px;
    font-weight: 500;
    color: #ffc500;
}
.close-panel {
    position: absolute;
    bottom: -40px;
}
@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>