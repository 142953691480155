<template>
    <div class="myDiv" v-if="isShow">
        <div class="outbox">
            <div class="dest">
                <div class="dest-pos">
                    Receba
                    <span class="dest-d">{{ curtData.cashback_rate }}</span> % de extra cashback
                </div>

                <span>de {{ curtData.account }}</span>
            </div>
            <div class="dest-btn" @click="coletarBtn">COLETAR</div>
            <p
                class="dest-p"
            >HÃ¡ apenas uma chance de extra cashback e ele será retiradoautomaticamente após o vencimento.</p>
            <van-icon @click="closed" class="close-panel" name="close" size="30" color="#fff" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: '',
    components: {},
    props: {},
    data() {
        return {
            isShow: false,
            curtData: {},
        }
    },
    watch: {
        showExtraCashback: {
            handler(data) {
                console.log(data)
                this.curtData = data;
                this.isShow = data.show;
            },
            immediate: true
        }
    },
    computed: {
        ...mapState(["showExtraCashback"]),
    },
    methods: {
        coletarBtn() {
            this.$store.commit("setShowExtraCashback", false);
            this.$store.commit('IN_OR_UP', 'in');
            this.$store.commit('MASKCONTENT', 'login');
            this.$store.commit('MASK', true);
        },
        closed() {
            this.$store.commit("setShowExtraCashback", {
                show: false,
                cashback_rate: '',
                account: ''
            });
        }
    },
    created() {

    },
    mounted() {

    },
    updated() {

    },
    beforeDestroy() {
    }
}
</script>
<style lang="less" scoped>
.myDiv {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat-VF;

    .outbox {
        width: 650px;
        min-height: 698px;
        border-radius: 10px;
        background: linear-gradient(#0f1623, #094456);
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: eject-pop 0.3s ease-in-out;
        padding-bottom: 10px;

        .dest {
            font-size: 30px;
            color: #ffce18;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 150px;
            .dest-pos {
                margin-bottom: 30px;
            }
            .dest-d {
                color: #ff3230;
            }
        }
        .dest-btn {
            width: 527px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: #ffc500;
            border-radius: 10px;
            font-size: 24px;
            color: #2e0000;
            font-weight: 800;
            margin-top: 157px;
        }
        .dest-p {
            font-size: 18px;
            color: #8c9eb1;
            width: 470px;
            margin-top: 57px;
            line-height: 36px;
        }

        .close-panel {
            position: absolute;
            bottom: -100px;
        }
    }
}

@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>