<template>
    <div class="container">
        <div class="container-top" v-if="!token">
            <div class="menu-icon" @click="openMenu">
                <van-icon name="bars" size="30" color="#fff" />
            </div>
            <div class="logo" @click="toHome">
                <img src="../../public/image/home/logo.png" alt />
            </div>
            <div class="btn_box">
                <button class="singIn" @click="signIn">ENTRAR</button>
                <button class="singUp" @click="signUp">REGISTRAR</button>
            </div>
        </div>
        <div class="container_top_logined" v-if="token">
            <div class="menu-icon" @click="openMenu">
                <van-icon name="bars" size="30" color="#fff" />
            </div>
            <img @click="toHome" src="../../public/image/home/logo.png" alt class="logo" />
            <div class="balance">
                <div class="coins" @click="changeCoinType">
                    <div v-show="coinType === 1">
                        <!-- <img src="../../public/image/headerImg/R$_pics.png" alt="" class="preimg"> -->
                        <span style="color: #2bb31b; font-weight: bold">BRL</span>
                        <span>{{ AmountFixed(amountMoney) }}</span>
                    </div>
                    <div v-show="coinType === 2">
                        <!-- <img src="../../public/image/headerImg/free-coin.png" alt="" class="preimg"> -->
                        <span style="color: #fff; font-weight: bold">Rico Coin</span>

                        <span>{{ AmountFixed(userData ? userData.free_coin : "") }}</span>
                    </div>
                    <van-icon
                        class="coin-type-icon"
                        :name="ShowCoinTypeBlock ? 'arrow-up' : 'arrow-down'"
                    />

                    <div class="coin-type-load" v-show="coinType === -1">
                        <van-loading type="spinner" size="20" color="#1989fa" />
                    </div>
                </div>
                <div class="win-money" v-show="winMoney">+ {{ winMoney }}</div>
            </div>
            <div class="rechargeBtn" @click="toRecharge">Depósito</div>
        </div>

        <div class="pay-success-block" v-if="paySuccess">
            <div class="pay-close-block">
                <img
                    src="../../public/image/common/close.png"
                    alt="close"
                    @click="paySuccess = false"
                />
            </div>
            <div class="pay-success">Sucesso no processamento</div>
        </div>

        <div
            class="coin-mask-block"
            v-show="ShowCoinTypeBlock"
            @click.stop="ShowCoinTypeBlock = false"
        >
            <div class="coin-block">
                <div @click.stop="selectCoinType(1)">
                    <!-- <img src="../../public/image/headerImg/R$_pics.png" alt="" class="preimg"> -->
                    <span style="color: #2bb31b; font-weight: bold">BRL</span>
                    <span>{{ AmountFixed(amountMoney) }}</span>
                </div>
                <div @click.stop="selectCoinType(2)">
                    <!-- <img src="../../public/image/headerImg/free-coin.png" alt="" class="preimg"> -->
                    <span style="color: #fff; font-weight: bold">Rico Coin</span>
                    <span>{{ AmountFixed(userData ? userData.free_coin : "") }}</span>
                </div>
            </div>
        </div>

        <div class="pay-success-block" v-show="ShowFreeCoinIntroduce">
            <div class="introduce-block">
                <div class="introduce-block-title">Sobre a Free Coin</div>
                <div class="introduce-block-context">
                    <div class="introduce-preface">
                        FreeCoin (F$) é a moeda exclusiva da Jogos, F$ pode ser obtido
                        através de recargas e ganhos em jogos, e F$ pode ser convertido em
                        BRL
                    </div>
                    <p>
                        1.Após cada recarga bem-sucedida, você receberá uma quantidade de
                        Free Coin equivalente ao valor que você recarregou.
                        <br />
                        <br />2.Free Coin só pode ser usada em 'jogos internos' e em 'Design
                        JILI'.
                        <br />
                        <br />
                        <span style="font-weight: 700; color: #2bb31b">
                            3.A cada {{ userData.FLR }} Free Coin, você pode trocar por 1 BRL.
                            O valor total acumulado em BRL não pode exceder o valor total das
                            suas recargas.
                        </span>
                    </p>
                    <div class="introduce-check">
                        <van-checkbox
                            v-model="isReadFreeCoinIntroduce"
                            shape="square"
                        >Não me mostre hoje</van-checkbox>
                    </div>
                    <div class="introduce-btn" @click="closeIntroduce">
                        <img src="../../public/image/bonus/freeEndBtn.png" alt />
                        <span>Entendi</span>
                    </div>
                </div>
            </div>
        </div>
        <SiteMessage :showState="isShowMessage" @closeAll="toCloseAll" />
    </div>
</template>
<script>
import SiteMessage from "@/components/SiteMessage";
import { mapState, mapGetters } from "vuex";
import { AmountFixed, generateUUID } from "@/utis/common";
import {
    changeBetType,
    getChannel,
    getVipChallengeDetail,
    getMailList,
} from "@/api/api";
import { buryingLog } from "@/api/PromotionInterFace";
import WSRequest, { ReceiveMessage } from "@/api/WebSocketServer";
import store from "@/store";
import { dotGTag ,dotKwai} from '@/native/native';
import { Dialog } from 'vant';

export default {
    components: { SiteMessage },
    data() {
        return {
            personal_show: false,
            userToken: "",
            isFold: false,
            paySuccess: false,
            coinType: 1,
            ShowCoinTypeBlock: false,
            ShowFreeCoinIntroduce: false,
            isReadFreeCoinIntroduce: false,
            isShowMessage: false,
            messageList: [],
        };
    },
    created() {
        this.getCurrentChannel();
        this.$store.dispatch("UserInfo");
        if (localStorage.getItem("token")) {
            this.$store.commit("setToken", localStorage.getItem("token"));
        }
        this.$router.beforeEach((to, from, next) => {
            if (from.name === "play") this.$store.dispatch("UserInfo");
            next();
        });
        this.eventPay();
        this.userInfoListener();
        // this.getMessageList();
    },
    watch: {
        innerMask(val) {
            this.isFold = val;
        },
        userData(val) {
            if (val) {
                this.$store.commit(
                    "setAmountMoney",
                    parseFloat(val.balance) + parseFloat(val.z_balance) + parseFloat(val.withdraw ? val.withdraw : 0)
                );
                if (val["bet_coin_type"]) this.coinType = val["bet_coin_type"];
            }
        },
        token(val) {
            this.userToken = val;
        },
    },
    computed: {
        ...mapState([
            "userData",
            "innerMask",
            "token",
            "menuBlockShow",
            "winMoney",
            "amountMoney",
        ]),
        ...mapGetters(["getUserData", "getToken"]),
    },
    methods: {
        AmountFixed,
        toHome() {
            this.$router.push("/");
        },
        signIn() {
            this.$store.commit("setMenuBlockShow", false);
            this.$store.commit("IN_OR_UP", "in");
            this.$store.commit("MASKCONTENT", "login");
            this.$store.commit("MASK", true);
        },
        signUp() {
            this.$store.commit("setMenuBlockShow", false);
            this.$store.commit("IN_OR_UP", "up");
            this.$store.commit("MASKCONTENT", "login");
            this.$store.commit("MASK", true);
            this.$emit("headerTop");
        },
        toRecharge() {
            // this.$store.commit('INNERMASK', false);
            // this.$store.commit('setMenuBlockShow', false);
            // this.$store.commit('setOpenRecharge', !this.openRecharge)
            this.$router.push("/recharge");
        },
        openPersonal() {
            this.$store.commit("setOpenRecharge", false);
            this.$store.commit("setMenuBlockShow", false);
            this.isShowMessage = false;
            this.$store.commit("INNERMASK", !this.innerMask);
        },
        openMenu() {
            this.$store.commit('setMessageShow', false);
            this.$store.commit("setMenuBlockShow", true);
        },
        toCloseAll() {
            this.isShowMessage = false;
        },
        loadShowMessage() {
            this.$store.commit("INNERMASK", false);
            this.$store.commit("setMenuBlockShow", false);
            this.$store.commit("setOpenRecharge", false);
            this.isShowMessage = true;
        },
        getCurrentChannel() {
            // 请求渠道
            getChannel()
                .then((res) => {
                    clearInterval(msgTimer);
                    if (res) this.$store.commit("setChannel", res);
                    // this.$store.dispatch("onLoading", false);
                    document.getElementById("init-loading").style.display = "none";
                    document.getElementById("app").style.display = "block";
                })
                .catch((err) => { });
        },
        eventPay() {
            ReceiveMessage("RechargeSuc", (res) => {
                dotGTag('purchase', { 'currency': 'BRL', 'value': res.amount, 'transaction_id': res.orderId });
                dotKwai('purchase',{'currency': 'BRL', 'value': res.amount })

                this.$store.commit("setOpenRecharge", false);
                this.paySuccess = true;
                console.log("RechargeSuc===>", res)
                this.$store.commit("setPaySuccess", res.orderId);
                this.$store.commit("setPaySuccessType", res.orderType);
                WSRequest.sendMessage({
                    Name: "RechargeAck",
                    Msg: { orderId: res.orderId },
                });
                this.$store.dispatch("UserInfo");

                buryingLog({
                    event_name: res.isFirst ? "EVENT_FIRST_DEPOSIT" : "EVENT_PURCHASE",
                    properties: JSON.stringify({
                        currency: "BRL",
                        value: res.amount,
                    }),
                    fb_event_name: "Purchase",
                    fb_custom_data: {
                        currency: "BRL",
                        value: res.amount,
                    },
                });
            });

            ReceiveMessage("WithdrawReqFail", (res) => {
                Dialog.alert({
                    type: 'danger',
                    title: 'Falha na retirada',
                    message: res.fail_msg + "",
                    confirmButtonText: 'Tente Novamente',
                }).then(() => {
                    // on close
                });
            });
        },
        userInfoListener() {
            ReceiveMessage("UserInfo", (res) => {
                if (res) {
                    this.$store.commit("setAmountMoney", res.balance);
                    if (res["bet_coin_type"]) this.coinType = res["bet_coin_type"];

                    localStorage.setItem("userData", res ? JSON.stringify(res) : "");
                    store.commit("USERDATA", res);
                }
            });
        },
        changeCoinType() {
            this.ShowCoinTypeBlock = !this.ShowCoinTypeBlock;
            this.$store.commit("setOpenRecharge", false);
            this.$store.commit("INNERMASK", false);
        },
        selectCoinType(val) {
            this.coinType = -1;
            changeBetType({
                bet_coin_type: val,
            })
                .then(() => {
                    this.coinType = val;
                    this.ShowCoinTypeBlock = false;
                    if (val === 2) this.whetherShowFreeCoinIntroduce();
                })
                .catch(() => { });
        },
        closeIntroduce() {
            if (this.isReadFreeCoinIntroduce) {
                const now = new Date().getTime();
                localStorage.setItem("ReadFreeCoinIntroduce", now.toString());
            }
            setTimeout(() => {
                this.ShowFreeCoinIntroduce = false;
            }, 200);
        },
        whetherShowFreeCoinIntroduce() {
            const time = parseInt(localStorage.getItem("ReadFreeCoinIntroduce"));
            this.isReadFreeCoinIntroduce = false;

            if (time) {
                const now = new Date().getTime();
                const day = 1000 * 60 * 60 * 24;
                if (time + day < now) {
                    this.ShowFreeCoinIntroduce = true;
                }
            } else {
                this.ShowFreeCoinIntroduce = true;
            }
        },
        getMessageList() {
            getMailList({
                pageNo: 1,
                pageSize: 100,
            })
                .then((res) => {
                    this.$store.commit("setMessageNumber", res.list.length);
                    this.messageList = res.list;
                })
                .catch(() => { });
        },
        checkShowDaZhuanPanPopup(res) {
            const lastPopupDate = localStorage.getItem('lastPopupDate');
            const isPopVip = localStorage.getItem('isPopVip');

            const currentDate = new Date().toLocaleDateString();

            console.log('currentDate===>', currentDate)
            console.log('lastPopupDate===>', lastPopupDate)
            console.log('isPopVip===>', isPopVip)
            console.log('challenge_status===>', res.status)

            if (!lastPopupDate || lastPopupDate !== currentDate || isPopVip !== currentDate) {
                console.log("每日大转盘弹窗")
                let vip_lv = this.userData.vip_lv;

                this.$store.state.isemit = false;

                //VIP大于1 并且没弹出过VIP弹窗并且 没参加过vip挑战
                if (vip_lv > 1 && isPopVip !== currentDate && res.status == 0) {
                    this.$store.commit("setVipChallengePop", true);
                    // vippop不在弹出
                    localStorage.setItem('isPopVip', currentDate);
                } else {
                    if (lastPopupDate !== currentDate) {
                        this.$store.commit("setShowDaZhuanPanPop", true);
                        // 更新最后一次弹出日期
                        localStorage.setItem('lastPopupDate', currentDate);
                    }
                }
            }
        }
    },
    mounted() {
        if (this.token) {
            getVipChallengeDetail().then((res) => {
                this.checkShowDaZhuanPanPopup(res);
            })
        }
    }
};
</script>
<style lang="less" scoped>
.container {
    width: 750px;
    height: 100px;

    &-top {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #14202d;
        padding: 10px 0;
        position: fixed;
        top: 0;
        z-index: 10;

        .logo {
            width: 265px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .btn_box {
            display: flex;
            justify-content: flex-end;
            width: 400px;

            button {
                border: none;
                margin-right: 20px;
            }

            .singIn {
                height: 65px;
                background: #273547;
                border-radius: 14px;
                opacity: 1;
                font-size: 24px;
                font-weight: bold;
                color: #9fb9dd;
            }

            .singUp {
                height: 65px;
                background: #d7234a;
                border-radius: 14px;
                opacity: 1;
                font-size: 24px;
                font-weight: bold;
                color: #fff;
            }
        }
    }

    .container_top_logined {
        width: calc(100vw - 40px);
        height: 106px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #14202d;
        position: fixed;
        top: 0;
        z-index: 12;
        padding: 0 20px;

        .logo {
            width: 220px;
            height: 34px;
            margin-right: 10px;
            margin-left: 12px;
        }

        .balance {
            position: relative;
            flex: 1;
            height: 65px;
            background: #1e3048;
            border-radius: 14px;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            font-size: 25px;
            font-weight: bold;
            color: #152131;
            line-height: 87px;
            padding: 0 60px 0 10px;
            box-sizing: border-box;
            margin-right: 10px;

            img.preimg {
                width: 36px;
                height: 36px;
                margin-left: 10px;
                margin-right: 10px;
            }

            .wallet {
                width: 86px;
                height: 66px;
                background: url("../../public/image/headerImg/red_bg.png")
                    no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: -2px;

                img {
                    width: 30px;
                    height: 27px;
                }
            }
        }

        .rechargeBtn {
            width: 122px;
            height: 66px;
            background: #d7234a;
            border-radius: 14px;
            color: #ffffff;
            font-weight: 500;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .avatarBox {
            display: flex;
            align-items: center;
            background: url("../../public/image/headerImg/graybg1.png")
                no-repeat;
            background-size: 100% 100%;
            margin-left: 2px;
            padding: 10px 15px;
            box-sizing: border-box;
        }

        .avatar {
            width: 66px;
            height: 66px;
            background: #ffffff;
            opacity: 1;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 21px;
            display: flex;
            justify-content: center;
            align-items: center;

            .avatar_pics {
                width: 66px;
                height: 66px;
            }
        }

        .select {
            width: 22px;
            height: 13px;
            background: url("../../public/image/headerImg/arrow2.png") no-repeat;
            background-size: 100% 100%;
            position: relative;

            div {
                width: 30px;
                height: 30px;
                color: #fff;
                position: absolute;
                top: -26px;
                right: -40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.win-money {
    color: #2bb31b;
    position: absolute;
    left: 140px;
    top: 40px;
    animation: load-in 0.6s ease-in;
}

.pay-success-block {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.6);
    position: fixed;
    z-index: 4000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .pay-success {
        background-color: #49a143;
        border-radius: 40px;
        padding: 100px 50px;
        font-size: 36px;
        width: 80vw;
        text-align: center;
        animation: eject-pop 0.3s ease-in-out;
        margin: 40px 0;
    }

    .pay-close-block {
        width: 90vw;
        text-align: right;
    }
}

.coin-mask-block {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0);
    position: fixed;
    z-index: 6;
    color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .coin-block {
        width: calc(100% - 40px);
        height: max-content;
        margin: 120px 20px 20px 20px;
        border-radius: 20px;
        background: linear-gradient(#0556a7 10%, #213648 70%);
        box-shadow: 0 0 20px 6px #323233;

        > div {
            width: calc(100% - 80px);
            margin: 20px;
            padding: 20px;
            background-color: #12212e;
            font-size: 30px;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
        }

        img {
            width: 60px;
        }
    }
}

.coins {
    position: relative;
    height: 100%;
    width: 100%;

    > div {
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;

        span {
            margin: 0 auto;
            color: #fff;
            /* width: 60%; */
        }
    }
}

.coin-type-load {
    margin-left: 15px;
}

.coin-type-icon {
    position: absolute;
    right: -45px;
    top: 20px;
    color: #92acd6;
    font-weight: bold;
}

.introduce-block {
    width: 80%;
    height: 1000px;
    padding: 40px;
    position: relative;
    background: linear-gradient(180deg, #031655 0%, #0f1420 20%);
    border-radius: 50px;

    img {
        width: 100%;
    }

    .introduce-block-title {
        position: absolute;
        top: 100px;
        font-size: 40px;
        width: 90%;
        text-align: center;
    }

    .introduce-block-context {
        position: absolute;
        top: 200px;
        font-size: 26px;
        padding: 0 20px;

        .introduce-preface {
            line-height: 35px;
            padding-right: 20px;
        }

        p {
            font-size: 24px;
            color: #86a7d8;
            margin-top: 40px;
            line-height: 30px;
            height: 440px;
            background: #01050a;
            padding: 40px;
            border-radius: 20px;
            width: 80%;
        }

        .introduce-check {
            padding: 26px 0;
            color: #ffffff;
        }

        .introduce-btn {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 93%;

            span {
                position: absolute;
                font-size: 34px;
            }

            :active {
                transform: scale(0.95);
            }
        }
    }
}

@keyframes load-in {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-40px);
    }
}

@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}

.openSelect {
    transform: rotate(180deg);
    transition: all 0.3s ease;
}

.closeSelect {
    transform: rotate(0deg);
    transition: all 0.3s ease;
}

::v-deep .van-checkbox__label {
    color: #ffffff;
}

.msgbox {
    width: 77px;
    height: 66px;
    background: url("../../public/image/headerImg/graybg2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 40px;
    box-sizing: border-box;

    img {
        width: 34px;
        height: 40px;
    }
}

::v-deep .van-badge {
    border: none;
    position: absolute;
    top: 0px;
    right: -6px;
}
</style>