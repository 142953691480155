<template>
    <div>
        <div class="active-mask-block" v-show="purseShow">
            <div class="active-close-block">
                <img src="../../public/image/purse/close.png" alt="close" @click.stop="close">
            </div>
            <div class="active-block">
                <div class="monney">
                    <img src="../../public/image/purse/coins.png" alt="">
                    <span>{{ amount }}</span>
                </div>
                <div class="submit" @click="showPop">
                    Saque
                </div>
            </div>
        </div>
        <PursePopup v-show="PursePopupShow" :times="times" :amount="amount" :info="info"/>
    </div>
</template>
<script>
    import PursePopup from '@/components/PursePopup';
    import { mapState } from 'vuex';
    import {reqUserPddInfo} from "@/api/api";
    export default {
        components: {
            PursePopup
        },
        data() {
            return {
                amount: 0.00,
                times: 0,
                info: null
            }
        },
        watch: {
            purseShow(val) {
                if (val) {
                    reqUserPddInfo().then(res => {
                        this.amount = res.current_amount;
                        this.times = res.remain_count;
                        this.info = res;
                    }).catch(err => {})
                }
            }
        },
        methods: {
            showPop() {
                this.$store.commit('setPursePopupShow', true)
                this.$store.commit('setPurseShow', false)
            },
            close() {
                this.$store.commit('setPurseShow', false)
            }
        },
        computed: {
            ...mapState([
                'PursePopupShow', 'purseShow'
            ]),
        },
    }
</script>
<style lang="less" scoped>
    .active-mask-block {
        width: 100%;
        height: 100vh;
        background: rgba(2, 2, 2, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
        color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .active-block {
            width: 487px;
            height: 688px;
            position: relative;
            animation: eject-pop 0.3s ease-in-out;
            background: url('../../public/image/purse/purseImg.png') no-repeat;
            background-size: 100% 100%;
        }

        .monney {
            position: absolute;
            bottom: 180px;
            left: 41px;
            right: 39px;
            display: flex;

            img {
                width: 83px;
                height: 81px;
                margin-right: 25px;
            }

            span {
                color: #22D247;
                font-weight: bold;
                font-size: 83px;
                text-shadow: 0px 4px 15px rgba(108, 43, 0, 0.56);
            }
        }

        .submit {
            width: 260px;
            height: 100px;
            position: absolute;
            bottom: 50px;
            left: 50%;
            margin-left: -130px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 31px;
            background: url('../../public/image/purse/btn.png') no-repeat;
            background-size: 100% 100%;
        }
    }

    .active-close-block {
        position: absolute;
        top: 130px;
        right: 60px;
        z-index: 100;

        img {
            width: 40px;
        }
    }

    .purse-disabled {
        filter: grayscale(1);
    }

    @keyframes eject-pop {
        from {
            transform: scale(0.6)
        }

        to {
            transform: scale(1)
        }
    }
</style>