import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import index from '../views/home/index'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: index,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/menu',
        name: 'menu',
        component: () => import("@/views/menu/index")
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import("@/views/vip/index")
    },
    {
        path: '/safe',
        name: 'safe',
        component: () => import("@/views/safe/SafeIndex")
    },
    {
        path: '/bonus',
        name: 'bonus',
        component: () => import("@/views/bonus/index")

    },
    {
        path: '/share',
        name: 'share',
        // component: () => import("@/views/share/index")
        component: () => import("@/views/share/newInvite/InviteIndex.vue")
    },
    // {
    //     path: '/recharge',
    //     name: 'recharge',
    //     component: () => import("@/views/wallet/WalletDialog.vue")
    // },
    {
        path: '/wallet',
        name: 'wallet',
        component: () => import("@/views/wallet/index.vue")
    },

    {
        path: '/personal',
        name: 'personal',
        component: () => import("@/views/personal/index.vue")
    },
    {
        path: '/newvip',
        name: 'newvip',
        component: () => import("@/views/newvip/newVipIndex.vue")
    },
    {
        path: '/play',
        name: 'play',
        component: () => import("@/views/game/play.vue")
    }, {
        path: '/gameList',
        component: () => import("@/views/game/gameList.vue"),
        redirect: '/gameList/populares',
        children: [{
                path: 'populares',
                name: 'populares',
                component: () => import("@/views/game/gameTypes/JogosPopulares.vue"),
            },
            {
                path: 'slots',
                name: 'slots',
                component: () => import("@/views/game/gameTypes/Slots.vue"),
            },

            {
                path: 'ppl',
                name: 'ppl',
                component: () => import("@/views/game/gameTypes/PPL.vue"),
            },
            {
                path: 'card',
                name: 'card',
                component: () => import("@/views/game/gameTypes/Card.vue"),
            },
            {
                path: 'pgGame',
                name: 'pgGame',
                component: () => import("@/views/game/gameTypes/PgGame.vue"),
            },
            {
                path: 'internal',
                name: 'internal',
                component: () => import("@/views/game/gameTypes/InternalGame.vue"),
            },
            {
                path: 'JiLi',
                name: 'JiLi',
                component: () => import("@/views/game/gameTypes/JiLi.vue"),
            },
            {
                path: 'Cq9',
                name: 'Cq9',
                component: () => import("@/views/game/gameTypes/Cq9.vue"),
            },
            {
                path: 'JiLiDesign',
                name: 'JiLiDesign',
                component: () => import("@/views/game/gameTypes/JiLiDesign.vue"),
            }
        ]
    },
    {
        path: '/gameShop/:type',
        name: 'gameShop',
        component: () => import("@/views/game/gameShop.vue")
    },
    {
        path: '/cashback',
        name: 'cashback',
        component: () => import("@/views/menu/child/cashback.vue")
    },
    {
        path: '/transactionRecords',
        name: 'transaction',
        component: () => import("@/views/record/transaction.vue")
    },
    {
        path: '/gameRecords',
        name: 'gameRecords',
        component: () => import("@/views/record/gameRecords.vue")
    },
    {
        path: '/referralsDetails',
        name: 'referralsDetails',
        component: () => import("@/views/record/referralsDetails.vue")
    },
    // KYC
    {
        path: '/kyc',
        name: 'kyc',
        component: () => import("@/views/rule/kyc.vue")
    },
    {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import("@/views/rule/privacyPolicy.vue")
    },
    {
        path: '/termsConditions',
        name: 'termsConditions',
        component: () => import("@/views/rule/terms&conditions.vue")
    },
    {
        path: '/dataRecords',
        name: 'dataRecords',
        component: () => import("@/views/share/child/dataRecords.vue")
    },
    {
        path: '/tournament',
        name: 'tournament',
        component: () => import("@/views/tournament/index.vue")
    },
    {
        path: '/seasonPass',
        name: 'seasonPass',
        component: () => import("@/views/menu/child/seasonPass")
    },
    {
        path: '/changeCode',
        name: 'changeCode',
        component: () => import("@/views/menu/child/ChangeCode.vue")
    },
    {
        path: '/messageChild/:id',
        name: 'messageChild',
        component: () => import("@/views/personal/messageChild.vue")
    },
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import("@/views/menu/child/Promotions.vue")
    },
    {
        path: '/saveMoney',
        name: 'saveMoney',
        component: () => import("@/views/save/saveMoney.vue")
    },
    {
        path: '/freeCoinsActive',
        name: 'freeCoinsActive',
        component: () => import("@/views/game/freeCoinsActive.vue")
    },
    {
        path: '/searchResultPage',
        name: 'searchResultPage',
        component: () => import("@/views/game/SearchResultPage.vue")
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: () => import("@/views/recharge/index.vue"),
        props: true // 开启props传参
    },
    {
        path: '/payforPage',
        name: 'payforPage',
        component: () => import("@/views/recharge/payforPage.vue")
    },
    {
        path: '/LimitedRechange',
        name: 'LimitedRechange',
        component: () => import("@/components/LimitedRechange.vue"),
        props: true // 开启props传参
    },
    {
        path: '/cashbackIndex',
        name: 'cashbackIndex',
        component: () => import("@/views/cashback/cashbackIndex.vue")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})

const originalRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (local) {
    return originalRouterPush.call(this, local).catch(err => err)
}

router.beforeEach((to, from, next) => {
    if (store.state.channel.banned_user && to.name === 'wallet') return
    store.dispatch('onLoading', true);
    store.commit('setMenuBlockShow', false);
    next();
});

router.afterEach((to, from, next) => {
    store.dispatch('onLoading', false);
});

export default router