<template>
    <div class="myDiv" v-if="show">
        <div class="outbox">
            <div class="nao">Não tenha medo de perder, ...</div>
            <div class="atebox">
                <span>Até</span>
                <span>R${{ 5.00 }}</span>
                <span>Seguro</span>
            </div>
            <div @click="seguroBtn" class="seguro">SEGURO</div>
            <p>
                Além do dinheiro do seguro, você também
                pode sacar até
                <span>{{ 30 }}%</span>
                de cashback fornecido pela
                Rico13 todas as semanas
            </p>
            <van-icon @click="incicioBtn" class="close-panel" name="close" size="30" color="#fff" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: '',
    components: {},
    props: {},
    data() {
        return {
            show: false,
        }
    },
    watch: {
        safeBoxShow(val) {
            this.show = val;
        }
    },
    computed: {
        ...mapState(["safeBoxShow"]),
    },
    methods: {
        incicioBtn() {
            this.$store.commit("setSafeBoxShow", false);
            this.$emit('incicioBtn');
        },
        seguroBtn() {
            this.incicioBtn();
            this.$router.push('/safe');
        },
        open() {
            this.$store.commit("setSafeBoxShow", true);
        }
    },
    created() { },
    mounted() { }
}
</script>
<style lang="less" scoped>
.myDiv {
    width: 100%;
    height: 100vh;
    background: rgba(2, 2, 2, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    .outbox {
        width: 615px;
        min-height: 698px;
        background: url("../../../public/image/limitRechange/bouns5.webp")
            no-repeat;
        background-size: 100% 100%;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: eject-pop 0.3s ease-in-out;

        .nao {
            font-size: 32px;
            color: #ffc500;
            font-weight: 600;
            font-family: Montserrat-VF;
            margin-top: 300px;
        }

        .atebox {
            width: 466px;
            height: 90px;
            background: #0204065b;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
        }
        .atebox span:nth-child(1) {
            color: #ffc500;
            font-size: 29px;
        }
        .atebox span:nth-child(2) {
            color: #ff3230;
            font-size: 43px;
            padding: 0 20px;
        }
        .atebox span:nth-child(3) {
            color: #ffc500;
            font-size: 29px;
        }

        .seguro {
            width: 527px;
            height: 80px;
            background: #ffc500;
            border-radius: 10px;
            text-align: center;
            line-height: 80px;
            margin-top: 19px;
            color: #2e0000;
            font-size: 30px;
            font-weight: 600;
        }

        .close-panel {
            position: absolute;
            bottom: -80px;
        }

        > p {
            font-size: 22px;
            width: 527px;
            text-align: center;
            color: #ffc500;
            margin-top: 29px;
            > span {
                color: #ff3230;
            }
        }
    }
}
@keyframes eject-pop {
    from {
        transform: scale(0.6);
    }

    to {
        transform: scale(1);
    }
}
</style>