<template>
    <div class="pix-confirm">
        <div class="pc-tip">
            Saque para PIX resultará emperda de
            <span>R$ {{ val }}</span>
        </div>
        <div class="pc-btn" @click="aceClick">Aceitar a perda</div>
        <div class="pc-select">
            <van-checkbox v-model="checked" @change="changeFun" checked-color="#07c160"></van-checkbox>
            <span>Não lembre novamente</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Toast } from 'vant';
export default {
    name: '',
    components: {},
    props: {
        message: {
            type: Object,
        }
    },
    data() {
        return {
            checked: localStorage.getItem('pix-confirm'),
            val: 0
        }
    },
    watch: {},
    computed: {
        ...mapState(['userData'])
    },
    methods: {
        changeFun(val) {
            console.log(val)
            if (val) {
                localStorage.setItem('pix-confirm', 'true')
            } else {
                localStorage.setItem('pix-confirm', 'false')
            }
        },
        aceClick() {
            this.$store.commit('setShowPopupPanel',
                {
                    show: false,
                    componentName: '',
                });

            if (!this.userData.bind_pix) {
                Toast('Adicione suas informações de pagamento primeiro');
                this.$router.push({ name: 'recharge', params: { tabIndex: 1 } });
                return;
            }
            this.$store.commit('setIsZhiXingTiXian', true)

        }
    },
    created() { },
    mounted() {
        console.log(this.message)
        this.val = parseFloat(this.message.qian) * (parseFloat(this.message.with_tax_rate) + parseFloat(this.message.with_extra_rate))
    }
}
</script>
<style lang="less" scoped>
.pix-confirm {
    width: 590px;
    background: #1c2532;
    border-radius: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat-VF";
    padding: 80px 50px 40px 50px;
    color: #fff;

    .pc-tip {
        font-size: 35px;
        line-height: 80px;
        font-weight: bolder;
        text-align: center;

        span {
            color: #f31e4b;
            font-size: 53px;
        }
    }

    .pc-btn {
        width: 569px;
        height: 90px;
        border-radius: 15px;
        background-image: linear-gradient(to top, #e61b4d, #ff4c2e);
        text-align: center;
        line-height: 90px;
        font-size: 32px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 130px;
    }
    .pc-select {
        display: flex;
        font-size: 29px;
        color: #748395;
        margin-top: 60px;
        :nth-child(2) {
            margin-left: 10px;
        }
    }
}
</style>