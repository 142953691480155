import { WS } from './WebSocketPackage';
import EventEmitter from 'eventemitter3';
import json from '@/ErrorCodeMap/Portugal.json';
import store from "@/store";
var event = new EventEmitter();
var baseUrl = process.env.VUE_APP_BASEURL_WS;
var WSRequest = new WS({
    wsUrl: baseUrl ? baseUrl : '',
    onConnect: function () { },
    onMessage: function (data) {
        // console.error("收到消息===>", data)
        event.emit('ReceiveMessage', data);
    },
    onClose: function () { console.error("sw close!"); },
    onError: function () { },
});
WSRequest.connect();
var seq = 10; // 请求序列号 心跳为0 从10开始
var post = function (name, params) {
    if (WSRequest.state === 2) {
        WSRequest.reconnect();
    }
    return new Promise(function (resolve, reject) {
        WSRequest.sendMessage({
            Name: name,
            Seq: seq++,
            Msg: params
        }, function (res) {
            errorHandler(res);
            if (res.code === 200) {
                resolve(res.data);
            }
            else {
                reject(res);
            }
        });
    });
};
var ReceiveMessage = function (name, callback) {
    if (!name) {
        console.error('ReceiveMessage: There must name is not found');
    }
    event.on('ReceiveMessage', function (msg) {
        if (msg.name === name && msg.code === 200) {
            callback(msg.data);
        }
        else {
            errorHandler(msg);
        }
    });
};
var deleteEvent = function (name) {
    if (!name) {
        console.error('deleteEvent: There must name is not found');
        return;
    }
    event.off(name);
};
var errorHandler = function (msg) {
    if (msg.code === 1013 || msg.code === 1018 || msg.name === 'LoginAnother') {
        localStorage.setItem('token', '');
        localStorage.setItem('userData', '');
        store.commit('USERDATA', '');
        store.commit('setToken', '');
    }
    else if (msg.code !== 200 && msg.code !== 9010) {
        store.dispatch('toast', {
            show: true,
            text: json[msg.code],
            type: 'failed'
        }).then(function () { });
    }
};
export default WSRequest;
export { event, ReceiveMessage, post, deleteEvent };
