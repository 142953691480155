const state = {
    inorup: '', //login/register
    mask: false, //is open mask
    Account_Operations: 'RECARGA',
    Authorization: '', //token
    innerMask: false,
    innerContent: '',
    maskContent: '', //App.vue mask
    choose_money: '', //recharge num
    messageNumber: 0,
    isMessageShow: false,
    winMoney: 0,
    amountMoney: 0.00,
    taskNumber: 0,
    eventBuryingClickId: '',

    WsConnect: true,
    isLoading: true,
    menuBlockShow: false,
    openRecharge: false,
    channel: {},
    token: '',
    userData: {},
    config: {},
    wheelShow: false,
    client: '',
    showSeasonPop1: false,
    toast: {},
    redBagId: 0,
    slotsTigerShow: false,
    purseShow: false,
    PursePopupShow: false,
    searchList: [],
    partMoneyGame: '',
    openCodingTask: false,
    openBetTaskList: false,
    notAllowGame: false,
    signInByDay: false,
    homePopOrder: 0,
    rechargeActiveShow: false,
    readyPopUp: false,
    cheerPopShow: false,
    speedUpPopShow: false,
    gameRechargePopup: false,
    payforUrl: {},
    playingGameName: '',
    paySuccess: '',
    paySuccessType: -1,
    afid: '',
    limitRechangeShow: false, //限时充值
    limitRechangeSource: false,
    bankruptcyShow: false, //破产保护
    bankruptcyBtnStatus: true,
    safeBoxShow: false, //保险箱
    showCashbackIndex: false,
    showNetWaiting: false,
    showDaZhuanPanPop: false, //大转盘弹窗
    showDaZhuanPanResult: false, //大转盘结束
    showDaZhuanPanIndex: false, //大转盘主页
    showExtraCashback: {
        show: false,
        cashback_rate: '',
        account: ''
    }, //邀请返现弹窗
    has_spin_count: 0,
    isemit: false,
    showPopupPanel: { //弹窗
        show: false,
        componentName: '',
        option: {},
    },
    showTgReward: false, //tg奖励
    vipData: {},
    vipRedDotData: {}, //vip 小红点
    vipChallenge: false, //vip挑战
    vipChallengePop: false, //vip挑战弹窗
    isZhiXingTiXian: false, //是否是紫星提现金额
    inviteDotData: {}, //设置邀请码小红点
    isShowPWATips: false, //是否显示PWA提示
}
export default state