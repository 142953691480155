<template>
    <div class="active-mask-block">
        <div class="active-block">
            <div class="active-close-block">
                <img src="../../public/image/purse/close.png" alt="close" @click.stop="toClosePopup">
            </div>
            <div class="outbox">
                <div class="wheel" id="wheel">
                    <img src="../../public/image/purse/win.png" alt="" class="win">
                </div>
                <div class="point">
                    <div class="text game-btn" @click="readyGo">{{residueTimes}}</div>
                </div>
            </div>
            <div class="active-text-block">
                <div class="top">Bonus: <span class="yellowText">{{residueAmount}}</span></div>
                <div class="secondTitle">Como obter mais chances de ganhar?</div>
                <div class="content">
                    <div class="smallTitle">
                        Método 1:
                    </div>
                    <p>
                        A cada convite bem-sucedido de um usuário para
                        registro e recarga, você receberá uma chance extra
                        para a loteria.
                    </p>
                </div>
                <div class="content">
                    <div class="smallTitle">
                        Método 2:
                    </div>
                    <p>
                        A cada recarga de 100 reais, você ganhará uma
                        chance para a loteria.
                    </p>
                </div>
                <div class="btnsbox">
                    <div class="btn_red" @click="tipWheel = true">Saque</div>
                    <div class="btn_green" @click="toBonus">Convidar</div>
                </div>
            </div>
        </div>
        <!-- 抽奖次数用完展示 -->
        <wheelPopup v-show="show1" :close-icon="true">
            <template #content>
                <div class="content">Você não possui tentativas de sorteio disponíveis</div>
            </template>
            <template #btns>
                <div class="btns" @click="closeByTimesOver">Receber entradas para o sorteio
                </div>
            </template>
        </wheelPopup>
        <!-- 抽奖结果 -->
        <wheelPopup v-show="show_result" :close-icon="true">
            <template #content>
                <div class="content">Acumule <span style="color: #FFD800;">R${{residueAmount}}</span> em bônus para poder retirar
                </div>
            </template>
            <template #btns>
                <div class="btns" @click="cashPrize">Obter um bônus
                </div>
            </template>
        </wheelPopup>

        <wheelPopup v-show="tipWheel" :close-icon="false">
            <template #content>
                <div class="content">Acumule <span style="color: #FFD800;">R${{info ? info.need_amount : ''}}</span> em bônus para poder retirar
                </div>
            </template>
            <template #btns>
                <div class="btns" @click="tipWheel = false">Obter um bônus
                </div>
            </template>
        </wheelPopup>
    </div>
</template>
<script>
    import wheelPopup from '@/components/wheelPopup'
    import { mapState } from 'vuex';
    import {reqUserPddReward, reqUserPddSpin, startWheelReward} from "@/api/api";
    import store from "@/store";
    export default {
        props: ['times', 'amount', 'info'],
        components: {
            wheelPopup
        },
        data() {
            return {
                show1: false,
                show_result: false,
                residueTimes: 0,
                residueAmount: 0,
                postPoneRes: {},
                submitLock: false,
                animation: null,
                lastPosition: '',
                tipWheel: false,
                testIndex: 0
            }
        },
        watch: {
            times(val) {
                this.residueTimes = val;
                this.residueAmount = this.amount;
            }
        },
        mounted() {

        },
        methods: {
            closePoster() {
                this.$store.commit('setPursePopupShow', false)
            },
            toBonus() {
                this.$store.commit('setPurseShow', false)
                this.$store.commit('setPursePopupShow', false)
                this.$router.push('/bonus')
            },
            toClosePopup() {
                this.$store.commit('setPurseShow', false)
                this.$store.commit('setPursePopupShow', false)
            },
            readyGo() {
                // this.start({
                //     amount: "99.7",
                //     count: 5,
                //     index: 2,
                //     status: this.testIndex
                // })
                //
                // this.testIndex++
                if (this.submitLock) return;
                if (this.residueTimes === 0) return;
                this.submitLock = true;

                reqUserPddSpin().then(res => {
                    this.start(res);
                }).catch(err => {})
            },
            start(res) {
                this.reStartAnimation();
                this.lastPosition = "rotate(" + ((res.index * (360 / 5))) + "deg)";

                setTimeout(() => {
                    document.getElementById('wheel').animate([
                        { transform: "rotate(0)" },
                        { transform: "rotate(" + ((4 * 360) + (res.index * (360 / 5))) + "deg)" },
                    ], {
                        duration: 6000,
                        iterations: 1,
                        fill: 'forwards',
                        easing: 'cubic-bezier(.19,-.11,.03,1.04)'
                    })

                    document.getElementById('wheel').getAnimations()[0].finished.then((animation) => {
                        this.residueTimes = res.count;
                        this.residueAmount = res.amount;

                        if (res.count === 0) {
                            this.show1 = true;
                        }
                        if (res.status === 2) {
                            this.show_result = true;
                        }
                        this.submitLock = false;
                        this.animation = animation;
                    });
                }, 600)
            },
            cashPrize() {
                reqUserPddReward().then(res => {
                    this.show_result = false;
                    this.toClosePopup();

                    this.$store.dispatch('UserInfo');
                    this.$store.dispatch('toast', 'Obter uma recompensa de' + this.residueAmount);
                }).catch(err => {

                })
            },
            closeByTimesOver() {
                this.show1 = false;
                this.toClosePopup();
                this.$router.push('/share');
            },
            reStartAnimation() {
                if (this.animation) this.animation.cancel();
                document.getElementById('wheel').animate([
                    { transform: this.lastPosition },
                    { transform: "rotate(0)" },
                ], {
                    duration: 500,
                    iterations: 1,
                    fill: 'forwards',
                })

                document.getElementById('wheel').getAnimations()[0].finished.then((animation) => {
                    animation.cancel();
                });
            }
        },
        computed: {
            ...mapState([
                'PursePopupShow'
            ]),
        },
    }
</script>
<style lang="less" scoped>
    .active-mask-block {
        width: 100%;
        height: 100vh;
        background: rgba(2, 2, 2, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
        color: #fff;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        .active-block {
            position: relative;
            width: 622px;
            background: url('../../public/image/purse/bg1.png') no-repeat;
            background-size: 100% 100%;
            padding-bottom: 57px;
            box-sizing: border-box;
            animation: eject-pop 0.3s ease-in-out;
        }

        .outbox {
            width: 507px;
            height: 499px;
            margin: 80px auto 47px;
            position: relative;
        }

        .wheel {
            width: 507px;
            height: 499px;
            background: url('../../public/image/purse/wheelBg.png') no-repeat;
            background-size: 100% 100%;
            .win {
                width: 290px;
                height: 287px;
                margin: 0 auto;
                position: absolute;
                left: 108px;
                right: 109px;
            }
        }

        .point {
            width: 169px;
            height: 217px;
            background: url('../../public/image/purse/point.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 114px;
            left: 169px;
            right: 169px;

            .text {
                width: 90px;
                height: 90px;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 56px;
                position: absolute;
                left: 38px;
                bottom: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .active-close-block {
            position: absolute;
            top: 50px;
            right: 49px;
            z-index: 6;

            img {
                width: 30px;
            }
        }


        .active-name-block {
            margin-top: -30px;
            img {
                height: 80px;
            }
        }

        .active-text-block {
            width: 80%;
            margin: 0 auto;
            .top {
                color: #FFFFFF;
                font-weight: bold;
                font-size: 42px;
                text-align: center;
                .yellowText {
                    color: #FFD200;
                }
            }

            .secondTitle {
                color: #FFFFFF;
                font-weight: bold;
                font-size: 24px;
                text-align: center;
                margin-top: 41px;
            }

            .content {
                margin-top: 15px;
                font-size: 21px;
                line-height: 28px;
                color: #fff;
                font-weight: 300;

                .smallTitle {
                    color: #A7CEFF;
                }
            }

            .btnsbox {
                margin-top: 39px;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 24px;
                display: flex;
                justify-content: space-between;

                .btn_red {
                    width: 212px;
                    height: 69px;
                    background: url('../../public/image/purse/redBtn.png') no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .btn_green {
                    width: 212px;
                    height: 69px;
                    background: url('../../public/image/purse/greenBtn.png') no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .game-btn {
        :active {
            transform: scale(0.8);
        }
    }
    @keyframes eject-pop {
        from {
            transform: scale(0.6)
        }

        to {
            transform: scale(1)
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(360deg);
        }

        to {
            transform: rotate(0deg);
        }
    }
</style>