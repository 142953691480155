<template>
    <div class="home-page-popup-logic-new">
        <LimitedRechange ref="Popup1" @incicioBtn="openNextPopup" />
        <DaZhuanPan ref="Popup2" @closed="openNextPopup" />
        <SafeBox ref="Popup3" @incicioBtn="openNextPopup" />
        <VipChllengePop ref="Popup4" @incicioBtn="openNextPopup" />
    </div>
</template>

<script>
import LimitedRechange from "@/components/LimitedRechange.vue";
import DaZhuanPan from "@/views/dazhuanpan/dazhuanpanPop.vue";
import SafeBox from "@/views/safe/SafeBox.vue";
import VipChllengePop from "@/views/newvip/VipChllengePop.vue";

export default {
    name: '',
    components: {
        LimitedRechange,
        DaZhuanPan,
        SafeBox,
        VipChllengePop
    },
    props: {},
    data() {
        return {
            popupRefs: [],
            currentPopupIndex: 0,
        }
    },
    watch: {},
    computed: {},
    methods: {
        openNextPopup() {
            // 关闭当前弹窗后打开下一个弹窗
            console.log("currentPopupIndex===>", this.currentPopupIndex)
            if (this.currentPopupIndex < this.popupRefs.length - 1) {
                this.currentPopupIndex++;
                console.log("currentPopupIndex===>", this.currentPopupIndex)
                this.popupRefs[this.currentPopupIndex].open(); // 打开下一个弹窗
            } else { //检查其他需要打开的弹窗


            }
        }
    },
    created() { },
    mounted() {
        this.popupRefs.push(this.$refs.Popup1);
        this.popupRefs.push(this.$refs.Popup2);
        this.popupRefs.push(this.$refs.Popup3);
        this.popupRefs.push(this.$refs.Popup4);

        //test
        // this.popupRefs[0].open();
        setTimeout(res => {
            // this.$store.commit('setLimitRechangeShow', { show: true, userClick: false });//弹出限时充值
        }, 2000);
    }
}
</script>
<style lang="less" scoped>
</style>