<template>
    <div v-show="isShow" class="mask-loading">
        <van-loading size="34px" vertical>Criando ordem...</van-loading>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: '',
    components: {},
    props: {},
    data() {
        return {
            isShow: false,
        }
    },
    watch: {
        showNetWaiting: {
            handler(val) {
                this.isShow = val;
            }
        },
        immediate: true
    },
    computed: {
        ...mapState(["showNetWaiting"])
    },
    methods: {},
    created() {

    },
    mounted() { }
}
</script>
<style lang="less" scoped>
.mask-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100000;
    left: 0;
    top: 0;
    background: #0b131d50;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep .van-loading {
        color: rgb(0, 255, 42);
    }
    ::v-deep .van-loading__text {
        color: rgb(0, 255, 42);
    }
}
</style>